import React, { useState } from 'react';
import { IonButton } from '@ionic/react';

import Listing from '../../interfaces/Listing';
import ListingCard from './ListingCard';
import useClasses from '../../components/styles';
import { useStoreState } from '../../store';

interface PaginatedListingCardProps {
  items: Listing[];
  favoriteListings: Listing[];
  goToListingDetail: (id: number | undefined) => void;
}

const PaginatedListingCard: React.FC<PaginatedListingCardProps> = ({
  items,
  favoriteListings,
  goToListingDetail,
}: PaginatedListingCardProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const locale = useStoreState((state) => state.translations.language);
  const classes = useClasses();

  const listing = items[currentIndex];
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.paginationButtons}>
        <div className={classes.paginationPrevButton}>
          {currentIndex !== 0 && (
            <IonButton onClick={(): void => setCurrentIndex(currentIndex - 1)}>
              Previous
            </IonButton>
          )}
        </div>
        <div className={classes.paginationLabel}>{currentIndex + 1} {locale === 'en' ? 'of' : 'de'} {items.length}</div>
        <div className={classes.paginationNextButton}>
          {currentIndex !== items.length - 1 && (
            <IonButton onClick={(): void => setCurrentIndex(currentIndex + 1)}>
              Next
            </IonButton>
          )}
        </div>
      </div>
      <div onClick={(): void => goToListingDetail(listing.id)}>
        <ListingCard
          listing={listing}
          isFavorite={
            favoriteListings.filter((fav) => fav.id === listing.id).length > 0
          }
        />
      </div>
    </div>
  );
};

export default PaginatedListingCard;
