import { action, thunk } from 'easy-peasy';

import ResetPasswordModel from '../interfaces/StoreModel/ResetPasswordModel';
import { passwordReset } from '../lib/api/services';

const model: ResetPasswordModel = {
  error: '',
  loading: false,
  passwordReset: false,
  resetEmailRequested: false,
  setPasswordReset: action((state, payload) => {
    state.passwordReset = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setResetEmailRequested: action((state, payload) => {
    state.resetEmailRequested = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  sendPasswordResetEmail: thunk(async (actions, email) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      await passwordReset.create({
        email,
      });
      actions.setResetEmailRequested(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setResetEmailRequested(false);
    }
    actions.setLoading(false);
  }),
  resetPassword: thunk(async (actions, { token, password }) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      await passwordReset.patch('', {
        token,
        password,
      });
      actions.setPasswordReset(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setPasswordReset(false);
    }
    actions.setLoading(false);
  }),
};

export default model;
