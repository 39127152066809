import React from 'react';
import { IconProps } from './Icon';

const Transit: React.FunctionComponent<IconProps> = ({ color, height, width }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 500 500"
  >
    <g fill={color}>
      <path
        id="Selection #1"
        d="M 81.00,377.00
           C 74.45,383.70 67.70,391.18 67.09,401.00
             66.21,415.43 79.04,420.92 91.00,415.78
             103.78,410.29 123.24,382.63 135.00,377.93
             140.45,375.75 143.48,376.93 149.00,377.00
             149.00,377.00 164.00,377.00 164.00,377.00
             174.70,376.94 176.87,373.36 177.00,363.00
             177.00,363.00 177.00,282.00 177.00,282.00
             176.99,276.60 177.13,269.33 171.89,266.14
             166.91,263.09 152.29,264.00 146.00,264.00
             146.00,264.00 85.00,264.00 85.00,264.00
             81.58,263.99 77.12,264.24 74.22,262.15
             68.37,257.92 70.00,240.89 70.00,234.00
             70.00,234.00 70.00,137.00 70.00,137.00
             70.01,132.49 69.81,127.81 73.39,124.43
             76.16,121.81 79.47,122.02 83.00,122.00
             83.00,122.00 110.00,122.00 110.00,122.00
             110.00,122.00 241.00,122.00 241.00,122.00
             243.43,122.00 246.75,121.87 249.00,122.74
             258.89,126.56 256.90,141.47 257.00,150.00
             257.10,157.89 258.09,162.83 267.00,163.00
             267.00,163.00 293.00,163.00 293.00,163.00
             295.77,162.99 299.44,163.29 301.69,161.40
             303.97,159.47 303.95,156.73 304.00,154.00
             304.00,154.00 304.00,132.00 304.00,132.00
             303.91,113.40 292.78,94.94 278.00,84.16
             272.00,79.78 261.43,73.98 254.00,73.04
             254.00,73.04 244.00,73.04 244.00,73.04
             244.00,73.04 222.00,72.00 222.00,72.00
             222.00,72.00 170.00,72.00 170.00,72.00
             170.00,72.00 153.00,73.00 153.00,73.00
             153.00,73.00 104.00,73.00 104.00,73.00
             94.62,73.00 70.01,73.22 62.00,76.13
             57.75,77.68 52.57,81.41 49.00,84.21
             31.54,97.91 20.11,119.52 20.00,142.00
             20.00,142.00 20.00,313.00 20.00,313.00
             20.12,322.94 25.01,338.93 30.79,347.00
             33.40,350.65 37.75,354.77 41.00,358.00
             47.97,364.93 54.72,370.00 64.00,373.53
             67.93,375.03 76.42,376.17 81.00,377.00 Z
           M 240.00,377.00
           C 240.00,377.00 407.00,377.00 407.00,377.00
             407.00,392.25 402.62,418.69 425.00,419.00
             429.67,419.06 440.12,419.58 444.00,417.69
             450.79,414.37 453.90,407.18 453.88,400.00
             453.88,400.00 453.88,366.00 453.88,366.00
             453.88,366.00 453.88,285.00 453.88,285.00
             454.00,282.13 454.05,279.87 453.88,277.00
             453.88,277.00 436.58,230.00 436.58,230.00
             436.58,230.00 424.80,199.00 424.80,199.00
             422.57,193.43 420.77,187.64 415.83,183.80
             411.08,180.11 405.70,180.01 400.00,180.00
             400.00,180.00 245.00,180.00 245.00,180.00
             240.25,180.01 235.14,179.99 231.02,182.72
             225.95,186.07 223.37,192.55 221.20,198.00
             221.20,198.00 209.58,229.00 209.58,229.00
             209.58,229.00 194.24,271.00 194.24,271.00
             193.07,275.96 193.01,278.00 193.00,283.00
             193.00,283.00 193.00,399.00 193.00,399.00
             193.02,411.86 199.72,419.93 213.00,420.00
             221.28,420.04 227.27,420.94 233.91,414.79
             236.18,412.68 238.15,409.95 239.15,407.00
             240.60,402.70 240.00,382.87 240.00,377.00 Z
           M 252.21,204.60
           C 252.21,204.60 296.00,204.00 296.00,204.00
             296.00,204.00 386.00,204.00 386.00,204.00
             389.23,204.01 392.70,203.52 394.93,206.31
             394.93,206.31 401.77,221.00 401.77,221.00
             401.77,221.00 411.58,243.00 411.58,243.00
             412.87,245.97 415.40,251.02 415.78,254.00
             416.54,260.04 412.86,265.73 407.00,267.49
             405.00,268.09 402.11,268.00 400.00,268.00
             400.00,268.00 247.00,268.00 247.00,268.00
             244.89,268.00 242.00,268.09 240.00,267.49
             234.55,265.85 229.90,259.78 230.30,254.00
             230.57,250.14 240.07,229.27 242.20,224.00
             244.37,218.64 247.64,207.71 252.21,204.60 Z
           M 405.00,288.32
           C 409.60,287.83 412.64,287.99 417.00,289.76
             438.65,298.57 431.63,332.59 407.00,330.90
             389.56,329.71 381.04,309.55 391.56,296.04
             395.18,291.38 399.43,289.59 405.00,288.32 Z
           M 233.00,289.32
           C 237.60,288.83 240.64,288.99 245.00,290.76
             266.65,299.57 259.63,333.59 235.00,331.90
             217.56,330.71 209.04,310.55 219.56,297.04
             223.18,292.38 227.43,290.59 233.00,289.32 Z
           M 84.00,290.32
           C 88.60,289.83 91.64,289.99 96.00,291.76
             117.65,300.57 110.63,334.59 86.00,332.90
             68.56,331.71 60.04,311.55 70.56,298.04
             74.18,293.38 78.43,291.59 84.00,290.32 Z"
      />
    </g>
  </svg>
);

export default Transit;
