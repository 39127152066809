/* eslint-disable max-len */
import { action, thunk } from 'easy-peasy';

import ServiceListingModel from '../interfaces/StoreModel/ServiceListingModel';
import {
  Language, Group, AgeRange, Category, SubCategory, Insurance,
} from '../interfaces/Meta';
import MetaModel from '../interfaces/StoreModel/MetaModel';
import Listing, { Hour, Website } from '../interfaces/Listing';
import { slugify } from '../lib/utils';
import ServiceData, { HoursEntry } from '../interfaces/ServiceData';
// import client from '../lib/api/client';

const getTimestamp = (hoursEntry: Date): string => (
  hoursEntry.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
  })
);

const convertListingData = (serviceData: ServiceData, metaState: MetaModel): Listing => {
  // Convert languages string (comma delimited ids) to array of Language objects for post, use string id as name.
  const languageStrings = serviceData.languages.toString().split(',');
  const languagesIn: Language[] = [];
  languageStrings.forEach((langString: string) => {
    const lang: Language = { name: langString, id: parseInt(langString, 10) };
    if (lang.id) {
      languagesIn.push(lang);
    }
  });

  // Convert groups string (comma delimited ids) to array of Group objects for post, use string id as name.
  const groupStrings = serviceData.groups.toString().split(',');
  const groupsIn: Group[] = [];
  groupStrings.forEach((groupString: string) => {
    const group: Group = { name: groupString, id: parseInt(groupString, 10) };
    if (group.id) {
      groupsIn.push(group);
    }
  });

  // Convert ages string (comma delimited ids) to array of AgeRange objects for post, use string id as name.
  const agesStrings = serviceData.ages.toString().split(',');
  const agesIn: AgeRange[] = [];
  agesStrings.forEach((agesString: string) => {
    const ageRange: AgeRange = { name: agesString, id: parseInt(agesString, 10) };
    if (ageRange.id) {
      agesIn.push(ageRange);
    }
  });

  // Convert insurances string (comma delimited ids) to array of Insurance objects for post, use string id as name.
  const insuranceStrings = serviceData.insurances.toString().split(',');
  const insurancesIn: Insurance[] = [];
  insuranceStrings.forEach((insuranceString: string) => {
    const insurance: Insurance = { name: insuranceString, id: parseInt(insuranceString, 10) };
    if (insurance.id) {
      insurancesIn.push(insurance);
    }
  });

  // Convert categories string (comma delimited ids) to array of Category objects for post, use string id as name.
  const categoriesStrings = serviceData.mainCategory.toString().split(',');
  const categoriesIn: Category[] = [];
  let slugStr = '';
  categoriesStrings.forEach((categoryString: string) => {
    slugStr = slugify(categoryString);
    const category: Category = {
      name: categoryString, id: parseInt(categoryString, 10), sub_categories: [], order: -1, icon: '', color: '', slug: slugStr,
    };
    if (category.id) {
      categoriesIn.push(category);
    }
  });

  // Convert subcategories string (comma delimited ids) to array of SubCategory objects for post, use string id as name.
  const subCategoriesStrings = serviceData.subCategory.toString().split(',');
  const subCategoriesIn: SubCategory[] = [];
  subCategoriesStrings.forEach((subCategoryString: string) => {
    const subCategory: SubCategory = {
      name: subCategoryString, id: parseInt(subCategoryString, 10), category_id: 0, slug: slugify(subCategoryString),
    };
    // Find a category that contains this subcategory in order to include its ID.
    const selectedCategories = metaState.data?.category.filter((cat) => !categoriesStrings.includes(cat.id.toString()));
    const categoryContainingSub = selectedCategories?.find((cat) => cat.sub_categories.filter((subcat) => subcat.id !== subCategory.id).length > 0);
    if (categoryContainingSub) {
      subCategory.category_id = categoryContainingSub.id;
    }
    if (subCategory.id) {
      subCategoriesIn.push(subCategory);
    }
  });

  // Convert OperatingHours data to array of Hour objects for post.
  const hoursIn: Hour[] = [];
  if ((serviceData.hours?.sunTimes.length || 0) > 0) {
    serviceData.hours?.sunTimes.forEach((hoursEntry: HoursEntry) => {
      const sunEntry: Hour = {
        day_of_week: 0, open_24_hours: false, is_closed: false, open: '', close: '',
      };
      sunEntry.open = getTimestamp(hoursEntry.openTime);
      sunEntry.close = getTimestamp(hoursEntry.closeTime);
      hoursIn.push(sunEntry);
    });
  } else {
    const sunEntry: Hour = {
      day_of_week: 0, open_24_hours: (serviceData.hours?.alwaysOpen || serviceData.hours?.sun24), is_closed: (!serviceData.hours?.alwaysOpen && !serviceData.hours?.sun24 && (serviceData.hours?.sunTimes === null || serviceData.hours?.sunTimes.length === 0)), open: '', close: '',
    };
    hoursIn.push(sunEntry);
  }

  if ((serviceData.hours?.monTimes.length || 0) > 0) {
    serviceData.hours?.monTimes.forEach((hoursEntry: HoursEntry) => {
      const monEntry: Hour = {
        day_of_week: 1, open_24_hours: false, is_closed: false, open: '', close: '',
      };
      monEntry.open = getTimestamp(hoursEntry.openTime);
      monEntry.close = getTimestamp(hoursEntry.closeTime);
      hoursIn.push(monEntry);
    });
  } else {
    const monEntry: Hour = {
      day_of_week: 1, open_24_hours: (serviceData.hours?.alwaysOpen || serviceData.hours?.mon24), is_closed: (!serviceData.hours?.alwaysOpen && !serviceData.hours?.mon24 && (serviceData.hours?.monTimes === null || serviceData.hours?.monTimes.length === 0)), open: '', close: '',
    };
    hoursIn.push(monEntry);
  }

  if ((serviceData.hours?.tueTimes.length || 0) > 0) {
    serviceData.hours?.tueTimes.forEach((hoursEntry: HoursEntry) => {
      const tueEntry: Hour = {
        day_of_week: 2, open_24_hours: false, is_closed: false, open: '', close: '',
      };
      tueEntry.open = getTimestamp(hoursEntry.openTime);
      tueEntry.close = getTimestamp(hoursEntry.closeTime);
      hoursIn.push(tueEntry);
    });
  } else {
    const tueEntry: Hour = {
      day_of_week: 2, open_24_hours: (serviceData.hours?.alwaysOpen || serviceData.hours?.tue24), is_closed: (!serviceData.hours?.alwaysOpen && !serviceData.hours?.tue24 && (serviceData.hours?.tueTimes === null || serviceData.hours?.tueTimes.length === 0)), open: '', close: '',
    };
    hoursIn.push(tueEntry);
  }

  if ((serviceData.hours?.wedTimes.length || 0) > 0) {
    serviceData.hours?.wedTimes.forEach((hoursEntry: HoursEntry) => {
      const wedEntry: Hour = {
        day_of_week: 3, open_24_hours: false, is_closed: false, open: '', close: '',
      };
      wedEntry.open = getTimestamp(hoursEntry.openTime);
      wedEntry.close = getTimestamp(hoursEntry.closeTime);
      hoursIn.push(wedEntry);
    });
  } else {
    const wedEntry: Hour = {
      day_of_week: 3, open_24_hours: (serviceData.hours?.alwaysOpen || serviceData.hours?.wed24), is_closed: (!serviceData.hours?.alwaysOpen && !serviceData.hours?.wed24 && (serviceData.hours?.wedTimes === null || serviceData.hours?.wedTimes.length === 0)), open: '', close: '',
    };
    hoursIn.push(wedEntry);
  }

  if ((serviceData.hours?.thuTimes.length || 0) > 0) {
    serviceData.hours?.thuTimes.forEach((hoursEntry: HoursEntry) => {
      const thuEntry: Hour = {
        day_of_week: 4, open_24_hours: false, is_closed: false, open: '', close: '',
      };
      thuEntry.open = getTimestamp(hoursEntry.openTime);
      thuEntry.close = getTimestamp(hoursEntry.closeTime);
      hoursIn.push(thuEntry);
    });
  } else {
    const thuEntry: Hour = {
      day_of_week: 4, open_24_hours: (serviceData.hours?.alwaysOpen || serviceData.hours?.thu24), is_closed: (!serviceData.hours?.alwaysOpen && !serviceData.hours?.thu24 && (serviceData.hours?.thuTimes === null || serviceData.hours?.thuTimes.length === 0)), open: '', close: '',
    };
    hoursIn.push(thuEntry);
  }

  if ((serviceData.hours?.friTimes.length || 0) > 0) {
    serviceData.hours?.friTimes.forEach((hoursEntry: HoursEntry) => {
      const friEntry: Hour = {
        day_of_week: 5, open_24_hours: false, is_closed: false, open: '', close: '',
      };
      friEntry.open = getTimestamp(hoursEntry.openTime);
      friEntry.close = getTimestamp(hoursEntry.closeTime);
      hoursIn.push(friEntry);
    });
  } else {
    const friEntry: Hour = {
      day_of_week: 5, open_24_hours: (serviceData.hours?.alwaysOpen || serviceData.hours?.fri24), is_closed: (!serviceData.hours?.alwaysOpen && !serviceData.hours?.fri24 && (serviceData.hours?.friTimes === null || serviceData.hours?.friTimes.length === 0)), open: '', close: '',
    };
    hoursIn.push(friEntry);
  }

  if ((serviceData.hours?.satTimes.length || 0) > 0) {
    serviceData.hours?.satTimes.forEach((hoursEntry: HoursEntry) => {
      const satEntry: Hour = {
        day_of_week: 6, open_24_hours: false, is_closed: false, open: '', close: '',
      };
      satEntry.open = getTimestamp(hoursEntry.openTime);
      satEntry.close = getTimestamp(hoursEntry.closeTime);
      hoursIn.push(satEntry);
    });
  } else {
    const satEntry: Hour = {
      day_of_week: 6, open_24_hours: (serviceData.hours?.alwaysOpen || serviceData.hours?.sat24), is_closed: (!serviceData.hours?.alwaysOpen && !serviceData.hours?.sat24 && (serviceData.hours?.satTimes === null || serviceData.hours?.satTimes.length === 0)), open: '', close: '',
    };
    hoursIn.push(satEntry);
  }

  const translationIn = serviceData.remove_translations ? { es: null } : { es: { name: serviceData.serviceNameEs, description: serviceData.descriptionEs, documentation_required: serviceData.documentationEs } };

  const websites = serviceData.websites?.map<Website>(website => {
    const newWebsite: Website = { name: website.name, url: website.url };
    if (website.id) {
      newWebsite.id = website.id;
    }
    if (website.name_es) {
      newWebsite.translation = { es: { name: website.name_es } };
    }
    return newWebsite;
  });

  return {
    id: serviceData.listingId,
    // provider_id: 4,
    name: serviceData.serviceName,
    phone_number: serviceData.phoneNumber,
    email: serviceData.email,
    description: serviceData.description,
    documentation_required: serviceData.documentation,
    insurance_required: serviceData.insurance,
    hours: hoursIn,
    sub_categories: subCategoriesIn,
    categories: categoriesIn,
    age_ranges: agesIn,
    insurance: insurancesIn,
    groups: groupsIn,
    languages: languagesIn,
    address: { street_address_1: serviceData.address, city_id: serviceData.cityId, zip_code: serviceData.zip },
    images: serviceData.images,
    auto_translate: serviceData.auto_translate,
    translation: translationIn,
    websites,
    do_not_provide_email: serviceData.doNotProvideEmail ?? true,
  };
};

const model: ServiceListingModel = {
  error: '',
  loading: false,
  success: false,
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setSuccess: action((state, payload) => {
    state.success = payload;
  }),
  // TODO: figure out how to get access to the Store without a ts-ignore
  // @ts-ignore
  create: thunk(async (actions, serviceData, store: ModuleStore<ServiceListingModel>) => {
    const metaState: MetaModel = store.getStoreState().meta;
    actions.setLoading(true);
    try {
      actions.setError('');
      const listing = convertListingData(serviceData, metaState);
      // TODO: should we just call the service directly here?
      const newListing = await store.getStoreActions().listings.create(listing);

      const listingState = store.getStoreState().listings;
      const providerState = store.getStoreState().provider;

      if (listingState.error.create) {
        actions.setError(listingState.error.create);
        actions.setSuccess(false);
      } else {
        if (providerState.currentProvider) {
          await store.getStoreActions().provider.getProviderById(Number(providerState.currentProvider.id));
        }
        actions.setSuccess(true);
        actions.setLoading(false);
        return newListing;
      }
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  // TODO: figure out how to get access to the Store without a ts-ignore
  // @ts-ignore
  updateListing: thunk(async (actions, serviceData, store: ModuleStore<ServiceListingModel>) => {
    const metaState: MetaModel = store.getStoreState().meta;
    actions.setLoading(true);
    try {
      actions.setError('');
      const listing = convertListingData(serviceData, metaState);
      // TODO: should we just call the service directly here?
      const updatedListing = await store.getStoreActions().listings.patch(listing);

      const listingState = store.getStoreState().listings;
      const providerState = store.getStoreState().provider;

      if (listingState.error.modify) {
        actions.setError(listingState.error.modify);
        actions.setSuccess(false);
      } else {
        if (providerState.currentProvider) {
          await store.getStoreActions().provider.getProviderById(Number(providerState.currentProvider.id));
        }
        actions.setSuccess(true);
        actions.setLoading(false);
        return updatedListing;
      }
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  // TODO: figure out how to get access to the Store without a ts-ignore
  // @ts-ignore
  submit: thunk(async (actions, listingId, store: ModuleStore<ServiceListingModel>) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      const payload = {
        id: listingId,
        submitted: true,
      };
      // TODO: should we just call the service directly here?
      const submittedListing = await store.getStoreActions().listings.patch(payload);

      const listingState = store.getStoreState().listings;
      const providerState = store.getStoreState().provider;

      if (listingState.error.modify) {
        actions.setError(listingState.error.modify);
        actions.setSuccess(false);
      } else {
        if (providerState.currentProvider) {
          await store.getStoreActions().provider.getProviderById(Number(providerState.currentProvider.id));
        }
        actions.setSuccess(true);
        actions.setLoading(false);
        return submittedListing;
      }
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  // TODO: figure out how to get access to the Store without a ts-ignore
  // @ts-ignore
  setApproval: thunk(async (actions, serviceApprovalData, store: ModuleStore<ServiceListingModel>) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      const payload = {
        id: serviceApprovalData.listingId,
        approved: serviceApprovalData.isApproved,
      };
      await store.getStoreActions().listings.patch(payload);

      const listingState = store.getStoreState().listings;
      const providerState = store.getStoreState().provider;

      if (listingState.error.modify) {
        actions.setError(listingState.error.modify);
        actions.setSuccess(false);
      } else {
        if (providerState.currentProvider) {
          await store.getStoreActions().provider.getProviderById(Number(providerState.currentProvider.id));
        }
        actions.setSuccess(true);
      }
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  saveAndSubmit: thunk(async (actions, serviceData) => {
    let listingId = 0;
    if (serviceData.listingId) {
      listingId = serviceData.listingId;
      await actions.updateListing(serviceData);
    } else {
      listingId = (await actions.create(serviceData)).id;
    }
    const submittedListing = await actions.submit(listingId);
    return submittedListing;
  }),
  // @ts-ignore
  remove: thunk(async (actions, listingId, store: ModuleStore<ServiceListingModel>) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      await store.getStoreActions().listings.remove(listingId);

      const listingState = store.getStoreState().listings;
      const providerState = store.getStoreState().provider;

      if (listingState.error.modify) {
        actions.setError(listingState.error.modify);
        actions.setSuccess(false);
      } else {
        if (providerState.currentProvider) {
          await store.getStoreActions().provider.getProviderById(Number(providerState.currentProvider.id));
        }
        actions.setSuccess(true);
      }
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
};

export default model;
