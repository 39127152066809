import {
  logOut, timer, information,
} from 'ionicons/icons';
import routePaths from '../lib/routePaths';

interface DesktopNavInfo {
  navTitleId: string;
  navOrderIndex: number;
  color: string;
}

interface AdminMenuItem {
  url: string;
  menuTitleId: string;
  desktopNavInfo?: DesktopNavInfo;
  nested?: boolean;
  icon: string;
}

const menuItems: AdminMenuItem[] = [
  {
    menuTitleId: 'admin.menutitle.listingCategories',
    desktopNavInfo: { navTitleId: 'admin.navtitle.listingCategories', navOrderIndex: 0, color: 'primary' },
    url: routePaths.admin.listingCategories,
    icon: information,
  },
  {
    menuTitleId: 'admin.menutitle.informationalpages',
    desktopNavInfo: { navTitleId: 'admin.navtitle.informationalpages', navOrderIndex: 1, color: 'primary' },
    url: routePaths.admin.informationalPages,
    icon: information,
  },
  {
    menuTitleId: 'admin.menutitle.crisishotlines',
    desktopNavInfo: { navTitleId: 'admin.navtitle.crisishotlines', navOrderIndex: 2, color: 'primary' },
    url: routePaths.admin.crisisHotlines,
    icon: information,
  },
  // {
  //   menuTitleId: 'admin.menutitle.analytics',
  // eslint-disable-next-line max-len
  //   desktopNavInfo: { navTitleId: 'admin.navtitle.analytics', navOrderIndex: 3, color: 'primary' },
  //   url: routePaths.admin.analytics,
  //   icon: information,
  // },
  {
    menuTitleId: 'admin.menutitle.dashboard',
    desktopNavInfo: { navTitleId: 'admin.navtitle.dashboard', navOrderIndex: 3, color: 'primary' },
    url: routePaths.admin.dashboard,
    icon: timer,
  },
  {
    menuTitleId: 'admin.menutitle.signout',
    desktopNavInfo: { navTitleId: 'admin.navtitle.signout', navOrderIndex: 4, color: 'primary' },
    url: '',
    icon: logOut,
  },
];

export default menuItems;
