import { createUseStyles } from 'react-jss';

export default createUseStyles({
  hoursHeader: {
    marginTop: '17px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  hoursLabel: {
    marginLeft: '10px',
  },
  hoursRow: {
    marginTop: '10px',
    marginLeft: '17px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dayLabel: {
    marginRight: '10px',
    marginLeft: '17px',
  },
  chipsRow: {
    marginTop: '5px',
    marginLeft: '17px',
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  hoursDivider: {
    height: '2px',
    marginLeft: '17px',
    marginTop: '8px',
    marginBottom: '8px',
    backgroundColor: 'var(--ion-color-primary)',
  },
  hoursModalContainer: {
    '--height': '300px',
  },
  hoursModal: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  hoursModalHeader: {
    width: '100%',
    color: 'var(--ion-color-primary)',
    textAlign: 'center',
    marginTop: '20px',
    marginBottom: '40px',
    fontSize: '20px',
    fontWeight: 'bold',
  },
  hoursModalError: {
    width: '100%',
    color: '#FF0000',
    textAlign: 'center',
    marginTop: '20px',
    fontSize: '20px',
  },
  hoursModalRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: '20px',
    color: 'var(--ion-color-primary)',
    marginBottom: '15px',
  },
});
