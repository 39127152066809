import React, { useEffect } from 'react';
import { IonLoading } from '@ionic/react';
import {
  Route, Redirect, Switch, useHistory, useLocation,
} from 'react-router-dom';
import { useStoreState, useStoreActions } from '../../store';

import routePaths from '../../lib/routePaths';
import SignIn from './SignIn';
import Onboarding from './Onboarding';
import Approved from './Approved';

const Provider: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    auth: { user, loading: authLoading },
    provider: { loading: providerLoading },
    createOrg: { loading: createOrgLoading },
    serviceListings: { loading: serviceListingsLoading },
    resetPassword: { loading: resetPasswordLoading },
  } = useStoreState(state => ({
    auth: state.auth,
    provider: state.provider,
    createOrg: state.createOrg,
    serviceListings: state.serviceListings,
    resetPassword: state.password,
  }));
  const { getProviderById } = useStoreActions(
    providerActions => providerActions.provider,
  );
  const { currentProvider } = useStoreState(state => state.provider);
  const loading = providerLoading || authLoading || serviceListingsLoading || createOrgLoading;
  const { setSelectedReviewTab } = useStoreActions(state => state.admin);

  useEffect(() => {
    if (!loading
      && !location.pathname.startsWith(routePaths.public.root)) {
      if (user) {
        if (user.admin) {
          setSelectedReviewTab('newProviders');
          history.push(routePaths.admin.dashboard);
        } else if (!user.verified_email) {
          history.push(routePaths.provider.onboarding.unverifiedEmail);
        } else if ((user.provider?.id ?? -1) >= 0) {
          if ((user.provider?.approved_date?.length ?? 0) > 0) {
            if (!location.pathname.startsWith(routePaths.provider.approved.root)) {
              history.push(routePaths.provider.approved.root);
            }
          } else if (currentProvider === null) {
            getProviderById(Number(user.provider?.id ?? 0));
          } else if ((currentProvider?.submit_date?.length ?? 0) > 0) {
            if (location.pathname !== routePaths.provider.onboarding.viewProfile
              && !location.pathname.startsWith(routePaths.provider.onboarding.editService)
            ) {
              history.push(routePaths.provider.onboarding.approval);
            }
          } else if (location.pathname === routePaths.provider.onboarding.root
            || location.pathname === routePaths.provider.signin
            || location.pathname === routePaths.provider.onboarding.unverifiedEmail) {
            if ((currentProvider.listings?.length ?? 0) > 0
              && location.pathname !== routePaths.provider.onboarding.submit) {
              history.push(routePaths.provider.onboarding.submit);
            } else if (location.pathname !== routePaths.provider.onboarding.addService
                && (currentProvider.listings?.length ?? 0) === 0) {
              history.push(routePaths.provider.onboarding.addService);
            }
          }
        }
      } else if (location.pathname !== routePaths.provider.signin
        && location.pathname !== routePaths.provider.onboarding.root
        && !location.pathname.startsWith(routePaths.provider.onboarding.requirements)
        && location.pathname !== routePaths.provider.onboarding.orgInfo) {
        history.push(routePaths.provider.signin);
      }
    }
  }, [
    history, user, currentProvider, getProviderById,
    location.pathname, loading, setSelectedReviewTab,
  ]);

  return (
    <>
      <Switch>
        <Route exact path={routePaths.provider.signin} component={SignIn} />
        <Route path={routePaths.provider.onboarding.root} component={Onboarding} />
        <Route path={routePaths.provider.approved.root} component={Approved} />
        <Redirect path={routePaths.provider.root} to={routePaths.provider.signin} />
      </Switch>
      { (providerLoading || authLoading || serviceListingsLoading
        || createOrgLoading || resetPasswordLoading) ? (
          <IonLoading isOpen />
        ) : null}
    </>
  );
};

export default Provider;
