import {
  logOut,
  people,
  clipboard,
} from 'ionicons/icons';

import routePaths from '../lib/routePaths';

interface DesktopNavInfo {
  navTitleId: string;
  navOrderIndex: number;
  color: string;
}

interface ProviderMenuItem {
  url: string;
  approvedUrl?: string;
  menuTitleId: string;
  desktopNavInfo?: DesktopNavInfo;
  nested?: boolean;
  icon: string;
}

const menuItems: ProviderMenuItem[] = [
  {
    menuTitleId: 'provider.menutitle.organizationprofile',
    desktopNavInfo: { navTitleId: 'provider.navtitle.organizationprofile', navOrderIndex: 0, color: 'tertiary' },
    url: routePaths.provider.onboarding.viewProfile,
    approvedUrl: routePaths.provider.approved.editProfile,
    icon: clipboard,
  },
  {
    menuTitleId: 'provider.menutitle.servicelistings',
    desktopNavInfo: { navTitleId: 'provider.navtitle.servicelistings', navOrderIndex: 0, color: 'tertiary' },
    url: routePaths.provider.onboarding.root,
    approvedUrl: routePaths.provider.approved.root,
    icon: people,
  },
  {
    menuTitleId: 'provider.menutitle.signout',
    desktopNavInfo: { navTitleId: 'provider.navtitle.signout', navOrderIndex: 0, color: 'primary' },
    url: '',
    icon: logOut,
  },
];

export default menuItems;
