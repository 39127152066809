import { IonLabel } from '@ionic/react';
import React from 'react';
import Icon from '../../components/Icon';
import useClasses from '../styles';
import { CrisisHotline } from '../../interfaces/CrisisHotlines';
import { useStoreState } from '../../store';
import { translateName } from '../../lib/utils';

interface CrisisCardProps {
  hotline: CrisisHotline;
}

const CrisisCard: React.FunctionComponent<CrisisCardProps> = ({
  hotline,
}: CrisisCardProps) => {
  const classes = useClasses();
  const primaryColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--ion-color-primary');
  const locale = useStoreState((state) => state.translations.language);
  return (
    <div className={classes.crisisCard}>
      <div id="text-wrapper">
        <IonLabel id="">{translateName(hotline, locale)}</IonLabel>
        <IonLabel>{hotline.organization_name}</IonLabel>
        <IonLabel>{hotline.phone_number}</IonLabel>
      </div>
      <div id="icon-wrapper">
        <a
          // eslint-disable-next-line max-len
          onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => e.stopPropagation()}
          href={`tel:${hotline?.phone_number}`}
          className={classes.listingIcon}
        >
          <Icon name="phone" width={30} color={primaryColor} />
        </a>
      </div>
    </div>
  );
};

export default CrisisCard;
