import { createUseStyles } from 'react-jss';

export default createUseStyles({
  content: {
    width: '100%',
    height: '100vh',
    // '--overflow': 'hidden',
    position: 'relative',
    '--background':
      'linear-gradient(rgba(255, 254, 253, 0.15), rgba(0, 32, 91, 0.15))',
    // '--background': `url(${triangleBackground}) no-repeat center center fixed`,
    // backgroundSize: 'cover',
  },
  ccToolbar: {
    '--min-height': '65px',
    '--background': 'transparent',
    marginTop: 10,
    '@media (max-width: 321px)': {
      marginTop: 0,
    },
  },
  ccLogoWrapper: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    height: '60px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  ccLogo: {
    height: '60px',
    width: '164px',
    '@media (min-width: 280px) and (max-width: 319px)': {
      width: 98.4,
      height: 36,
    },
    '@media (min-width: 320px) and (max-width: 321px)': {
      width: 131.2,
      height: 48,
    },
  },
  desktopContainer: {
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
  },
  mobileContainer: {
    width: '100%',
    paddingRight: 10,
    paddingLeft: 10,
  },
  searchServicesWrapper: {
    width: '100%',
    paddingRight: 5,
    paddingLeft: 5,
  },
  searchWrapper: {
    marginBottom: 10,
  },
  searchWrapperDesktop: {
    '@media (min-width: 880px) and (max-width: 1520px)': {
      paddingRight: 0,
      paddingLeft: 0,
    },
    paddingRight: 20,
    paddingLeft: 20,
    marginBottom: 10,
  },
  title: {
    textAlign: 'left',
    fontSize: '20px',
    fontWeight: '400',
  },
  subtitle: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: '400',
    color: '#000',
  },
  subtitleBold: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: '500',
  },
  subtext: {
    textAlign: 'left',
    fontSize: '14px',
    fontWeight: '400',
  },
  serviceLink: {
    textAlign: 'left',
    fontSize: '16px',
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  toggleItemSingleLine: {
    '--background': 'transparent',
    height: '40px',
    marginTop: '10px',
  },
  toggleItem: {
    '--background': 'transparent',
    height: '100%',
    marginTop: '10px',
  },
  toggle: {
    paddingLeft: '0px',
    marginRight: '10px',
  },
  popularText: {
    color: 'var(--ion-color-primary)',
    fontWeight: 'bold',
    fontSize: 14,
  },
  searchInput: {
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
    marginTop: '17px',
    border: '1px solid black',
    borderRadius: '5px',
    '--background': '#FFF',
    '--border-color': 'transparent!important',
    '--highlight-height': '0',
    padding: '0px',
    '&.item-has-focus': {
      border: '3px solid var(--ion-color-primary)',
    },
    '&.item-input ion-label, &.item-textarea ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
      position: 'absolute',
      right: 0,
    },
    '&.item-has-value ion-label': {
      display: 'none',
    },
    '& ion-input': {
      fontSize: 20,
      '--placeholder-color': 'var(--ion-color-primary)',
    },
  },
  backButton: {
    '&.no-padding': {
      '--padding-start': '0px',
    },
  },
  backgroundImage: {
    '& svg': {
      width: '100%',
      height: '100%',
    },
    position: 'fixed',
    bottom: '0',
    right: '0',
    minWidth: '800px',
    width: '100%',
    zIndex: '-1',
  },
  popularWrapper: {
    width: '100%',
    paddingRight: 5,
    paddingLeft: 5,
  },
  popularWrapperDesktop: {
    width: '100%',
    '@media (min-width: 880px) and (max-width: 1520px)': {
      paddingRight: 5,
      paddingLeft: 5,
    },
    paddingRight: 25,
    paddingLeft: 25,
  },
  semiTransparentBackground: {
    '--background': 'rgba(255, 255, 255, 0.3)',
  },
  transparentBackground: {
    '--background': 'rgba(255, 255, 255, 0)',
  },
  leftJustifiedRow: {
    display: 'flex',
    justifyContent: 'left',
  },
  rightJustifiedRow: {
    display: 'flex',
    justifyContent: 'right',
  },
  spaceBetweenRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  minWidth175: {
    minWidth: '175px',
  },
  font20Medium: {
    fontSize: '18px',
    fontWeight: '400',
  },
  homeErrorMessage: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    '& ion-label': {
      fontSize: '14px!important',
      color: 'red!important',
      alignSelf: 'center',
    },
  },
  ccPageTitle: {
    '& ion-label': {
      whiteSpace: 'pre-wrap!important',
      wordBreak: 'break-word!important',
    },
  },
  crisisPage: {
    backgroundColor: '#D9D9D9',
    '& ion-content': {
      '--background': '#D9D9D9',
    },
    '& #header': {
      height: '100px',
      backgroundColor: '#D22727',
      display: 'flex',
      flexDirection: 'column',
      '& #buttons': {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        '& #times': {
          flex: 1,
          alignSelf: 'flex-start',
          '& ion-button': {
            textTransform: 'none',
            '--box-shadow': 'none',
            '--background-activated': 'none',
            '--background-focused': 'none',
            '--background': 'none',
          },
        },
        '& #continue': {
          alignSelf: 'flex-end',
          '& ion-button': {
            fontSize: 16,
            fontWeight: 'bold',
            textTransform: 'none',
            '--box-shadow': 'none',
            '--color': 'var(--ion-color-primary)',
            '--background-activated': 'none',
            '--background-focused': 'none',
            '--background': 'none',
          },
        },
      },
      '& #title': {
        flex: 1,
        fontSize: 27,
        color: 'white',
        textAlign: 'center',
      },
    },
    '& #groups': {
      marginTop: 8,
    },
    '& #search-btn-wrapper': {
      padding: 15,
      '&  ion-button': {
        width: '100%',
        fontSize: 20,
        '--border-radius': '20px',
      },
    },
  },
  crisisCardGroup: {
    '& #title': {
      paddingLeft: 15,
      paddingRight: 15,
      marginBottom: 5,
      '& ion-label': {
        fontWeight: 500,
        fontSize: 19,
        color: 'var(--ion-color-primary)',
      },
    },
    '& .crisis-card-wrapper': {
      '& a': {
        textDecoration: 'none',
      },
      marginBottom: 5,
    },
  },
  crisisCard: {
    width: '100%',
    height: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'white',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
    textDecoration: 'none',
    cursor: 'pointer',
    '& #text-wrapper': {
      flex: 7,
      display: 'flex',
      flexDirection: 'column',
      '& ion-label:nth-child(1)': {
        fontSize: 15,
        marginTop: 2.5,
        marginBottom: 2.5,
        color: '#000',
      },
      '& ion-label:nth-child(2)': {
        fontSize: 12,
        fontWeight: 300,
        color: '#222',
        marginTop: 2.5,
        marginBottom: 2.5,
      },
      '& ion-label:nth-child(3)': {
        fontWeight: 'bold',
        fontSize: 15,
        marginTop: 2.5,
        marginBottom: 2.5,
        color: '#000',
      },
    },
    '& #icon-wrapper': {
      flex: 3,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
  listingIcon: {
    border: '1px solid #808080',
    borderRadius: '30px',
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  whitebg: {
    borderRadius: '5px',
    backgroundColor: '#FFF',
  },
  flippedIcon: {
    transform: 'scaleX(-1)',
  },
  divider: {
    backgroundColor: '#EAAA00',
    height: '2px',
    marginTop: '16px',
  },
  pageWrapper: {
    padding: 20,
    '& .ql-align-center': {
      textAlign: 'center',
    },
    '& .ql-align-left': {
      textAlign: 'left',
    },
    '& .ql-align-right': {
      textAlign: 'right',
    },
    '& ql-align-justify': {
      textAlign: 'justify',
    },
    '& #not-found': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '24px',
      color: 'var(--ion-color-primary)',
    },
    '& p': {
      marginBlockStart: 0,
      marginBlockEnd: 0,
    },
  },
});
