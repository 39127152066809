import React from 'react';
import { IconProps } from './Icon';

const ShelterHousing: React.FunctionComponent<IconProps> = ({
  color,
  height,
  width,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 500 500"
  >
    <g fill={color}>
      <path
        id="Selection #1"
        d="M 32.75,99.50
           C 29.81,103.50 27.95,105.52 28.00,122.00
             28.00,122.00 28.00,364.00 28.00,364.00
             28.01,372.63 28.02,374.02 31.06,381.81
             31.64,383.09 31.98,383.87 32.88,384.88
             34.11,386.28 34.69,387.54 36.38,388.31
             43.77,389.40 44.91,388.72 52.81,388.50
             55.14,387.52 57.09,386.55 58.10,385.28
             59.31,383.90 61.13,380.85 61.94,378.88
             62.97,376.34 63.19,373.79 63.86,371.00
             63.86,371.00 63.86,343.00 63.86,343.00
             64.02,333.06 64.70,331.02 75.00,331.00
             75.00,331.00 147.00,331.00 147.00,331.00
             147.00,331.00 419.00,331.00 419.00,331.00
             427.47,331.02 429.87,331.83 430.15,341.00
             430.15,341.00 430.15,374.00 430.15,374.00
             430.60,375.37 431.26,382.48 432.09,383.65
             433.67,385.90 436.74,387.60 439.04,388.65
             447.65,390.68 448.44,390.87 454.91,388.18
             457.08,387.71 458.17,386.51 459.42,385.28
             461.08,383.63 463.26,381.98 464.00,379.87
             467.06,371.14 465.98,374.28 466.00,363.00
             466.00,363.00 466.00,223.00 466.00,223.00
             465.98,212.49 465.46,209.72 464.18,200.00
             464.18,200.00 460.28,188.00 460.28,188.00
             460.28,188.00 457.09,182.00 457.09,182.00
             457.09,182.00 454.74,176.28 454.74,176.28
             448.58,164.93 435.70,152.35 424.58,145.85
             424.58,145.85 418.00,143.12 418.00,143.12
             418.00,143.12 413.00,140.57 413.00,140.57
             408.25,138.90 402.00,137.00 397.19,136.19
             389.85,134.94 390.41,135.12 382.00,135.00
             382.00,135.00 355.00,135.00 355.00,135.00
             355.00,135.00 275.00,135.00 275.00,135.00
             264.96,135.02 265.33,134.32 257.09,136.64
             257.09,136.64 249.55,139.18 249.55,139.18
             244.29,141.52 236.71,150.23 234.27,155.45
             234.27,155.45 232.00,160.25 232.00,160.25
             229.06,167.97 229.02,165.87 229.00,176.00
             229.00,176.00 229.00,264.00 229.00,264.00
             229.00,266.77 229.32,271.29 227.26,273.40
             225.49,275.21 222.34,274.98 220.00,275.00
             220.00,275.00 193.00,275.00 193.00,275.00
             193.00,275.00 75.00,275.00 75.00,275.00
             71.82,274.99 67.87,275.38 65.60,272.69
             63.71,270.44 64.01,266.77 64.00,264.00
             64.00,264.00 64.00,231.00 64.00,231.00
             64.00,231.00 64.00,137.00 64.00,137.00
             64.00,132.63 64.41,117.14 63.44,114.00
             63.44,114.00 62.00,105.38 62.00,105.38
             61.04,103.11 59.60,101.92 57.75,100.25
             52.63,95.62 39.00,97.00 32.75,99.50 Z
           M 138.00,131.22
           C 116.72,135.37 99.86,145.91 90.32,166.00
             75.40,197.42 87.17,236.26 119.00,251.74
             126.45,255.37 135.69,257.90 144.00,258.00
             153.56,258.11 162.22,256.65 171.00,252.69
             211.13,234.60 220.75,180.03 189.91,149.09
             184.10,143.26 176.62,138.53 169.00,135.45
             162.06,132.65 154.48,131.03 147.00,131.22
             147.00,131.22 138.00,131.22 138.00,131.22 Z
           M 61.56,387.62"
      />
    </g>
  </svg>
);

export default ShelterHousing;
