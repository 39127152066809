import React from 'react';

import CCPage from '../../components/CCPage';

const Help: React.FC = () => (
  <CCPage
    title="Help"
    content={(
      <div className="container">
        <strong>Help Page</strong>
      </div>
    )}
  />
);

export default Help;
