import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import React from 'react';
import { Category } from '../../../../interfaces/Meta';
import useStyles from '../../styles';

interface ListingAssociatedCategoryRowProps {
  category: Category;
  removeCategory: (id: number) => void;
}

// eslint-disable-next-line max-len
const ListingAssociatedCategoryRow: React.FunctionComponent<ListingAssociatedCategoryRowProps> = ({
  category,
  removeCategory,
}: ListingAssociatedCategoryRowProps) => {
  const classes = useStyles();
  const { id, name, translation } = category;

  return (
    <>
      <div className={classes.categoryCardContent}>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="9">
              <div className={classes.providerNameRow}>
                <IonItem
                  className={[
                    classes.providerOrgName,
                    'disabled-pointer-events',
                  ].join(' ')}
                >
                  <IonLabel position="floating" color="primary">
                    Associated Category Name
                  </IonLabel>
                  <IonInput
                    color="primary"
                    value={`${name} ${translation?.es?.name ? '/' : ''} ${translation?.es?.name ?? ''}`}
                  />
                </IonItem>
              </div>
            </IonCol>
            <IonCol className={classes.removeButtonCol} sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
              <IonButton
                className={classes.removeButton}
                shape="round"
                fill="outline"
                onClick={(): void => removeCategory(id)}
              >
                Remove
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default ListingAssociatedCategoryRow;
