import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
  IonLabel,
  IonRouterLink,
} from '@ionic/react';
import React, { useRef } from 'react';
import useAppStyles from '../apps/styles';
import useIsDesktop from '../lib/hooks/useIsDesktop';
import DesktopMenu from './DesktopMenu';
// @ts-ignore
import ccLogo from '../assets/cc_logo.png';
import routePaths from '../lib/routePaths';
import IonContentContext from '../lib/context/IonContentContext';

interface CCPageProps {
  startButtons?: React.ReactElement;
  endButtons?: React.ReactElement;
  content: React.ReactElement;
  title?: string;
  useLogo?: boolean;
  useDesktopMenu?: boolean;
}

const CCPage: React.FC<CCPageProps> = ({
  content,
  title,
  startButtons,
  endButtons,
  useLogo,
  useDesktopMenu = true,
}: CCPageProps) => {
  const isDesktop = useIsDesktop();
  const appClasses = useAppStyles();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  useIonViewDidEnter(() => {
    window.scrollTo(0, 0);
  });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={appClasses.ccToolbar}>
          {isDesktop ? (
            <>
              {useDesktopMenu && (
                <DesktopMenu />
              )}
            </>
          ) : (
            <>
              <IonButtons slot="start" className="ion-no-padding">
                <IonMenuButton />
              </IonButtons>
              <IonButtons slot="start" className="ion-no-padding">
                {startButtons}
              </IonButtons>
              <IonButtons slot="end" className="ion-no-padding">
                {endButtons}
              </IonButtons>
              { title
                ? (
                  <IonTitle className={appClasses.ccPageTitle}>
                    <IonLabel>{title}</IonLabel>
                  </IonTitle>
                )
                : useLogo ? (
                  <div className={appClasses.ccLogoWrapper}>
                    <IonRouterLink routerLink={routePaths.public.home}>
                      <div style={{ display: 'flex' }}>
                        <img
                          className={appClasses.ccLogo}
                          src={ccLogo}
                          alt="home"
                        />
                      </div>
                    </IonRouterLink>
                  </div>
                ) : null}
            </>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContentContext.Provider value={contentRef}>
        <IonContent ref={contentRef} id="contentElement">{content}</IonContent>
      </IonContentContext.Provider>
    </IonPage>
  );
};

export default CCPage;
