import { createUseStyles } from 'react-jss';

export default createUseStyles({
  serviceImage: {
    width: '50%',
  },
  description: {
    '& textarea': {
      height: '140px',
    },
  },
  selectColumn: {
    backgroundColor: '#FFF',
    padding: '0px',
    '&.interiorItem': {
      marginRight: '10px',
    },
  },
  selectRow: {
    marginTop: '17px',
    padding: '0px',
    margin: '0px',
  },
  iconInputColumn: {
    margin: '0px',
    padding: '0px',
    '&.interiorItem': {
      '@media (min-width:576px)': {
        paddingRight: '12px',
      },
    },
    '&.exteriorItem': {
      '@media (max-width:576px)': {
        marginTop: '17px',
      },
    },
  },
  iconInputGrid: {
    padding: '0px',
    margin: '0px',
    marginTop: '17px',
  },
  iconInputRow: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconSelectRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
  },
  inputIcon: {
    marginRight: '5px',
    width: '30px',
  },
  dummyInputIcon: {
    '@media(max-width: 576px)': {
      marginRight: '0px',
      width: '0px',
    },
    '@media(min-width: 577px)': {
      marginRight: '5px',
      width: '30px',
    },
  },
  descriptionCharacterCount: {
    paddingTop: 5,
    paddingBottom: 20,
    fontSize: 14,
    color: 'var(--ion-color-primary)',
    opacity: 0.75,
  },
  characteCountText: {
    textAlign: 'left',
    float: 'left',
  },
  characterCountValue: {
    textAlign: 'right',
    float: 'right',
  },
  input: {
    marginTop: '0px',
    width: '100%',
  },
  confirmDelete: {
    textAlign: 'center',
    '@media (max-width:576px)': {
      width: '100%',
    },
  },
  alignFlexEnd: {
    alignItems: 'flex-end',
    '@media (max-width:576px)': {
      flexDirection: 'column',
      '& .cc-button': {
        width: '100%',
      },
    },
  },
  confirmationButtons: {
    width: '100%',
    '@media (max-width:576px)': {
      flexDirection: 'column',
      '& .cc-button': {
        width: '100%',
      },
    },
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px',
    marginTop: '5px',
    backgroundColor: '#C3C8D7',
    '&.isOpen': {
      backgroundColor: 'var(--ion-color-primary)',
    },
  },
  sectionContent: {
    maxHeight: '0',
    overflow: 'hidden',
    backgroundColor: '#FFF',
    transition: 'max-height 300ms ease-in-out',
    '&.isOpen': {
      maxHeight: '1400px',
      padding: '5px',
    },
  },
  nextButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  circleButton: {
    '--padding-start': '15px',
    '--padding-end': '15px',
    width: '10px',
    height: '32px',
  },
  websiteUrlInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& ion-item': {
      width: 'calc(100% - 33px)',
    },
  },
  websiteUrlInputWithRemoveButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& ion-item': {
      marginRight: '10px',
      '@media(min-width: 320px) and (max-width: 480px)': {
        width: '70%',
      },
      '@media(min-width: 480px) and (max-width: 960px)': {
        width: '80%',
      },
      '@media(min-width: 960px)': {
        width: '85%',
      },
    },
  },
  websiteUrlInputWithRemoveButtonSpanish: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& ion-item': {
      width: '100%',
    },
  },
  addButtonWrapper: {
    width: '45px',
    height: '60px',
    float: 'left',
  },
  addButtonHelperTextWrapper: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
  },
  helperText: {
    color: '#777',
    fontStyle: 'italic',
  },
  labelWrapped: {
    whiteSpace: 'normal!important',
    textOverflow: 'clip!important',
    marginRight: 'var(--ion-margin, 16px)!important',
    marginLeft: 'var(--ion-margin, 16px)!important',
    '& button': {
      position: 'absolute',
      paddingLeft: 5,
      paddingRight: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
});
