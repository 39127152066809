import React from 'react';

import { useStoreState } from '../../../../../store';
import useStyles from '../styles';

const CurrentStepDesktop: React.FC = () => {
  const classes = useStyles();
  const stepIndex = useStoreState(store => store.createOrg.currentStep);
  return (
    <div>
      <div className={[stepIndex === 1 ? 'active' : '', classes.labelRowDesktop].join(' ')}>
        <div className={[classes.stepNumberDesktop, 'number'].join(' ')}>1</div>
        <div className={[classes.stepTitleDesktop, 'label'].join(' ')}>Eligibility</div>
      </div>
      <div className={[stepIndex === 2 ? 'active' : '', classes.labelRowDesktop].join(' ')}>
        <div className={[classes.stepNumberDesktop, 'number'].join(' ')}>2</div>
        <div className={[classes.stepTitleDesktop, 'label'].join(' ')}>Sign Up</div>
      </div>
      <div className={[stepIndex === 3 ? 'active' : '', classes.labelRowDesktop].join(' ')}>
        <div className={[classes.stepNumberDesktop, 'number'].join(' ')}>3</div>
        <div className={[classes.stepTitleDesktop, 'label'].join(' ')}>Add Service</div>
      </div>
      <div className={[stepIndex === 4 ? 'active' : '', classes.labelRowDesktop].join(' ')}>
        <div className={[classes.stepNumberDesktop, 'number'].join(' ')}>4</div>
        <div className={[classes.stepTitleDesktop, 'label'].join(' ')}>Approval</div>
      </div>
    </div>
  );
};
export default CurrentStepDesktop;
