import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown/with-html';
import 'react-quill/dist/quill.snow.css';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CCPage from '../../components/CCPage';
import { Page as IPage } from '../../interfaces/Page';
import usePrevious from '../../lib/hooks/usePrevious';
import { useStoreActions, useStoreState } from '../../store';
import useStyles from '../styles';
import { translateProp } from '../../lib/utils';
import useIsDesktop from '../../lib/hooks/useIsDesktop';

interface PageParams {
  slug?: string;
}

const Page: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const isDesktop = useIsDesktop();
  const params = useParams<PageParams>();
  const [currentPage, setCurrentPage] = useState<IPage | null>(null);
  const [notFound, setNotFound] = useState<boolean>(false);
  const { data: pagesSelector, loading } = useStoreState((state) => state.pages);
  let pages: IPage[] | null = (localStorage.getItem('pages') ? JSON.parse(localStorage.getItem('pages') as string) : []) as IPage[];
  if (!pages) {
    pages = pagesSelector;
  }
  const { language: locale } = useStoreState((state) => state.translations);
  const { get } = useStoreActions((state) => state.pages);
  const previousLocation = usePrevious(location);

  useEffect(() => {
    const { slug } = params;
    const foundPage = pages?.find((p) => p.slug === slug);
    if (!loading && pages !== null) {
      if (foundPage) {
        if (foundPage.contents && !currentPage) {
          setCurrentPage(foundPage);
          setNotFound(false);
        }
      } else {
        setNotFound(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get, history, loading, pages, location]);

  useEffect(() => {
    if (
      location
      && previousLocation
      && location.pathname !== previousLocation.pathname
    ) {
      setCurrentPage(null);
      const { slug } = params;
      if (pages && slug) {
        const foundPage = pages?.find((p) => p.slug === slug);
        if (foundPage) {
          if (foundPage.contents && !currentPage) {
            setCurrentPage(foundPage);
            setNotFound(false);
          }
        }
      } else if (slug) {
        get(slug);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const content = (
    <>
      {currentPage && (
        <>
          {!loading && (
            <div className={classes.pageWrapper}>
              <ReactMarkdown
                source={translateProp(currentPage, 'contents', locale)}
                escapeHtml={false}
              />
            </div>
          )}
        </>
      )}
      {notFound && (
        <div className={classes.pageWrapper}>
          <div id="not-found">
            <FormattedMessage id="page.notfound.message" />
          </div>
        </div>
      )}
    </>
  );

  return isDesktop ? content : (
    <CCPage
      useLogo
      useDesktopMenu={false}
      content={content}
    />
  );
};

export default Page;
