import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import React from 'react';
import { SubCategory } from '../../../../interfaces/Meta';
import useStyles from '../../styles';

interface ListingSubcategoryRowProps {
  subcategory: SubCategory;
  editSubcategory: (id: number) => void;
  deleteSubcategory: (id: number) => void;
}

const ListingSubcategoryRow: React.FunctionComponent<ListingSubcategoryRowProps> = ({
  subcategory,
  editSubcategory,
  deleteSubcategory,
}: ListingSubcategoryRowProps) => {
  const classes = useStyles();
  const { id, name, translation } = subcategory;

  return (
    <>
      <div className={classes.crisisCategoryCardHeader} />
      <div className={classes.providerCardContent}>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="8">
              <div className={classes.providerNameRow}>
                <IonItem
                  className={[
                    classes.providerOrgName,
                    'disabled-pointer-events',
                  ].join(' ')}
                >
                  <IonLabel position="floating" color="primary">
                    Subcategory Name
                  </IonLabel>
                  <IonInput
                    color="primary"
                    value={`${name} / ${translation?.es?.name}`}
                  />
                </IonItem>
              </div>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="1" />
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
              <IonButton
                className={classes.providerActionButton}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => editSubcategory(id)}
              >
                Edit
              </IonButton>
              <IonButton
                className={classes.providerActionButton}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => deleteSubcategory(id)}
              >
                Delete
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default ListingSubcategoryRow;
