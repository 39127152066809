import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Map from '../../components/Map';
import CCPage from '../../components/CCPage';
import { useStoreActions, useStoreState } from '../../store';
import routePaths from '../../lib/routePaths';

const Search: React.FC = () => {
  const history = useHistory();
  const { data } = useStoreState(state => state.search);
  const { updateFilters } = useStoreActions(actions => actions.search);

  useEffect(() => {
    updateFilters({});
  }, [updateFilters]);

  return (
    <CCPage
      useLogo
      content={(
        <div>
          <Map
            data={data || []}
            onSelected={(info): void => {
              if (info && info.id) {
                const path = routePaths.public.listingDetail.replace(':id', info.id.toString());
                history.push(path);
              }
            }}
          />
        </div>
      )}
    />
  );
};

export default Search;
