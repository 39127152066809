/* eslint-disable react/button-has-type */
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// @ts-ignore
import ImageUploader from 'quill-image-uploader';

Quill.register('modules/imageUploader', ImageUploader);

interface WysiwygProps {
  name: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any;
}

const fontSizes = [...Array.from(Array(100).keys())].filter((n) => n % 2 !== 0);
const fontSizeStyle = Quill.import('attributors/style/size');
fontSizeStyle.whitelist = fontSizes.map((n) => `${n + 1}px`);
Quill.register(fontSizeStyle, true);

const WysiwygInput: React.FunctionComponent<WysiwygProps> = ({
  name,
  placeholder,
  value,
  onChange,
}: WysiwygProps) => {
  const quillRef = useRef<ReactQuill | null>(null);
  const [valueSet, setValueSet] = useState(false);
  const initialId = `toolbar-${Math.floor(Math.random() * 100).toString(16)}`;
  const [toolbarId] = useState(initialId);
  const [modules] = useState({
    clipboard: {
      matchVisual: false,
    },
    toolbar: { container: `#${initialId}` },
    imageUploader: {
      upload: (file: File): Promise<string | null> => {
        const BASE_URL: string = process.env.REACT_APP_API_HOST || 'http://localhost:5000';
        const formData = new FormData();
        formData.append('uri', file);
        return fetch(`${BASE_URL}/api/v1/page-images`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${localStorage['feathers-jwt']}`,
          },
        }).then(response => {
          if (response.status === 200) {
            return response.json().then((image) => image.url).catch(() => null);
          }
          return null;
        }).catch(() => null);
      },
    },
  });
  useEffect(() => {
    if (quillRef) {
      const editor = quillRef.current?.getEditor();
      editor?.format('size', '14px');
      editor?.setContents(value);
      if (editor && editor.getLength() <= 1) {
        editor.format('size', '20em');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!valueSet && value) {
      if (quillRef) {
        const editor = quillRef.current?.getEditor();
        editor?.clipboard.dangerouslyPasteHTML(value);
        // editor?.setContents(value);
        setValueSet(true);
      }
    }
  }, [value, valueSet]);

  const handleChange = (val: string): void => {
    onChange({ target: { name, value: val } });
  };

  const toolbar = (
    <>
      <div id={toolbarId}>
        <span className="ql-formats">
          <select className="ql-size">
            {fontSizes.map((n) => (
              <option key={n + 1} value={`${n + 1}px`}>{`${n + 1}px`}</option>
            ))}
          </select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold hint--bottom-right" aria-label="Bold" />
          <button
            className="ql-italic hint--bottom-right"
            aria-label="Italic"
          />
          <button
            className="ql-underline hint--bottom-right"
            aria-label="Underline"
          />
        </span>
        <span className="ql-formats">
          <button
            className="ql-list hint--bottom-right"
            value="ordered"
            aria-label="Numbered list"
          />
          <button
            className="ql-list hint--bottom-right"
            value="bullet"
            aria-label="Bullet list"
          />
          <select
            className="ql-align hint--bottom-right"
            aria-label="Alignment"
          />
        </span>
        <span className="ql-formats">
          <button
            className="ql-link hint--bottom-right"
            aria-label="Hyperlink"
          />
        </span>
        <span className="ql-formats">
          <button
            className="ql-image hint--bottom-right"
            aria-label="Image"
          />
        </span>
        <span className="ql-formats">
          <select
            className="ql-color"
            aria-label="Color"
          />
          <select
            className="ql-background"
            aria-label="Background"
          />
        </span>
      </div>
    </>
  );

  return (
    <>
      {toolbar}
      <ReactQuill
        ref={quillRef}
        defaultValue={value}
        onChange={(contents: string): void => {
          if (quillRef) {
            const editor = quillRef.current?.getEditor();
            if (!editor?.getText().trim()) {
              handleChange('');
            } else {
              handleChange(contents);
            }
          }
        }}
        placeholder={placeholder}
        modules={modules}
      />
    </>
  );
};

export default WysiwygInput;
