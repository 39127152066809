import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import {
  IonInput,
  IonItem,
  IonButton,
  IonList,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonText,
  IonAlert,
} from '@ionic/react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import useProvidersStyles from './styles';
import useOnboardingStyles from './Onboarding/styles';
import useAppClasses from '../styles';
import Credentials from '../../interfaces/Credentials';
import useForm from '../../lib/hooks/useForm';
import { useStoreState, useStoreActions } from '../../store';
import routePaths from '../../lib/routePaths';
import OnboardingPage from './components/OnboardingPage';

const initial = {
  email: '',
  password: '',
};

const getSchema = (intl: IntlShape) => yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(intl.formatMessage({
      id: 'app.signin.emailinvalid',
    }))
    .required(intl.formatMessage({
      id: 'app.signin.emailrequired',
    })),
  password: yup
    .string()
    .trim()
    .required(intl.formatMessage({
      id: 'app.signin.passwordrequired',
    })),
});

const SignIn: React.FC = () => {
  const intl = useIntl();
  const providersClasses = useProvidersStyles();
  const eligibilityClasses = useOnboardingStyles();
  const appClasses = useAppClasses();
  const { error } = useStoreState(state => state.auth);
  const { login } = useStoreActions(actions => actions.auth);
  const { resetEmailRequested } = useStoreState(actions => actions.password);
  const locale = useStoreState(state => state.translations.language);
  const {
    sendPasswordResetEmail,
    setResetEmailRequested,
  } = useStoreActions(actions => actions.password);
  const [alertText, setAlertText] = useState<string>('');
  const {
    errors, values, onChange, handleSubmit, reset, setSchema,
  } = useForm<Credentials>({
    schema: getSchema(intl),
    initial,
  });

  useEffect(() => {
    const schema = getSchema(intl);
    setSchema(schema);
  }, [locale, intl, setSchema]);

  useEffect(() => {
    reset();
  }, [locale, reset]);

  const formSubmitted = (data: Credentials): void => {
    login(data);
  };

  useEffect(() => {
    if (resetEmailRequested) {
      setAlertText(intl.formatMessage({
        id: 'app.signin.passwordresetconfirmation',
      }));
      setResetEmailRequested(false);
    }
  }, [intl, locale, resetEmailRequested, setResetEmailRequested]);

  const requestEmailReset = (): void => {
    if (values.email.length === 0
      || !(/\S+@\S+\.\S+/.test(values.email))
    ) {
      setAlertText(intl.formatMessage({
        id: 'app.signin.passwordresetmessage',
      }));
    } else {
      sendPasswordResetEmail(values.email);
    }
  };

  return (
    <OnboardingPage
      content={(
        <>
          <h3 className={appClasses.title}>
            <FormattedMessage id="app.signin.title" />
          </h3>
          <form className={providersClasses.main} onSubmit={handleSubmit(formSubmitted)}>
            {error && (
            <IonCard color="danger">
              <IonCardHeader>
                <IonCardTitle>{error}</IonCardTitle>
              </IonCardHeader>
            </IonCard>
            )}
            <IonList lines="none" className={[eligibilityClasses.inputsList, 'ion-no-margin ion-no-padding'].join(' ')}>
              <IonItem className={eligibilityClasses.input}>
                <IonInput
                  inputMode="email"
                  placeholder={intl.formatMessage({
                    id: 'app.signin.email',
                  })}
                  value={values.email}
                  name="email"
                  onIonChange={onChange}
                />
              </IonItem>
              {errors.email && (
              <p className="error-message">
                <IonText color="danger">{errors.email}</IonText>
              </p>
              )}
              <IonItem className={eligibilityClasses.input}>
                <IonInput
                  value={values.password}
                  placeholder={intl.formatMessage({
                    id: 'app.signin.password',
                  })}
                  name="password"
                  type="password"
                  onIonChange={onChange}
                />
              </IonItem>
              {errors.password && (
              <p className="error-message">
                <IonText color="danger">{errors.password}</IonText>
              </p>
              )}
            </IonList>
            <div className={['', providersClasses.buttonRow].join(' ')}>
              <IonButton className="cc-link-button" routerLink={routePaths.provider.onboarding.root} fill="clear">
                <FormattedMessage id="app.signin.signup" />
              </IonButton>
              <IonButton className="cc-link-button" fill="clear" onClick={(): void => requestEmailReset()}>
                <FormattedMessage id="app.signin.forgotpassword" />
              </IonButton>
            </div>
            <div className="ion-padding">
              <IonButton className="cc-button cc-tall-button" shape="round" type="submit" expand="block">
                <FormattedMessage id="app.signin.signin" />
              </IonButton>
            </div>
          </form>
          <IonAlert
            isOpen={alertText.length > 0}
            onDidDismiss={(): void => {
              setAlertText('');
            }}
            message={alertText}
            buttons={[
              { text: 'OK' },
            ]}
          />
        </>
      )}
    />
  );
};

export default SignIn;
