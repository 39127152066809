import React, { useState } from 'react';
import {
  IonButton, IonLabel, IonSelect, IonSelectOption,
} from '@ionic/react';
import useStyles from './styles';

type HoursModalProps = {
  closeAction: Function;
  day: string;
};

const HoursModal: React.FC<HoursModalProps> = ({
  closeAction, day,
}: HoursModalProps) => {
  const classes = useStyles();
  const [openHour, setOpenHour] = useState(8);
  const [closeHour, setCloseHour] = useState(5);
  const [openMinute, setOpenMinute] = useState(0);
  const [closeMinute, setCloseMinute] = useState(0);
  const [openAMPM, setOpenAMPM] = useState('AM');
  const [closeAMPM, setCloseAMPM] = useState('PM');
  const [showError, setShowError] = useState(false);

  const closeClicked = (): void => {
    const opening = new Date();
    opening.setSeconds(0);
    opening.setMilliseconds(0);
    if (openHour === 12) {
      opening.setHours(openAMPM === 'AM' ? 0 : 12);
    } else {
      opening.setHours(openAMPM === 'AM' ? openHour : openHour + 12);
    }
    opening.setMinutes(openMinute);

    const closing = new Date();
    closing.setSeconds(0);
    closing.setMilliseconds(0);
    if (closeHour === 12) {
      closing.setHours(closeAMPM === 'AM' ? 0 : 12);
    } else {
      closing.setHours(closeAMPM === 'AM' ? closeHour : closeHour + 12);
    }
    closing.setMinutes(closeMinute);

    if (closing.getTime() <= opening.getTime()) {
      setShowError(true);
    } else {
      closeAction(day, opening, closing);
    }
  };

  return (
    <div className={classes.hoursModal}>
      <IonLabel className={classes.hoursModalHeader}>
        {`Select Operating Hours for ${day}`}
      </IonLabel>
      <div className={classes.hoursModalRow}>
        <IonLabel>
          Opening Time
        </IonLabel>
        <IonSelect
          style={{
            padding: '0px',
            width: '42px',
            marginLeft: '30px',
            color: 'var(--ion-color-primary)',
          }}
          value={openHour}
          onIonChange={(e): void => {
            setShowError(false);
            setOpenHour(e.detail.value);
          }}
        >
          <IonSelectOption value={1}>01</IonSelectOption>
          <IonSelectOption value={2}>02</IonSelectOption>
          <IonSelectOption value={3}>03</IonSelectOption>
          <IonSelectOption value={4}>04</IonSelectOption>
          <IonSelectOption value={5}>05</IonSelectOption>
          <IonSelectOption value={6}>06</IonSelectOption>
          <IonSelectOption value={7}>07</IonSelectOption>
          <IonSelectOption value={8}>08</IonSelectOption>
          <IonSelectOption value={9}>09</IonSelectOption>
          <IonSelectOption value={10}>10</IonSelectOption>
          <IonSelectOption value={11}>11</IonSelectOption>
          <IonSelectOption value={12}>12</IonSelectOption>
        </IonSelect>
        <IonLabel className="mr-5">:</IonLabel>
        <IonSelect
          style={{
            padding: '0px',
            width: '42px',
            color: 'var(--ion-color-primary)',
          }}
          value={openMinute}
          onIonChange={(e): void => {
            setShowError(false);
            setOpenMinute(e.detail.value);
          }}
        >
          <IonSelectOption value={0}>00</IonSelectOption>
          <IonSelectOption value={15}>15</IonSelectOption>
          <IonSelectOption value={30}>30</IonSelectOption>
          <IonSelectOption value={45}>45</IonSelectOption>
        </IonSelect>
        <IonSelect
          style={{
            padding: '0px',
            width: '50px',
            marginLeft: '5px',
            color: 'var(--ion-color-primary)',
          }}
          value={openAMPM}
          onIonChange={(e): void => {
            setShowError(false);
            setOpenAMPM(e.detail.value);
          }}
        >
          <IonSelectOption value="AM">AM</IonSelectOption>
          <IonSelectOption value="PM">PM</IonSelectOption>
        </IonSelect>
      </div>
      <div className={classes.hoursModalRow}>
        <IonLabel>
          Closing Time
        </IonLabel>
        <IonSelect
          style={{
            padding: '0px',
            width: '42px',
            marginLeft: '30px',
            color: 'var(--ion-color-primary)',
          }}
          value={closeHour}
          onIonChange={(e): void => {
            setShowError(false);
            setCloseHour(e.detail.value);
          }}
        >
          <IonSelectOption value={1}>01</IonSelectOption>
          <IonSelectOption value={2}>02</IonSelectOption>
          <IonSelectOption value={3}>03</IonSelectOption>
          <IonSelectOption value={4}>04</IonSelectOption>
          <IonSelectOption value={5}>05</IonSelectOption>
          <IonSelectOption value={6}>06</IonSelectOption>
          <IonSelectOption value={7}>07</IonSelectOption>
          <IonSelectOption value={8}>08</IonSelectOption>
          <IonSelectOption value={9}>09</IonSelectOption>
          <IonSelectOption value={10}>10</IonSelectOption>
          <IonSelectOption value={11}>11</IonSelectOption>
          <IonSelectOption value={12}>12</IonSelectOption>
        </IonSelect>
        <IonLabel className="mr-5">:</IonLabel>
        <IonSelect
          style={{
            padding: '0px',
            width: '42px',
            color: 'var(--ion-color-primary)',
          }}
          value={closeMinute}
          onIonChange={(e): void => {
            setShowError(false);
            setCloseMinute(e.detail.value);
          }}
        >
          <IonSelectOption value={0}>00</IonSelectOption>
          <IonSelectOption value={15}>15</IonSelectOption>
          <IonSelectOption value={30}>30</IonSelectOption>
          <IonSelectOption value={45}>45</IonSelectOption>
        </IonSelect>
        <IonSelect
          style={{
            padding: '0px',
            width: '50px',
            marginLeft: '5px',
            color: 'var(--ion-color-primary)',
          }}
          value={closeAMPM}
          onIonChange={(e): void => {
            setShowError(false);
            setCloseAMPM(e.detail.value);
          }}
        >
          <IonSelectOption value="AM">AM</IonSelectOption>
          <IonSelectOption value="PM">PM</IonSelectOption>
        </IonSelect>
      </div>
      <IonButton style={{ width: '200px', marginTop: '20px', alignSelf: 'center' }} onClick={() => closeClicked()}>Set Operating Hours</IonButton>
      <IonLabel className={classes.hoursModalError} hidden={!showError}>
        Closing Time Must Be Later Than Opening Time
      </IonLabel>
    </div>
  );
};

export default HoursModal;
