import {
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getTranslatedString, translateProp } from '../lib/utils';
import { useStoreActions, useStoreState } from '../store';
import appMenuItems from '../lib/appMenuItems';
import useStyles from './styles';
import routePaths from '../lib/routePaths';

const DesktopMenu: React.FunctionComponent = () => {
  const classes = useStyles();
  const { setLocale } = useStoreActions((actions) => actions.translations);
  const { language } = useStoreState((state) => state.translations);
  const { pagesByCategoryId } = useStoreState((state) => state.pages);
  const history = useHistory();
  const [valueChanger, setValueChanger] = useState(0);

  return (
    <div className={classes.publicDesktopMenuContainer}>
      <IonSelect
        style={{
          padding: '0px',
          color: 'var(--ion-color-primary)',
        }}
        interface="popover"
        interfaceOptions={{
          showBackdrop: false,
          cssClass: classes.desktopNavSelect,
          header: getTranslatedString('select-language', language),
        }}
        value={language}
        onIonChange={(e): void => {
          setLocale(e.detail.value);
        }}
      >
        <IonSelectOption value="en">English</IonSelectOption>
        <IonSelectOption value="es">Español</IonSelectOption>
      </IonSelect>
      {appMenuItems.map((navItem) => (
        <IonSelect
          key={navItem.navTitleId}
          style={{
            padding: '0px',
            color: 'var(--ion-color-primary)',
          }}
          interface="popover"
          interfaceOptions={{
            showBackdrop: false,
            cssClass: classes.desktopNavSelect,
          }}
          selectedText={getTranslatedString(navItem.navTitleId, language)}
          onIonChange={(e): void => {
            history.push(e.detail.value.substring((e.detail.value as string).indexOf('/') + 1));
            setValueChanger(valueChanger + 1);
          }}
        >
          {(pagesByCategoryId.get(Number(navItem.navbar_category)) || [])
            .map((page) => (
              <IonSelectOption
                value={`${valueChanger}/${routePaths.public.page.replace(':slug', page.slug || '')}`}
                className={classes.desktopNavOption}
                key={page.id}
              >
                {translateProp(page, 'navbar_title', language)}
              </IonSelectOption>
            ))}
          {(navItem.children || []).map((child) => (
            <IonSelectOption
              value={`${valueChanger}/${child.url}`}
              className={classes.desktopNavOption}
              key={child.navTitleId}
            >
              {getTranslatedString(child.navTitleId, language)}
            </IonSelectOption>
          ))}
        </IonSelect>
      ))}
    </div>
  );
};

export default DesktopMenu;
