/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FCM } from '@capacitor-community/fcm';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { Storage } from '@capacitor/storage';
import {
  IonIcon, IonLabel, IonPage, IonRouterOutlet, IonSplitPane, IonTabBar, IonTabButton, IonTabs, isPlatform, setupIonicReact,
} from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/typography.css';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Redirect, Route, useHistory, useLocation,
} from 'react-router-dom';
import { Browser } from '@capacitor/browser';
import Admin from './apps/Admin';
import EmailVerify from './apps/Email/Verify';
import ChangePassword from './apps/Password/ChangePassword';
import Provider from './apps/Provider';
import Public from './apps/Public';
import Menu from './components/Menu';
import menuItems from './components/MenuItems';
import FirebaseData from './interfaces/FirebaseData';
import client from './lib/api/client';
import useIsDesktop from './lib/hooks/useIsDesktop';
import routePaths from './lib/routePaths';
import { useStoreActions, useStoreState } from './store';
/* Theme variables */
import './theme/index.css';
import './theme/variables.css';

setupIonicReact();

const App: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [showToolbar, setShowToolbar] = useState(true);
  const [selectedPage, setSelectedPage] = useState('');
  const [registeredPushNotification, setRegisteredPushNotification] = useState(false);

  // // required to keep user info in sync via a computed property
  useStoreState((state) => state.analytics.user);
  const { getGeneralNotifications, getUrgentNotifications } = useStoreState(state => state.settings);
  const findHotlines = useStoreActions((actions) => actions.crisisHotlines.find);
  const {
    auth: { reAuth, setError },
    settings: { loadSettings, setGetGeneralNotifications, setGetUrgentNotifications },
    meta: { get: metaGet },
    favorites: { loadFavoriteListings },
    translations: { setLocale, loadSavedLanguage },
    pages: { find: loadPages },
    analytics: { initialize, createEvent },
  } = useStoreActions(
    ({
      auth, meta, settings, favorites, translations, pages, analytics,
    }) => ({
      auth,
      meta,
      settings,
      favorites,
      translations,
      pages,
      analytics,
    }),
  );

  const initializePushNotifications = useCallback(() => {
    PushNotifications.addListener('registration', () => {
      setRegisteredPushNotification(true);
    });
    PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      if (notification.actionId === 'tap') {
        const { data } = notification.notification;
        if (data?.link) {
          Browser.open({ url: data.link });
        }
      }
    });
    FCM.setAutoInit({ enabled: true });
    PushNotifications.register();
  }, []);

  useEffect(() => {
    if (getGeneralNotifications || getUrgentNotifications) {
      PushNotifications.checkPermissions().then(res => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then(newRes => {
            if (newRes.receive === 'denied') {
              setGetGeneralNotifications(false);
              setGetUrgentNotifications(false);
            } else {
              initializePushNotifications();
            }
          });
        } else {
          initializePushNotifications();
        }
      });
    }
  }, [getGeneralNotifications, getUrgentNotifications, initializePushNotifications, setGetGeneralNotifications, setGetUrgentNotifications]);

  useEffect(() => {
    if (registeredPushNotification) {
      if (getUrgentNotifications) {
        FCM.subscribeTo({ topic: 'urgent' });
      } else {
        FCM.unsubscribeFrom({ topic: 'urgent' });
      }

      if (getGeneralNotifications) {
        FCM.subscribeTo({ topic: 'general' });
      } else {
        FCM.unsubscribeFrom({ topic: 'general' });
      }
    }
  }, [getGeneralNotifications, getUrgentNotifications, registeredPushNotification]);

  useEffect(() => {
    if (
      location.pathname === routePaths.public.crisis
      || location.pathname === routePaths.public.onboarding
    ) {
      setShowToolbar(false);
    } else if (!showToolbar) {
      setShowToolbar(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    document.addEventListener('onFirebaseData', (data: unknown) => {
      const { route } = data as FirebaseData;
      history.push(route);
    });
  }, [history]);

  useEffect(() => {
    if (selectedPage !== location.pathname) {
      setSelectedPage(location.pathname);
    }
  }, [selectedPage, location.pathname]);

  useEffect(() => {
    const currentVersion = '0.0.25';
    (async (): Promise<void> => {
      const version = await Storage.get({ key: 'version' });
      if (!version.value) {
        await Storage.clear();
        await Storage.set({ key: 'version', value: currentVersion });
        const lang = await Device.getLanguageCode();
        if (lang.value.startsWith('es')) {
          setLocale('es');
        }
      } else if (version.value !== currentVersion) {
        await Storage.set({ key: 'version', value: currentVersion });
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      const onboarded = await Storage.get({ key: 'seeker_onboarded' });
      if (isPlatform('mobile') && onboarded) {
        if (getGeneralNotifications || getUrgentNotifications) {
          PushNotifications.checkPermissions().then(res => {
            if (res.receive !== 'granted') {
              PushNotifications.requestPermissions().then(newRes => {
                if (newRes.receive === 'denied') {
                  setGetGeneralNotifications(false);
                  setGetUrgentNotifications(false);
                }
              });
            } else {
              initializePushNotifications();
            }
          });
        }
      }
    })();
  }, [getGeneralNotifications, getUrgentNotifications, initializePushNotifications, setGetGeneralNotifications, setGetUrgentNotifications]);

  useEffect(() => {
    (async (): Promise<void> => {
      await metaGet();
      await loadFavoriteListings();
      await loadSettings();
      await loadSavedLanguage();
      await loadPages();
      await findHotlines();
      reAuth();
      await initialize();
      await createEvent({
        type: 'app-load',
        meta: {},
      });
    })();
  }, [reAuth, metaGet, loadFavoriteListings, loadSettings, loadSavedLanguage, loadPages, initialize, createEvent, findHotlines]);

  useEffect(() => {
    client.hooks({
      error: [
        (context): void => {
          if (
            context.error.message.includes('jwt')
            || context.error.message.includes('auth')
          ) {
            setError('Session expired.');
            history.push(routePaths.provider.signin);
          }
          throw context.error;
        },
      ],
    });
  }, [history, setError]);

  const isDesktop = useIsDesktop();
  const [resizeCount, setResizeCount] = useState(0);
  useEffect(() => {
    setResizeCount((prev) => prev + 1);
  }, [isDesktop]);

  if (location.pathname.startsWith(routePaths.email.root)) {
    return (
      <IonRouterOutlet>
        <Route
          exact
          path={routePaths.email.verifyToken}
          component={EmailVerify}
        />
        <Route exact path={routePaths.email.verify} component={EmailVerify} />
        <Redirect to={routePaths.email.verify} />
      </IonRouterOutlet>
    );
  }

  if (location.pathname.startsWith(routePaths.password.root)) {
    return (
      <IonRouterOutlet>
        <Route
          exact
          path={routePaths.password.resetToken}
          component={ChangePassword}
        />
        <Route
          exact
          path={routePaths.password.reset}
          component={ChangePassword}
        />
        <Redirect to={routePaths.password.reset} />
      </IonRouterOutlet>
    );
  }

  // Desktop users seeing admin do not see split pane.
  if (isDesktop && location.pathname.startsWith(routePaths.admin.root)) {
    return (
      <IonRouterOutlet>
        <Route path={routePaths.admin.root} component={Admin} />
      </IonRouterOutlet>
    );
  }

  // Mobile users seeing admin do see "split pane" in that they have a menu that loads other content.

  return isDesktop
    || location.pathname.startsWith(routePaths.admin.root)
    || location.pathname.startsWith(routePaths.provider.approved.root) ? (
      <IonSplitPane
        contentId="desktopMain"
        when={isDesktop ? 'sm' : '(min-width: 880px)'}
      >
        <Menu selectedPage={selectedPage} />
        <div
          id="desktopMain"
          style={{
            width: resizeCount > 1 ? '100%' : undefined,
            position: resizeCount > 1 ? 'relative' : undefined,
          }}
        >
          <IonRouterOutlet>
            <Route path={routePaths.provider.root} component={Provider} />
            <Route path={routePaths.public.root} component={Public} />
            <Route path={routePaths.admin.root} component={Admin} />
            <Redirect to={routePaths.public.root} />
          </IonRouterOutlet>
        </div>
      </IonSplitPane>
    ) : (
      <IonSplitPane contentId="mobileMain" when="(min-width: 880px)">
        <Menu selectedPage={selectedPage} />
        <IonPage id="mobileMain">
          <IonTabs>
            <IonRouterOutlet>
              <Route path={routePaths.provider.root} component={Provider} />
              <Route path={routePaths.public.root} component={Public} />
              <Route path={routePaths.admin.root} component={Admin} />
              <Redirect to={routePaths.public.root} />
            </IonRouterOutlet>
            <IonTabBar hidden={!showToolbar} slot="bottom">
              {menuItems
                .filter((menuItem) => menuItem.tabInfo)
                .sort(
                  (a, b) => {
                    if (a.tabInfo && b.tabInfo) {
                      return a.tabInfo.tabOrderIndex - b.tabInfo.tabOrderIndex;
                    }
                    return 1;
                  },
                )
                .map((tabItem) => (
                  <IonTabButton
                    selected={selectedPage === tabItem.url}
                    key={tabItem.tabInfo?.tabTitleId}
                    tab={tabItem.tabInfo?.tabTitleId}
                    href={tabItem.url}
                  >
                    <IonIcon icon={tabItem.icon} />
                    <IonLabel className="ion-text-wrap">
                      <FormattedMessage id={tabItem.tabInfo?.tabTitleId} />
                    </IonLabel>
                  </IonTabButton>
                ))}
            </IonTabBar>
          </IonTabs>
        </IonPage>
      </IonSplitPane>
    );
};

export default App;
