import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonLabel,
  IonLoading,
} from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { Network } from '@capacitor/network';
import Map from '../../components/Map';
import useClasses from '../../components/styles';
import Listing from '../../interfaces/Listing';
import SearchResult, { Filter } from '../../interfaces/SearchResult';
import { listings } from '../../lib/api/services';
import usePrevious from '../../lib/hooks/usePrevious';
import useService from '../../lib/hooks/useService';
import routePaths from '../../lib/routePaths';
import { getFiltersFromQuery, translateName } from '../../lib/utils';
import { useStoreActions, useStoreState } from '../../store';
import ListingCard from './ListingCard';
import SearchHeader from './SearchHeader';
import Search from './SearchInput';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import PaginatedListingCard from './PaginatedListingCard';

const SearchResults: React.FC = () => {
  const resultPageSize = 5;
  const location = useLocation();
  const classes = useClasses();
  const history = useHistory();
  const { data } = useStoreState((state) => state.meta);
  const { favoriteListings } = useStoreState((state) => state.favorites);
  const { loading: mapLoading, data: searchResults } = useStoreState(
    (state) => state.search,
  );
  const { replaceFavoriteListings } = useStoreActions(
    (actions) => actions.favorites,
  );
  const { setCenter } = useStoreActions((actions) => actions.map);
  const { updateFilters } = useStoreActions((actions) => actions.search);
  const { createEvent } = useStoreActions((actions) => actions.analytics);
  const {
    filters: initialFilters,
    term: initialTerm,
    category: selectedCategory,
  } = getFiltersFromQuery({
    locationSearch: location.search,
    categories: data?.category || [],
  });
  const [filters, setFilters] = useState<Filter>(initialFilters);
  const [noMoreData, setNoMoreData] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string | null>(initialTerm);
  const [showMap, setShowMap] = useState(false);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [multiSelectedListings, setMultiSelectedListings] = useState<Listing[]>(
    [],
  );
  const [skip, setSkip] = useState<number>(0);
  const [page, setPage] = useState({ limit: resultPageSize, skip });
  const { loading: listingsLoading, result } = useService<Listing>(
    listings,
    page,
    filters,
    true,
  );
  const locale = useStoreState((state) => state.translations.language);
  const prevResult = usePrevious(result);
  const [fetchedListings, setFetchedListings] = useState<Listing[]>([]);
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (result && prevResult) {
      if (result.skip !== prevResult.skip) {
        if (!noMoreData) {
          setFetchedListings([...fetchedListings].concat(result.data));
          setNoMoreData(result.data.length < resultPageSize);
        }
      } else if (result.skip === prevResult.skip) {
        setFetchedListings(result.data);
      }
      createEvent({
        type: 'search',
        meta: filters,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  useEffect(() => {
    // Replaced any cached (favorite) listing with potentially updated ones returned from server.
    replaceFavoriteListings(fetchedListings);
  }, [fetchedListings, replaceFavoriteListings]);

  useEffect(() => {
    const { filters: newFilters, term } = getFiltersFromQuery({
      locationSearch: location.search,
      categories: data?.category || [],
    });

    if (JSON.stringify(newFilters) !== JSON.stringify(filters)) {
      setFilters(newFilters);
      setFetchedListings([]);
      setSelectedListing(null);
      setMultiSelectedListings([]);
      setPage({ limit: resultPageSize, skip: 0 });
      setNoMoreData(false);
      if (term !== searchTerm) {
        setSearchTerm(term);
      }
    }
  }, [filters, location, data, searchTerm]);

  useEffect(() => {
    updateFilters(filters);
  }, [updateFilters, filters]);

  const searchNext = ($event: CustomEvent<void>): void => {
    if (!noMoreData) {
      setSkip(skip + resultPageSize);
      setPage({ limit: page.limit, skip: page.skip + resultPageSize });
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  };

  const goToListingDetail = (id: number | undefined): void => {
    if (id) {
      const path = routePaths.public.listingDetail.replace(':id', `${id}`);
      history.push(path);
    }
  };

  const showListingInfo = useCallback(
    (info: { id: number } | null | number[] | number): void => {
      if (!info) return setSelectedListing(null);
      let id = info;
      if (Array.isArray(info)) {
        [id] = info;
      } else if (typeof info === 'object') {
        id = info.id;
      }
      const listing = location.pathname === routePaths.public.favorites
        ? favoriteListings.find((l) => l.id === id)
        : fetchedListings.find((l) => l.id === id);
      if (
        listing
        && (selectedListing ? selectedListing.id !== listing.id : true)
      ) {
        setSelectedListing(listing);
        setCenter({
          latitude: listing?.address?.latitude || 0,
          longitude: listing?.address?.longitude || 0,
        });
        setMultiSelectedListings([]);
      } else {
        (async (): Promise<void> => {
          const foundListing = await listings.get(id);
          setSelectedListing(foundListing);
          setMultiSelectedListings([]);
          setCenter({
            latitude: foundListing?.address?.latitude || 0,
            longitude: foundListing?.address?.longitude || 0,
          });
        })();
      }
    },
    [
      fetchedListings,
      selectedListing,
      favoriteListings,
      location.pathname,
      setCenter,
    ],
  );

  useEffect(() => {
    Network.getStatus().then((status) => {
      setIsConnected(status.connected);
    });
  }, []);
  const listingCard = (l: Listing): JSX.Element => (
    <div
      onClick={(): void => goToListingDetail(l.id)}
      key={l.id}
      className={
        selectedCategory
          ? classes.listingWrapper
          : classes.listingWrapperNoTopMargin
      }
    >
      <ListingCard
        listing={l}
        isFavorite={
          favoriteListings.filter((fav) => fav.id === l.id).length > 0
        }
      />
    </div>
  );

  return (
    <div className={[classes.searchResults, showMap ? 'map' : ''].join(' ')}>
      {/* <IonLabel>abc</IonLabel> */}
      {location.pathname === routePaths.public.favorites ? null : (
        <div className={classes.searchResultsHeaderWrapper}>
          <Search />
          <SearchHeader
            category={selectedCategory}
            searchResults={result.total || 0}
            showMap={showMap}
            setShowMap={setShowMap}
          />
        </div>
      )}
      {showMap ? (
        <>
          <div className={classes.mapContainer}>
            <Map
              data={(
                (Array.isArray(searchResults)
                  ? (searchResults as unknown)
                  : []) as SearchResult[]
              ).map((l) => ({
                id: l.id || 0,
                name: translateName(l, locale),
                latitude: l.latitude || 0,
                longitude: l.longitude || 0,
                icon: 'marker',
              }))}
              showMultiPopup={isDesktop}
              onSelected={showListingInfo}
              onMultiSelected={(items): void => {
                setSelectedListing(null);
                if (items.length) {
                  const listing = items[0];
                  setCenter({
                    latitude: listing?.address?.latitude || 0,
                    longitude: listing?.address?.longitude || 0,
                  });
                }
                setMultiSelectedListings(items);
              }}
            />
          </div>
          {selectedListing ? (
            <div onClick={(): void => goToListingDetail(selectedListing.id)}>
              <ListingCard
                listing={selectedListing}
                isFavorite={
                  favoriteListings.filter(
                    (fav) => fav.id === selectedListing.id,
                  ).length > 0
                }
              />
            </div>
          ) : null}
          {multiSelectedListings.length ? (
            <PaginatedListingCard
              items={multiSelectedListings}
              goToListingDetail={goToListingDetail}
              favoriteListings={favoriteListings}
            />
          ) : null}
        </>
      ) : (
        <>
          {location.pathname === routePaths.public.favorites
            ? favoriteListings.map((l) => listingCard(l))
            : fetchedListings.map((l) => listingCard(l))}
          {location.pathname === routePaths.public.favorites
          && !favoriteListings.length ? (
            <div className={classes.searchResultsEmptyCategory}>
              <FormattedMessage id="no-favorites-selected" />
            </div>
            ) : location.pathname !== routePaths.public.favorites
            && !fetchedListings.length ? (
                !selectedCategory ? (
                  <div className={classes.searchResultsEmpty}>
                    <IonLabel className="ion-text-wrap">{`No matching results for "${searchTerm}"`}</IonLabel>
                  </div>
                ) : (
                  <div className={classes.searchResultsEmptyCategory}>
                    <FormattedMessage id="no-matching-results" />
                  </div>
                )
              ) : null}
          <IonInfiniteScroll
            threshold="100px"
            onIonInfinite={(e: CustomEvent<void>): void => searchNext(e)}
          >
            <IonInfiniteScrollContent />
          </IonInfiniteScroll>
        </>
      )}
      {(listingsLoading || mapLoading) && isConnected ? (
        <IonLoading isOpen={listingsLoading || mapLoading} />
      ) : null}
    </div>
  );
};

export default SearchResults;
