import { IonButton, IonLabel, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from './Icon';
import useClasses from './styles';

const MAX_FILE_SIZE = 5120000;
interface UploadComponentProps {
  name: string;
  accept: string[];
  fileSizeLimit?: number;
  url?: string | null;
  onChange?(file: File): void;
}

const UploadComponent: React.FunctionComponent<UploadComponentProps> = ({
  name,
  accept,
  fileSizeLimit,
  url,
  onChange,
}: UploadComponentProps) => {
  const primaryColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--ion-color-primary');
  const [file, setFile] = useState<File | null>(null);
  const [inputElement, setInputElement] = useState({} as HTMLInputElement);
  const [errors, setErrors] = useState<string | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(url || '');
  const classes = useClasses();

  useEffect(() => {
    if (url) {
      setImageUrl(url);
    } else {
      setFile(null);
      setImageUrl('');
    }
  }, [url]);

  const onFileSelect = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setErrors(null);
    const sizeLimit = fileSizeLimit || MAX_FILE_SIZE;
    const selectedFile = (e.nativeEvent.target as HTMLInputElement).files?.item(
      0,
    );
    if (selectedFile) {
      if (sizeLimit && selectedFile.size > sizeLimit) {
        setErrors('Image is too large, limit is 5MB');
        return;
      }

      const type = accept.find((a) => a === selectedFile.type);
      if (type) {
        const objUrl = URL.createObjectURL(selectedFile);
        setImageUrl(objUrl);
        setFile(selectedFile);
        if (onChange) {
          onChange(selectedFile);
        }
      } else {
        setErrors(
          `Selected format not accepted, please use one of the following: ${accept
            .map((a) => `.${a.split('/')[1]}`)
            .join(', ')}`,
        );
      }
    }
  };

  return (
    <>
      <div className={classes.uploadComponentWrapper}>
        <IonButton onClick={(): void => inputElement.click()}>
          {file === null && !imageUrl && (
            <div className={classes.uploadComponentTextWrapper}>
              <Icon name="camera" width={40} color={primaryColor} />
              <IonLabel><FormattedMessage id="image-upload" /></IonLabel>
            </div>
          )}

          {(file || imageUrl) && (
            <div className={classes.uploadComponentCameraIcon}>
              <Icon name="camera" width={30} color={primaryColor} />
            </div>
          )}

          {imageUrl && <img srcSet={imageUrl || ''} alt="listing" />}

          <input
            name={name}
            hidden
            accept={accept?.join(',')}
            type="file"
            ref={(el): void | null => (el !== null ? setInputElement(el) : null)}
            onChange={onFileSelect}
          />
        </IonButton>
      </div>
      {errors && (
        <p className="error-message">
          <IonText color="danger">{errors}</IonText>
        </p>
      )}
    </>
  );
};

export default UploadComponent;
