import {
  IonButton,
  IonButtons,
  IonContent, IonHeader,
  IonIcon, IonItem,
  IonLabel, IonModal,
  IonToolbar,
} from '@ionic/react';
import {
  callSharp,
  caretBack, caretForward, documentsSharp, globeSharp,
  languageSharp, location,
  mailSharp, peopleSharp, personSharp, timeSharp,
  walletSharp,
} from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import directionsIcon from '../../../../assets/directions_icon.png';
// @ts-ignore
import emailIcon from '../../../../assets/email_icon.png';
// @ts-ignore
import phoneIcon from '../../../../assets/phone_icon.png';
// @ts-ignore
import websiteIcon from '../../../../assets/website_icon.png';
import Listing, { Hour } from '../../../../interfaces/Listing';
import useIsDesktop from '../../../../lib/hooks/useIsDesktop';
import routePaths from '../../../../lib/routePaths';
import { goToDirections } from '../../../../lib/utils';
import { useStoreActions, useStoreState } from '../../../../store';
import useAppStyles from '../../../styles';
import useStyles from './styles';

const ServiceListingsPreview: React.FC = () => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const contentRef = useRef(null);
  const { currentProvider } = useStoreState((state) => state.provider);
  const { getProviderById } = useStoreActions(
    providerActions => providerActions.provider,
  );
  const { listingPreviewModalListings } = useStoreState((state) => state.admin);
  const { setListingPreviewModalListings } = useStoreActions((state) => state.admin);
  const {
    setApproval,
    setSuccess,
    remove,
  } = useStoreActions((actions) => actions.serviceListings);
  const { success } = useStoreState((state) => state.serviceListings);
  const [listingToDisplay, setListingToDisplay] = useState<Listing | null>(null);
  const [listingIdxToDisplay, setListingIdxToDisplay] = useState<number>(0);
  const [showSpanishVersion, setShowSpanishVersion] = useState<boolean>(false);
  const isDesktop = useIsDesktop();
  const classes = useStyles();
  const appClasses = useAppStyles();
  const history = useHistory();

  useEffect(() => {
    setSuccess(false);
    if (listingPreviewModalListings.length > listingIdxToDisplay) {
      setListingToDisplay(listingPreviewModalListings[listingIdxToDisplay]);
      setConfirmDelete(false);
    }
  }, [listingIdxToDisplay, listingPreviewModalListings, setSuccess]);

  useEffect(() => {
    setListingIdxToDisplay(0);
    setConfirmDelete(false);
  }, [currentProvider, listingPreviewModalListings]);

  useEffect(() => {
    if (success) {
      setListingPreviewModalListings([]);
    }
  }, [currentProvider, getProviderById, setListingPreviewModalListings, success]);

  useEffect(() => {
    if (confirmDelete && contentRef.current) {
      // @ts-ignore
      contentRef.current.scrollToBottom();
    }
  }, [confirmDelete]);

  const getHoursText = (openString: string, closeString: string): string => {
    const openTime = new Date(`1970/01/01 ${openString}`);
    const closeTime = new Date(`1970/01/01 ${closeString}`);
    const openStr = openTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    const closeStr = closeTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return `${openStr} - ${closeStr}`;
  };

  const formatHours = (dayOfWeek: number): string => {
    let hoursString = '';
    listingToDisplay?.hours.filter((item) => item.day_of_week === dayOfWeek)
      .forEach((hourForDay: Hour) => {
        if (hourForDay.is_closed) {
          hoursString = 'Closed';
        } else if (hourForDay.open_24_hours) {
          hoursString = 'Open 24 Hours';
        } else if (hourForDay.open.length > 0 && hourForDay.close.length > 0) {
          hoursString += `${hoursString.length > 0 ? ', ' : ''}${getHoursText(hourForDay.open, hourForDay.close)}`;
        }
      });
    return ((hoursString.length === 0) ? 'Call for Hours' : hoursString);
  };

  return (
    <IonModal
      onDidDismiss={(): void => {
        setListingPreviewModalListings([]);
      }}
      isOpen={listingPreviewModalListings.length > 0}
    >
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons collapse slot="end">
            <IonButton onClick={(): void => {
              setListingPreviewModalListings([]);
            }}
            >
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className={classes.modalContent} ref={contentRef}>
        { (listingToDisplay?.translation?.es?.name.length ?? 0) > 0 ? (
          <IonButton
            shape="round"
            fill="outline"
            color="primary"
            onClick={(): void => {
              setShowSpanishVersion(!showSpanishVersion);
            }}
          >
            { showSpanishVersion ? 'English' : 'Spanish' }
          </IonButton>
        ) : null }
        <div className={classes.modalPageControls}>
          <IonButton
            hidden={listingPreviewModalListings.length === 1}
            fill="clear"
            color="primary"
            onClick={(): void => {
              let newIdx = listingIdxToDisplay - 1;
              if (newIdx < 0) {
                newIdx = listingPreviewModalListings.length - 1;
              }
              setListingIdxToDisplay(newIdx);
            }}
          >
            <IonIcon color="primary" icon={caretBack} />
            Previous
          </IonButton>
          <IonButton
            hidden={listingPreviewModalListings.length === 1}
            fill="clear"
            color="primary"
            onClick={(): void => {
              let newIdx = listingIdxToDisplay + 1;
              if (newIdx >= listingPreviewModalListings.length) {
                newIdx = 0;
              }
              setListingIdxToDisplay(newIdx);
            }}
          >
            <FormattedMessage id="next" />
            <IonIcon color="primary" icon={caretForward} />
          </IonButton>
        </div>
        <IonItem lines="none" className={classes.noMinHeightItem}>
          <IonLabel color="primary" className={classes.modalTitleLabel}>
            {showSpanishVersion ? listingToDisplay?.translation?.es?.name : listingToDisplay?.name}
          </IonLabel>
        </IonItem>
        <IonItem lines="none" className={classes.noMinHeightItem}>
          <IonLabel color="primary" className={[classes.modalOrgName, 'ion-text-wrap'].join(' ')}>
            {showSpanishVersion
              ? listingToDisplay?.translation?.es?.description
              : listingToDisplay?.description}
          </IonLabel>
        </IonItem>
        <IonItem lines="none" className={classes.noMinHeightItem}>
          <IonLabel color="primary" className={classes.modalCategories}>
            {listingToDisplay?.categories.map((category) => (category.name)).join(', ')}
          </IonLabel>
        </IonItem>
        <IonItem lines="none" className={classes.noMinHeightItem}>
          <IonLabel color="primary" className={classes.modalCategories}>
            {listingToDisplay?.sub_categories.map((category) => (category.name)).join(', ')}
          </IonLabel>
        </IonItem>
        <IonItem lines="none" className={classes.noMinHeightItem}>
          <IonLabel color="primary" className={classes.modalOrgName}>
            {currentProvider?.name}
          </IonLabel>
        </IonItem>
        <div className={classes.modalButtonRow}>
          <img
            src={directionsIcon}
            className={classes.modalIcons}
            alt="background"
            onClick={(
              e: React.MouseEvent<HTMLDivElement, MouseEvent>,
            ): void => {
              if (listingToDisplay) {
                goToDirections(isDesktop, listingToDisplay);
              }
              e.stopPropagation();
            }}
          />
          <a href={`tel:${listingToDisplay?.phone_number}`}>
            <img
              src={phoneIcon}
              className={classes.modalIcons}
              alt="background"
            />
          </a>
          <a href={`mailto:${listingToDisplay?.email}`}>
            <img
              src={emailIcon}
              className={classes.modalIcons}
              alt="background"
            />
          </a>
          <a
            href={(listingToDisplay?.websites && listingToDisplay?.websites.length > 0) ? listingToDisplay.websites[0].url : ' '}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={websiteIcon}
              className={classes.modalIcons}
              alt="background"
            />
          </a>
        </div>
        <div className={classes.modalDivider} />
        <IonItem lines="none" className={classes.modalIconRow}>
          <IonIcon icon={timeSharp} color="primary" className="mr-16" />
          <IonLabel color="primary" className="ion-text-wrap">
            {`Sunday: ${formatHours(0)}`}<br />
            {`Monday: ${formatHours(1)}`}<br />
            {`Tuesday: ${formatHours(2)}`}<br />
            {`Wednesday: ${formatHours(3)}`}<br />
            {`Thursday: ${formatHours(4)}`}<br />
            {`Friday: ${formatHours(5)}`}<br />
            {`Saturday: ${formatHours(6)}`}
          </IonLabel>
        </IonItem>
        <div className={classes.modalDivider} />
        <IonItem lines="none" className={classes.modalIconRow}>
          <IonIcon icon={location} color="primary" className="mr-16" />
          <IonLabel color="primary" className="ion-text-wrap">
            {`${listingToDisplay?.address.street_address_1}, FL, ${listingToDisplay?.address.zip_code}`}
          </IonLabel>
        </IonItem>
        <div className={classes.modalDivider} />
        <IonItem lines="none" className={classes.modalIconRow}>
          <IonIcon icon={callSharp} color="primary" className="mr-16" />
          <IonLabel color="primary" className="ion-text-wrap">
            {listingToDisplay?.phone_number}
          </IonLabel>
        </IonItem>
        <div className={classes.modalDivider} />
        <IonItem lines="none" className={classes.modalIconRow}>
          <IonIcon icon={mailSharp} color="primary" className="mr-16" />
          <IonLabel color="primary" className="ion-text-wrap">
            {listingToDisplay?.email}
          </IonLabel>
        </IonItem>
        {listingToDisplay?.websites && (
        <div className={classes.modalDivider} />
        )}
        {listingToDisplay?.websites?.map((w, i) => {
          const itemKey = `website-${i}`;
          return (
            <IonItem key={itemKey} lines="none" className={classes.modalIconRow}>
              <IonIcon icon={globeSharp} color="primary" className="mr-16" />
              <IonLabel color="primary" className="ion-text-wrap">
                {w.url}
              </IonLabel>
            </IonItem>
          );
        })}
        <div className={classes.modalDivider} />
        <IonItem lines="none" className={classes.modalIconRow}>
          <IonIcon icon={walletSharp} color="primary" className="mr-16" />
          <IonLabel color="primary" className="ion-text-wrap">
            {`${listingToDisplay?.insurance_required ? 'Insurance Required' : 'Insurance Not Required'}`}<br />
            {`${listingToDisplay?.insurance.map((ins) => (ins.name)).join(', ')}`}
          </IonLabel>
        </IonItem>
        <div className={classes.modalDivider} />
        <IonItem lines="none" className={classes.modalIconRow}>
          <IonIcon icon={peopleSharp} color="primary" className="mr-16" />
          <IonLabel color="primary" className="ion-text-wrap">
            {(listingToDisplay?.groups?.length ?? 0) > 0 ? listingToDisplay?.groups.map((group) => (group.name)).join(', ') : 'N/A'}
          </IonLabel>
        </IonItem>
        <div className={classes.modalDivider} />
        <IonItem lines="none" className={classes.modalIconRow}>
          <IonIcon icon={personSharp} color="primary" className="mr-16" />
          <IonLabel color="primary" className="ion-text-wrap">
            {listingToDisplay?.age_ranges.map((age) => (age.name)).join(', ')}
          </IonLabel>
        </IonItem>
        <div className={classes.modalDivider} />
        <IonItem lines="none" className={classes.modalIconRow}>
          <IonIcon icon={languageSharp} color="primary" className="mr-16" />
          <IonLabel color="primary" className="ion-text-wrap">
            {listingToDisplay?.languages.map((lang) => (lang.name)).join(', ')}
          </IonLabel>
        </IonItem>
        <div className={classes.modalDivider} />
        <IonItem lines="none" className={classes.modalIconRow}>
          <IonIcon icon={documentsSharp} color="primary" className="mr-16" />
          <IonLabel color="primary" className="ion-text-wrap">
            {((listingToDisplay?.documentation_required?.length ?? 0) > 0)
              ? showSpanishVersion
                  && ((listingToDisplay?.translation?.es?.documentation_required?.length ?? 0) > 0)
                ? listingToDisplay?.translation?.es?.documentation_required
                : listingToDisplay?.documentation_required
              : 'N/A'}
          </IonLabel>
        </IonItem>
        {currentProvider?.approved_date ? (
          !listingToDisplay?.approved_date ? (
            <div className={[appClasses.spaceBetweenRow, classes.confirmationButtons, 'mt-24'].join(' ')}>
              <IonButton
                className={[appClasses.minWidth175, 'cc-button'].join(' ')}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => {
                  setApproval({ listingId: (listingToDisplay?.id ?? 0), isApproved: true });
                }}
              >
                Approve Service
              </IonButton>
              <IonButton
                className={[appClasses.minWidth175, 'cc-button'].join(' ')}
                shape="round"
                fill="solid"
                color="primary"
                onClick={(): void => {
                  setApproval({ listingId: (listingToDisplay?.id ?? 0), isApproved: false });
                }}
              >
                Deny Service
              </IonButton>
            </div>
          ) : (
            <div className={[appClasses.spaceBetweenRow, classes.alignFlexEnd, 'mt-24'].join(' ')}>
              <IonButton
                className={[appClasses.minWidth175, 'cc-button'].join(' ')}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => {
                  // setListingPreviewModalListings([]);
                  history.push(`${routePaths.admin.editService}/${(listingToDisplay?.id ?? 0)}`);
                }}
              >
                Edit Service
              </IonButton>
              {!confirmDelete ? (
                <IonButton
                  className={[appClasses.minWidth175, 'cc-button'].join(' ')}
                  shape="round"
                  fill="solid"
                  color="primary"
                  onClick={(): void => {
                    setConfirmDelete(true);
                  }}
                >
                  Delete Service
                </IonButton>
              ) : (
                <div className={classes.confirmDelete}>
                  <p>Are you sure?</p>
                  <div className={classes.confirmationButtons}>
                    <IonButton
                      className={[appClasses.minWidth175, 'cc-button'].join(' ')}
                      shape="round"
                      fill="solid"
                      color="primary"
                      onClick={(): void => {
                        setConfirmDelete(false);
                      }}
                    >
                      Cancel
                    </IonButton>
                    <IonButton
                      className={[appClasses.minWidth175, 'cc-button'].join(' ')}
                      shape="round"
                      fill="solid"
                      color="secondary"
                      onClick={(): void => {
                        remove((listingToDisplay?.id ?? 0));
                      }}
                    >
                      Yes
                    </IonButton>
                  </div>
                </div>
              )}
            </div>
          )
        ) : null}
      </IonContent>
    </IonModal>
  );
};
export default ServiceListingsPreview;
