import {
  useRef, useEffect, useState, MutableRefObject,
} from 'react';

interface InViewportOptions<T> {
  elementRef: MutableRefObject<T>;
  isInViewport: boolean;
}

export default function useIsInViewport<T>(): InViewportOptions<T> {
  const elementRef = useRef<T>();
  const [isInViewport, setIsInViewport] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      setIsInViewport(!!entries[0]?.isIntersecting);
    });

    if (elementRef.current) {
      observer.observe(elementRef.current as unknown as Element);
    }

    return () => observer.disconnect();
  }, [elementRef]);

  return {
    elementRef: elementRef as MutableRefObject<T>,
    isInViewport,
  };
}
