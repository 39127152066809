import React from 'react';
import { IconProps } from './Icon';

const Benefits: React.FunctionComponent<IconProps> = ({ color, height, width }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 500 500"
  >
    <g fill={color}>
      <path
        id="Selection"
        d="M 248.00,21.74
            C 248.00,21.74 198.00,50.58 198.00,50.58
              198.00,50.58 87.00,115.58 87.00,115.58
              79.32,120.03 59.51,130.82 54.04,136.09
              44.40,145.40 41.02,156.96 41.00,170.00
              41.00,170.00 41.00,372.00 41.00,372.00
              41.01,378.11 41.42,382.13 43.36,388.00
              44.56,391.64 46.46,395.83 48.60,399.00
              61.38,417.92 78.01,417.00 98.00,417.00
              98.00,417.00 127.00,417.00 127.00,417.00
              127.00,417.00 415.00,417.00 415.00,417.00
              442.69,416.96 458.96,397.97 459.00,371.00
              459.00,371.00 459.00,271.00 459.00,271.00
              459.00,271.00 459.00,171.00 459.00,171.00
              458.85,158.24 455.39,145.20 445.96,136.09
              440.44,130.76 423.46,121.65 416.00,117.20
              416.00,117.20 327.00,65.15 327.00,65.15
              327.00,65.15 271.00,32.28 271.00,32.28
              264.81,28.75 255.12,20.87 248.00,21.74 Z
            M 421.00,163.00
            C 421.00,163.00 379.00,190.05 379.00,190.05
              379.00,190.05 295.00,242.58 295.00,242.58
              295.00,242.58 264.00,261.95 264.00,261.95
              264.00,261.95 250.00,269.19 250.00,269.19
              250.00,269.19 236.00,261.81 236.00,261.81
              236.00,261.81 205.00,242.42 205.00,242.42
              205.00,242.42 121.00,190.05 121.00,190.05
              121.00,190.05 79.00,163.00 79.00,163.00
              79.00,163.00 204.00,89.72 204.00,89.72
              204.00,89.72 236.00,70.99 236.00,70.99
              236.00,70.99 250.00,63.83 250.00,63.83
              250.00,63.83 264.00,70.85 264.00,70.85
              264.00,70.85 298.00,90.80 298.00,90.80
              298.00,90.80 421.00,163.00 421.00,163.00 Z
            M 240.00,100.00
            C 240.00,103.34 240.52,112.21 238.98,114.78
              236.88,118.27 231.90,118.44 226.00,122.47
              216.56,128.91 211.77,138.59 213.18,150.00
              215.66,169.95 237.32,174.63 253.00,179.66
              258.12,181.30 263.59,182.78 267.70,186.47
              275.41,193.38 274.08,203.23 265.00,208.08
              260.59,210.44 256.80,210.01 252.00,210.00
              244.77,209.99 238.42,209.13 233.70,202.83
              231.52,199.91 231.33,196.51 229.30,194.99
              227.18,193.40 215.25,194.00 212.00,194.00
              212.01,196.65 211.92,199.41 212.53,202.00
              215.45,214.35 228.10,222.85 240.00,225.00
              240.00,225.00 240.00,242.00 240.00,242.00
              240.00,242.00 265.00,242.00 265.00,242.00
              265.00,242.00 265.00,225.00 265.00,225.00
              274.97,225.49 288.47,215.55 291.35,206.00
              292.24,203.06 292.04,196.32 292.00,193.00
              291.86,183.11 287.32,179.55 282.00,172.00
              278.14,171.27 273.23,167.60 269.00,165.92
              259.91,162.32 248.06,160.77 240.00,155.79
              235.72,153.14 231.36,148.44 232.89,143.00
              235.16,134.98 243.53,132.09 251.00,132.00
              254.43,131.96 258.87,131.69 262.00,133.14
              268.25,136.03 270.98,141.54 272.00,148.00
              272.00,148.00 290.00,148.00 290.00,148.00
              289.86,132.58 280.60,119.93 265.00,117.00
              265.00,117.00 265.00,100.00 265.00,100.00
              265.00,100.00 240.00,100.00 240.00,100.00 Z"
      />
    </g>
  </svg>
);

export default Benefits;
