import { action, thunk } from 'easy-peasy';
import AuthModel from '../interfaces/StoreModel/AuthModel';
import client from '../lib/api/client';
import ModuleStore from '../interfaces/ModuleStore';

const model: AuthModel = {
  error: '',
  loading: true,
  user: null,
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  // @ts-ignore
  reset: thunk((state, payload, store: ModuleStore<AuthModel>) => {
    store.getStoreActions().reset();
  }),
  login: thunk(async (actions, credentials) => {
    actions.setLoading(true);
    actions.setError('');
    try {
      const info = await client.authenticate({
        strategy: 'local',
        ...credentials,
      });
      actions.setUser(info.user);
    } catch (error) {
      actions.setUser(null);
      actions.setError(error.message);
      client.authentication.reset();
    }
    actions.setLoading(false);
  }),
  logout: thunk(async (actions) => {
    actions.setLoading(true);
    actions.setError('');
    try {
      await client.logout();
      actions.setUser(null);
      actions.setError('');
    } catch (error) {
      actions.setError(error.message);
    }
    actions.reset(null);
    actions.setLoading(false);
  }),
  reAuth: thunk(async (actions) => {
    actions.setLoading(true);
    actions.setError('');
    try {
      const accessToken = localStorage['feathers-jwt'];
      if (accessToken) {
        const info = await client.authenticate({
          strategy: 'jwt',
          accessToken,
        });
        actions.setUser(info.user);
      } else {
        actions.setUser(null);
      }
    } catch (error) {
      actions.setUser(null);
      client.authentication.reset();
    }
    actions.setLoading(false);
  }),
};

export default model;
