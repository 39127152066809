import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage, IonTitle, IonToolbar, useIonViewDidEnter,
} from '@ionic/react';
import React from 'react';
import useAppStyles from '../apps/styles';
import TrianglesDesktop from '../assets/triangles_desktop';
import TrianglesMobile from '../assets/triangles_mobile';
import useIsDesktop from '../lib/hooks/useIsDesktop';
import ProviderDesktopMenu from './ProviderDesktopMenu';
import useStyles from './styles';

interface CCProviderPageProps {
  startButtons?: React.ReactElement;
  endButtons?: React.ReactElement;
  showTriangles?: boolean;
  useMenuButton: boolean;
  content: React.ReactElement;
  title: string;
}

const CCProviderPage: React.FC<CCProviderPageProps> = ({
  content,
  title,
  startButtons,
  endButtons,
  showTriangles,
  useMenuButton,
}: CCProviderPageProps) => {
  const isDesktop = useIsDesktop();
  const appClasses = useAppStyles();
  const classes = useStyles();
  useIonViewDidEnter(() => {
    window.scrollTo(0, 0);
  });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={appClasses.ccToolbar}>
          {isDesktop ? (
            <ProviderDesktopMenu />
          ) : (
            <>
              <IonButtons slot="start" className="ion-no-padding">
                {useMenuButton ? (
                  <IonMenuButton />
                ) : null}
                {startButtons}
              </IonButtons>
              <IonButtons slot="end" className="ion-no-padding">
                {endButtons}
              </IonButtons>
            </>
          )}
          {!isDesktop && <IonTitle>{title}</IonTitle>}
        </IonToolbar>
      </IonHeader>
      <IonContent id="contentElementProvider" className={[appClasses.content, 'ion-padding'].join(' ')}>
        {showTriangles ? (
          <div style={{ height: '100%' }} className={appClasses.backgroundImage}>
            {isDesktop ? <TrianglesDesktop /> : <TrianglesMobile />}
          </div>
        ) : null }
        <div className={classes.providerDesktopContentContainer}>
          {content}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CCProviderPage;
