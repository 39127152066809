import { action } from 'easy-peasy';
import AdminModel from '../interfaces/StoreModel/AdminModel';

const model: AdminModel = {
  selectedReviewTab: 'newProviders',
  setSelectedReviewTab: action((state, payload) => {
    state.selectedReviewTab = payload;
  }),
  listingPreviewModalListings: [],
  setListingPreviewModalListings: action((state, payload) => {
    state.listingPreviewModalListings = payload;
  }),
};

export default model;
