import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import React from 'react';
import { CrisisGroup } from '../../../../interfaces/CrisisHotlines';
import useStyles from '../../styles';

interface CrisisCategoryRowProps {
  category: CrisisGroup;
  editCategory: (id: number) => void;
  deleteCategory: (id: number) => void;
}

const CrisisCategoryRow: React.FunctionComponent<CrisisCategoryRowProps> = ({
  category,
  editCategory,
  deleteCategory,
}: CrisisCategoryRowProps) => {
  const classes = useStyles();
  const {
    id, name, translation,
  } = category;

  return (
    <>
      <div className={classes.crisisCategoryCardHeader} />
      <div className={classes.providerCardContent}>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="8">
              <div className={classes.providerNameRow}>
                <IonItem
                  className={[
                    classes.providerOrgName,
                    'disabled-pointer-events',
                  ].join(' ')}
                >
                  <IonLabel position="floating" color="primary">
                    Crisis Category
                  </IonLabel>
                  <IonInput color="primary" value={`${name} / ${translation?.es?.name}`} />
                </IonItem>
              </div>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="1" />
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
              <IonButton
                className={classes.providerActionButton}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => editCategory(id)}
              >
                Edit
              </IonButton>
              <IonButton
                className={classes.providerActionButton}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => deleteCategory(id)}
              >
                Delete
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default CrisisCategoryRow;
