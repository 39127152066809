import {
  Computed, Thunk, Action,
} from 'easy-peasy';

export interface ObjectWithId {
  id?: number | string;
  archived?: boolean;
}

export enum MetaType {
  ITEMS = 'items',
  CREATE = 'create',
  MODIFY = 'modify',
}

export default interface ServiceModel<DataItem extends ObjectWithId> {
  loading: {
    items: boolean;
    create: boolean;
    modify: boolean;
  };
  error: {
    items: string;
    create: string;
    modify: string;
  };
  items: DataItem[];
  sortedItems: Computed<ServiceModel<DataItem>, DataItem[]>;
  itemsById: Computed<ServiceModel<DataItem>, Map<number | string, DataItem>>;
  setLoading: Action<ServiceModel<DataItem>, {
    type: MetaType;
    value: boolean;
  }>;
  setError: Action<ServiceModel<DataItem>, {
    type: MetaType;
    value: string;
  }>;
  setItems: Action<ServiceModel<DataItem>, DataItem[]>;
  addItem: Action<ServiceModel<DataItem>, DataItem>;
  updateItem: Action<ServiceModel<DataItem>, DataItem>;
  removeItem: Action<ServiceModel<DataItem>, number | string>;
  create: Thunk<ServiceModel<DataItem>, DataItem>;
  update: Thunk<ServiceModel<DataItem>, DataItem>;
  patch: Thunk<ServiceModel<DataItem>, DataItem>;
  remove: Thunk<ServiceModel<DataItem>, number | string>;
  list: Thunk<ServiceModel<DataItem>>;
  unListen: Action<ServiceModel<DataItem>>;
}
