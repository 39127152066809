import { IonInput, IonItem, IonLabel } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useAppStyles from '../styles';
import Icon from '../../components/Icon';
import routePaths from '../../lib/routePaths';
import { getDefaultFiltersAsUrlParams, getTranslatedString, logFirebaseEvent } from '../../lib/utils';
import { useStoreState, useStoreActions } from '../../store';

const SearchInput: React.FC = () => {
  const appClasses = useAppStyles();
  const history = useHistory();
  const location = useLocation();
  const [term, setTerm] = useState('');
  const settings = useStoreState((state) => state.settings);
  const { language } = useStoreState(state => state.translations);
  const [defaultQueryParams, setDefaultQueryParams] = useState<string>('');
  const { setUseLocationOnSearch, getLocation } = useStoreActions(
    (actions) => ({
      setUseLocationOnSearch: actions.settings.setUseLocationOnSearch,
      getLocation: actions.analytics.getLocation,
    }),
  );

  useEffect(() => {
    (async (): Promise<void> => {
      const coordinates = await getLocation();
      const q = getDefaultFiltersAsUrlParams(settings, setUseLocationOnSearch, coordinates);
      setDefaultQueryParams(q);
    })();
  }, [settings, setUseLocationOnSearch, getLocation]);

  useEffect(() => {
    const searchParams = new URLSearchParams(decodeURI(location.search));
    const termParam = searchParams.get('term');
    if (termParam) {
      setTerm(decodeURI(termParam));
    }
  }, [location]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const inputTerm = term.trim();
    logFirebaseEvent('search_event', { searchTerm: inputTerm });
    if (inputTerm) {
      const defaultParams = `${
        defaultQueryParams.length ? '&' : ''
      }${defaultQueryParams}`;
      history.push(
        `${routePaths.public.listings}?term=${inputTerm}${defaultParams}`,
      );
    }
  };

  return (
    <div className={appClasses.searchServicesWrapper}>
      <form onSubmit={handleSubmit}>
        <IonItem className={appClasses.searchInput}>
          <IonInput
            onIonChange={(e): void => setTerm(e.detail.value as string)}
            placeholder={getTranslatedString('search-services', language)}
            value={term}
          >
            <IonLabel>
              <Icon name="search" width={30} />
            </IonLabel>
          </IonInput>
        </IonItem>
      </form>
    </div>
  );
};

export default SearchInput;
