import {
  IonContent, IonLabel, IonModal, IonRow,
} from '@ionic/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useStoreState } from '../../store';
import { Hour } from '../../interfaces/Listing';
import { daysOfWeekEn, daysOfWeekEs, getDayOfWeekOpenHours } from '../../lib/utils';
import Icon from '../../components/Icon';
import useClasses from '../../components/styles';

interface ListingDetailHoursProps {
  toggleModal: Function;
  showModal: boolean;
  hours: Hour[];
}

const ListingDetailHours: React.FunctionComponent<ListingDetailHoursProps> = ({
  toggleModal,
  showModal,
  hours,
}: ListingDetailHoursProps) => {
  const classes = useClasses();
  const { language } = useStoreState(state => state.translations);
  return (
    <IonContent>
      <IonModal
        className={classes.hoursModalContainer}
        isOpen={showModal}
        onDidDismiss={() => toggleModal(false)}
      >
        <div className={classes.listingDetailHoursModalWrapper}>
          <IonRow className={classes.rowHeader}>
            <div className={classes.modalHeaderWrapper}>
              <div
                className={classes.modalHeaderCloseIcon}
                onClick={(): void => toggleModal(false)}
              >
                <Icon name="close" width={40} color="black" />
              </div>
              <div>
                <IonLabel className={classes.modalHeaderText}>
                  <FormattedMessage id="hours-camel" />
                </IonLabel>
              </div>
            </div>
          </IonRow>
          <IonRow className={classes.listingDetailHoursRow}>
            {[...Array.from(Array(7).keys())].map((day) => {
              const dayName = language === 'es' ? daysOfWeekEs[day] : daysOfWeekEn[day];
              const hourText = getDayOfWeekOpenHours(hours || [], day, language);
              return (
                <IonLabel
                  key={day}
                >
                  <div className={classes.listingDetailHoursDayName}>{dayName}</div>
                  <div className={classes.listingDetailHoursDayHours}>{hourText}</div>
                </IonLabel>
              );
            })}
          </IonRow>
        </div>
      </IonModal>
    </IonContent>
  );
};

export default ListingDetailHours;
