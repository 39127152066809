import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import CCAdminPage from '../../../../components/CCAdminPage';
import { Category, SubCategory } from '../../../../interfaces/Meta';
import { listings } from '../../../../lib/api/services';
import { useStoreActions, useStoreState } from '../../../../store';
import useStyle from '../../styles';
import ServiceListingsPreview from '../ServiceListingsPreview';
import ListingAssociatedSubcategoryRow from './ListingAssociatedSubcategoryRow';

const ListingCategoryEdit: React.FC = () => {
  const params = useParams();
  const history = useHistory();
  const [
    originalAssociatedSubcategories,
    setOriginalAssociatedSubcategories,
  ] = useState<SubCategory[]>([]);
  const [finishedFetching, setFinishedFetching] = useState(false);
  const [areThereUnsavedChanges, setAreThereUnsavedChanges] = useState(false);
  const [categoryToEdit, setCategoryToEdit] = useState<Category | null>(null);
  const [
    selectedSubcategory,
    setSelectedSubcategory,
  ] = useState<SubCategory | null>(null);
  const [
    selectedSubCategoriesToAssociate,
    setSelectedSubcategoriesToAssociate,
  ] = useState<SubCategory[]>([]);
  const [availableSubcategoriesList, setAvailableSubcategoriesList] = useState<
  SubCategory[]
  >([]);
  const { get, update } = useStoreActions(actions => actions.categories);
  const { setListingPreviewModalListings } = useStoreActions(
    (actions) => actions.admin,
  );
  const { data: allSubcategories } = useStoreState(state => state.subcategories);
  const { find: findSubcategories } = useStoreActions(actions => actions.subcategories);
  const classes = useStyle();

  // eslint-disable-next-line max-len
  const getSortedSubcategories = (
    subcategories: SubCategory[],
  ): SubCategory[] => subcategories.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    const { id } = params as { id: number };
    if (!categoryToEdit) {
      get(id).then((foundCategory: Category) => {
        setCategoryToEdit(foundCategory);
        setOriginalAssociatedSubcategories(foundCategory.sub_categories ?? []);
        setSelectedSubcategoriesToAssociate(foundCategory.sub_categories);
        setFinishedFetching(true);
      });
      findSubcategories();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calculatedSubcategoriesList = allSubcategories?.filter(
      (subcategory) => !selectedSubCategoriesToAssociate.find((s) => s.id === subcategory.id),
    );
    setAvailableSubcategoriesList(calculatedSubcategoriesList ?? []);
    if (calculatedSubcategoriesList && calculatedSubcategoriesList[0]) {
      setSelectedSubcategory(calculatedSubcategoriesList[0]);
    } else {
      setSelectedSubcategory(null);
    }
  }, [allSubcategories, selectedSubCategoriesToAssociate]);

  useEffect(() => {
    let result = false;
    if (finishedFetching) {
      if (originalAssociatedSubcategories.length > selectedSubCategoriesToAssociate.length) {
        result = originalAssociatedSubcategories.some(
          c => !selectedSubCategoriesToAssociate.find(s => s.id === c.id),
        );
      } else if (originalAssociatedSubcategories.length < selectedSubCategoriesToAssociate.length) {
        result = selectedSubCategoriesToAssociate.some(
          c => !originalAssociatedSubcategories.find(s => s.id === c.id),
        );
      // eslint-disable-next-line max-len
      } else if (originalAssociatedSubcategories.length !== 0 && selectedSubCategoriesToAssociate.length !== 0) {
        result = selectedSubCategoriesToAssociate.some(
          c => !originalAssociatedSubcategories.find(s => s.id === c.id),
        );
      }
      setAreThereUnsavedChanges(result);
    }
  }, [originalAssociatedSubcategories, selectedSubCategoriesToAssociate, finishedFetching]);

  const handleAddSubcategory = (): void => {
    if (selectedSubcategory) {
      setSelectedSubcategoriesToAssociate(
        getSortedSubcategories([
          ...(selectedSubCategoriesToAssociate ?? []),
          selectedSubcategory,
        ]),
      );
    }
  };

  const handleRemoveSubcategory = (id: number): void => {
    const foundIndex = selectedSubCategoriesToAssociate.findIndex(
      (s) => s.id === id,
    );
    const newSubcategoriesToAssociate = [...selectedSubCategoriesToAssociate];
    newSubcategoriesToAssociate.splice(foundIndex, 1);
    setSelectedSubcategoriesToAssociate(
      getSortedSubcategories(newSubcategoriesToAssociate),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectSubcategory = (event: any): void => {
    const subcategoryId = event.target.value;
    const foundSubcategory = allSubcategories?.find(
      (s) => s.id === subcategoryId,
    );
    if (foundSubcategory) {
      setSelectedSubcategory(foundSubcategory);
    }
  };

  const handleViewSubcategoryServices = async (id: number): Promise<void> => {
    const results = await listings.find({ query: { sub_categories: [id] } });
    setListingPreviewModalListings(results.data);
  };

  const handleUpdateCategory = (): void => {
    update({
      id: categoryToEdit?.id,
      name: categoryToEdit?.name ?? '',
      translation: categoryToEdit?.translation ?? { es: { name: '' } },
      sub_categories: selectedSubCategoriesToAssociate.map(s => ({ id: s.id })),
    });
    history.goBack();
  };

  return (
    <>
      <CCAdminPage
        title="Edit Category Associations"
        content={(
          <>
            <div className={classes.adminContent}>
              <h3>Edit Category Associations</h3>
              <IonGrid>
                <IonRow>
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="9">
                    <div className={classes.providerNameRow}>
                      <IonItem
                        className={[
                          classes.providerOrgName,
                          'disabled-pointer-events',
                        ].join(' ')}
                      >
                        <IonLabel position="floating" color="primary">
                          Category Name
                        </IonLabel>
                        <IonInput
                          color="primary"
                          value={`${categoryToEdit?.name}`}
                        />
                      </IonItem>
                    </div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="9">
                    <div className={classes.providerNameRow}>
                      <IonItem
                        className={[
                          classes.providerOrgName,
                          'disabled-pointer-events',
                        ].join(' ')}
                      >
                        <IonLabel position="floating" color="primary">
                          Category Name Spanish
                        </IonLabel>
                        <IonInput
                          color="primary"
                          value={`${categoryToEdit?.translation?.es?.name}`}
                        />
                      </IonItem>
                    </div>
                  </IonCol>
                </IonRow>
                {areThereUnsavedChanges && (
                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12">
                      <p style={{ color: '#777', textAlign: 'left' }}>
                        <i><FormattedMessage id="unsaved-changes" /></i>
                      </p>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="9">
                    <IonItem>
                      <IonLabel>Subcategories</IonLabel>
                      <IonSelect
                        onIonChange={handleSelectSubcategory}
                        className={classes.categorySelect}
                        value={selectedSubcategory?.id}
                      >
                        {availableSubcategoriesList?.map((item) => (
                          <IonSelectOption key={item.id} value={item.id}>
                            {item.name}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
                    <IonButton
                      className={classes.providerActionButton}
                      shape="round"
                      fill="outline"
                      color="primary"
                      onClick={handleAddSubcategory}
                    >
                      Add
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <ServiceListingsPreview />
              {selectedSubCategoriesToAssociate.map((subcategory) => (
                <ListingAssociatedSubcategoryRow
                  key={subcategory.id}
                  subcategory={subcategory}
                  removeSubcategory={handleRemoveSubcategory}
                  viewServices={handleViewSubcategoryServices}
                />
              ))}
              <div className={classes.saveButtonWrapper}>
                <IonButton
                  className={classes.cancelButton}
                  shape="round"
                  fill="solid"
                  color="secondary"
                  onClick={(): void => history.goBack()}
                >
                  Cancel
                </IonButton>

                <IonButton
                  className={classes.saveButton}
                  shape="round"
                  fill="solid"
                  color="primary"
                  onClick={handleUpdateCategory}
                >
                  Save
                </IonButton>
              </div>
            </div>
          </>
        )}
      />
    </>
  );
};

export default ListingCategoryEdit;
