import { action, thunk } from 'easy-peasy';

import VerifyEmailModel from '../interfaces/StoreModel/VerifyEmailModel';
import { verifyEmail } from '../lib/api/services';

let verifiedOnce = false;

const model: VerifyEmailModel = {
  error: '',
  loading: false,
  verified: false,
  resent: false,
  setVerified: action((state, payload) => {
    state.verified = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setResent: action((state, payload) => {
    state.resent = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  resendVerification: thunk(async (actions, id) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      await verifyEmail.patch(id, {
        resendVerification: true,
      });
      actions.setResent(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setResent(false);
    }
    actions.setLoading(false);
  }),
  verify: thunk(async (actions, token) => {
    if (verifiedOnce) return;
    verifiedOnce = true;
    actions.setLoading(true);
    try {
      actions.setError('');
      await verifyEmail.create({
        token,
      });
      actions.setVerified(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setVerified(false);
    }
    actions.setLoading(false);
  }),
};

export default model;
