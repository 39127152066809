import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonSelect,
  IonSelectOption,
  IonText,
  IonTextarea,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import * as yup from 'yup';
import CCAdminPage from '../../../../components/CCAdminPage';
import {
  CrisisHotline,
  CrisisHotlineForm,
  CrisisHotlineWithCategory,
} from '../../../../interfaces/CrisisHotlines';
import useForm from '../../../../lib/hooks/useForm';
import routePaths from '../../../../lib/routePaths';
import { useStoreActions, useStoreState } from '../../../../store';
import useOnboardingStyles from '../../../Provider/Onboarding/styles';
import useStyle from '../../styles';

const schema = yup.object().shape({
  name: yup.string().trim().required('Title is required'),
  name_es: yup.string().trim().required('Title is required'),
  phone_number: yup.string().trim().required('Phone Number is required.'),
  organization_name: yup
    .string()
    .trim()
    .required('Organization Name is required.'),
  description: yup.string().trim(),
  crisis_hotline_category_id: yup
    .number()
    .min(1, 'Crisis Hotline Category is required.')
    .required('Crisis Hotline Category is required.'),
});

const deserializeCrisisHotline = (
  crisisHotline: CrisisHotline | null,
): CrisisHotlineForm => ({
  name: crisisHotline?.name || '',
  name_es: crisisHotline?.translation?.es?.name || '',
  phone_number: crisisHotline?.phone_number || '',
  organization_name: crisisHotline?.organization_name || '',
  description: crisisHotline?.description || '',
  crisis_hotline_category_id: crisisHotline?.crisis_hotline_category_id || 1,
});

const CrisisHotlineUpsert: React.FC = () => {
  const location = useLocation();
  const params = useParams();
  const classes = useStyle();
  const history = useHistory();
  const onboardingClasses = useOnboardingStyles();
  const [crisisHotlines, setCrisisHotlines] = useState<CrisisHotline[]>([]);
  const [currentMode, setCurrentMode] = useState<string | null>(null);
  const [crisisHotlineToEdit, setCrisisHotlineToEdit] = useState<CrisisHotline | null>(
    null,
  );

  const {
    create, update, get, setSuccess,
  } = useStoreActions(
    (actions) => actions.crisisHotlines,
  );
  const {
    data: crisisGroups, loading, success, error,
  } = useStoreState(
    (state) => state.crisisHotlines,
  );

  const initialData = deserializeCrisisHotline(crisisHotlineToEdit);

  const {
    errors, values, onChange, handleSubmit, setValues,
  } = useForm<
  CrisisHotlineForm
  >({
    schema,
    initial: { ...initialData },
  });

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      const { id } = params as { id: number };
      if (id) {
        get(id);
      }
    }
    setSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (crisisGroups && crisisGroups.length) {
      const newFlattenCrisisHotlines = crisisGroups.reduce<
      CrisisHotlineWithCategory[]
      >((acc, curr) => {
        const category = { id: curr.id, name: curr.name };
        const newHotlines = curr.hotlines?.map<CrisisHotlineWithCategory>(
          (crisisHotline) => ({
            ...crisisHotline,
            category,
          }),
        );
        acc = [...acc, ...(newHotlines ?? [])];
        return acc;
      }, []);
      setCrisisHotlines(newFlattenCrisisHotlines);
    }
  }, [crisisGroups]);

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      const { id } = params as { id: number };
      const foundCrisisHotline = crisisHotlines?.find(
        (p) => Number(p.id) === Number(id),
      );
      if (foundCrisisHotline) {
        setCrisisHotlineToEdit(foundCrisisHotline);
        setValues(deserializeCrisisHotline(foundCrisisHotline));
      }
    }
  }, [crisisHotlines, location.pathname, params, setValues]);

  useEffect(() => {
    if (location.pathname.includes('create')) {
      setCurrentMode('create');
    } else {
      setCurrentMode('edit');
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('create')) {
      setValues(deserializeCrisisHotline(null));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createCrisisHotline = async (data: CrisisHotlineForm): Promise<void> => {
    await create(data);
  };

  const updateCrisisHotline = async (data: CrisisHotlineForm): Promise<void> => {
    data.id = crisisHotlineToEdit?.id;
    await update(data);
  };

  useEffect(() => {
    if (success) {
      history.push(routePaths.admin.crisisHotlines);
    }
    return (): void => {
      setSuccess(false);
    };
  }, [success, setSuccess, history]);

  return (
    <>
      <CCAdminPage
        title={
          currentMode === 'create'
            ? 'Create crisis hotline'
            : 'Edit crisis hotline'
        }
        content={(
          <>
            <div className={classes.adminCenteredContent}>
              <div id="header">
                <h3 style={{ float: 'left' }}>
                  {currentMode === 'create'
                    ? 'Create crisis hotline'
                    : 'Edit crisis hotline'}
                </h3>
              </div>
              <div id="body" className={classes.upsertInformationalPage}>
                {error || Object.keys(errors).length ? (
                  <IonCard color="danger">
                    <IonCardHeader>
                      <IonCardTitle>
                        {error || 'Please correct the errors below.'}
                      </IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                ) : null}
                <form>
                  <IonItem className={onboardingClasses.input}>
                    <IonInput
                      value={values.name}
                      name="name"
                      type="text"
                      onIonChange={onChange}
                      placeholder="Hotline Title"
                    />
                  </IonItem>
                  {errors.name && (
                    <p className="error-message">
                      <IonText color="danger">{errors.name}</IonText>
                    </p>
                  )}
                  <IonItem className={onboardingClasses.input}>
                    <IonInput
                      value={values.name_es}
                      name="name_es"
                      type="text"
                      onIonChange={onChange}
                      placeholder="Hotline Title Spanish"
                    />
                  </IonItem>
                  {errors.name_es && (
                    <p className="error-message">
                      <IonText color="danger">{errors.name}</IonText>
                    </p>
                  )}
                  <IonItem
                    className={[
                      onboardingClasses.tallInput,
                    ].join(' ')}
                  >
                    <IonTextarea
                      color="primary"
                      className={[
                        classes.description,
                      ].join(' ')}
                      maxlength={400}
                      value={values.description}
                      name="description"
                      onIonChange={onChange}
                      placeholder="Description"
                    />
                  </IonItem>
                  {errors.description && (
                    <p className="error-message">
                      <IonText color="danger">{errors.description}</IonText>
                    </p>
                  )}
                  <IonItem className={onboardingClasses.input}>
                    <IonInput
                      value={values.organization_name}
                      name="organization_name"
                      type="text"
                      onIonChange={onChange}
                      placeholder="Organization Name"
                    />
                  </IonItem>
                  {errors.organization_name && (
                    <p className="error-message">
                      <IonText color="danger">{errors.organization_name}</IonText>
                    </p>
                  )}
                  <IonItem className={onboardingClasses.input}>
                    <IonInput
                      value={values.phone_number}
                      name="phone_number"
                      type="text"
                      onIonChange={onChange}
                      placeholder="Phone"
                    />
                  </IonItem>
                  {errors.phone_number && (
                    <p className="error-message">
                      <IonText color="danger">{errors.phone_number}</IonText>
                    </p>
                  )}
                  <br />
                  <IonItem>
                    <IonLabel>Crisis Hotline Category</IonLabel>
                    <IonSelect
                      name="crisis_hotline_category_id"
                      onIonChange={onChange}
                      className={classes.categorySelect}
                      value={values.crisis_hotline_category_id}
                    >
                      {crisisGroups?.map((item) => (
                        <IonSelectOption key={item.id} value={item.id}>
                          {item.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                  <br />
                  <div>
                    <IonButton
                      className={classes.providerActionButton}
                      shape="round"
                      fill="solid"
                      color="primary"
                      onClick={(e): void => {
                        if (currentMode === 'create') {
                          handleSubmit(createCrisisHotline)(e);
                        } else {
                          handleSubmit(updateCrisisHotline)(e);
                        }
                      }}
                    >
                      {currentMode === 'create'
                        ? 'Create crisis hotline'
                        : 'Update crisis hotline'}
                    </IonButton>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      />
      <IonLoading isOpen={loading || currentMode === null} />
    </>
  );
};

export default CrisisHotlineUpsert;
