import { action, thunk } from 'easy-peasy';

import SearchModel from '../interfaces/StoreModel/SearchModel';
import { search } from '../lib/api/services';

const model: SearchModel = {
  error: '',
  loading: false,
  data: null,
  filters: {},
  setData: action((state, payload) => {
    state.data = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setFilters: action((state, payload) => {
    state.filters = payload;
  }),
  updateFilters: thunk((actions, filters) => {
    actions.setFilters(filters);
    actions.get();
  }),
  get: thunk(async (actions, _, store) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      const data = await search.find({
        query: {
          ...store.getState().filters,
          // @ts-ignore
          locale: store.getStoreState().translations.language,
        },
      });
      actions.setData(data);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
};

export default model;
