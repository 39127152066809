import { IonButton, IonIcon } from '@ionic/react';
import { locate } from 'ionicons/icons';
import React from 'react';
import { useHistory } from 'react-router';
import CCPage from '../../components/CCPage';
import Icon from '../../components/Icon';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import routePaths from '../../lib/routePaths';
import Subcategories from './Subcategories';

const SubcategoryPage: React.FC = () => {
  const primaryColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--ion-color-primary');
  const isDesktop = useIsDesktop();
  const isNotXtraSmall = useIsDesktop(321);
  const history = useHistory();
  return (
    <CCPage
      startButtons={(
        <IonButton onClick={() => history.goBack()}>
          <Icon name="arrow_back" width={isNotXtraSmall ? 40 : 35} color={primaryColor} />
        </IonButton>
      )}
      useLogo
      endButtons={(
        <IonButton routerLink={routePaths.public.search}>
          <IonIcon slot="end" icon={locate} />
        </IonButton>
      )}
      content={(
        <>
          {isDesktop ? null : (
            <Subcategories />
          )}
        </>
      )}
    />
  );
};

export default SubcategoryPage;
