import {
  IonContent,
  IonPage,
} from '@ionic/react';
import { useParams } from 'react-router-dom';

import React, { useEffect } from 'react';
// @ts-ignore
import logo from '../../assets/cc_logo.png';
import useAppStyles from '../styles';
import useStyles from './styles';
import { useStoreState, useStoreActions } from '../../store';

interface TokenParams {
  token: string;
}

const errorMessage = 'Unable to verify email. Try signing in again and select "Resend Verification Email"';

const EmailVerify: React.FC = () => {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const params = useParams<TokenParams>();
  const { loading, error, verified } = useStoreState(state => state.verifyEmail);
  const { verify } = useStoreActions(actions => actions.verifyEmail);

  useEffect(() => {
    if (params.token) {
      verify(params.token);
    }
  }, [verify, params]);

  return (
    <IonPage>
      <IonContent>
        <div className={[appClasses.desktopContainer, classes.container].join(' ')}>
          <div>
            <img src={logo} alt="logo" />
          </div>
          <h1>{loading && 'Verifying Email...'}</h1>
          <h1>{!loading && (params.token ? verified ? 'Your email has been verified' : error : 'Invalid Verification Token')}</h1>
          <h4>
            {error ? (
              errorMessage
            ) : null}
          </h4>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default EmailVerify;
