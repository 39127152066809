import React, { useEffect, useState } from 'react';
import { IonButton } from '@ionic/react';

import CCAdminPage from '../../../../components/CCAdminPage';
import { analyticsEvents } from '../../../../lib/api/services';
import useStyles from '../../styles';

const Analytics: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ users: [], events: [] });

  const getAnalyticsData = async (): Promise<void> => {
    setLoading(true);
    setData({ users: [], events: [] });
    const analyticsData = await analyticsEvents.find();
    analyticsData.users.reverse();
    analyticsData.events.reverse();
    setData(analyticsData);
    setLoading(false);
  };

  useEffect(() => {
    getAnalyticsData();
  }, []);

  return (
    <CCAdminPage
      title="Analytics"
      content={(
        <div className={classes.adminContent}>
          <h3>Analytics</h3>
          <IonButton onClick={getAnalyticsData}>Refresh</IonButton>
          {loading ? <h4>Loading...</h4> : null}
          <h4>Users</h4>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {data.users.map((user: any) => (
            <div key={user.id}>
              <pre>{JSON.stringify(user, null, 2)}</pre>
            </div>
          ))}
          <h4>Events</h4>
          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {data.events.map((event: any) => (
            <div key={event.id}>
              <h5>{event.type} | {JSON.stringify(event.data)}</h5>
              <pre>{JSON.stringify(event, null, 2)}</pre>
            </div>
          ))}
        </div>
      )}
    />
  );
};

export default Analytics;
