import React from 'react';

function TrianglesMobile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="920"
      height="280"
      version="1.1"
      viewBox="0 0 920 280"
    >
      <path
        fill="#919191"
        fillRule="evenodd"
        d="M922.364 242.79h-60l30 37.29z"
      />
      <g opacity="0.3" transform="translate(2.364 51.636)">
        <path
          fill="#e3b205"
          fillRule="evenodd"
          d="M770 153.866h-60l30 37.288z"
        />
      </g>
      <path
        fill="#1ac62b"
        fillRule="evenodd"
        d="M862.364 242.79h-60l30 37.29z"
      />
      <g opacity="0.3" transform="translate(2.364 51.636)">
        <path
          fill="#195fe8"
          fillRule="evenodd"
          d="M830 228.443h-60l30-37.289z"
        />
      </g>
      <g opacity="0.3" transform="translate(2.364 51.636)">
        <path
          fill="#26aea6"
          fillRule="evenodd"
          d="M860 116.577h-60l30 37.289z"
        />
      </g>
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M952.364 205.502h-60l30-37.288z"
      />
      <path
        fill="#f4f4f4"
        fillRule="evenodd"
        d="M892.364 130.925h-60l30-37.289z"
      />
      <path
        fill="#f4f4f4"
        fillRule="evenodd"
        d="M772.364 130.925h60l-30 37.289z"
      />
      <path
        fill="#d22727"
        fillRule="evenodd"
        d="M952.364 280.08h-60l30-37.29z"
      />
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M802.364 242.79h-60l30 37.29z"
      />
      <g opacity="0.15" transform="translate(2.364 51.636)">
        <path
          fill="#dd1368"
          fillRule="evenodd"
          d="M830 153.866h-60l30 37.288z"
        />
      </g>
      <g opacity="0.5" transform="translate(2.364 51.636)">
        <path
          fill="#5b5b5b"
          fillRule="evenodd"
          d="M830 153.866h-60l30-37.289z"
        />
      </g>
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M892.364 130.925h-60l30 37.289z"
      />
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M952.364 130.925h-60l30-37.289z"
      />
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M892.364 280.08h-60l30-37.29z"
      />
      <g opacity="0.3" transform="translate(2.364 51.636)">
        <path
          fill="#692fd4"
          fillRule="evenodd"
          d="M890 153.866h-60l30-37.289z"
        />
      </g>
      <path
        fill="#e3b205"
        fillRule="evenodd"
        d="M922.364 242.79h-60l30-37.288z"
      />
      <path
        fill="#a010a8"
        fillRule="evenodd"
        d="M922.364 168.214h-60l30-37.289z"
      />
      <path
        fill="#5b5b5b"
        fillRule="evenodd"
        d="M892.364 205.502h-60l30 37.289z"
      />
    </svg>
  );
}

export default TrianglesMobile;
