import { action, thunk } from 'easy-peasy';

import FavoritesModel from '../interfaces/StoreModel/FavoritesModel';
import {
  getSavedListings,
  removeSavedListingById,
  addSavedListing,
  replaceSavedListing,
  replaceSavedListings,
} from '../lib/utils';

const model: FavoritesModel = {
  favoriteListings: [],
  setFavoriteListings: action((state, listings) => {
    state.favoriteListings = listings;
  }),
  addFavoriteListing: thunk(async (actions, listing) => {
    const listings = await addSavedListing(listing);
    actions.setFavoriteListings(listings);
  }),
  removeFavoriteListingById: thunk(async (actions, listingId) => {
    const listings = await removeSavedListingById(listingId);
    actions.setFavoriteListings(listings);
  }),
  loadFavoriteListings: thunk(async (actions) => {
    const listings = await getSavedListings();
    actions.setFavoriteListings(listings);
  }),
  replaceFavoriteListing: thunk(async (actions, listing) => {
    const listings = await replaceSavedListing(listing);
    actions.setFavoriteListings(listings);
  }),
  replaceFavoriteListings: thunk(async (actions, newListings) => {
    const listings = await replaceSavedListings(newListings);
    actions.setFavoriteListings(listings);
  }),
};

export default model;
