/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import {
  IonButton,
  IonLabel,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonItem,
  IonText,
  IonInput,
  IonCheckbox,
  IonAlert,
  isPlatform,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonContent,
  IonLoading,
} from '@ionic/react';

import useForm from '../../../lib/hooks/useForm';
import { useStoreState, useStoreActions } from '../../../store';
import useOnboardingStyles from '../Onboarding/styles';
import useProvidersStyles from '../styles';
import useAppStyles from '../../styles';
import OrgData from '../../../interfaces/OrgData';
import routePaths from '../../../lib/routePaths';
import { translateProp } from '../../../lib/utils';

const CreateOrgSchema = yup.object().shape({
  orgName: yup.string().trim().required('Organization Name is required.'),
  contactName: yup
    .string()
    .trim()
    .required('Primary Contact Name is required.'),
  contactEmail: yup
    .string()
    .trim()
    .email('Invalid Email.')
    .required('Primary Contact Email is required.'),
  website: yup
    .string()
    .trim()
    .url('Invalid URL, Please format http://www.yourwebsite.com')
    .required('Organization Website URL is required.'),
  password: yup.string().trim().required('Password is required.'),
  confirmPassword: yup
    .string()
    .trim()
    .required()
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

const UpdateOrgSchema = yup.object().shape({
  orgName: yup.string().trim().required('Organization Name is required.'),
  contactName: yup
    .string()
    .trim()
    .required('Primary Contact Name is required.'),
  contactEmail: yup
    .string()
    .trim()
    .email('Invalid Email.')
    .required('Primary Contact Email is required.'),
  website: yup
    .string()
    .trim()
    .url('Invalid URL, Please format http://www.yourwebsite.com')
    .required('Organization Website URL is required.'),
  ein: yup
    .string()
    .trim(),
});

const initialEmpty = {
  orgName: '',
  contactName: '',
  contactEmail: '',
  website: '',
  password: '',
  confirmPassword: '',
  ein: '',
};

const OrgInfo: React.FC = () => {
  const onboardingClasses = useOnboardingStyles();
  const providersClasses = useProvidersStyles();
  const appClasses = useAppStyles();
  const history = useHistory();
  const location = useLocation();
  const { user } = useStoreState(state => state.auth);
  const { currentProvider } = useStoreState(state => state.provider);
  const approved = (currentProvider?.approved_date?.length ?? 0) > 0;
  const submitted = (currentProvider?.submit_date?.length ?? 0) > 0;
  const readOnly = submitted && !approved;
  const [alertText, setAlertText] = useState<string>('');
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const { resetEmailRequested } = useStoreState(actions => actions.password);
  const { language: locale } = useStoreState(state => state.translations);
  const { data: meta } = useStoreState(state => state.meta);
  const {
    sendPasswordResetEmail,
    setResetEmailRequested,
  } = useStoreActions(actions => actions.password);
  const {
    error, ein, success,
  } = useStoreState(
    (state) => state.createOrg,
  );

  const {
    patch, create, setCurrentStep, setSuccess, setError,
  } = useStoreActions(
    (actions) => actions.createOrg,
  );

  const {
    errors, values, onChange, handleSubmit, setValues,
  } = useForm<OrgData>({
    schema: currentProvider === null ? CreateOrgSchema : UpdateOrgSchema,
    initial: {
      ...initialEmpty,
      ...({
        orgName: currentProvider?.name ?? '',
        contactName: currentProvider?.user?.name ?? '',
        contactEmail: currentProvider?.user?.email ?? '',
        website: currentProvider?.website_url ?? '',
        ein: ein ? currentProvider?.ein ?? '' : '',
      }),
    },
  });
  const [tosChecked, setTosChecked] = useState<boolean>(false);

  useEffect(() => {
    if (resetEmailRequested) {
      setAlertText('A password reset email has been sent.');
      setResetEmailRequested(false);
    }
  }, [resetEmailRequested, setResetEmailRequested]);

  const requestEmailReset = (): void => {
    sendPasswordResetEmail(user?.email ?? '');
  };

  useEffect(() => {
    if (currentProvider) {
      setValues({
        orgName: currentProvider?.name ?? '',
        contactName: currentProvider?.user?.name ?? '',
        contactEmail: currentProvider?.user?.email ?? '',
        website: currentProvider?.website_url ?? '',
        ein: currentProvider.ein ?? '',
        password: '',
        confirmPassword: '',
      });
    }
  }, [currentProvider, setValues]);

  useEffect(() => {
    setTosChecked(false);
    if (location.pathname === routePaths.provider.onboarding.orgInfo) {
      setCurrentStep(2);
    }
    setError('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set success to false when component unmounts.
  // eslint-disable-next-line arrow-body-style
  useEffect(() => {
    return (): void => {
      setSuccess(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success && location.pathname === routePaths.admin.orgInfo) {
      history.goBack();
    }
  }, [success, history, location.pathname]);

  const createProvider = (data: OrgData): void => {
    create(data);
  };
  const updateProvider = (data: OrgData): void => {
    if (currentProvider?.user?.id) {
      data.providerTypeId = Number(currentProvider.provider_type_id);
      data.providerId = Number(currentProvider.id);
      data.userId = Number(currentProvider.user.id);
    }
    patch(data);
  };

  useEffect(() => {
    if (success) {
      if (location.pathname === routePaths.provider.onboarding.orgInfo) {
        history.push(routePaths.provider.onboarding.unverifiedEmail);
      }
    }
  }, [history, success, currentProvider, location.pathname]);

  return (
    <>
      {location.pathname === routePaths.provider.onboarding.orgInfo
        ? (
          <>
            <h3 className={appClasses.title}>Service Provider Sign Up</h3>
            <div className={onboardingClasses.description}>
              <IonLabel>
                It looks like your organization is eligible! Create an account to
                submit services for our review.
              </IonLabel>
            </div>
            <IonLabel
              className={onboardingClasses.descriptionLink}
              onClick={(): void => {
                if (isPlatform('android') || isPlatform('ios')) {
                  history.push('/cc/page/provider-requirements');
                } else {
                  window.open('/cc/page/provider-requirements', '_blank');
                }
              }}
            >
              View provider requirements.
            </IonLabel>
          </>
        )
        : location.pathname === routePaths.admin.orgInfo
          ? (
            <>
              <h3 className={[appClasses.title, providersClasses.main, 'ml-24'].join(' ')}>
                <b>Provider Profile</b>
              </h3>
            </>
          ) : (
            <>
              <h3 className={appClasses.title}>Organization Profile</h3>
            </>
          )}
      <form
        className={providersClasses.main}
        onSubmit={currentProvider === null ? handleSubmit(createProvider) : handleSubmit(updateProvider)}
      >
        {error && (
          <IonCard color="danger">
            <IonCardHeader>
              <IonCardTitle>{error}</IonCardTitle>
            </IonCardHeader>
          </IonCard>
        )}
        <IonList
          lines="none"
          className={[
            onboardingClasses.inputsList,
            'ion-no-margin ion-no-padding',
          ].join(' ')}
        >
          <IonItem className={onboardingClasses.input}>
            <IonInput
              value={values.orgName}
              name="orgName"
              disabled={readOnly}
              type="text"
              placeholder="Organization Name*"
              onIonChange={onChange}
            />
          </IonItem>
          {errors.orgName && (
            <p className="error-message">
              <IonText color="danger">{errors.orgName}</IonText>
            </p>
          )}
          <IonItem className={onboardingClasses.input}>
            <IonInput
              value={values.contactName}
              name="contactName"
              type="text"
              disabled={readOnly}
              placeholder="Primary Contact Name*"
              onIonChange={onChange}
            />
          </IonItem>
          {errors.contactName && (
            <p className="error-message">
              <IonText color="danger">{errors.contactName}</IonText>
            </p>
          )}
          <IonItem className={onboardingClasses.input}>
            <IonInput
              value={values.contactEmail}
              name="contactEmail"
              disabled={readOnly}
              type="email"
              placeholder="Primary Contact Email*"
              onIonChange={onChange}
            />
          </IonItem>
          {errors.contactEmail && (
            <p className="error-message">
              <IonText color="danger">{errors.contactEmail}</IonText>
            </p>
          )}
          <IonItem className={onboardingClasses.input}>
            <IonInput
              value={values.website}
              name="website"
              disabled={readOnly}
              placeholder="Organization Website*"
              onIonChange={onChange}
            />
          </IonItem>
          {errors.website && (
            <p className="error-message">
              <IonText color="danger">{errors.website}</IonText>
            </p>
          )}
          {location.pathname !== routePaths.admin.orgInfo ? null : (
            <>
              <IonItem className={onboardingClasses.input}>
                <IonInput
                  value={values.ein}
                  name="ein"
                  disabled={readOnly}
                  placeholder="Organization EIN*"
                  onIonChange={onChange}
                />
              </IonItem>
              {errors.website && (
                <p className="error-message">
                  <IonText color="danger">{errors.ein}</IonText>
                </p>
              )}
            </>
          )}
          {location.pathname === routePaths.provider.onboarding.orgInfo
            ? (
              <>
                <IonItem className={onboardingClasses.input}>
                  <IonInput
                    value={values.password}
                    name="password"
                    type="password"
                    placeholder="Password*"
                    onIonChange={onChange}
                  />
                </IonItem>
                {errors.password && (
                <p className="error-message">
                  <IonText color="danger">{errors.password}</IonText>
                </p>
                )}
                <IonItem className={onboardingClasses.input}>
                  <IonInput
                    value={values.confirmPassword}
                    name="confirmPassword"
                    type="password"
                    placeholder="Confirm Password*"
                    onIonChange={onChange}
                  />
                </IonItem>
                {errors.confirmPassword && (
                <p className="error-message">
                  <IonText color="danger">{errors.confirmPassword}</IonText>
                </p>
                )}
                <IonItem
                  className={[
                    onboardingClasses.tosCheckboxContainer,
                    'ion-no-padding ion-no-margin',
                  ].join(' ')}
                >
                  <IonCheckbox
                    className={onboardingClasses.tosCheckbox}
                    style={{ position: 'relative' }}
                    name="tosCheckbox"
                    color="primary"
                    onIonChange={(e): void => setTosChecked(e.detail.checked)}
                  />
                  <IonLabel
                    className="ion-no-margin ion-no-padding"
                    color="primary"
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={(): void => setShowTermsModal(true)}
                  >
                    I accept Collier CARES' Terms.
                  </IonLabel>
                </IonItem>
              </>
            )
            : null}
        </IonList>
        {location.pathname === routePaths.provider.onboarding.orgInfo
          ? (
            <div className="ion-padding-top">
              <IonButton
                className="cc-button cc-tall-button"
                disabled={!tosChecked}
                shape="round"
                type="submit"
                expand="block"
              >
                Sign Up
              </IonButton>
            </div>
          )
          : (readOnly) ? (
            <IonItem lines="none" className={[appClasses.semiTransparentBackground, 'mt-24'].join(' ')}>
              <IonLabel color="danger" className="ion-text-wrap">Organization information is not editable while approval is pending.</IonLabel>
            </IonItem>
          ) : (!approved) ? (
            <div className="mt-24 ion-padding-top">
              <IonButton
                className="cc-button cc-tall-button"
                shape="round"
                type="submit"
                expand="block"
                disabled={false}
              >
                Update
              </IonButton>
            </div>
          ) : (
            <>
              {location.pathname === routePaths.admin.orgInfo ? null : (
                <div className={[appClasses.leftJustifiedRow, 'mt-16'].join(' ')}>
                  <IonButton className="cc-link-button" fill="clear" onClick={(): void => requestEmailReset()}>
                    Change Password
                  </IonButton>
                </div>
              )}
              <div className={[
                isPlatform('ios') || isPlatform('android') ? '' : appClasses.spaceBetweenRow,
                'mt-24',
              ].join(' ')}
              >
                {/* <IonButton
                  className={[appClasses.minWidth175, 'cc-button'].join(' ')}
                  shape="round"
                  fill="outline"
                  color="primary"
                  expand={isPlatform('ios') || isPlatform('android') ? 'block' : undefined}
                >
                  Close Account
                </IonButton> */}
                <IonButton
                  className={[appClasses.minWidth175, 'cc-button'].join(' ')}
                  shape="round"
                  fill="solid"
                  color="primary"
                  expand={isPlatform('ios') || isPlatform('android') ? 'block' : undefined}
                  type="submit"
                >
                  Save
                </IonButton>
              </div>
            </>
          )}
      </form>
      <IonModal
        onDidDismiss={(): void => {
          setShowTermsModal(false);
        }}
        isOpen={showTermsModal}
      >
        <IonHeader>
          <IonToolbar color="primary">
            <IonButtons collapse slot="end">
              <IonButton onClick={(): void => {
                setShowTermsModal(false);
              }}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div className="ion-padding">
            {meta && meta.terms_and_conditions ? (
              <ReactMarkdown
                source={translateProp(meta.terms_and_conditions, 'contents', locale)}
                escapeHtml={false}
              />
            ) : <IonLoading isOpen />}
          </div>
        </IonContent>
      </IonModal>
      <IonAlert
        isOpen={alertText.length > 0}
        onDidDismiss={(): void => {
          setAlertText('');
        }}
        message={alertText}
        buttons={[
          { text: 'OK' },
        ]}
      />
    </>
  );
};

export default OrgInfo;
