import { Share } from '@capacitor/share';
import { IonButton } from '@ionic/react';
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router';
import CCPage from '../../components/CCPage';
import Icon from '../../components/Icon';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import { useStoreState } from '../../store';
import useClasses from '../styles';
import SearchResults from './SearchResults';
import { getFiltersFromQuery, getTranslatedString } from '../../lib/utils';
import { SubCategory } from '../../interfaces/Meta';

interface Normalized<T> {
  [key: number]: T;
}

const SearchResultsPage: React.FC = () => {
  const history = useHistory();
  const classes = useClasses();
  const isDesktop = useIsDesktop();
  const isNotXtraSmall = useIsDesktop(321);
  const [currentUrl, setCurrentUrl] = useState('');
  const [flatSubcategories, setFlatSubcategories] = useState<Normalized<SubCategory> | null>(null);
  const location = useLocation();
  const { data } = useStoreState((state) => state.meta);
  const { language } = useStoreState((state) => state.translations);
  const primaryColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--ion-color-primary');

  useEffect(() => {
    if (data && data.category && !flatSubcategories) {
      const subs: Normalized<SubCategory> = {};
      data.category.forEach(v => {
        v.sub_categories.forEach(s => {
          subs[s.id] = s;
        });
      });
      setFlatSubcategories(subs);
    }
  }, [data, flatSubcategories]);

  const parseUrl = useCallback(() => {
    const queryResult = getFiltersFromQuery({
      locationSearch: location.search,
      categories: data?.category || [],
    });
    const BASE_URL: string = process.env.REACT_APP_WEB_HOST || 'http://localhost:5000';
    let query = `${BASE_URL}${location.pathname}?`;
    Object.keys(queryResult.filters).forEach(v => {
      query += (query.length > 0 || query.endsWith('?')) ? '&' : '';
      if (v === 'category') {
        query += `category=${queryResult.category?.name.toLowerCase()}`;
      } else if (v === 'sub_categories') {
        query += `${queryResult.filters[v].map((id: string) => {
          if (flatSubcategories !== null) {
            const subcategory = flatSubcategories[Number(id)];
            return `${v}[]=${subcategory.slug}`;
          }
          return '';
        }).join('&')}`;
      } else if (Array.isArray(queryResult.filters[v])) {
        query += `${queryResult.filters[v].map((id: string) => `${v}[]=${id}`).join('&')}`;
      } else {
        query += `${v}=${queryResult.filters[v]}`;
      }
    });
    return query;
  }, [data, flatSubcategories, location.pathname, location.search]);

  useEffect(() => {
    const url = parseUrl();
    setCurrentUrl(url);
  }, [parseUrl]);

  const share = (): void => {
    Share.share({
      title: getTranslatedString('services-from-collier-cares', language),
      dialogTitle: getTranslatedString('services-from-collier-cares', language),
      text: getTranslatedString('services-from-collier-cares', language),
      url: currentUrl,
    });
  };

  return (
    <CCPage
      startButtons={(
        <IonButton onClick={() => history.goBack()}>
          <Icon name="arrow_back" width={isNotXtraSmall ? 40 : 35} color={primaryColor} />
        </IonButton>
      )}
      useLogo
      endButtons={(
        <IonButton className={classes.flippedIcon} onClick={share}>
          <Icon name="reply" color={primaryColor} width={isNotXtraSmall ? 40 : 35} />
        </IonButton>
      )}
      content={(
        <>
          {isDesktop ? null : (
            <SearchResults />
          )}
        </>
      )}
    />
  );
};

export default SearchResultsPage;
