/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Storage } from '@capacitor/storage';
import { isPlatform } from '@ionic/react';
import { getHours, getMinutes, isAfter } from 'date-fns';
import isBefore from 'date-fns/isBefore';
import ItemWithName from '../interfaces/ItemWithName';
import Listing, { Hour } from '../interfaces/Listing';
import { Category } from '../interfaces/Meta';
import { Filter, GetQueryInfo } from '../interfaces/SearchResult';
import { Location } from '../interfaces/StoreModel/MapModel';
import localeData from './translations/locales';
import enStrings from './translations/locales/en.json';
import esStrings from './translations/locales/es.json';

export const getTranslatedString = (
  stringId: string,
  language: keyof typeof localeData,
): string => {
  // @ts-ignore
  let result = enStrings[stringId];
  if (language === 'es') {
    // @ts-ignore
    result = esStrings[stringId] || result;
  }
  return result;
};

/* eslint-disable no-bitwise */
export const slugify = (str: string): string => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

export const hexToRgbA = (hex: string, alpha: number): string => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const transformIntoNonMilitaryTime = (HHMM: string): string => {
  const HHMMArray = HHMM.split(':');
  if (Number(HHMMArray[0]) > 12) {
    return `${Number(HHMMArray[0]) % 12}:${HHMMArray[1]} PM`;
  }
  if (Number(HHMMArray[0]) === 12) {
    return `${Number(HHMMArray[0])}:${HHMMArray[1]} PM`;
  }
  return `${Number(HHMMArray[0])}:${HHMMArray[1]} AM`;
};

export const getDayOfWeekOpenHours = (
  hours: Hour[],
  dayOfWeek: number,
  language: keyof typeof localeData,
  doNotCompareToNow = true,
): string => {
  let hourString = `${getTranslatedString('open', language)} `;
  let openingHours = hours
    .filter((h) => h.day_of_week === dayOfWeek && !h.is_closed)
    .sort((a, b) => (isBefore(
      new Date(`1970/01/01 ${a.open}`),
      new Date(`1970/01/01 ${b.close}`),
    )
      ? -1
      : 1));

  if (doNotCompareToNow) {
    if (!openingHours.length) {
      return getTranslatedString('closed', language);
    }
  } else {
    const isClosed = hours.find(h => h.day_of_week === dayOfWeek && h.is_closed);
    if (isClosed || hours.length === 0) {
      return getTranslatedString('closed-today', language);
    }

    const openingHoursToday = hours
      .filter((h) => h.day_of_week === dayOfWeek && !h.is_closed);

    const hourNow = getHours(new Date());
    const minutesNow = getMinutes(new Date());
    const openingHoursLaterToday = openingHoursToday
      .filter(h => isAfter(new Date(`1970/01/01 ${h.open}`), new Date(`1970/01/01 ${hourNow}:${minutesNow}:00`)) && !h.open_24_hours);

    // Opening Soon
    if (openingHoursLaterToday.length > 0) {
      hourString = `${getTranslatedString('opening-soon', language)} `;
      openingHours = openingHoursLaterToday;
    }

    // Open now
    const openRightNowHour = openingHoursToday
      .filter(h => isAfter(new Date(`1970/01/01 ${hourNow}:${minutesNow}:00`), new Date(`1970/01/01 ${h.open}`))
      && isBefore(new Date(`1970/01/01 ${hourNow}:${minutesNow}:00`), new Date(`1970/01/01 ${h.close}`)) && !h.open_24_hours);

    if (openRightNowHour.length > 0) {
      openingHours = openRightNowHour;
      hourString = `${getTranslatedString('open', language)} `;
    }

    const closedNowHours = openingHoursToday
      .filter(h => isAfter(new Date(`1970/01/01 ${hourNow}:${minutesNow}:00`), new Date(`1970/01/01 ${h.close}`)) && !h.open_24_hours);
    if (closedNowHours.length > 0
      && openRightNowHour.length === 0
      && openingHoursLaterToday.length === 0
    ) {
      return `${getTranslatedString('closed-now', language)}`;
    }
  }

  for (let i = 0; i < openingHours.length; i += 1) {
    const hourElement = openingHours[i];
    if (hourElement.open_24_hours) {
      return `${getTranslatedString('open', language)} 24h`;
    }
    const openTime = transformIntoNonMilitaryTime(hourElement.open);
    const closeTime = transformIntoNonMilitaryTime(hourElement.close);
    if (i > 0) {
      hourString += '\n';
    }
    hourString += `${openTime} - ${closeTime}`;
  }

  return hourString;
};

export const getListingFullAddress = (listing: Listing): string => `${listing.address.street_address_1}, ${
  listing.address.street_address_2
    ? `${listing.address.street_address_2}, `
    : ''
}${listing.address.zip_code}`;

export const goToDirections = (isDesktop: boolean, listing: Listing): void => {
  const fullAddress = getListingFullAddress(listing);
  if (isDesktop) {
    window.open(`https://maps.google.com/maps?q=${encodeURI(fullAddress)}`);
  } else if (isPlatform('ios')) {
    window.open(
      `maps://?q=${fullAddress}(${encodeURI(
        listing.provider?.name as string,
      )})`,
      '_system',
    );
  } else {
    window.open(
      `geo:0,0?q=${fullAddress}(${encodeURI(
        listing.provider?.name as string,
      )})`,
      '_system',
    );
  }
};

export const daysOfWeekEn = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const daysOfWeekEs = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
];

export const getFiltersFromQuery = ({
  locationSearch,
  categories,
}: GetQueryInfo): {
  term: string;
  filters: Filter;
  category: Category | null;
} => {
  const filters: Filter = {} as Filter;
  const searchParams = new URLSearchParams(decodeURI(locationSearch));
  let category: Category | null = null;
  searchParams.forEach((p, i) => {
    if (i === 'category') {
      const foundCategory = categories.find((c) => c.slug === p) as Category;
      if (foundCategory) {
        category = foundCategory;
        filters[i] = foundCategory.id;
      }
    }
    if (i.includes('[]')) {
      let value = p;
      const keyName = i.slice(0, -2);
      if (keyName === 'sub_categories') {
        const subcat = category?.sub_categories.find((s) => s.slug === p);
        value = subcat ? `${subcat.id}` : p;
      }
      if (filters[keyName]) {
        (filters[keyName] as string[]).push(value);
      } else {
        filters[keyName] = [value];
      }
    } else if (i !== 'category') {
      filters[i] = p;
    }
  });

  const term = searchParams.get('term') || '';
  if (term) {
    category = null;
    delete filters.category;
    delete filters.sub_categories;
    delete filters.term;
    filters.term = term;
  }

  return {
    term,
    filters,
    category,
  };
};

// TODO memoize this function.
export const getDefaultFiltersAsUrlParams = (
  settings: any,
  setUseLocationOnSearch: any,
  coordinates: Location | null,
): string => {
  let query = '';
  if (settings.useAgeOnSearch && settings.useAgeOnSearch.length > 0) {
    const ageGroupIds = settings.useAgeOnSearch
      .map((id: any) => `age_ranges[]=${id}`)
      .join('&');
    query += `${query.length > 0 ? '&' : ''}${ageGroupIds}`;
  }

  if (settings.useLocationOnSearch) {
    if (coordinates) {
      query += `${query.length > 0 ? '&' : ''}lat=${encodeURI(
        `${coordinates.latitude}`,
      )}&long=${encodeURI(`${coordinates.longitude}`)}`;
    } else {
      setUseLocationOnSearch(false);
    }
  } else if (settings.useCitiesOnSearch.length) {
    const cities = settings.useCitiesOnSearch
      .map((id: any) => `cities[]=${id}`)
      .join('&');
    query += `${query.length > 0 ? '&' : ''}${cities}`;
  }
  if (settings.useGroupsOnSearch && settings.useGroupsOnSearch.length) {
    const groups = settings.useGroupsOnSearch
      .map((id: any) => `groups[]=${id}`)
      .join('&');
    if (groups) {
      query += `${query.length > 0 ? '&' : ''}${groups}`;
    }
  }
  return `${query}`;
};

export const getSavedListings = async (): Promise<Listing[]> => {
  let favorites: Listing[] = [];
  const ret = await Storage.get({ key: 'favorites' });
  if (ret.value != null) {
    favorites = JSON.parse(ret.value);
  }
  return favorites;
};

export const removeSavedListingById = async (
  listingId: number,
): Promise<Listing[]> => {
  const favorites = await getSavedListings();
  const newFavorites = favorites.filter(
    (favorite) => favorite.id !== listingId,
  );
  const newFavoritesString = JSON.stringify(newFavorites);
  await Storage.set({ key: 'favorites', value: newFavoritesString });
  return newFavorites;
};

export const addSavedListing = async (listing: Listing): Promise<Listing[]> => {
  const favorites = await getSavedListings();
  const newFavorites = favorites.filter(
    (favorite) => favorite.id !== listing.id,
  );
  newFavorites.push(listing);
  const newFavoritesString = JSON.stringify(newFavorites);
  await Storage.set({ key: 'favorites', value: newFavoritesString });
  return newFavorites;
};

export const replaceSavedListing = async (
  listing: Listing,
): Promise<Listing[]> => {
  const favorites = await getSavedListings();
  // eslint-disable-next-line max-len
  const newFavorites: Listing[] = favorites.map((favorite) => (favorite.id === listing.id ? listing : favorite));
  const newFavoritesString = JSON.stringify(newFavorites);
  await Storage.set({ key: 'favorites', value: newFavoritesString });
  return newFavorites;
};

export const replaceSavedListings = async (
  listings: Listing[],
): Promise<Listing[]> => {
  let favorites = await getSavedListings();
  listings.forEach((listing) => {
    favorites = favorites.map((favorite) => (favorite.id === listing.id ? listing : favorite));
  });
  const newFavoritesString = JSON.stringify(favorites);
  await Storage.set({ key: 'favorites', value: newFavoritesString });
  return favorites;
};

export const logFirebaseEvent = (name: string, params?: any): void => {
  FirebaseAnalytics.logEvent({ name, params });
};

export const translateName = (item: ItemWithName, locale: string): string => {
  if (locale === 'us') return item.name;
  if (locale === 'es') return item.translation?.es?.name || item.name;
  return item.name;
};

export const translateProp = (
  item: {
    translation?: { es?: {} | null } | null;
    translations?: { es?: {} | null } | null;
  },
  propName: string,
  locale: string,
): string => {
  if (locale === 'us') return Reflect.get(item, propName);
  if (locale === 'es') {
    return (
      Reflect.get(item.translation?.es || item.translations?.es || {}, propName)
      || Reflect.get(item, propName)
    );
  }
  return Reflect.get(item, propName);
};
