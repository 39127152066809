import { IonContent, IonPage } from '@ionic/react';
import React, { useRef } from 'react';
import ListingDetail from './ListingDetail';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import IonContentContext from '../../lib/context/IonContentContext';

const ListingDetailPage: React.FC = () => {
  const isDesktop = useIsDesktop();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  return (
    <IonPage>
      <IonContentContext.Provider value={contentRef}>
        <IonContent ref={contentRef}>{!isDesktop ? <ListingDetail /> : null}</IonContent>
      </IonContentContext.Provider>
    </IonPage>
  );
};

export default ListingDetailPage;
