import { IonButton, IonIcon } from '@ionic/react';
import { locate } from 'ionicons/icons';
import React from 'react';
import routePaths from '../../lib/routePaths';
import CCPage from '../../components/CCPage';
import SearchResults from './SearchResults';

const SavedServices: React.FC = () => (
  <CCPage
    useLogo
    endButtons={(
      <IonButton routerLink={routePaths.public.search}>
        <IonIcon slot="end" icon={locate} />
      </IonButton>
      )}
    content={(
      <>
        <SearchResults />
      </>
      )}
  />
);

export default SavedServices;
