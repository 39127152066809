import { createUseStyles } from 'react-jss';

export default createUseStyles({
  description: {
    textAlign: 'left',
    marginTop: '19px',
    fontSize: '18px',
    fontWeight: '400',
    color: '#2D2D2D',
  },
  descriptionLink: {
    textAlign: 'left',
    marginTop: '19px',
    fontSize: '18px',
    fontWeight: '400',
    color: '#2D2D2D',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  ineligibleText: {
    color: '#B31316',
    fontSize: '14px',
    fontWeight: '400',
  },
  radioGroupHeader: {
    textAlign: 'left',
    fontSize: '18px',
    fontWeight: '400',
    color: 'var(--ion-color-primary)',
  },
  radioButtonItem: {
    '--min-height': '24px',
    textAlign: 'top',
    margin: '0px',
    marginTop: '5px',
    '--padding-start': '0px',
  },
  radioButtonLabel: {
    textAlign: 'top',
    margin: '5px',
    padding: '0px',
  },
  radioButton: {
    textAlign: 'top',
    margin: '0px',
    padding: '0px',
  },
  input: {
    marginTop: '17px',
    border: '2px solid var(--ion-color-primary)',
    borderRadius: '5px',
    '--background': '#FFF',
    '--border-color': 'transparent!important',
    '--highlight-height': '0',
    padding: '0px',
    '&.item-has-focus': {
      border: '3px solid var(--ion-color-primary)',
    },
    '&.item-input ion-label, &.item-textarea ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
    },
    '&.item-has-focus ion-label, &.item-has-value ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
      position: 'absolute',
      fontSize: 10,
      top: 5,
      left: 7,
      margin: 0,
    },
    '& ion-input': {
      '--placeholder-color': 'var(--ion-color-primary)',
    },
  },
  tallInput: {
    marginTop: '17px',
    border: '2px solid var(--ion-color-primary)',
    borderRadius: '5px',
    '--background': '#FFF',
    '--min-height': '140px',
    '--border-color': 'transparent!important',
    '--highlight-height': '0',
    padding: '0px',
    '&.item-has-focus': {
      border: '3px solid var(--ion-color-primary)',
    },
    '& ion-input': {
      '--placeholder-color': 'var(--ion-color-primary)',
    },
    '&.item-input ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
    },
    '&.item-has-focus ion-label, &.item-has-value ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
      position: 'absolute',
      fontSize: 10,
      top: 5,
      left: 7,
      margin: 0,
    },
  },
  'ion-select::before': {
    width: 20,
  },
  inputsList: {
    '--ion-item-background': 'transparent',
  },
  tosCheckboxContainer: {
    marginTop: '20px',
  },
  tosCheckbox: {
    marginRight: '10px',
  },
  desktopSignInBar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  checkbox: {
    '--background': 'none',
    '--background-hover': 'none',
    '--border-width': 0,
    '--inner-border-width': 0,
    '& ion-checkbox': {
      marginRight: '10px',
    },
  },
});
