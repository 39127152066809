import { IonLabel } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Category } from '../../interfaces/Meta';
import Icon from '../../components/Icon';
import useClasses from '../../components/styles';
import routePaths from '../../lib/routePaths';
import { translateName } from '../../lib/utils';
import { useStoreState } from '../../store';

interface CategoryItemProps {
  category: Category;
  disabled: boolean;
}

const CategoryItem: React.FunctionComponent<CategoryItemProps> = (
  { category, disabled }: CategoryItemProps,
) => {
  const classes = useClasses();
  const history = useHistory();
  const [iconDim, setIconDim] = useState(48);
  const locale = useStoreState((state) => state.translations.language);

  const name = translateName(category, locale);
  const customStyle = disabled ? {
    backgroundColor: 'gray',
    cursor: 'auto',
  } : { backgroundColor: category.color };

  const onClick = (isDisabled: boolean): void => {
    if (isDisabled) return;
    const path = routePaths.public.subcategories.replace(':category_name', category.slug);
    if (category.slug === 'crisis') {
      history.push(`${routePaths.public.crisis}?url=${encodeURIComponent(path)}`);
    } else {
      history.push(path);
    }
  };

  const getDim = (height: number): number => {
    let dim = 48;
    if (height >= 667) {
      dim = 64;
    }
    return dim;
  };

  useEffect(() => {
    const updateDim = (e: Event): void => {
      const target = e.target as Window;
      const { height } = target.screen;
      const dim = getDim(height);
      setIconDim(dim);
    };
    const { height } = window.screen;
    const dim = getDim(height);
    setIconDim(dim);
    window.addEventListener('resize', updateDim);
    return (): void => {
      window.removeEventListener('resize', updateDim);
    };
  }, []);

  return (
    <div className={classes.categoryItem}>
      <div onClick={() => onClick(disabled)} className={classes.category} style={customStyle}>
        <Icon name={category.icon} color="#FFF" width={iconDim} height={iconDim} />
        <IonLabel style={{ fontSize: name.length > 12 ? '10px' : '14px', textAlign: 'center' }}>{name}</IonLabel>
      </div>
    </div>
  );
};

export default CategoryItem;
