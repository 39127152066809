import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routePaths from '../../../../lib/routePaths';
import { useStoreActions, useStoreState } from '../../../../store';
import ListingCategoryRow from './ListingCategoryRow';
import useStyles from '../../styles';

const ListingCategories: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const { data } = useStoreState(state => state.categories);
  const { find } = useStoreActions(actions => actions.categories);

  useEffect(() => {
    find();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.adminTabContent}>
      {data?.map((category) => (
        <ListingCategoryRow
          key={category.id}
          category={category}
          editCategory={(id): void => {
            history.push(
              routePaths.admin.editListingCategory.replace(':id', `${id}`),
            );
          }}
        />
      ))}
    </div>
  );
};

export default ListingCategories;
