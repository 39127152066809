import { IonCol, IonRow } from '@ionic/react';
import React, {
  MutableRefObject, ReactChild, useCallback, useEffect, useRef, useState,
} from 'react';
import { Network } from '@capacitor/network';
import useClasses from '../../components/styles';
import { Category } from '../../interfaces/Meta';
import CategoryItem from './CategoryItem';

interface CategoryProps {
  categories: Category[];
}

const Categories: React.FunctionComponent<CategoryProps> = ({
  categories,
}: CategoryProps) => {
  const classes = useClasses();
  const wrapperRef = useRef() as MutableRefObject<HTMLDivElement>;
  const [currentWidth, setCurrentWidth] = useState(0);
  const [currentWidthSet, setCurrentWidthSet] = useState(false);
  const [loadCounter, setLoadCounter] = useState(0);
  const [networkStatus, setNetworkStatus] = useState<boolean | undefined>(undefined);

  const getNetworkStatus = () => {
    Network.getStatus().then((status) => {
      setNetworkStatus(status.connected);
    });
  };

  let columns: ReactChild[] = [];
  const rows: ReactChild[] = [];

  const resize = useCallback(() => {
    if (wrapperRef.current) {
      const boundingClientRect = wrapperRef.current.getBoundingClientRect();
      if (boundingClientRect.width > 0 && !currentWidthSet && loadCounter < 3) {
        setCurrentWidth(boundingClientRect.width);
        setCurrentWidthSet(true);
      } else {
        setLoadCounter(loadCounter + 1);
        setTimeout(() => {
          resize();
        }, 100);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    resize();
    getNetworkStatus();
    window.addEventListener('resize', resize);
    return (): void => {
      window.removeEventListener('resize', resize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (currentWidth > 0 && networkStatus !== undefined) {
    let itemsPerRows = 3;
    if (currentWidth < 285) {
      itemsPerRows = 2;
    }
    if (!networkStatus) {
      const categoriesStored = localStorage.getItem('categories');
      if (categoriesStored) {
        categories = JSON.parse(categoriesStored);
      }
    }
    categories
      .sort((a, b) => a.order - b.order)
      .forEach((c) => {
        columns.push(
          <IonCol key={columns.length} className={classes.categoryColumn}>
            <CategoryItem category={c} disabled={!networkStatus && c.slug !== 'crisis'} />
          </IonCol>,
        );
        if (columns.length === itemsPerRows) {
          rows.push(
            <IonRow key={rows.length} className={classes.row}>
              {columns}
            </IonRow>,
          );
          columns = [];
        }
      });
  }

  return <div ref={wrapperRef}>{rows}</div>;
};

export default Categories;
