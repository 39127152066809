import React from 'react';
import { IconProps } from './Icon';

const More: React.FunctionComponent<IconProps> = ({ color, height, width }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 500 500"
  >
    <g fill={color}>
      <path
        id="Selection"
        d="M 79.00,100.12
           C 74.83,101.44 65.37,104.09 62.00,106.26
             42.31,118.95 46.00,147.84 46.00,168.00
             46.00,168.00 46.00,357.00 46.00,357.00
             46.01,363.97 46.25,365.31 48.34,372.00
             49.87,376.91 50.70,380.70 53.79,385.00
             59.58,393.04 70.23,397.95 80.00,398.00
             80.00,398.00 112.00,398.00 112.00,398.00
             112.00,398.00 325.00,398.00 325.00,398.00
             345.81,397.97 352.65,386.50 363.19,371.00
             363.19,371.00 385.74,337.00 385.74,337.00
             385.74,337.00 425.95,277.00 425.95,277.00
             429.93,270.83 439.78,257.77 440.63,251.00
             441.32,245.61 437.71,240.36 434.95,236.00
             434.95,236.00 417.69,210.00 417.69,210.00
             417.69,210.00 367.19,134.00 367.19,134.00
             361.64,125.84 353.84,112.13 346.00,106.65
             339.59,102.16 328.72,100.01 321.00,100.12
             321.00,100.12 161.00,100.12 161.00,100.12
             161.00,100.12 108.00,100.12 108.00,100.12
             108.00,100.12 79.00,100.12 79.00,100.12 Z
           M 121.00,223.33
           C 126.69,222.69 135.92,222.24 141.00,224.92
             149.53,229.41 154.88,236.29 155.00,246.00
             155.15,258.44 154.64,265.29 143.00,272.89
             137.14,276.72 132.64,276.08 126.00,276.00
             119.14,275.91 116.37,274.73 111.09,270.19
             108.00,267.54 104.79,263.79 103.22,260.00
             102.07,257.20 102.04,254.95 102.20,252.00
             101.97,249.41 101.87,244.36 102.20,242.00
             104.28,233.30 112.54,225.43 121.00,223.33 Z
           M 204.00,223.39
           C 209.34,222.66 219.15,222.37 224.00,224.67
             233.40,229.14 238.87,236.59 239.00,247.00
             239.13,257.73 238.14,264.31 228.99,271.47
             222.60,276.46 217.61,276.09 210.00,276.00
             197.35,275.84 186.18,265.92 186.20,253.00
             185.97,250.23 185.85,243.40 186.20,241.00
             188.18,233.04 196.46,225.75 204.00,223.39 Z
           M 288.00,223.39
           C 293.20,222.69 303.33,222.37 308.00,224.68
             327.95,234.58 328.20,263.03 309.00,273.79
             304.17,276.50 299.34,276.06 294.00,276.00
             264.05,275.63 259.89,231.84 288.00,223.39 Z"
      />
    </g>
  </svg>
);

export default More;
