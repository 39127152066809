import client from './client';
import getServiceName from './getServiceName';

export const meta = client.service(getServiceName('meta'));
export const pages = client.service(getServiceName('pages'));
export const users = client.service(getServiceName('users'));
export const listings = client.service(getServiceName('listings'));
export const search = client.service(getServiceName('search'));
export const providers = client.service(getServiceName('providers'));
export const verifyEmail = client.service(getServiceName('verify-email'));
export const passwordReset = client.service(getServiceName('password-reset'));
export const categories = client.service(getServiceName('categories'));
export const subcategories = client.service(getServiceName('sub-categories'));
export const crisisHotlines = client.service(getServiceName('crisis-hotlines'));
export const crisisHotlineCategories = client.service(getServiceName('crisis-hotline-categories'));
export const analyticsUsers = client.service(getServiceName('analytics/users'));
export const analyticsEvents = client.service(getServiceName('analytics/events'));
