import React from 'react';

function TrianglesDesktop() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="920"
      height="480"
      version="1.1"
      viewBox="0 0 920 480"
    >
      <g opacity="0.15" transform="translate(0 -9.182)">
        <path
          fill="#195fe8"
          fillRule="evenodd"
          d="M500 414.886h-60l30-37.289z"
        />
      </g>
      <path
        fill="#c3c7cd"
        fillRule="evenodd"
        d="M920 405.704h-60l30-37.288z"
      />
      <path
        fill="#195fe8"
        fillRule="evenodd"
        d="M830 442.993h-60l30 37.288z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#919191"
          fillRule="evenodd"
          d="M800 414.886h-60l30 37.289z"
        />
      </g>
      <path
        fill="#142582"
        fillRule="evenodd"
        d="M890 442.993h-60l30-37.289z"
      />
      <path
        fill="#5b5b5b"
        fillRule="evenodd"
        d="M920 480.281h-60l30-37.288z"
      />
      <path
        fill="#692fd4"
        fillRule="evenodd"
        d="M740 480.281h-60l30-37.288z"
      />
      <path
        fill="#919191"
        fillRule="evenodd"
        d="M680 480.281h-60l30-37.288z"
      />
      <path
        fill="#e37605"
        fillRule="evenodd"
        d="M830 368.416h-60l30-37.289z"
      />
      <path
        fill="#a010a8"
        fillRule="evenodd"
        d="M770 442.993h-60l30-37.289z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#26aea6"
          fillRule="evenodd"
          d="M800 414.886h-60l30-37.289z"
        />
      </g>
      <g opacity="0.8" transform="translate(0 -9.182)">
        <path
          fill="#195fe8"
          fillRule="evenodd"
          d="M680 414.886h-60l30-37.289z"
        />
      </g>
      <g opacity="0.8" transform="translate(0 -9.182)">
        <path
          fill="#5b5b5b"
          fillRule="evenodd"
          d="M860 340.309h-60l30 37.288z"
        />
      </g>
      <path
        fill="#919191"
        fillRule="evenodd"
        d="M920 181.973h-60l30 37.288z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#5b5b5b"
          fillRule="evenodd"
          d="M860 414.886h-60l30-37.289z"
        />
      </g>
      <g opacity="0.8" transform="translate(0 -9.182)">
        <path
          fill="#5b5b5b"
          fillRule="evenodd"
          d="M710 377.597h-60l30-37.288z"
        />
      </g>
      <path
        fill="#5b5b5b"
        fillRule="evenodd"
        d="M740 405.704h-60l30-37.288z"
      />
      <g opacity="0.8" transform="translate(0 -9.182)">
        <path
          fill="#26aea6"
          fillRule="evenodd"
          d="M920 340.309h-60l30-37.289z"
        />
      </g>
      <g opacity="0.8" transform="translate(0 -9.182)">
        <path
          fill="#142582"
          fillRule="evenodd"
          d="M740 340.309h-60l30-37.289z"
        />
      </g>
      <path
        fill="#e3b205"
        fillRule="evenodd"
        d="M950 368.416h-60l30-37.289z"
      />
      <path
        fill="#a010a8"
        fillRule="evenodd"
        d="M950 293.838h-60l30-37.288z"
      />
      <path
        fill="#195fe8"
        fillRule="evenodd"
        d="M890 219.261h-60l30 37.289z"
      />
      <path
        fill="#01663c"
        fillRule="evenodd"
        d="M890 293.838h-60l30-37.288z"
      />
      <g opacity="0.8" transform="translate(0 -9.182)">
        <path
          fill="#5b5b5b"
          fillRule="evenodd"
          d="M830 303.02h-60l30-37.288z"
        />
      </g>
      <g opacity="0.4" transform="translate(0 -9.182)">
        <path
          fill="#8e8e8e"
          fillRule="evenodd"
          d="M950 303.02h-60l30 37.289z"
        />
      </g>
      <g opacity="0.8" transform="translate(0 -9.182)">
        <path
          fill="#692fd4"
          fillRule="evenodd"
          d="M800 265.732h-60l30-37.289z"
        />
      </g>
      <path
        fill="#dd1368"
        fillRule="evenodd"
        d="M770 368.416h-60l30-37.289z"
      />
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M860 331.127h-60l30-37.289z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#dd1368"
          fillRule="evenodd"
          d="M950 452.175h-60l30-37.289z"
        />
      </g>
      <path
        fill="#8e8e8e"
        fillRule="evenodd"
        d="M860 480.281h-60l30-37.288z"
      />
      <g opacity="0.6" transform="translate(0 -9.182)">
        <path
          fill="#5b5b5b"
          fillRule="evenodd"
          d="M800 340.309h-60l30 37.288z"
        />
      </g>
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#e3b205"
          fillRule="evenodd"
          d="M770 153.866h-60l30 37.288z"
        />
      </g>
      <path
        fill="#5b5b5b"
        fillRule="evenodd"
        d="M710 293.838h-60l30-37.288z"
      />
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M470 442.993h-60l30 37.288z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#5b5b5b"
          fillRule="evenodd"
          d="M680 340.309h-60l30-37.289z"
        />
      </g>
      <path
        fill="#919191"
        fillRule="evenodd"
        d="M770 293.838h-60l30-37.288z"
      />
      <path
        fill="#777879"
        fillRule="evenodd"
        d="M770 219.261h-60l30 37.289z"
      />
      <path
        fill="#1ac62b"
        fillRule="evenodd"
        d="M860 181.973h-60l30 37.288z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#195fe8"
          fillRule="evenodd"
          d="M830 228.443h-60l30-37.289z"
        />
      </g>
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#26aea6"
          fillRule="evenodd"
          d="M860 116.577h-60l30 37.289z"
        />
      </g>
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M950 144.684h-60l30-37.289z"
      />
      <path
        fill="#f4f4f4"
        fillRule="evenodd"
        d="M890 70.107h-60l30-37.289z"
      />
      <path
        fill="#f4f4f4"
        fillRule="evenodd"
        d="M770 70.107h60l-30 37.288z"
      />
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M950 219.261h-60l30 37.289z"
      />
      <path
        fill="#d22727"
        fillRule="evenodd"
        d="M950 219.261h-60l30-37.288z"
      />
      <path
        fill="#5b5b5b"
        fillRule="evenodd"
        d="M920 331.127h-60l30 37.289z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#5b5b5b"
          fillRule="evenodd"
          d="M710 452.175h-60l30-37.289z"
        />
      </g>
      <path
        fill="#692fd4"
        fillRule="evenodd"
        d="M920 405.704h-60l30 37.289z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#26aea6"
          fillRule="evenodd"
          d="M620 414.886h-60l30-37.289z"
        />
      </g>
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#195fe8"
          fillRule="evenodd"
          d="M530 452.175h-60l30 37.288z"
        />
      </g>
      <path
        fill="#919191"
        fillRule="evenodd"
        d="M590 442.993h-60l30-37.289z"
      />
      <path
        fill="#5b5b5b"
        fillRule="evenodd"
        d="M620 480.281h-60l30-37.288z"
      />
      <g opacity="0.15" transform="translate(0 -9.182)">
        <path
          fill="#692fd4"
          fillRule="evenodd"
          d="M560 414.886h-60l30-37.289z"
        />
      </g>
      <path
        fill="#1ac62b"
        fillRule="evenodd"
        d="M800 480.281h-60l30-37.288z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#e3b205"
          fillRule="evenodd"
          d="M650 377.597h-60l30-37.288z"
        />
      </g>
      <g opacity="0.15" transform="translate(0 -9.182)">
        <path
          fill="#a010a8"
          fillRule="evenodd"
          d="M650 303.02h-60l30-37.288z"
        />
      </g>
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M800 181.973h-60l30 37.288z"
      />
      <g opacity="0.15" transform="translate(0 -9.182)">
        <path
          fill="#dd1368"
          fillRule="evenodd"
          d="M830 153.866h-60l30 37.288z"
        />
      </g>
      <g opacity="0.8" transform="translate(0 -9.182)">
        <path
          fill="#dd1368"
          fillRule="evenodd"
          d="M650 452.175h-60l30-37.289z"
        />
      </g>
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M560 480.281h-60l30-37.288z"
      />
      <g opacity="0.5" transform="translate(0 -9.182)">
        <path
          fill="#5b5b5b"
          fillRule="evenodd"
          d="M830 153.866h-60l30-37.289z"
        />
      </g>
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M890 70.107h-60l30 37.288z"
      />
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M950 70.107h-60l30-37.289z"
      />
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M620 331.127h-60l30 37.289z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#692fd4"
          fillRule="evenodd"
          d="M620 414.886h-60l30 37.289z"
        />
      </g>
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M770 293.838h-60l30 37.289z"
      />
      <g opacity="0.5" transform="translate(0 -9.182)">
        <path
          fill="#142582"
          fillRule="evenodd"
          d="M920 265.732h-60l30 37.288z"
        />
      </g>
      <path
        fill="#d0d0d0"
        fillRule="evenodd"
        d="M890 219.261h-60l30-37.288z"
      />
      <g opacity="0.3" transform="translate(0 -9.182)">
        <path
          fill="#692fd4"
          fillRule="evenodd"
          d="M890 153.866h-60l30-37.289z"
        />
      </g>
      <path
        fill="#e3b205"
        fillRule="evenodd"
        d="M920 181.973h-60l30-37.289z"
      />
      <path
        fill="#a010a8"
        fillRule="evenodd"
        d="M920 107.395h-60l30-37.288z"
      />
      <path
        fill="#5b5b5b"
        fillRule="evenodd"
        d="M890 144.684h-60l30 37.289z"
      />
    </svg>
  );
}

export default TrianglesDesktop;
