import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import React from 'react';
import { CrisisHotlineWithCategory } from '../../../../interfaces/CrisisHotlines';
import useStyles from '../../styles';

interface CrisisHotlineRowProps {
  hotline: CrisisHotlineWithCategory;
  editHotline: (id: number) => void;
  deleteHotline: (id: number) => void;
}

const CrisisHotlineRow: React.FunctionComponent<CrisisHotlineRowProps> = ({
  hotline,
  editHotline,
  deleteHotline,
}: CrisisHotlineRowProps) => {
  const classes = useStyles();
  const {
    id, name, category, organization_name, phone_number, translation,
  } = hotline;

  return (
    <>
      <div className={classes.crisisCategoryCardHeader} />
      <div className={classes.providerCardContent}>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="8">
              <div className={classes.providerNameRow}>
                <IonItem
                  className={[
                    classes.providerOrgName,
                    'disabled-pointer-events',
                  ].join(' ')}
                >
                  <IonLabel position="floating" color="primary">
                    Hotline Title
                  </IonLabel>
                  <IonInput color="primary" value={`${name} / ${translation?.es?.name}`} />
                </IonItem>
              </div>
              <IonItem className="disabled-pointer-events">
                <IonLabel position="floating" color="primary">
                  Organization Name
                </IonLabel>
                <IonInput color="primary" value={organization_name} />
              </IonItem>
              <IonItem className="disabled-pointer-events">
                <IonLabel position="floating" color="primary">
                  Phone Number
                </IonLabel>
                <IonInput color="primary" value={phone_number} />
              </IonItem>
              <IonItem className="disabled-pointer-events">
                <IonLabel position="floating" color="primary">
                  Crisis Category
                </IonLabel>
                <IonInput color="primary" value={category.name} />
              </IonItem>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="1" />
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
              <IonButton
                className={classes.providerActionButton}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => editHotline(id)}
              >
                Edit
              </IonButton>
              <IonButton
                className={classes.providerActionButton}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => deleteHotline(id)}
              >
                Delete
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default CrisisHotlineRow;
