import { IonSegment, IonSegmentButton } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import CCAdminPage from '../../../../components/CCAdminPage';
import useStyles from '../../styles';
import ListingSubcategories from './ListingSubcategories';
import ListingCategories from './ListingCategories';
import { useStoreState, useStoreActions } from '../../../../store';

const CategoriesWrapper: React.FC = () => {
  const classes = useStyles();
  const [selectedReviewTab, setSelectedReviewTab] = useState('categories');
  const { find } = useStoreActions(actions => actions.subcategories);

  useEffect(() => {
    find();
  }, [find]);

  const { data: categories } = useStoreState(state => state.categories);
  const { data: subcategories } = useStoreState(state => state.subcategories);

  return (
    <CCAdminPage
      title="Manage Categories"
      content={(
        <>
          <div className={classes.adminContent}>
            <h3>Manage Categories</h3>
            <div className="mt-24">
              <IonSegment
                className={classes.adminTabs}
                value={selectedReviewTab}
                onIonChange={(e): void => setSelectedReviewTab(e.detail.value as string)}
                color="primary"
                mode="md"
              >
                <IonSegmentButton
                  className={[
                    classes.adminTab,
                    'interiorItem ion-text-wrap',
                  ].join(' ')}
                  value="categories"
                >
                  {' '}
                  Categories ({categories?.length})
                </IonSegmentButton>
                <IonSegmentButton
                  className={[
                    classes.adminTab,
                    'interiorItem ion-text-wrap',
                  ].join(' ')}
                  value="subcategories"
                >
                  {' '}
                  Subcategories ({subcategories?.length})
                </IonSegmentButton>
              </IonSegment>
            </div>
            {((): unknown => {
              switch (selectedReviewTab) {
                case 'categories':
                  return <ListingCategories />;
                case 'subcategories':
                  return <ListingSubcategories />;
                default:
                  return null;
              }
            })()}
          </div>
        </>
      )}
    />
  );
};

export default CategoriesWrapper;
