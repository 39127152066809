import React from 'react';
import {
  Switch, Route, useLocation, useHistory,
} from 'react-router-dom';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowBack } from 'ionicons/icons';

import routePaths from '../../../lib/routePaths';
import ServiceListing from '../components/ServiceListing';
import CCProviderPage from '../../../components/CCProviderPage';
import ServiceListings from './ServiceListings';

import useAppStyles from '../../styles';
import useIsDesktop from '../../../lib/hooks/useIsDesktop';
import OrgInfo from '../components/OrgInfo';

const Approved: React.FC = () => {
  const isDesktop = useIsDesktop();
  const location = useLocation();
  const history = useHistory();
  const appClasses = useAppStyles();

  return (
    <CCProviderPage
      useMenuButton={
        location.pathname === routePaths.provider.approved.root
        || location.pathname === routePaths.provider.approved.editProfile
      }
      showTriangles
      title={location.pathname === routePaths.provider.approved.editProfile ? 'Profile' : 'Listings'}
      startButtons={
        (!isDesktop
          && (location.pathname.startsWith(routePaths.provider.approved.editService)
          || location.pathname === routePaths.provider.approved.addService)) ? (
            <IonButton
              className={appClasses.backButton}
              onClick={(): void => history.goBack()}
            >
              <IonIcon color="primary" icon={arrowBack} />
            </IonButton>
          ) : <></>
      }
      content={(
        <>
          <Switch>
            <Route
              exact
              path={routePaths.provider.approved.root}
              component={ServiceListings}
            />
            <Route
              path={routePaths.provider.approved.editServiceId}
              component={ServiceListing}
            />
            <Route
              path={routePaths.provider.approved.addService}
              component={ServiceListing}
            />
            <Route
              path={routePaths.provider.approved.editProfile}
              component={OrgInfo}
            />
          </Switch>
        </>
      )}
    />
  );
};

export default Approved;
