import { Network } from '@capacitor/network';
import { IonItem, IonLabel } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import routePaths from '../../lib/routePaths';
import { useStoreActions, useStoreState } from '../../store';
import useAppClasses from '../styles';
import Categories from './Categories';
import Search from './SearchInput';

const MainCategory: React.FC = () => {
  const appClasses = useAppClasses();
  const location = useLocation();
  const { data, loading } = useStoreState((state) => state.meta);
  const { updateFilters } = useStoreActions((actions) => actions.search);
  const params = new URLSearchParams(decodeURI(location.search));
  const redirectReason = params.get('redirect');
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const [showOfflineBanner, setShowOfflineBanner] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const networkHandler = Network.addListener('networkStatusChange', (status: { connected: boolean }) => {
    setShowOfflineBanner(!status.connected);
  });

  useEffect(() => {
    async function checkNetworkStatus(): Promise<void> {
      const status = await Network.getStatus();
      setShowOfflineBanner(!status.connected);
    }
    checkNetworkStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateFilters({});
  }, [updateFilters]);

  return (
    <>
      {isDesktop || !showOfflineBanner ? (
        <div className={isDesktop ? appClasses.searchWrapperDesktop : appClasses.searchWrapper}>
          <Search />
        </div>
      ) : (
        <IonItem color="primary" className="ion-margin-top ion-margin-bottom" onClick={(): void => { history.push(routePaths.public.favorites); }}>
          <IonLabel color="light" className="ion-text-wrap" style={{ textAlign: 'center', fontSize: '18px' }}>
            <FormattedMessage id="offline-nag" />
          </IonLabel>
        </IonItem>
      )}
      {redirectReason && redirectReason === 'no_listing' && (
        <div className={appClasses.homeErrorMessage}>
          <IonLabel>
            <FormattedMessage id="that-service-has-been-removed" />
          </IonLabel>
        </div>
      )}
      <div className={isDesktop ? appClasses.popularWrapperDesktop : appClasses.popularWrapper}>
        <IonLabel className={appClasses.popularText}>
          <FormattedMessage id="popular-camel" />
        </IonLabel>
      </div>
      {loading ? (
        <IonLabel className={appClasses.popularText}>
          <FormattedMessage id="loading-ellipsis" />
        </IonLabel>
      ) : (
        <Categories categories={data?.category || []} />
      )}
    </>
  );
};

export default MainCategory;
