import { createUseStyles } from 'react-jss';

export default createUseStyles({
  modalContent: {
    '--padding-start': '8px',
    '--padding-end': '8px',
    '--padding-top': '8px',
    '--padding-bottom': '8px',
  },
  noMinHeightItem: {
    '--min-height': '0px',
  },
  modalDivider: {
    height: '1px',
    backgroundColor: '#d9a928',
  },
  modalTitleLabel: {
    'font-size': '24px!important',
    marginTop: '10px',
    marginBottom: '0px',
  },
  modalCategories: {
    'font-size': '14px!important',
    marginTop: '10px',
    marginBottom: '0px',
  },
  modalOrgName: {
    'font-size': '18px!important',
    marginTop: '10px',
    marginBottom: '0px',
  },
  modalButtonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '30px',
    paddingBottom: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  modalIcons: {
    width: '35px',
    height: '35px',
  },
  modalIconRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  modalPageControls: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    paddingTop: '10px',
  },
  actionButtons: {
    alignItems: 'flex-end',
  },
  confirmDelete: {
    textAlign: 'center',
    '@media (max-width:576px)': {
      width: '100%',
    },
  },
  alignFlexEnd: {
    alignItems: 'flex-end',
    '@media (max-width:576px)': {
      flexDirection: 'column',
      '& .cc-button': {
        width: '100%',
      },
    },
  },
  confirmationButtons: {
    width: '100%',
    '@media (max-width:576px)': {
      flexDirection: 'column',
      '& .cc-button': {
        width: '100%',
      },
    },
  },
});
