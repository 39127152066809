import React from 'react';
import {
  IonLabel,
} from '@ionic/react';
import { useStoreState } from '../../../../../store';
import useStyles from '../styles';

const CurrentStepMobile: React.FC = () => {
  const classes = useStyles();
  const stepIndex = useStoreState(store => store.createOrg.currentStep);

  return (
    <div className={classes.labelRow}>
      <div className={[stepIndex === 1 ? classes.stepItemActive : classes.stepItem, 'interiorItem'].join(' ')}>
        <IonLabel className={[classes.stepNumber, 'number'].join(' ')}>1</IonLabel>
        <IonLabel className={[classes.stepTitle, 'title'].join(' ')}>ELIGIBILITY</IonLabel>
      </div>
      <div className={[stepIndex === 2 ? classes.stepItemActive : classes.stepItem, 'interiorItem'].join(' ')}>
        <IonLabel className={classes.stepNumber}>2</IonLabel>
        <IonLabel className={[classes.stepTitle, 'title'].join(' ')}>SIGN UP</IonLabel>
      </div>
      <div className={[stepIndex === 3 ? classes.stepItemActive : classes.stepItem, 'interiorItem'].join(' ')}>
        <IonLabel className={classes.stepNumber}>3</IonLabel>
        <IonLabel className={[classes.stepTitle, 'title'].join(' ')}>ADD SERVICE</IonLabel>
      </div>
      <div className={[stepIndex === 4 ? classes.stepItemActive : classes.stepItem].join(' ')}>
        <IonLabel className={classes.stepNumber}>4</IonLabel>
        <IonLabel className={[classes.stepTitle, 'title'].join(' ')}>APPROVAL</IonLabel>
      </div>
    </div>
  );
};
export default CurrentStepMobile;
