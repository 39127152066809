/* eslint-disable max-len */
import React from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import {
  useLocation, useHistory,
} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import useAppStyles from '../../styles';
import useIsDesktop from '../../../lib/hooks/useIsDesktop';
import DesktopMenu from '../../../components/DesktopMenu';
import ProviderDesktopMenu from '../../../components/ProviderDesktopMenu';
import routePaths from '../../../lib/routePaths';
import TrianglesDesktop from '../../../assets/triangles_desktop';
import TrianglesMobile from '../../../assets/triangles_mobile';

interface OnboardingPageProps {
  content: React.ReactElement;
}

const OnboardingPage: React.FC<OnboardingPageProps> = ({
  content,
}: OnboardingPageProps) => {
  const isDesktop = useIsDesktop();
  const location = useLocation();
  const history = useHistory();
  const appClasses = useAppStyles();

  const actionButton = location.pathname === routePaths.provider.signin ? (
    <IonButton
      className="cc-button"
      routerLink={routePaths.provider.onboarding.root}
      shape="round"
      fill="outline"
      color="primary"
      size="large"
    >
      <FormattedMessage id="app.signin.signup" />
    </IonButton>
  ) : (
    <IonButton
      className="cc-button"
      routerLink={routePaths.provider.signin}
      shape="round"
      fill="outline"
      color="primary"
      size="large"
    >
      <FormattedMessage id="app.signin.signin" />
    </IonButton>
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={appClasses.ccToolbar}>
          {isDesktop ? (
            location.pathname === routePaths.provider.onboarding.addService
            || location.pathname.startsWith(routePaths.provider.onboarding.editService)
            || location.pathname === routePaths.provider.onboarding.approval
            || location.pathname === routePaths.provider.onboarding.unverifiedEmail
            || location.pathname === routePaths.provider.onboarding.submit
            || location.pathname === routePaths.provider.onboarding.viewProfile
              ? (<ProviderDesktopMenu />)
              : (<DesktopMenu />)
          ) : (
            <>
              <IonButtons slot="start" className="ion-no-padding ion-no-margin">
                {location.pathname === routePaths.provider.signin
                || location.pathname === routePaths.provider.onboarding.approval
                || location.pathname === routePaths.provider.onboarding.submit
                || location.pathname === routePaths.provider.onboarding.addService
                || location.pathname.startsWith(routePaths.provider.onboarding.editService)
                || location.pathname === routePaths.provider.onboarding.viewProfile
                || location.pathname === routePaths.provider.onboarding.unverifiedEmail ? (
                  <IonMenuButton />
                  ) : (
                    <IonButton
                      className={appClasses.backButton}
                      onClick={(): void => history.goBack()}
                    >
                      <IonIcon color="primary" icon={arrowBack} />
                    </IonButton>
                  )}
              </IonButtons>
              {location.pathname === routePaths.provider.signin
                || location.pathname === routePaths.provider.onboarding.requirements
                || location.pathname === routePaths.provider.onboarding.orgInfo ? (
                  <IonButtons slot="end" className="ion-no-padding">
                    {actionButton}
                  </IonButtons>
                ) : null }
            </>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className={[appClasses.content, 'ion-padding'].join(' ')}>
        <div className={appClasses.backgroundImage}>
          {isDesktop ? <TrianglesDesktop /> : <TrianglesMobile />}
        </div>
        <div className={appClasses.desktopContainer}>
          {isDesktop
          && !location.pathname.startsWith(routePaths.provider.onboarding.editService)
          && location.pathname !== routePaths.provider.onboarding.addService
          && location.pathname !== routePaths.provider.onboarding.approval
          && location.pathname !== routePaths.provider.onboarding.submit
          && location.pathname !== routePaths.provider.onboarding.viewProfile
          && location.pathname !== routePaths.provider.onboarding.unverifiedEmail ? (
            <IonToolbar className={appClasses.ccToolbar}>
              {location.pathname !== routePaths.provider.signin ? (
                <IonButtons slot="start" className="ion-no-padding ion-no-margin">
                  <IonButton
                    className={[appClasses.backButton, 'no-padding'].join(
                      ' ',
                    )}
                    onClick={(): void => history.goBack()}
                  >
                    <IonIcon color="primary" icon={arrowBack} />
                  </IonButton>
                </IonButtons>
              ) : null}
              <IonButtons slot="end" className="ion-no-padding">
                {actionButton}
              </IonButtons>
            </IonToolbar>
            ) : null}
          {content}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default OnboardingPage;
