import { useState, useEffect } from 'react';
import { isPlatform } from '@ionic/react';

export default function useIsDesktop(minWidth = 880): boolean {
  const [isDesktop, setDesktop] = useState(window.innerWidth >= minWidth);

  useEffect(() => {
    const sizeChanged = (): void => {
      setDesktop(window.innerWidth >= minWidth);
    };
    window.addEventListener('resize', sizeChanged);
    return (): void => window.removeEventListener('resize', sizeChanged);
  }, [minWidth]);

  return isDesktop && !isPlatform('ios') && !isPlatform('android');
}
