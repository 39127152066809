import { createUseStyles } from 'react-jss';

interface StyleProps {
  backgroundColor?: string
}

export default createUseStyles({
  sidebar: {
    height: '100%',
    width: '100%',
  },
  publicDesktopMenuContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  userDesktopMenuContainer: {
    // width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  categoryColumn: {
    '--ion-grid-column-padding': '7px',
  },
  row: {
    marginBottom: 10,
    height: '126px',
    '@media (max-height: 639px) and (max-width: 450px)': {
      height: '80px',
    },
    '@media (min-height: 640px) and (max-height: 666px) and (max-width: 450px)': {
      height: '95px',
    },
    '@media (min-height: 667px) and (max-height: 735px)': {
      height: '100px',
    },
  },
  category: {
    color: 'white',
    borderRadius: 10,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 100,
    '& ion-label': {
      fontWeight: 'bold',
    },
    cursor: 'pointer',
  },
  menuIcon: {
    '& g': {
      fill: '#FFFFFF',
    },
  },
  providerDesktopContentContainer: {
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
  },
  subcategories: {
    padding: 15,
  },
  categoryItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  categoryIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    width: 55,
    height: 55,
  },
  subcategoryRow: {
    textAlign: 'center',
    color: 'var(--ion-color-primary)',
    fontWeight: 'bold',
    marginBottom: 20,
  },
  subcategoryContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  btnRow: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  listingDetailSelectAllWrapper: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  listingDetailClearWrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
  },
  checkBoxText: {
    fontSize: '14px',
    fontWeight: '500',
    fontFamily: 'Roboto',
    color: 'var(--ion-color-primary)',
    marginLeft: 7.5,
  },
  subcategoryTitleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
  },
  subcategoryTitle: {
    flex: 1,
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
  },
  subcategoryTitleText: {
    flex: 1,
    fontSize: 24,
    fontWeight: 'bold',
  },
  subcategoryBtnWrapper: {
    width: '50%',
    padding: '3px',
  },
  subcategoryBtn: {
    '--background': '#FFF',
    '--background-activated': 'none',
    '--background-hover': (props: StyleProps): string => props?.backgroundColor || 'white',
    '--background-hover-opacity': 0.25,
    '--border-color': '#808080',
    '--border-style': 'solid',
    '--border-width': '1px',
    '--border-radius': '30px',
    '--color': 'var(--ion-color-primary)',
    '--padding-top': '15px',
    '--padding-bottom': '15px',
    textTransform: 'none',
    letterSpacing: '0px',
    minHeight: '55px',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    margin: 0,
    '& ion-label': {
      whiteSpace: 'normal!important',
      wordBreak: 'break-word!important',
    },
    cursor: 'pointer',
    '@media (max-width: 360px)': {
      fontSize: 12,
    },
  },
  subcategorySelectedBtn: {
    '--background': (props: StyleProps): string => props?.backgroundColor || 'white',
  },
  selectedFilterBtn: {
    '--background': 'rgba(0, 32, 91, 0.25)',
  },
  filterGroup: {
    marginBottom: 20,
  },
  subCategoryColPair: {
    display: 'flex',
    justifyContent: 'center',
    '--ion-grid-column-padding': '5px',
  },
  subCategoryColOdd: {
    display: 'flex',
    justifyContent: 'center',
    '--ion-grid-column-padding': '5px',
  },
  arrowBack: {
    cursor: 'pointer',
  },
  clearBtn: {
    '--background-activated': 'none',
    '--background': 'none',
    '--color': 'var(--ion-color-primary)',
    '--box-shadow': 'none',
    fontSize: '16px',
    fontWeight: '500',
    // position: 'absolute',
    '& button': {
      boxShadow: 'none',
    },
  },
  selectAllCheckbox: {
    '--border-radius': '0px',
    '--border-color': 'var(--ion-color-primary)',
    '--border-width': '2px',
    position: 'relative',
    width: '20px',
    height: '20px',
  },
  searchBtn: {
    '--border-radius': '20px',
    textTransform: 'none',
    width: '100%',
    height: '50px',
    fontSize: '20px',
    fontWeight: 500,
  },
  listingCard: {
    width: '100%',
    minHeight: 130,
    maxHeight: 200,
    overflowY: 'auto',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 15,
    paddingRight: 15,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
  },
  listingBottom: {
    display: 'flex',
    flex: 1,
  },
  listingIcons: {
    '@media (min-width: 768px) and (max-width: 992px)': {
      flex: 2,
    },
    '@media (max-width: 414px)': {
      flex: 4,
    },
    flex: 3,
    alignSelf: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
  },
  listingIcon: {
    border: '1px solid #808080',
    borderRadius: '30px',
    display: 'flex',
    alignItems: 'center',
    padding: 4,
    width: '36px',
    height: '36px',
    justifyContent: 'center',
    cursor: 'pointer',
    '& a': {
      height: 24,
    },
  },
  listingTitle: {
    fontSize: 18,
    color: 'var(--ion-color-primary)!important',
    fontWeight: 'bold',
    marginBottom: 5,
    whiteSpace: 'pre-wrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listingSubcategory: {
    fontSize: 13,
    color: 'var(--ion-color-primary)',
    marginBottom: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listingProvider: {
    fontSize: 14,
    color: 'var(--ion-color-primary)',
    marginBottom: 5,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listingDetail: {
    paddingLeft: 8,
    paddingRight: 8,
    marginTop: 10,
  },
  listingDetailRow: {
    marginBottom: 10,
  },
  listingDetailHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
  },
  listingDetailRowArrowBack: {
    cursor: 'pointer',
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  listingDetailRowLogo: {
    flex: 8,
    display: 'flex',
    justifyContent: 'center',
  },
  listingDetailRowHeartWeb: {
    right: 0,
    position: 'absolute',
  },
  listingDetailRowHeartMobile: {
    right: 44,
    position: 'absolute',
  },
  listingDetailRowShare: {
    position: 'absolute',
    right: 0,
    top: 10,
  },
  flippedIcon: {
    '--background': 'none',
    '--box-shadow': 'none',
    margin: 0,
    width: 40,
    height: 40,
    transform: 'scaleX(-1)',
  },
  listingDetailImageRow: {
    '@media (min-width: 451px)': {
      width: '80%',
      height: '20%',
    },
    '@media (max-width: 450px)': {
      width: '80vw',
      height: '20vh',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  listingDetailTitle: {
    color: 'var(--ion-color-primary)',
    fontSize: '20px',
    marginBottom: 10,
  },
  listingDetailEllipsisText: {
    whiteSpace: 'normal!important',
    wordBreak: 'break-word!important',
    maxWidth: '90%',
  },
  listingDetailSubcategories: {
    color: 'var(--ion-color-primary)',
    fontSize: '14px',
    marginBottom: 10,
  },
  listingDetailProvider: {
    color: 'var(--ion-color-primary)',
    fontSize: '18px',
    marginBottom: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listingDetailIcons: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid #EAAA00',
    paddingBottom: 15,
    marginBottom: 10,
  },
  listingDetailOpenHours: {
    borderBottom: '1px solid #EAAA00',
    paddingBottom: 15,
    marginBottom: 10,
    cursor: 'pointer',
  },
  listingDetailHourColumn: {
    display: 'flex',
    flexDirection: 'column',
    '& ion-label': {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  listingDetailIconCol: {
    display: 'flex',
    alignItems: 'center',
  },
  listingDetailInfoRow: {
    borderBottom: '1px solid #EAAA00',
    paddingBottom: 15,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    '& a': {
      textDecoration: 'none',
      color: 'black',
    },
  },
  listingDetailDescriptionRow: {
    display: 'block',
    textAlign: 'left',
    borderBottom: '1px solid #EAAA00',
    paddingBottom: 15,
    marginBottom: 10,
    fontSize: '16px',
    color: 'var(--ion-color-primary)',
    '& ion-label': {
      fontSize: '16px',
      color: 'var(--ion-color-primary)',
    },
  },
  listingDetailAllServices: {
    display: 'block',
    textAlign: 'left',
    paddingBottom: 15,
    marginBottom: 10,
    fontSize: '16px',
    color: 'var(--ion-color-primary)',
    '& ion-label': {
      whiteSpace: 'normal!important',
      wordWrap: 'word-break!important',
      display: 'inline',
      fontSize: '16px!important',
      color: 'var(--ion-color-primary)',
    },
  },
  listingDetailOtherListingUrl: {
    textDecoration: 'none',
    marginBottom: '10px',
  },
  listingDetailDescriptionText: {
    color: '#000',
  },
  listingDetailHoursText: {
    fontSize: '16px',
    color: 'var(--ion-color-primary)',
    whiteSpace: 'pre-line',
  },
  listingDetailSeeMore: {
    fontSize: '16px',
    color: '#000',
    fontWeight: 'bold',
  },
  listingDetailHoursModalWrapper: {
    padding: 13,
  },
  listingDetailOtherListings: {
    display: 'flex',
    flexDirection: 'column',
    '& ion-label': {
      flex: 1,
      textDecoration: 'underline',
      color: 'var(--ion-color-primary)',
      cursor: 'pointer',
      fontWeight: 'bold',
      fontSize: '16px!important',
    },
  },
  listingDetailHoursRow: {
    flexDirection: 'column',
    '& ion-label': {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
    },
  },
  listingDetailHoursDayName: {
    flex: 1,
    height: '38px',
    fontWeight: 'bold',
  },
  listingDetailHoursDayHours: {
    flex: 2,
    height: '38px',
    whiteSpace: 'pre-line',
  },
  listingDetailLink: {
    textDecoration: 'underline',
    color: 'var(--ion-color-primary)',
    cursor: 'pointer',
  },
  listingDetailDirectionsNoAddress: {
    color: 'var(--ion-color-primary)',
  },
  scheduleText: {
    fontSize: 14,
    color: 'var(--ion-color-primary)',
    flex: 5,
    alignSelf: 'center',
    display: 'flex',
    '& ion-label': {
      marginLeft: 3,
      alignSelf: 'center',
      whiteSpace: 'pre-line',
    },
    '@media (min-width: 768px) and (max-width: 992px)': {
      flex: 7,
    },
  },
  scheduleTextClosed: {
    '& ion-label': {
      marginLeft: 0,
      alignSelf: 'center',
      whiteSpace: 'pre-line',
    },
  },
  searchFiltersContainer: {
    backgroundColor: 'white',
    padding: 15,
  },
  searchHeaderFirstRowTitle: {
    display: 'flex!important',
    alignItems: 'center!important',
  },
  searchHeaderCategory: {
    flex: '10!important',
    marginLeft: 5,
  },
  searchHeaderCategoryName: {
    display: 'inline!important',
    fontSize: '24px!important',
    fontWeight: 'bold!important',
    color: 'var(--ion-color-primary)!important',
  },
  searchHeaderTerm: {
    display: 'inline!important',
    fontSize: '20px!important',
    color: 'var(--ion-color-primary)!important',
  },
  searchHeaderTermIn: {
    display: 'inline!important',
    fontSize: '20px!important',
    color: 'var(--ion-color-primary)!important',
  },
  searchHeaderCategoryIn: {
    display: 'inline!important',
    fontSize: '24px!important',
    color: 'var(--ion-color-primary)!important',
  },
  searchHeaderMapIcon: {
    flex: 1,
    textAlign: 'right',
    fontSize: 24,
  },
  searchHeaderCities: {
    right: 0,
    top: -2,
  },
  searchHeaderQty: {
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center',
    '& ion-label': {
      fontSize: '14px',
      fontWeight: 300,
      color: 'var(--ion-color-primary)',
    },
  },
  searchFilterButtonsWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  searchHeaderCategoryIcon: {
    borderRadius: '30px',
    width: 30,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: (props: StyleProps): string => props?.backgroundColor || 'white',
  },
  searchResults: {
    backgroundColor: '#D9D9D9',
    '& ion-infinite-scroll': {
      backgroundColor: 'white',
    },
    '&.map': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  },
  searchResultsEmpty: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    marginTop: '40px',
    // height: 'calc(100vh - 370.73px)',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'var(--ion-color-primary)',
    '& ion-label': {
      width: '80%',
      textAlign: 'center',
    },
  },
  searchResultsEmptyCategory: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    marginTop: '40px',
    // height: 'calc(100vh - 370.73px)',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'var(--ion-color-primary)',
  },
  searchResultsHeaderWrapper: {
    backgroundColor: 'white',
    paddingBottom: 10,
    paddingRight: 10,
    paddingLeft: 10,
  },
  listingWrapper: {
    marginTop: 10,
    marginBottom: 10,
    cursor: 'pointer',
  },
  listingWrapperNoTopMargin: {
    marginBottom: 10,
    cursor: 'pointer',
  },
  filterButton: {
    '--background': 'white',
    '--border-radius': '30px',
    '--border-color': '#808080',
    '--border-width': '1px',
    '--border-style': 'solid',
    '--color': 'var(--ion-color-primary)',
    '--padding-end': '0px',
    textTransform: 'none',
    letterSpacing: '0px',
    fontWeight: 400,
    fontSize: '14px',
    '& div': {
      position: 'absolute',
      right: '-15px',
      top: '4px',
    },
  },
  citySelector: {
    cursor: 'pointer',
    position: 'relative',
    width: 'fit-content',
    maxWidth: '75%',
    marginTop: '10px',
    marginBottom: '20px',
    borderBottomStyle: 'solid',
    borderBottom: '1px',
    borderTop: '0px',
    borderLeft: '0px',
    borderRight: '0px',
    borderBottomColor: 'var(--ion-color-primary)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& div:nth-child(1)': {
      display: 'flex',
    },
    '& #cities': {
      maxWidth: '100%',
      display: 'block',
    },
    '& div': {
      fontSize: '16px',
      color: 'var(--ion-color-primary)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  hoursModalContainer: {
    '--height': '350px',
    '--width': '320px',
  },
  modalContainer: {
    overflowY: 'scroll',
    padding: 15,
  },
  rowHeader: {
    marginBottom: 20,
  },
  modalHeaderWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    height: '50px',
    alignItems: 'center',
  },
  modalHeaderCloseIcon: {
    position: 'absolute',
    left: 0,
  },
  modalHeaderText: {
    fontSize: '24px',
    color: 'var(--ion-color-primary)',
    fontWeight: 'bold',
  },
  uploadComponentWrapper: {
    backgroundColor: 'rgba(0,32,91,0.1)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    borderRadius: '10px',
    border: '1px solid #00205B',
    '& ion-button': {
      margin: 0,
      height: '115px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '--box-shadow': 'none',
      '--background': 'none',
      '--border-width': '0px',
    },
    '& img': {
      maxHeight: '100%',
      maxWidth: '100%',
    },
  },
  uploadComponentTextWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& ion-label': {
      fontSize: '14px',
      letterSpacing: '0px',
      textTransform: 'none',
      color: 'var(--ion-color-primary)',
    },
  },
  uploadComponentCameraIcon: {
    alignSelf: 'flex-end',
    position: 'absolute',
    right: 0,
  },
  drawerItem: {
    whiteSpace: 'pre-wrap!important',
    wordBreak: 'break-word!important',
  },
  mapContainer: {
    position: 'relative',
    height: '100%',
  },
  toggle: {
    paddingLeft: '0px',
    marginRight: '10px',
  },
  subtitle: {
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: '400',
  },
  aboutSelect: {
    '& .select-placeholder': {
      '--color': 'var(--ion-color-primary)!important',
    },
  },
  popupStyles: {
    '& .mapboxgl-popup-content': {
      padding: '1rem',
      maxHeight: '360px',
      maxWidth: '360px',
      overflow: 'auto',
    },
  },
  popupListing: {
    cursor: 'pointer',
  },
  cardWrapper: {
    background: '#FFFFFF',
  },
  paginationButtons: {
    display: 'flex',
  },
  paginationPrevButton: {
    minWidth: '104px',
    alignSelf: 'flex-start',
  },
  paginationNextButton: {
    minWidth: '104px',
    alignSelf: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  paginationLabel: {
    width: '100%',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  mapNavigationControl: {
    position: 'absolute',
    bottom: '4rem',
    right: '1rem',
  },
  desktopNavSelect: {
    '& .popover-content': {
      color: 'var(--ion-color-primary)',
      '& ion-list-header': {
        '--color': 'var(--ion-color-primary)',
      },
      minWidth: 150,
      width: 'auto',
    },
    '& .select-interface-option': {
      color: 'var(--ion-color-primary)',
    },
  },
  desktopNavOption: {
    cursor: 'pointer',
    color: 'var(--ion-color-primary)',
    '& ion-radio': {
      width: 0,
      '& .item.sc-ion-label-md-h': { '--color': '#00FF00' },
    },
    '& .item.sc-ion-label-md-h': { '--color': '#00FF00' },
  },
  drawerHeader: {
    fontWeight: 'bold',
    color: 'var(--ion-color-primary)',
    fontSize: '14px',
  },
  drawerText: {
    fontSize: '14px',
    color: 'var(--ion-color-primary)',
  },
  ccDesktopSidebarHeader: {
    position: 'relative',
    height: 70,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
  },
  ccDesktopSidebarHeaderSubcategories: {
    position: 'relative',
    height: 70,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  ccDesktopLogoWrapper: {
    left: 0,
    position: 'absolute',
    width: '100%',
    height: 70,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerGoBackIcon: {
    cursor: 'pointer',
    position: 'relative',
  },
});
