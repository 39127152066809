import { isPlatform } from '@ionic/react';
import routePaths from './routePaths';

interface MenuItem {
  navbar_category?: number;
  navTitleId: string;
  children?: MenuItem[];
  url?: string;
}

const menuItems: MenuItem[] = [{
  navbar_category: 0,
  navTitleId: isPlatform('android') || isPlatform('ios') ? 'app.menutitle.mobile.about' : 'app.menutitle.about',
  children: [],
}, {
  navbar_category: 1,
  navTitleId: isPlatform('android') || isPlatform('ios') ? 'app.menutitle.mobile.settings' : 'app.menutitle.settings',
  children: [{
    navTitleId: 'app.menutitle.settings-preferences',
    url: routePaths.public.settings,
  }, {
    navTitleId: 'app.menutitle.favorites',
    url: routePaths.public.favorites,
  }],
}, {
  navbar_category: 2,
  navTitleId: 'app.menutitle.providers',
  children: [{
    navTitleId: 'app.menutitle.login',
    url: routePaths.provider.signin,
  }],
}, {
  navbar_category: 3,
  navTitleId: 'app.menutitle.more-resources',
  children: [],
}];

export const mobileMenuItems: MenuItem[] = [{
  navTitleId: 'app.menutitle.home',
  url: routePaths.public.home,
}, ...menuItems];

export default menuItems;
