import { IonLabel } from '@ionic/react';
import React from 'react';
import useClasses from '../styles';
import CrisisCard from './CrisisCard';
import { CrisisGroup, CrisisHotline } from '../../interfaces/CrisisHotlines';
import { useStoreState } from '../../store';
import { translateName } from '../../lib/utils';

interface CrisisCardGroupProps {
  group: CrisisGroup;
}

const CrisisCardGroup: React.FunctionComponent<CrisisCardGroupProps> = ({
  group,
}: CrisisCardGroupProps) => {
  const classes = useClasses();
  const locale = useStoreState((state) => state.translations.language);
  return (
    <div className={classes.crisisCardGroup}>
      <div id="title">
        <IonLabel>{translateName(group, locale)}</IonLabel>
      </div>
      {group.hotlines?.map((h: CrisisHotline) => (
        <div key={h.id} className="crisis-card-wrapper">
          <div onClick={(): Window | null => window.open(`tel:${h.phone_number}`, '_self')}>
            <CrisisCard hotline={h} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CrisisCardGroup;
