import {
  IonButton,
  IonContent,
  IonLoading,
  IonPage,
  isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Network } from '@capacitor/network';
import Icon from '../../components/Icon';
import routePaths from '../../lib/routePaths';
import { useStoreActions, useStoreState } from '../../store';
import useClasses from '../styles';
import CrisisCardGroup from './CrisisCardGroup';

const CrisisPage: React.FC = () => {
  const history = useHistory();
  const classes = useClasses();
  const { data, loading } = useStoreState((state) => state.crisisHotlines);
  const find = useStoreActions((actions) => actions.crisisHotlines.find);
  const [networkStatus, setNetworkStatus] = useState<boolean | undefined>(undefined);

  const getNetworkStatus = () => {
    Network.getStatus().then((status) => {
      setNetworkStatus(status.connected);
    });
  };

  const primaryColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--ion-color-primary');

  const continueSearch = (): void => {
    const params = new URLSearchParams(history.location.search);
    const encodedUrl = params.get('url');
    const url = decodeURIComponent(encodedUrl as string);
    if (url && url !== 'null') {
      history.push(url);
    } else {
      history.push(routePaths.public.subcategories.replace(':category_name', 'crisis'));
    }
  };

  useEffect(() => {
    find();
  }, [find]);

  useEffect(() => {
    getNetworkStatus();
  }, []);

  const HeaderContent = () => (
    <div id="header">
      <div id="buttons">
        <div id="times">
          <IonButton onClick={(): void => history.push(routePaths.public.root)}>
            <Icon name="close" width={40} color={primaryColor} />
          </IonButton>
        </div>

        <div id="continue">
          {
            networkStatus && (
              <IonButton onClick={continueSearch}><FormattedMessage id="crisis-search-button-text" /></IonButton>
            )
          }
        </div>
      </div>
      <div id="title">24/7 Crisis Hotlines</div>
    </div>
  );

  const BodyContent = () => (
    <>
      <div id="groups">
        {(data || []).map((g) => (
          <CrisisCardGroup key={g.id} group={g} />
        ))}
      </div>
      <div id="search-btn-wrapper">
        <IonButton disabled={!networkStatus} onClick={continueSearch}><FormattedMessage id="crisis-search-button-text" /></IonButton>
      </div>
    </>
  );

  return networkStatus !== undefined ? (
    <>
      {isPlatform('android') || isPlatform('ios') ? (
        <IonPage className={classes.crisisPage}>
          <HeaderContent />
          <IonContent>
            <BodyContent />
          </IonContent>
        </IonPage>
      ) : (
        <div className={classes.crisisPage}>
          <HeaderContent />
          <BodyContent />
        </div>
      )}
    </>
  ) : <IonLoading isOpen />;
};

export default CrisisPage;
