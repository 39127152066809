import { createUseStyles } from 'react-jss';

// @ts-ignore
// import triangleBackground from '../../assets/triangle_background.png';

export default createUseStyles({
  main: {
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '0 0',
    '& .error-message': {
      margin: '0.75rem',
    },
  },
  outlineButton: {
    padding: '0px',
  },
  inputOutline: {
    width: '100%',
    height: '50px',
    borderRadius: '10px',
    marginBottom: '10px',
  },
  buttonRow: {
    marginTop: '22px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconButton: {
    fontWeight: '500',
    fontSize: '16px',
    height: '40px',
    textTransform: 'none',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editIcon: {
    width: '35px',
    height: '35px',
    marginTop: '5dp',
  },
  subcategoryLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  subcategoryContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
});
