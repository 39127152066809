import React, { useEffect } from 'react';
import {
  IonButton,
} from '@ionic/react';

import useAppStyles from '../../styles';
import { useStoreActions, useStoreState } from '../../../store';

const EmailUnverified: React.FC = () => {
  const appClasses = useAppStyles();
  const { auth: { user }, verifyEmail: { resent } } = useStoreState((state) => ({
    auth: state.auth,
    verifyEmail: state.verifyEmail,
  }));
  const {
    createOrg: { setCurrentStep },
    auth: { reAuth },
    verifyEmail: { resendVerification },
  } = useStoreActions(
    (actions) => ({
      createOrg: actions.createOrg,
      provider: actions.provider,
      auth: actions.auth,
      verifyEmail: actions.verifyEmail,
    }),
  );

  useEffect(() => {
    setCurrentStep(2);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reauth = (): void => {
    reAuth();
  };

  const resend = (): void => {
    if (user) {
      resendVerification(Number(user.id));
    }
  };

  return (
    <>
      <h3 className={appClasses.title}>Please Verify Your Account</h3>
      <h4>Your account needs email verification to continue. Please look for an email
        to the address provided and click on the link inside to validate your account.
      </h4>
      <div className="ion-padding-top">
        <IonButton fill="clear" className="cc-link-button" onClick={(): void => reauth()}>Ok, I've Verified</IonButton>
      </div>
      <div className="mt-16">
        {!resent ? (
          <IonButton fill="clear" className="cc-link-button" onClick={(): void => resend()}>Resend Verification Email</IonButton>
        ) : (
          <IonButton disabled fill="clear" className="cc-link-button">Verification Email Sent</IonButton>
        )}
      </div>
    </>
  );
};

export default EmailUnverified;
