/* eslint-disable max-len */
import { Storage } from '@capacitor/storage';
import {
  IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonSpinner, IonToolbar,
} from '@ionic/react';
import { locate } from 'ionicons/icons';
import React, { ReactChild, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import { Route, Switch, useHistory } from 'react-router-dom';
import CCPage from '../../components/CCPage';
import Map from '../../components/Map';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import routePaths from '../../lib/routePaths';
import { translateProp } from '../../lib/utils';
import { useStoreActions, useStoreState } from '../../store';
import useAppClasses from '../styles';
import MainCategory from './MainCategory';
import Page from './Page';

const Home: React.FC = () => {
  const appClasses = useAppClasses();
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const [showNag, setShowNag] = useState<boolean>(false);

  const { data } = useStoreState(state => state.search);
  const { updateFilters } = useStoreActions(actions => actions.search);
  const { language: locale } = useStoreState(state => state.translations);
  const { data: meta } = useStoreState(state => state.meta);

  useEffect(() => {
    async function showDisclaimer(): Promise<void> {
      const disclaimerApproved = await Storage.get({ key: 'disclaimer_approved' });
      if (disclaimerApproved.value === null) {
        setShowNag(true);
      }
    }
    showDisclaimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data && isDesktop) {
      updateFilters({});
    }
  }, [updateFilters, data, isDesktop]);

  return (
    <CCPage
      useLogo
      endButtons={(
        <IonButton routerLink={routePaths.public.search}>
          <IonIcon slot="end" icon={locate} />
        </IonButton>
      )}
      content={(
        <>
          <div className={appClasses.desktopContainer}>
            {isDesktop ? null : (
              <div className={appClasses.mobileContainer}>
                <MainCategory />
              </div>
            )}
          </div>
          {isDesktop && (
            <Switch>
              <Route path="/cc/page/:slug" component={Page} />
              <Route
                path="/cc"
                render={(): ReactChild => (
                  <Map
                    data={data || []}
                    onSelected={(info): void => {
                      if (info && info.id) {
                        const path = routePaths.public.listingDetail.replace(':id', info.id.toString());
                        history.push(path);
                      }
                    }}
                  />
                )}
              />
            </Switch>
          )}
          <IonModal
            isOpen={showNag}
            onDidDismiss={(): void => setShowNag(false)}
            backdropDismiss={false}
          >
            <IonHeader>
              <IonToolbar color="primary">
                <IonButtons collapse slot="end">
                  <IonButton
                    onClick={(): void => {
                      Storage.set({ key: 'disclaimer_approved', value: 'true' });
                      setShowNag(false);
                    }}
                  >
                    I Agree / Estoy de acuerdo
                  </IonButton>
                </IonButtons>
              </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
              {meta && meta.terms_and_conditions ? (
                <ReactMarkdown
                  source={translateProp(meta.terms_and_conditions, 'contents', locale)}
                  escapeHtml={false}
                />
              ) : <IonSpinner />}
            </IonContent>
          </IonModal>
        </>
      )}
    />
  );
};

export default Home;
