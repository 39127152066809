import { action } from 'easy-peasy';

import MapModel from '../interfaces/StoreModel/MapModel';

const model: MapModel = {
  center: null,
  setCenter: action((state, center) => {
    state.center = center;
  }),
};

export default model;
