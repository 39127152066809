import { IonSegment, IonSegmentButton } from '@ionic/react';
import React, { useState } from 'react';
import CCAdminPage from '../../../../components/CCAdminPage';
import useStyles from '../../styles';
import CrisisCategories from './CrisisCategories';
import CrisisHotlines from './CrisisHotlines';
import { useStoreState } from '../../../../store';

const CrisisHotlineWrapper: React.FC = () => {
  const classes = useStyles();
  const [selectedReviewTab, setSelectedReviewTab] = useState('hotlines');
  const { data: categories, hotlines } = useStoreState(state => state.crisisHotlines);

  return (
    <CCAdminPage
      title="Crisis Hotlines"
      content={(
        <>
          <div className={classes.adminContent}>
            <h3>Crisis Hotlines</h3>
            <div className="mt-24">
              <IonSegment
                className={classes.adminTabs}
                value={selectedReviewTab}
                onIonChange={(e): void => setSelectedReviewTab(e.detail.value as string)}
                color="primary"
                mode="md"
              >
                <IonSegmentButton
                  className={[
                    classes.adminTab,
                    'interiorItem ion-text-wrap',
                  ].join(' ')}
                  value="hotlines"
                >
                  {' '}
                  Hotlines ({hotlines.length})
                </IonSegmentButton>
                <IonSegmentButton
                  className={[
                    classes.adminTab,
                    'interiorItem ion-text-wrap',
                  ].join(' ')}
                  value="categories"
                >
                  {' '}
                  Categories ({categories?.length})
                </IonSegmentButton>
              </IonSegment>
            </div>
            {((): unknown => {
              switch (selectedReviewTab) {
                case 'hotlines':
                  return <CrisisHotlines />;
                case 'categories':
                  return <CrisisCategories />;
                default:
                  return null;
              }
            })()}
          </div>
        </>
      )}
    />
  );
};

export default CrisisHotlineWrapper;
