import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
  IonTitle,
  useIonViewDidEnter,
} from '@ionic/react';

import React from 'react';
import useIsDesktop from '../lib/hooks/useIsDesktop';
import AdminDesktopMenu from './AdminDesktopMenu';
import useAppStyles from '../apps/styles';

interface CCAdminPageProps {
  startButtons?: React.ReactElement;
  endButtons?: React.ReactElement;
  content: React.ReactElement;
  title: string;
}

const CCAdminPage: React.FC<CCAdminPageProps> = ({
  content,
  title,
  startButtons,
  endButtons,
}: CCAdminPageProps) => {
  const isDesktop = useIsDesktop();
  const appClasses = useAppStyles();
  useIonViewDidEnter(() => {
    window.scrollTo(0, 0);
  });
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={appClasses.ccToolbar}>
          {isDesktop ? (
            <AdminDesktopMenu />
          ) : (
            <>
              <IonButtons slot="start" className="ion-no-padding">
                <IonMenuButton />
                {startButtons}
              </IonButtons>
              <IonButtons slot="end" className="ion-no-padding">
                {endButtons}
              </IonButtons>
            </>
          )}
          {!isDesktop && <IonTitle>{title}</IonTitle>}
        </IonToolbar>
      </IonHeader>
      <IonContent id="contentElementAdmin" className={isDesktop ? '' : 'ion-padding'}>
        {content}
      </IonContent>
    </IonPage>
  );
};

export default CCAdminPage;
