import React, { useState, useEffect, MutableRefObject } from 'react';
import {
  IonLabel,
  IonSpinner,
  IonRouterLink,
} from '@ionic/react';
import Listing from '../../interfaces/Listing';
import { listings } from '../../lib/api/services';
import useService from '../../lib/hooks/useService';
import useClasses from '../../components/styles';
import routePaths from '../../lib/routePaths';
import { useStoreState } from '../../store';
import { translateName } from '../../lib/utils';
import useIsInViewport from '../../lib/hooks/useIsInViewport';

interface ListingDetailOtherListingsProps {
  excludedListings: number[];
  providerId?: number;
  contentRef: MutableRefObject<HTMLIonContentElement | null> | null;
  otherListingsCB?: Function;
}

const resultPageSize = 5;
const ListingDetailOtherListings: React.FunctionComponent<ListingDetailOtherListingsProps> = ({
  providerId,
  excludedListings,
  contentRef,
  otherListingsCB,
}: ListingDetailOtherListingsProps) => {
  const classes = useClasses();
  const [originalProviderId, setOriginalProviderId] = useState(providerId);
  const [originalExcludeListings, setOriginalExcludeListings] = useState(excludedListings.join(','));
  const { elementRef, isInViewport } = useIsInViewport<HTMLDivElement>();
  const [filters, setFilters] = useState(
    excludedListings.length
      ? { provider_id: providerId, id: { $ne: excludedListings } }
      : { provider_id: providerId },
  );
  const [page, setPage] = useState({ limit: resultPageSize, skip: 0 });
  const { result, loading } = useService<Listing>(listings, page, filters);
  const [fetchedListings, setFetchedListings] = useState<Listing[]>([]);
  const locale = useStoreState((state) => state.translations.language);

  useEffect(() => {
    if (originalProviderId !== providerId || originalExcludeListings !== excludedListings.join(',')) {
      if (contentRef && contentRef.current) {
        contentRef.current.scrollToTop();
      }
      setOriginalProviderId(providerId);
      setOriginalExcludeListings(excludedListings.join(','));
      setFilters(excludedListings.length
        ? { provider_id: providerId, id: { $ne: excludedListings } }
        : { provider_id: providerId });
      setFetchedListings([]);
      setPage({ limit: resultPageSize, skip: 0 });
    }
  }, [providerId, contentRef, excludedListings, originalExcludeListings, originalProviderId]);

  useEffect(() => {
    if (fetchedListings && fetchedListings.length && otherListingsCB) {
      otherListingsCB(true);
    }
  }, [fetchedListings, otherListingsCB]);

  useEffect(() => {
    if (!fetchedListings.length) return;
    if (!loading && isInViewport) {
      if (result.total !== undefined && (page.skip + page.limit) < result.total) {
        setPage({
          limit: page.limit,
          skip: page.skip + resultPageSize,
        });
      }
    }
  }, [fetchedListings, loading, isInViewport, result, page]);

  useEffect(() => {
    if (result.data) {
      const uniqueListings = new Set();
      setFetchedListings((current) => [...current].concat(result.data).filter((listing) => {
        // eslint-disable-next-line eqeqeq
        if (!uniqueListings.has(listing.id) && listing.provider_id == providerId) {
          uniqueListings.add(listing.id);
          return true;
        }
        return false;
      }));
    }
  }, [result, providerId]);

  return (
    <>
      <div className={classes.listingDetailOtherListings}>
        {fetchedListings.map((l) => (
          <IonRouterLink
            key={l.id}
            className={classes.listingDetailOtherListingUrl}
            routerLink={routePaths.public.listingDetail.replace(':id', `${l.id}`)}
          >
            <IonLabel>{translateName(l, locale)}</IonLabel>
          </IonRouterLink>
        ))}
        <div ref={elementRef} />
        {loading ? <IonSpinner name="crescent" /> : null}
      </div>
    </>
  );
};

export default ListingDetailOtherListings;
