import { action, thunk } from 'easy-peasy';
import { Storage } from '@capacitor/storage';

import SettingsModel from '../interfaces/Settings';
import ModuleStore from '../interfaces/ModuleStore';

const model: SettingsModel = {
  getGeneralNotifications: false,
  getUrgentNotifications: false,
  useDefaultFilters: true,
  useLocationOnSearch: false,
  useAgeOnSearch: [],
  useCitiesOnSearch: [],
  useGroupsOnSearch: [],
  profileGender: '',
  profileGenderInput: '',
  profileEthnicity: [],
  profileEthnicityInput: '',
  setGetGeneralNotifications: action((state, subscribeGeneral) => {
    state.getGeneralNotifications = subscribeGeneral;
    Storage.set({ key: 'getGeneralNotifications', value: subscribeGeneral ? 'true' : 'false' });
  }),
  setGetUrgentNotifications: action((state, subscribeUrgent) => {
    state.getUrgentNotifications = subscribeUrgent;
    Storage.set({ key: 'getUrgentNotifications', value: subscribeUrgent ? 'true' : 'false' });
  }),
  // @ts-ignore
  setGetAllNotifications: thunk((state, subscribeAll, store: ModuleStore<SettingsModel>) => {
    if (subscribeAll) {
      store.getStoreActions().settings.setGetGeneralNotifications(true);
      store.getStoreActions().settings.setGetUrgentNotifications(true);
    } else {
      store.getStoreActions().settings.setGetGeneralNotifications(false);
    }
  }),
  setUseDefaultFilters: action((state, payload) => {
    state.useDefaultFilters = payload;
    Storage.set({ key: 'useDefaultFilters', value: payload ? 'true' : 'false' });
  }),
  setUseLocationOnSearch: action((state, payload) => {
    state.useLocationOnSearch = payload;
    Storage.set({ key: 'useLocationOnSearch', value: payload ? 'true' : 'false' });
  }),
  setUseAgeOnSearch: action((state, payload) => {
    state.useAgeOnSearch = payload;
    Storage.set({ key: 'useAgeOnSearch', value: JSON.stringify(payload) });
  }),
  setUseCitiesOnSearch: action((state, payload) => {
    state.useCitiesOnSearch = payload;
    Storage.set({ key: 'useCitiesOnSearch', value: JSON.stringify(payload) });
  }),
  setUseGroupsOnSearch: action((state, payload) => {
    state.useGroupsOnSearch = payload;
    Storage.set({ key: 'useGroupsOnSearch', value: JSON.stringify(payload) });
  }),
  setProfileGender: action((state, payload) => {
    state.profileGender = payload;
    Storage.set({ key: 'profileGender', value: payload });
  }),
  setProfileGenderInput: action((state, payload) => {
    state.profileGenderInput = payload;
    Storage.set({ key: 'profileGenderInput', value: payload });
  }),
  setProfileEthnicity: action((state, payload) => {
    state.profileEthnicity = payload;
    Storage.set({ key: 'profileEthnicities', value: JSON.stringify(payload) });
  }),
  setProfileEthnicityInput: action((state, payload) => {
    state.profileEthnicityInput = payload;
    Storage.set({ key: 'profileEthnicityInput', value: payload });
  }),
  loadSettings: thunk(async (actions) => {
    const generalNotifications = await Storage.get({ key: 'getGeneralNotifications' });
    actions.setGetGeneralNotifications(generalNotifications.value === 'true');

    const urgentNotifications = await Storage.get({ key: 'getUrgentNotifications' });
    actions.setGetUrgentNotifications(urgentNotifications.value === 'true');

    const useDefaultFilters = await Storage.get({ key: 'useDefaultFilters' });
    actions.setUseDefaultFilters(useDefaultFilters.value === 'true');

    const useLocationOnSearch = await Storage.get({ key: 'useLocationOnSearch' });
    actions.setUseLocationOnSearch(useLocationOnSearch.value === 'true');

    const ageGroupIds = await Storage.get({ key: 'useAgeOnSearch' });
    if (!ageGroupIds.value?.includes('[')) {
      if (ageGroupIds.value !== null && ageGroupIds.value !== 'null' && ageGroupIds.value !== '0' && ageGroupIds.value !== '-1') {
        ageGroupIds.value = `[${ageGroupIds.value}]`;
      } else {
        ageGroupIds.value = null;
      }
    } else if (ageGroupIds.value === '[null]' || ageGroupIds.value === '[0]' || ageGroupIds.value === '[-1]') {
      ageGroupIds.value = null;
    }
    actions.setUseAgeOnSearch(JSON.parse(ageGroupIds.value ?? '[]'));

    const cityIds = await Storage.get({ key: 'useCitiesOnSearch' });
    actions.setUseCitiesOnSearch(JSON.parse(cityIds.value ?? '[]'));

    const groupIds = await Storage.get({ key: 'useGroupsOnSearch' });
    actions.setUseGroupsOnSearch(JSON.parse(groupIds.value ?? '[]'));

    const ethnicities = await Storage.get({ key: 'profileEthnicities' });
    actions.setProfileEthnicity(JSON.parse(ethnicities.value ?? '[]'));

    const ethnicityInput = await Storage.get({ key: 'profileEthnicityInput' });
    actions.setProfileEthnicityInput(ethnicityInput.value ?? '');

    const gender = await Storage.get({ key: 'profileGender' });
    actions.setProfileGender(gender.value ?? '');

    const genderInput = await Storage.get({ key: 'profileGenderInput' });
    actions.setProfileGenderInput(genderInput.value ?? '');
  }),
};

export default model;
