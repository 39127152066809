/* eslint-disable max-len */
import { Network } from '@capacitor/network';
import {
  IonAlert,
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import { Page } from '../interfaces/Page';
import { mobileMenuItems } from '../lib/appMenuItems';
import IonContentContext from '../lib/context/IonContentContext';
import useIsDesktop from '../lib/hooks/useIsDesktop';
import routePaths from '../lib/routePaths';
import { getTranslatedString, translateProp } from '../lib/utils';
import { useStoreActions, useStoreState } from '../store';
import adminMenuItems from './AdminMenuItems';
import DesktopSidebar from './DesktopSidebar';
import providerMenuItems from './ProviderMenuItems';
import useClasses from './styles';

interface MenuProps {
  selectedPage: string;
}

const Menu: React.FunctionComponent<MenuProps> = ({
  selectedPage,
}: MenuProps) => {
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const { logout } = useStoreActions((actions) => actions.auth);
  const { setLocale } = useStoreActions((actions) => actions.translations);
  const { pagesByCategoryId } = useStoreState((state) => state.pages);
  const isDesktop = useIsDesktop();
  const location = useLocation();
  const { currentProvider } = useStoreState((state) => state.provider);
  const { language } = useStoreState((state) => state.translations);
  const classes = useClasses();
  const approved = (currentProvider?.approved_date?.length ?? 0) > 0;
  const languageAlertOptions = {
    header: getTranslatedString('select-language', language),
  };
  const [selectedMenuTitleId, setSelectedMenuTitleId] = useState<string | null>(null);
  const contentRef = useRef<HTMLIonContentElement | null>(null);

  const [networkStatus, setNetworkStatus] = useState(false);

  const getNetworkStatus = () => {
    Network.getStatus().then((status) => {
      setNetworkStatus(status.connected);
    });
  };

  useEffect(() => {
    getNetworkStatus();
  }, []);

  const renderPagesByCategory = (menuItem: any) => {
    let items = [];

    console.log('NETWORK STATUS!!!!!!!!!!!!!!!!');
    console.log(networkStatus);
    if (networkStatus) {
      items = pagesByCategoryId.get(Number(menuItem.navbar_category)) || [];
    } else {
      const itemsStored = localStorage.getItem('pages');
      if (itemsStored) {
        const pages = JSON.parse(itemsStored);
        items = pages.filter((page: Page) => page.navbar_category === menuItem.navbar_category);
      }
    }
    console.log(items);

    return items
      .map((page: Page) => (
        <IonItem
          routerLink={routePaths.public.page.replace(':slug', page.slug || '')}
          routerDirection="none"
          lines="none"
          detail={false}
          key={page.id}
          className={classes.drawerText}
        >
          <IonLabel className={classes.drawerItem}>
            {translateProp(page, 'navbar_title', language)}
          </IonLabel>
        </IonItem>
      ));
  };

  useEffect(() => {
    if (location.pathname.includes('listing-category') || location.pathname.includes('listing-subcategory')) {
      setSelectedMenuTitleId('admin.menutitle.listingCategories');
    } else if (location.pathname.includes('informational-page')) {
      setSelectedMenuTitleId('admin.menutitle.informationalpages');
    } else if (location.pathname.includes('crisis-hotline')) {
      setSelectedMenuTitleId('admin.menutitle.crisishotlines');
    } else {
      setSelectedMenuTitleId(null);
    }
  }, [location]);

  return (
    <>
      <IonMenu
        contentId={isDesktop ? 'desktopMain' : 'mobileMain'}
        type="overlay"
      >
        <IonContentContext.Provider value={contentRef}>
          <IonContent ref={contentRef}>
            {isDesktop ? (
              <IonList id="sidebar-list">
                <DesktopSidebar />
              </IonList>
            ) : (
              <IonList id="pages-list" className="mt-48">
                {location.pathname.startsWith(routePaths.admin.root) ? (
                  adminMenuItems.map((menuItem) => (
                    // Show Admin options if path is in admin.
                    <IonMenuToggle key={menuItem.url} autoHide={false}>
                      {menuItem.menuTitleId === 'admin.menutitle.signout' ? (
                        <IonItem
                          onClick={(): void => setShowLogoutAlert(true)}
                          lines="none"
                          detail={false}
                          className={classes.drawerText}
                        >
                          <IonLabel>
                            <FormattedMessage id={menuItem.menuTitleId} />
                          </IonLabel>
                        </IonItem>
                      ) : (
                        <IonItem
                          className={
                            selectedPage.startsWith(menuItem.url)
                              ? [classes.drawerText, 'selected'].join(' ')
                              : classes.drawerText
                        }
                          routerLink={menuItem.url}
                          routerDirection="none"
                          lines="none"
                          detail={false}
                        >
                          <IonLabel>
                            {selectedMenuTitleId ? (
                              <>
                                {selectedMenuTitleId === menuItem.menuTitleId ? (
                                  <b><FormattedMessage id={menuItem.menuTitleId} /></b>
                                ) : (
                                  <FormattedMessage id={menuItem.menuTitleId} />
                                )}
                              </>
                            ) : (
                              <>
                                {location.pathname === menuItem.url ? (
                                  <b><FormattedMessage id={menuItem.menuTitleId} /></b>
                                ) : (
                                  <FormattedMessage id={menuItem.menuTitleId} />
                                )}
                              </>
                            )}
                          </IonLabel>
                        </IonItem>
                      )}
                    </IonMenuToggle>
                  ))
                ) // Show Provider options if path is in provider, with the exception of onboarding/signin/verify screens.
                  : location.pathname.startsWith(routePaths.provider.root)
                  && location.pathname !== routePaths.provider.onboarding.root
                  && location.pathname
                    !== routePaths.provider.onboarding.requirements
                  && location.pathname
                    !== routePaths.provider.onboarding.questions
                  && location.pathname !== routePaths.provider.onboarding.orgInfo
                  && location.pathname !== routePaths.provider.signin
                  && !location.pathname.startsWith(
                    routePaths.provider.onboarding.verifyEmail.root,
                  ) ? (
                    <IonMenuToggle autoHide={false}>
                      {providerMenuItems.map((providerMenuItem) => (providerMenuItem.menuTitleId
                      === 'provider.menutitle.signout' ? (
                        <IonItem
                          onClick={(): void => setShowLogoutAlert(true)}
                          lines="none"
                          detail={false}
                          key={providerMenuItem.menuTitleId}
                          className={classes.drawerText}
                        >
                          <IonLabel>
                            <FormattedMessage id={providerMenuItem.menuTitleId} />
                          </IonLabel>
                        </IonItem>
                        ) : (
                          <IonItem
                            className={
                            approved && providerMenuItem.approvedUrl
                              ? selectedPage.startsWith(
                                providerMenuItem.approvedUrl,
                              )
                                ? [classes.drawerText, 'selected'].join(' ')
                                : classes.drawerText
                              : selectedPage.startsWith(providerMenuItem.url)
                                ? [classes.drawerText, 'selected'].join(' ')
                                : classes.drawerText
                          }
                            routerLink={
                            approved && providerMenuItem.approvedUrl
                              ? providerMenuItem.approvedUrl
                              : providerMenuItem.url
                          }
                            routerDirection="none"
                            lines="none"
                            detail={false}
                            key={providerMenuItem.menuTitleId}
                          >
                            <IonLabel>
                              <FormattedMessage id={providerMenuItem.menuTitleId} />
                            </IonLabel>
                          </IonItem>
                        )))}
                    </IonMenuToggle>
                    ) : (
                  // Show public options otherwise.
                      <IonMenuToggle autoHide={false}>
                        <IonSelect
                          className="cc-select"
                          style={{
                            marginTop: '15px',
                            marginLeft: '15px',
                            marginBottom: '30px',
                            width: '90%',
                            fontSize: '14px',
                          }}
                          placeholder="Select Language"
                          value={language}
                          interfaceOptions={languageAlertOptions}
                          onIonChange={(e): void => {
                            setLocale(e.detail.value);
                          }}
                        >
                          <IonSelectOption value="en">English</IonSelectOption>
                          <IonSelectOption value="es">Español</IonSelectOption>
                        </IonSelect>
                        {mobileMenuItems.map((menuItem) => (menuItem.url ? (
                          <IonItem
                            key={menuItem.navTitleId}
                            className={
                            selectedPage.startsWith(menuItem.url)
                              ? [classes.drawerText, 'selected'].join(' ')
                              : classes.drawerText
                          }
                            routerLink={menuItem.url}
                            routerDirection="none"
                            lines="none"
                            detail={false}
                          >
                            <IonLabel className={classes.drawerItem}>
                              <FormattedMessage id={menuItem.navTitleId} />
                            </IonLabel>
                          </IonItem>
                        ) : (
                          <React.Fragment key={menuItem.navTitleId}>
                            <IonItem lines="full" className={classes.drawerHeader}>
                              <IonLabel className={classes.drawerItem}>
                                <FormattedMessage id={menuItem.navTitleId} />
                              </IonLabel>
                            </IonItem>
                            {renderPagesByCategory(menuItem)}
                            {menuItem.children?.map((child) => (
                              <IonItem
                                routerLink={child.url}
                                routerDirection="none"
                                lines="none"
                                detail={false}
                                key={child.navTitleId}
                                className={classes.drawerText}
                              >
                                <IonLabel className={classes.drawerItem}>
                                  <FormattedMessage id={child.navTitleId} />
                                </IonLabel>
                              </IonItem>
                            ))}
                          </React.Fragment>
                        )))}
                      </IonMenuToggle>
                    )}
              </IonList>
            )}
          </IonContent>
        </IonContentContext.Provider>
      </IonMenu>
      <IonAlert
        isOpen={showLogoutAlert}
        onDidDismiss={(): void => setShowLogoutAlert(false)}
        message="Would you like to sign out?"
        buttons={[
          {
            text: 'Cancel',
          },
          {
            text: 'Sign Out',
            handler: (): void => {
              logout();
            },
          },
        ]}
      />
    </>
  );
};

export default Menu;
