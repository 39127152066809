/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  IonLabel,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonButtons,
  IonIcon,
} from '@ionic/react';
import { useHistory } from 'react-router-dom';

import React, { useState, useEffect } from 'react';
import { globe, caretForward, caretBack } from 'ionicons/icons';
import { format } from 'date-fns';
import useStyles from '../styles';
import useAppStyles from '../../styles';
import useService from '../../../lib/hooks/useService';
import { providers } from '../../../lib/api/services';
import Provider from '../../../interfaces/Provider';
import { Page } from '../../../interfaces/PaginationResult';
import Icon from '../../../components/Icon';
import { useStoreActions, useStoreState } from '../../../store';
import ServiceListingsPreview from './ServiceListingsPreview';
import routePaths from '../../../lib/routePaths';

const formatCreatedAtString = (isoString: string): string => {
  const createdDate: Date = new Date(isoString);
  return `Submitted ${format(createdDate, 'MM/dd/yyyy')}`;
};

const RecentlyUpdatedServices: React.FC = () => {
  const history = useHistory();
  const { setProvider } = useStoreActions(actions => actions.provider);
  const { listingPreviewModalListings } = useStoreState((state) => state.admin);
  const { setListingPreviewModalListings } = useStoreActions(actions => actions.admin);
  const classes = useStyles();
  const appClasses = useAppStyles();
  const resultPageSize = 5;
  const [page, setPage] = useState({ limit: resultPageSize, skip: 0 });
  const [term, setTerm] = useState('');
  const [filter, setFilter] = useState({
    approved_date: {
      $ne: null,
    },
    name: { $like: `%${term}%` },
    listings: {
      submitted: true,
      approved: true,
    },
    user: { name: { $like: `%${term}%` } },
  });
  const { error, result, loading } = useService<Provider>(providers, page, filter);

  useEffect(() => {
    setFilter({
      approved_date: {
        $ne: null,
      },
      name: { $like: `%${term}%` },
      listings: {
        submitted: true,
        approved: true,
      },
      user: { name: { $like: `%${term}%` } },
    });
  }, [listingPreviewModalListings, term]);

  const formatProviderTypeString = (provider: Provider): string => {
    if (provider.type == null || provider.type.name.trim().length === 0) {
      return '';
    }
    return provider.type.name + (provider.ein != null && provider.ein.length > 0 ? ` EIN - ${provider.ein}` : '');
  };

  const changeResultsPage = (newPage: Page): void => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  return (
    <div className={classes.adminTabContent}>
      <div className={[appClasses.searchServicesWrapper, 'mb-16'].join(' ')}>
        <IonItem className={appClasses.searchInput}>
          <IonInput
            onIonChange={(e): void => { setTerm(e.detail.value ?? ''); }}
            placeholder="Search Providers"
          >
            <IonLabel>
              <Icon name="search" width={30} />
            </IonLabel>
          </IonInput>
        </IonItem>
      </div>

      {result.data.sort((a, b) => {
        // TODO: remove this client side page sort when server can handle sorted queries.
        const aListings = a.listings?.sort(
          // @ts-ignore
          (a1, a2) => new Date(a2.updated_at) - new Date(a1.updated_at),
        );
        const bListings = b.listings?.sort(
          // @ts-ignore
          (b1, b2) => new Date(b2.updated_at) - new Date(b1.updated_at),
        );
        if ((aListings?.length ?? 0) === 0) {
          return ((bListings?.length ?? 0) === 0) ? 0 : 1;
        }
        if ((bListings?.length ?? 0) === 0) {
          return ((aListings?.length ?? 0) === 0) ? 0 : -1;
        }
        // @ts-ignore
        return new Date(bListings[0].updated_at) - new Date(aListings[0].updated_at);
      }).map((provider) => (
        <React.Fragment key={provider.id}>
          <div className={classes.providerCardHeader}>
            <IonLabel
              className="ion-no-margin ion-no-padding"
              color="secondary"
            >
              {formatProviderTypeString(provider)}
            </IonLabel>
            <IonLabel className="ion-no-margin ion-no-padding" color="light">
              {provider.submit_date === null ? '' : formatCreatedAtString(provider.submit_date!)}
            </IonLabel>
          </div>
          <div className={classes.providerCardContent}>
            <IonGrid>
              <IonRow>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="8">
                  <div className={classes.providerNameRow}>
                    <IonItem className={[classes.providerOrgName, 'disabled-pointer-events'].join(' ')}>
                      <IonLabel position="floating" color="primary">
                        Organization Name
                      </IonLabel>
                      <IonInput
                        color="primary"
                        value={provider.name}
                      />
                    </IonItem>
                    <IonIcon className="mt-16" size="large" color="primary" icon={globe} onClick={(): Window | null => window.open(provider.website_url, '_blank')} />
                  </div>
                  <IonItem className="disabled-pointer-events">
                    <IonLabel position="floating" color="primary">
                      Contact
                    </IonLabel>
                    <IonInput
                      color="primary"
                      value={provider.user?.name}
                    />
                  </IonItem>
                  <IonItem className="disabled-pointer-events">
                    <IonLabel position="floating" color="primary">
                      Email
                    </IonLabel>
                    <IonInput
                      color="primary"
                      value={provider.user?.email}
                    />
                  </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="1" />
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
                  <IonButton
                    className={classes.providerActionButton}
                    shape="round"
                    fill="solid"
                    color="primary"
                    disabled={(provider.listings?.filter(
                      (listing) => listing.approved_date,
                    ).length ?? 0) === 0}
                    onClick={(): void => {
                      setProvider(provider);
                      setListingPreviewModalListings((provider.listings?.filter(
                        (listing) => listing.approved_date,
                      ) ?? [])
                        // @ts-ignore
                        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)));
                    }}
                  >
                    View Services ({provider.listings?.filter(
                    (listing) => listing.approved_date,
                  ).length ?? 0})
                  </IonButton>
                  <IonButton
                    className={classes.providerActionButton}
                    shape="round"
                    fill="outline"
                    color="primary"
                    href={`mailto:${provider.user?.email}`}
                  >
                    Contact
                  </IonButton>
                  <IonButton
                    className={classes.providerActionButton}
                    shape="round"
                    fill="outline"
                    color="primary"
                    onClick={(): void => {
                      setProvider(provider);
                      history.push(routePaths.admin.orgInfo);
                    }}
                  >
                    Edit
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </React.Fragment>
      ))}
      <IonButtons slot="start">
        <IonButton hidden={result.skip === 0} color="primary" onClick={(): void => changeResultsPage({ limit: resultPageSize, skip: result.skip - resultPageSize })}>
          <IonIcon color="primary" icon={caretBack} />
          {result.skip - resultPageSize + 1} - {result.skip}
        </IonButton>
        <IonButton hidden={result.skip + resultPageSize >= (result.total ?? 0)} color="primary" onClick={(): void => changeResultsPage({ limit: resultPageSize, skip: result.skip + resultPageSize })}>
          {result.skip + resultPageSize + 1} - {result.skip + (2 * resultPageSize)}
          <IonIcon color="primary" icon={caretForward} />
        </IonButton>
      </IonButtons>
      <ServiceListingsPreview />
      {error.length > 0 ? <h4>Uh-oh, unable to retrieve data at this time.</h4> : (
        (!loading && result.data.length === 0) ? <h4>No Providers</h4> : null
      )}
    </div>
  );
};

export default RecentlyUpdatedServices;
