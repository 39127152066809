import { action, thunk } from 'easy-peasy';

import ProviderModel from '../interfaces/StoreModel/ProviderModel';
import { providers } from '../lib/api/services';

const model: ProviderModel = {
  error: '',
  loading: false,
  success: false,
  currentProvider: null,
  setSuccess: action((state, payload) => {
    state.success = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setProvider: action((state, payload) => {
    state.currentProvider = payload;
  }),
  approveById: thunk(async (actions, { id, approved }) => {
    try {
      actions.setLoading(true);
      await providers.patch(id, {
        approved,
      });
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  submitForApprovalById: thunk(async (actions, id) => {
    try {
      actions.setLoading(true);
      const provider = await providers.patch(id, { submitted: true });
      actions.setProvider(provider);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  getProviderById: thunk(async (actions, id) => {
    try {
      actions.setLoading(true);
      const provider = await providers.get(id);
      actions.setProvider(provider);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
};

export default model;
