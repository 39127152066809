import { IonAlert, IonButton } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routePaths from '../../../../lib/routePaths';
import { useStoreState, useStoreActions } from '../../../../store';
import useStyles from '../../styles';
import CrisisCategoryRow from './CrisisCategoryRow';

const CrisisCategories: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: categories } = useStoreState(
    (state) => state.crisisHotlines,
  );
  const { removeCategory, find } = useStoreActions(actions => actions.crisisHotlines);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [currentDelete, setCurrentDelete] = useState<number | null>(null);

  const removeCategoryHandler = async (): Promise<void> => {
    setShowDeleteAlert(false);
    const idToBeRemoved = currentDelete as number;
    await removeCategory(idToBeRemoved);
  };

  useEffect(() => {
    if (!categories) {
      find();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.adminTabContent}>
      <div className={classes.addInformationalPage}>
        <IonButton
          className={classes.providerActionButton}
          shape="round"
          fill="solid"
          color="primary"
          onClick={(): void => history.push(routePaths.admin.addCrisisHotlineGroup)}
        >
          Add
        </IonButton>
      </div>

      {categories?.map((category) => (
        <CrisisCategoryRow
          key={category.id}
          category={category}
          editCategory={(id): void => {
            history.push(
              routePaths.admin.editCrisisHotlineGroup.replace(':id', `${id}`),
            );
          }}
          deleteCategory={(id): void => {
            setCurrentDelete(id);
            setShowDeleteAlert(true);
          }}
        />
      ))}
      {/* Delete Confirmation Modal */}
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={(): void => setShowDeleteAlert(false)}
        message="Are you sure you want to delete this category? Please bear in mind that this will also delete all the hotlines associated to it"
        buttons={[
          { text: 'Cancel' },
          {
            text: 'Delete',
            handler: removeCategoryHandler,
          },
        ]}
      />
    </div>
  );
};

export default CrisisCategories;
