import { IonAlert, IonButton } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routePaths from '../../../../lib/routePaths';
import { useStoreActions, useStoreState } from '../../../../store';
import useStyles from '../../styles';
import CrisisHotlineRow from './CrisisHotlineRow';

const CrisisHotlines: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: crisisGroups, hotlines } = useStoreState(
    (state) => state.crisisHotlines,
  );
  const { remove } = useStoreActions((actions) => actions.crisisHotlines);
  const find = useStoreActions((actions) => actions.crisisHotlines.find);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [currentDelete, setCurrentDelete] = useState<number | null>(null);

  useEffect(() => {
    if (!crisisGroups || crisisGroups.length === 0) {
      find();
    }
  }, [crisisGroups, find]);

  const removeCrisisHotline = async (): Promise<void> => {
    setShowDeleteAlert(false);
    const idToBeRemoved = currentDelete as number;
    await remove(idToBeRemoved);
  };

  return (
    <div className={classes.adminTabContent}>
      <div className={classes.addInformationalPage}>
        <IonButton
          className={classes.providerActionButton}
          shape="round"
          fill="solid"
          color="primary"
          onClick={(): void => history.push(routePaths.admin.addCrisisHotline)}
        >
          Add
        </IonButton>
      </div>

      {hotlines?.map((hotline) => (
        <CrisisHotlineRow
          key={hotline.id}
          hotline={hotline}
          editHotline={(id): void => {
            history.push(
              routePaths.admin.editCrisisHotline.replace(':id', `${id}`),
            );
          }}
          deleteHotline={(id): void => {
            setCurrentDelete(id);
            setShowDeleteAlert(true);
          }}
        />
      ))}
      {/* Delete Confirmation Modal */}
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={(): void => setShowDeleteAlert(false)}
        message="Are you sure you want to delete this crisis hotline?"
        buttons={[
          { text: 'Cancel' },
          {
            text: 'Delete',
            handler: removeCrisisHotline,
          },
        ]}
      />
    </div>
  );
};

export default CrisisHotlines;
