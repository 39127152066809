import Add from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBackRounded';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowDropDown from '@material-ui/icons/ArrowDropDownRounded';
import ArrowRight from '@material-ui/icons/ArrowRight';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import CameraAlt from '@material-ui/icons/CameraAltRounded';
import CheckCircle from '@material-ui/icons/CheckCircleRounded';
import Close from '@material-ui/icons/CloseRounded';
import Directions from '@material-ui/icons/DirectionsRounded';
import Email from '@material-ui/icons/EmailRounded';
import Heart from '@material-ui/icons/FavoriteBorderRounded';
import HeartSolid from '@material-ui/icons/FavoriteRounded';
import Gavel from '@material-ui/icons/GavelRounded';
import Help from '@material-ui/icons/Help';
import Language from '@material-ui/icons/LanguageRounded';
import Map from '@material-ui/icons/MapRounded';
import Phone from '@material-ui/icons/PhoneRounded';
import Remove from '@material-ui/icons/Remove';
import Reply from '@material-ui/icons/ReplyRounded';
import Room from '@material-ui/icons/RoomRounded';
import School from '@material-ui/icons/SchoolRounded';
import Search from '@material-ui/icons/SearchRounded';
import Share from '@material-ui/icons/ShareRounded';
import ShoppingCart from '@material-ui/icons/ShoppingCartRounded';
import List from '@material-ui/icons/ViewListRounded';
import Warning from '@material-ui/icons/WarningRounded';
import WatchLater from '@material-ui/icons/WatchLaterRounded';
import Work from '@material-ui/icons/WorkRounded';
import React from 'react';
import Benefits from '../assets/category_icons/Benefits';
import HealthWellness from '../assets/category_icons/HealthWellness';
import MentalHealth from '../assets/category_icons/MentalHealth';
import More from '../assets/category_icons/More';
import ShelterHousing from '../assets/category_icons/ShelterHousing';
import SubstanceAbuse from '../assets/category_icons/SubstanceAbuse';
import Transit from '../assets/category_icons/Transit';

interface IconProps {
  name: string;
  color?: string;
  width?: number;
  height?: number;
}

const customSVGs = [
  { name: 'more', component: More },
  { name: 'benefits', component: Benefits },
  { name: 'mental_health', component: MentalHealth },
  { name: 'wellness', component: HealthWellness },
  { name: 'shelter', component: ShelterHousing },
  { name: 'transit', component: Transit },
  { name: 'substance', component: SubstanceAbuse },
];

const materialIcons = [
  { name: 'gavel', component: Gavel },
  { name: 'work', component: Work },
  { name: 'warning', component: Warning },
  { name: 'school', component: School },
  { name: 'shopping_cart', component: ShoppingCart },
  { name: 'arrow_back', component: ArrowBack },
  { name: 'search', component: Search },
  { name: 'check_circle', component: CheckCircle },
  { name: 'directions', component: Directions },
  { name: 'heart', component: Heart },
  { name: 'heart_solid', component: HeartSolid },
  { name: 'phone', component: Phone },
  { name: 'map', component: Map },
  { name: 'arrow_drop_down', component: ArrowDropDown },
  { name: 'arrow_right', component: ArrowRight },
  { name: 'close', component: Close },
  { name: 'camera', component: CameraAlt },
  { name: 'share', component: Share },
  { name: 'language', component: Language },
  { name: 'list', component: List },
  { name: 'email', component: Email },
  { name: 'watch_later', component: WatchLater },
  { name: 'room', component: Room },
  { name: 'reply', component: Reply },
  { name: 'add', component: Add },
  { name: 'remove', component: Remove },
  { name: 'help', component: Help },
  { name: 'arrow_downward', component: ArrowDownward },
  { name: 'arrow_upward', component: ArrowUpward },
];

const Icon: React.FunctionComponent<IconProps> = ({
  name, height, width, color,
}: IconProps) => {
  const foundCustom = customSVGs.find(i => i.name === name);
  if (foundCustom) {
    const CustomSVG = foundCustom.component;
    return <CustomSVG color={color || 'white'} width={width || 24} height={height || 24} />;
  }
  const materialIcon = materialIcons.find(i => i.name === name);
  if (materialIcon) {
    const MaterialIcon = materialIcon.component;
    const iconStyle = { color, fontSize: width };
    return <MaterialIcon style={iconStyle} />;
  }
  return <></>;
};

export default Icon;
