import React from 'react';
import { IconProps } from './Icon';

const HealthWellness: React.FunctionComponent<IconProps> = ({
  color,
  height,
  width,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 500 500"
  >
    <g fill={color}>
      <path
        id="Selection"
        d="M 200.00,267.00
           C 201.37,265.34 202.42,264.06 203.19,261.98
             203.19,261.98 209.17,236.00 209.17,236.00
             209.17,236.00 211.69,225.09 211.69,225.09
             211.69,225.09 213.53,220.72 213.53,220.72
             213.53,220.72 214.60,214.00 214.60,214.00
             214.60,214.00 218.41,200.00 218.41,200.00
             218.41,200.00 221.55,186.04 221.55,186.04
             221.55,186.04 223.57,180.72 223.57,180.72
             223.57,180.72 224.37,175.00 224.37,175.00
             224.37,175.00 226.20,168.00 226.20,168.00
             229.60,153.83 232.13,134.99 251.00,136.04
             265.36,136.84 268.75,151.64 270.75,163.00
             270.75,163.00 276.55,189.00 276.55,189.00
             276.55,189.00 278.68,199.00 278.68,199.00
             278.68,199.00 279.24,204.83 279.24,204.83
             279.24,204.83 281.53,212.01 281.53,212.01
             281.53,212.01 286.45,237.00 286.45,237.00
             286.45,237.00 288.49,245.00 288.49,245.00
             288.49,245.00 289.47,252.00 289.47,252.00
             290.78,257.42 291.65,255.94 292.00,263.00
             292.00,263.00 340.00,263.00 340.00,263.00
             342.40,263.02 344.73,262.94 347.00,263.85
             353.74,266.58 361.15,276.36 359.43,284.00
             356.94,295.12 348.70,299.98 338.00,300.00
             338.00,300.00 279.00,300.00 279.00,300.00
             276.09,300.00 272.75,300.13 270.00,299.11
             259.79,295.33 257.60,285.26 255.64,276.00
             255.64,276.00 252.37,261.00 252.37,261.00
             252.37,261.00 251.78,255.00 251.78,255.00
             250.68,248.48 249.04,251.81 249.00,241.00
             249.00,241.00 247.00,241.00 247.00,241.00
             246.32,249.32 242.94,256.96 241.24,265.00
             241.24,265.00 231.59,304.00 231.59,304.00
             231.59,304.00 226.63,324.00 226.63,324.00
             226.63,324.00 223.41,335.00 223.41,335.00
             221.54,342.69 221.94,349.11 215.32,355.20
             205.68,364.08 193.99,361.46 186.90,350.96
             182.07,343.80 180.96,334.07 178.33,326.00
             178.33,326.00 168.80,295.00 168.80,295.00
             167.16,289.29 166.24,291.04 166.00,284.00
             162.92,287.27 163.11,289.03 160.72,292.20
             155.67,298.89 150.83,299.99 143.00,300.00
             143.00,300.00 42.00,300.00 42.00,300.00
             32.01,300.02 22.32,302.41 21.51,314.00
             20.97,321.83 24.17,327.27 32.00,329.39
             32.00,329.39 68.00,330.00 68.00,330.00
             68.00,330.00 115.00,330.00 115.00,330.00
             125.67,330.02 128.01,334.07 136.00,340.58
             136.00,340.58 154.00,354.88 154.00,354.88
             154.00,354.88 193.09,384.93 193.09,384.93
             193.09,384.93 226.96,407.58 226.96,407.58
             226.96,407.58 244.72,419.40 244.72,419.40
             247.29,421.16 252.08,424.79 254.96,425.45
             259.27,426.42 268.32,418.57 272.17,416.04
             272.17,416.04 318.42,387.04 318.42,387.04
             354.17,365.45 396.65,340.20 422.50,307.00
             430.73,296.42 440.80,279.49 445.56,267.00
             445.56,267.00 450.53,250.00 450.53,250.00
             450.53,250.00 452.74,243.00 452.74,243.00
             452.74,243.00 453.53,235.00 453.53,235.00
             455.02,228.35 455.95,230.77 456.00,222.00
             456.00,222.00 456.00,198.00 456.00,198.00
             455.89,190.10 454.36,190.88 453.33,185.00
             453.33,185.00 452.53,178.00 452.53,178.00
             452.53,178.00 450.54,172.00 450.54,172.00
             450.54,172.00 445.56,155.17 445.56,155.17
             435.00,129.84 416.74,106.88 390.58,96.66
             390.58,96.66 372.00,91.38 372.00,91.38
             372.00,91.38 365.00,90.70 365.00,90.70
             358.74,89.77 358.83,88.11 351.00,88.00
             347.13,87.95 339.47,87.73 336.00,88.61
             336.00,88.61 329.00,90.67 329.00,90.67
             329.00,90.67 322.00,91.38 322.00,91.38
             311.22,93.53 300.77,98.17 291.00,103.03
             281.58,107.72 265.90,119.14 256.00,119.14
             245.80,119.14 228.30,105.63 219.00,100.39
             202.71,91.22 183.66,85.78 165.00,86.00
             165.00,86.00 145.00,88.54 145.00,88.54
             145.00,88.54 140.00,90.48 140.00,90.48
             140.00,90.48 122.42,96.60 122.42,96.60
             94.58,108.94 73.28,131.80 62.27,160.00
             58.83,168.81 55.12,180.52 55.00,190.00
             55.00,190.00 55.00,219.00 55.00,219.00
             55.00,219.00 57.54,236.00 57.54,236.00
             57.54,236.00 60.21,244.00 60.21,244.00
             61.11,247.34 65.95,260.29 68.47,261.98
             70.34,263.23 73.79,263.00 76.00,263.00
             76.00,263.00 123.00,263.00 123.00,263.00
             125.33,263.00 129.55,263.25 131.44,261.83
             132.94,260.70 136.44,250.38 137.30,248.00
             139.99,240.53 147.54,218.11 151.53,213.02
             154.89,208.73 162.30,203.96 168.00,204.72
             179.29,206.24 183.69,215.44 186.67,225.00
             186.67,225.00 200.00,267.00 200.00,267.00 Z"
      />
    </g>
  </svg>
);

export default HealthWellness;
