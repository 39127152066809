const routePaths = {
  provider: {
    root: '/provider',
    signin: '/provider/signin',
    onboarding: {
      root: '/provider/onboarding',
      requirements: '/provider/onboarding/requirements',
      questions: '/provider/onboarding/requirements/:providerTypeId',
      orgInfo: '/provider/onboarding/org-info',
      addService: '/provider/onboarding/add-service',
      editService: '/provider/onboarding/edit-service',
      editServiceId: '/provider/onboarding/edit-service/:listingId',
      unverifiedEmail: '/provider/onboarding/unverified',
      approval: '/provider/onboarding/approval',
      submit: '/provider/onboarding/submit',
      viewProfile: '/provider/onboarding/view-profile',
      verifyEmail: {
        root: '/provider/onboarding/signup/verify-email',
        token: '/provider/onboarding/signup/verify-email/:token',
      },
    },
    approved: {
      root: '/provider/approved',
      addService: '/provider/approved/services/add-service',
      editService: '/provider/approved/services/edit-service',
      editServiceId: '/provider/approved/services/edit-service/:listingId',
      editProfile: '/provider/approved/services/edit-profile',
    },
  },
  admin: {
    root: '/admin',
    dashboard: '/admin/dashboard',
    editService: '/admin/dashboard/edit-service',
    editServiceId: '/admin/dashboard/edit-service/:listingId',
    orgInfo: '/admin/org-info',
    informationalPages: '/admin/informational-pages',
    addInformationalPage: '/admin/informational-pages/create',
    editInformationalPage: '/admin/informational-pages/edit/:id',
    crisisHotlines: '/admin/crisis-hotline',
    addCrisisHotline: '/admin/crisis-hotline/create',
    editCrisisHotline: '/admin/crisis-hotline/edit/:id',
    addCrisisHotlineGroup: '/admin/crisis-hotline-group/create',
    editCrisisHotlineGroup: '/admin/crisis-hotline-group/edit/:id',
    listingCategories: '/admin/listing-category',
    editListingCategory: '/admin/listing-category/edit/:id',
    addListingSubcategory: '/admin/listing-subcategory/create',
    editListingSubcategory: '/admin/listing-subcategory/edit/:id',
    analytics: '/admin/analytics',
  },
  public: {
    root: '/cc',
    home: '/cc/home',
    search: '/cc/search',
    settings: '/cc/settings',
    favorites: '/cc/favorites',
    onboarding: '/cc/onboarding',
    help: '/cc/help',
    info: '/cc/info',
    subcategories: '/cc/category/:category_name/subcategories',
    listings: '/cc/listings',
    listingDetail: '/cc/listings/:id',
    crisis: '/cc/crisis',
    page: '/cc/page/:slug',
  },
  email: {
    root: '/email',
    verify: '/email/verify',
    verifyToken: '/email/verify/:token',
  },
  password: {
    root: '/password',
    reset: '/password/reset',
    resetToken: '/password/reset/:token',
  },
};

export default routePaths;
