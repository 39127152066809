import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonLabel,
  IonRow,
  IonRouterLink,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import Icon from '../../components/Icon';
import useClasses from '../../components/styles';
import { Category } from '../../interfaces/Meta';
import routePaths from '../../lib/routePaths';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import {
  getDefaultFiltersAsUrlParams,
  hexToRgbA,
  translateName,
} from '../../lib/utils';
import { useStoreState, useStoreActions } from '../../store';
import CategoryIcon from './CategoryIcon';
// @ts-ignore
import logo from '../../assets/cc_logo.png';

interface SubcategoriesParams {
  category_name?: string
}

const Subcategories: React.FunctionComponent = () => {
  const isNotXtraSmall = useIsDesktop(321);
  const history = useHistory();
  const params = useParams<SubcategoriesParams>();
  const isDesktop = useIsDesktop();
  const { category_name: slug } = params;
  const { data } = useStoreState((state) => state.meta);
  const settings = useStoreState((state) => state.settings);
  const locale = useStoreState((state) => state.translations.language);
  const [category, setCategory] = useState<Category>({} as Category);
  const [selectedSubCats, setSelectedSubCats] = useState<string[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [searchUrl, setSearchUrl] = useState<string>(
    `${routePaths.public.listings}?category=${category.slug}`,
  );
  const [defaultQueryParams, setDefaultQueryParams] = useState<string>('');
  const { setUseLocationOnSearch, getLocation } = useStoreActions(
    (actions) => ({
      setUseLocationOnSearch: actions.settings.setUseLocationOnSearch,
      getLocation: actions.analytics.getLocation,
    }),
  );

  useEffect(() => {
    (async (): Promise<void> => {
      const coordinates = await getLocation();
      const q = getDefaultFiltersAsUrlParams(
        settings,
        setUseLocationOnSearch,
        coordinates,
      );
      setDefaultQueryParams(q);
    })();
  }, [settings, setUseLocationOnSearch, getLocation]);

  const classes = useClasses({
    backgroundColor: hexToRgbA(category.color || '#FFF', 0.25),
  });
  const primaryColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--ion-color-primary');

  useEffect(() => {
    if (data && data.category && data.category.length) {
      let foundCategory = data.category.find((c) => c.slug === slug);
      if (!foundCategory) {
        const orderedCategories = data.category.sort(
          (a, b) => a.order - b.order,
        );
        [foundCategory] = orderedCategories;
      }
      setCategory(foundCategory);
    }
  }, [data, slug]);

  const clearBtnStyle = { right: 0 };

  useEffect(() => {
    if (category && category.sub_categories) {
      setSelectAllChecked(
        category.sub_categories.length === selectedSubCats.length,
      );
      const subcategorySearchString = category.sub_categories.length === selectedSubCats.length
        ? ''
        : category.sub_categories
          .filter((s) => selectedSubCats.includes(s.slug ?? ''))
          .map((s) => `sub_categories[]=${s.slug}`)
          .join('&');
      const url = `${routePaths.public.listings}?category=${category.slug}${
        subcategorySearchString.length > 0 ? '&' : ''
      }${subcategorySearchString}${`${
        defaultQueryParams.length > 0 ? '&' : ''
      }${defaultQueryParams}`}`;
      setSearchUrl(url);
    }
  }, [category, defaultQueryParams, selectedSubCats]);

  const updateSubcategoryState = (subSlug: string): void => {
    const index = selectedSubCats.indexOf(subSlug);
    let newSet = [];
    if (index > -1) {
      newSet = [...selectedSubCats];
      newSet.splice(index, 1);
    } else {
      newSet = [...selectedSubCats, subSlug];
    }
    setSelectedSubCats(newSet);
  };

  const selectAll = (e: React.MouseEvent<HTMLIonCheckboxElement>): void => {
    const { checked } = e.target as HTMLIonCheckboxElement;
    if (checked) {
      const selected = category.sub_categories.map((s) => s.slug ?? '');
      setSelectedSubCats(selected);
    } else {
      setSelectedSubCats([]);
    }
  };

  return (
    <div className={classes.subcategories}>
      {isDesktop && (
        <div className={classes.ccDesktopSidebarHeaderSubcategories}>
          <div className={classes.ccDesktopLogoWrapper}>
            <IonRouterLink routerLink={routePaths.public.home}>
              <img
                src={logo}
                alt="home"
                style={{
                  height: '60px',
                  width: '164px',
                }}
              />
            </IonRouterLink>
          </div>
          <div
            className={[classes.arrowBack, classes.headerGoBackIcon].join(' ')}
            onClick={(): void => history.goBack()}
          >
            <Icon name="arrow_back" width={isNotXtraSmall ? 40 : 35} color={primaryColor} />
          </div>
        </div>
      )}

      <IonRow className={classes.subcategoryRow}>
        <CategoryIcon iconName={category.icon} color={category.color} />
        <div className={classes.subcategoryTitleWrapper}>
          <div className={classes.subcategoryTitle}>
            <IonLabel className={classes.subcategoryTitleText}>
              {translateName(category, locale)}
            </IonLabel>
          </div>
          <div className={classes.subcategoryTitle}>
            <IonLabel>
              <FormattedMessage id="services-camel" />
            </IonLabel>
          </div>
        </div>
      </IonRow>

      <IonRow className={classes.btnRow}>
        <div className={classes.listingDetailSelectAllWrapper}>
          <IonCheckbox
            onClick={selectAll}
            checked={selectAllChecked}
            className={classes.selectAllCheckbox}
          />
          <IonLabel className={classes.checkBoxText}>
            <FormattedMessage id="select-all" />
          </IonLabel>
        </div>
        <div className={classes.listingDetailClearWrapper}>
          <IonButton
            onClick={(): void => setSelectedSubCats([])}
            className={classes.clearBtn}
            style={clearBtnStyle}
          >
            <FormattedMessage id="clear-upper" />
          </IonButton>
        </div>
      </IonRow>

      <div className={[classes.subcategoryRow, classes.subcategoryContainer].join(' ')}>
        {(category && category.id) ? (
          category.sub_categories.sort(
            (a, b) => (locale === 'es' ? ((a.translation?.es?.name ?? '') > (b.translation?.es?.name ?? '') ? 1 : -1) : ((a.name > b.name) ? 1 : -1)),
          ).map((sub_category) => {
            const subcategoryBtnClass = selectedSubCats.includes(sub_category.slug ?? '')
              ? [classes.subcategoryBtn, classes.subcategorySelectedBtn].join(' ')
              : classes.subcategoryBtn;
            return (
              <div className={classes.subcategoryBtnWrapper} key={sub_category.id}>
                <IonButton
                  onClick={(): void => updateSubcategoryState(sub_category.slug ?? '')}
                  className={subcategoryBtnClass}
                >
                  <IonLabel style={{ fontSize: 14 }}>
                    {translateName(sub_category, locale).replace(/\//g, ' / ')}
                  </IonLabel>
                </IonButton>
              </div>
            );
          })
        ) : null}
      </div>

      <IonRow>
        <IonCol sizeXs="12">
          <IonButton
            onClick={(): void => history.push(searchUrl)}
            className={classes.searchBtn}
          >
            <FormattedMessage id="search-results" />
          </IonButton>
        </IonCol>
      </IonRow>
    </div>
  );
};

export default Subcategories;
