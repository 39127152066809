import {
  home,
  person,
  heart,
  settings,
} from 'ionicons/icons';

import routePaths from '../lib/routePaths';

interface TabInfo {
  tabTitleId: string;
  tabOrderIndex: number;
}

interface DesktopNavInfo {
  navTitleId: string;
  navOrderIndex: number;
}

interface MenuItem {
  url: string;
  menuTitleId: string;
  tabInfo?: TabInfo;
  desktopNavInfo?: DesktopNavInfo;
  nested?: boolean;
  icon: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items?: any[];
}

const menuItems: MenuItem[] = [
  {
    menuTitleId: 'app.menutitle.home',
    tabInfo: { tabTitleId: 'app.tabtitle.home', tabOrderIndex: 2 },
    url: routePaths.public.home,
    icon: home,
  },
  {
    menuTitleId: 'app.menutitle.settings',
    tabInfo: { tabTitleId: 'app.tabtitle.settings', tabOrderIndex: 1 },
    desktopNavInfo: { navTitleId: 'app.navtitle.settings', navOrderIndex: 1 },
    url: routePaths.public.settings,
    icon: person,
  },
  {
    menuTitleId: 'app.menutitle.favorites',
    tabInfo: { tabTitleId: 'app.tabtitle.favorites', tabOrderIndex: 3 },
    desktopNavInfo: { navTitleId: 'app.navtitle.favorites', navOrderIndex: 2 },
    url: routePaths.public.favorites,
    icon: heart,
  },
  {
    menuTitleId: 'app.menutitle.providers',
    url: routePaths.provider.root,
    desktopNavInfo: { navTitleId: 'app.navtitle.providers', navOrderIndex: 4 },
    icon: settings,
  },
];

export default menuItems;
