import { IonAlert, IonButton } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CCAdminPage from '../../../../components/CCAdminPage';
import routePaths from '../../../../lib/routePaths';
import { useStoreActions, useStoreState } from '../../../../store';
import useStyles from '../../styles';
import PageRow from './PageRow';

const InformationalPages: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: pages } = useStoreState((state) => state.pages);
  const { remove } = useStoreActions((state) => state.pages);
  const { find: getPages, moveUp, moveDown } = useStoreActions((state) => state.pages);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [currentDelete, setCurrentDelete] = useState<number | null>(null);

  useEffect(() => {
    getPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removePage = async (): Promise<void> => {
    setShowDeleteAlert(false);
    const idToBeRemoved = currentDelete as number;
    await remove(idToBeRemoved);
  };

  return (
    <CCAdminPage
      title="Informational Pages"
      content={(
        <>
          <div className={classes.adminContent}>
            <h3>Informational Pages</h3>
            <div className={classes.addInformationalPage}>
              <IonButton
                className={classes.providerActionButton}
                shape="round"
                fill="solid"
                color="primary"
                onClick={(): void => history.push(routePaths.admin.addInformationalPage)}
              >
                Add
              </IonButton>
            </div>

            {pages?.sort((a, b) => (a?.navbar_order ?? 0) - (b?.navbar_order ?? 0)).map((p) => (
              <PageRow
                key={p.id}
                page={p}
                editPage={(id: number): void => {
                  history.push(
                    routePaths.admin.editInformationalPage.replace(
                      ':id',
                      `${id}`,
                    ),
                  );
                }}
                deletePage={(id: number): void => {
                  setCurrentDelete(id);
                  setShowDeleteAlert(true);
                }}
                moveUp={moveUp}
                moveDown={moveDown}
              />
            ))}
          </div>

          {/* Delete Confirmation Modal */}
          <IonAlert
            isOpen={showDeleteAlert}
            onDidDismiss={(): void => setShowDeleteAlert(false)}
            message="Are you sure you want to delete this page?"
            buttons={[
              { text: 'Cancel' },
              {
                text: 'Delete',
                handler: removePage,
              },
            ]}
          />
        </>
      )}
    />
  );
};

export default InformationalPages;
