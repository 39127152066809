import React from 'react';
import Icon from '../../components/Icon';
import useClasses from '../../components/styles';

interface CategoryIconProps {
  iconName: string;
  color: string;
}

const CategoryIcon: React.FunctionComponent<CategoryIconProps> = ({
  iconName,
  color,
}: CategoryIconProps) => {
  const classes = useClasses();
  const customStyle = { backgroundColor: color };
  return (
    <div className={classes.categoryIcon} style={customStyle}>
      <Icon width={48} height={48} color="white" name={iconName} />
    </div>
  );
};

export default CategoryIcon;
