import {
  action, createStore, createTypedHooks, persist,
} from 'easy-peasy';

import StoreModel from '../interfaces/StoreModel';

import auth from './auth';
import createOrg from './createOrg';
import serviceListings from './serviceListings';
import translations from './translations';
import settings from './settings';
import users from './users';
import meta from './meta';
import listings from './listings';
import verifyEmail from './verifyEmail';
import provider from './providers';
import admin from './admins';
import password from './passwordReset';
import search from './search';
import favorites from './favorites';
import crisisHotlines from './crisisHotlines';
import pages from './pages';
import map from './map';
import analytics from './analytics';
import categories from './categories';
import subcategories from './subcategories';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let initialState: any = {};

const storeModel: StoreModel = {
  auth,
  createOrg,
  serviceListings,
  translations: persist(
    translations,
    {
      storage: 'localStorage',
    },
  ),
  settings: persist(
    settings,
    {
      storage: 'localStorage',
    },
  ),
  users,
  meta,
  listings,
  verifyEmail,
  provider,
  admin,
  crisisHotlines,
  categories,
  subcategories,
  pages,
  map,
  reset: action((state) => ({
    ...initialState,
    settings: {
      getGeneralNotifications: state.settings.getGeneralNotifications,
      getUrgentNotifications: state.settings.getUrgentNotifications,
      useDefaultFilters: state.settings.useDefaultFilters,
      useLocationOnSearch: state.settings.useLocationOnSearch,
      useAgeOnSearch: state.settings.useAgeOnSearch,
      useCitiesOnSearch: state.settings.useCitiesOnSearch,
      useGroupsOnSearch: state.settings.useGroupsOnSearch,
      profileGender: state.settings.profileGender,
      profileGenderInput: state.settings.profileGenderInput,
      profileEthnicity: state.settings.profileEthnicity,
      profileEthnicityInput: state.settings.profileEthnicityInput,
    },
    translations: {
      language: state.translations.language,
      messages: state.translations.messages,
    },
    meta: {
      loading: false,
      error: '',
      data: state.meta.data,
    },
    favorites: {
      favoriteListings: state.favorites.favoriteListings,
    },
    pages: {
      data: state.pages.data,
    },
  })),
  password,
  search,
  favorites,
  analytics,
};

export const store = createStore<StoreModel>(storeModel);
initialState = store.getState();

export const typedHooks = createTypedHooks<StoreModel>();
/* eslint-disable */
export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;
export const useStore = typedHooks.useStore;
/* eslint-enable */
