/* eslint-disable max-len */
import {
  IonSelect, IonSelectOption, IonItem, IonInput, IonRouterLink, isPlatform,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useStoreState, useStoreActions } from '../../store';
import useAppStyles from '../styles';
import useOnboardingStyles from '../Provider/Onboarding/styles';
import { getTranslatedString } from '../../lib/utils';
// @ts-ignore
import logo from '../../assets/cc_logo.png';
import routePaths from '../../lib/routePaths';
import useStyles from '../../components/styles';

const SettingsSidebar: React.FC = () => {
  const classes = useStyles();
  const { language } = useStoreState(state => state.translations);
  const ethnicitiesAlertOptions = {
    header: getTranslatedString('select-race-ethnicity', language),
  };
  const genderAlertOptions = {
    header: getTranslatedString('select-gender', language),
  };
  const [useESStrings, setUseESStrings] = useState(false);
  const genders = [
    'female',
    'male',
    'prefer-to-self-describe',
    'prefer-not-to-say',
  ];
  const ethnicities = [
    'american_indian_or_alaska_native',
    'asian',
    'black_or_african_american',
    'native_hawaiian_or_other_pacific_islander',
    'white',
    'hispanic_or_latino',
    'not_hispanic_or_latino',
    'other',
  ];
  const {
    profileEthnicity,
    profileEthnicityInput,
    profileGender,
    profileGenderInput,
  } = useStoreState(state => state.settings);
  const {
    setProfileEthnicity,
    setProfileEthnicityInput,
    setProfileGender,
    setProfileGenderInput,
  } = useStoreActions(state => state.settings);
  const appClasses = useAppStyles();
  const onboardingClasses = useOnboardingStyles();

  useEffect(() => {
    setUseESStrings(language === 'es');
  }, [profileEthnicity, profileGender, language]);

  return (
    <div className={classes.sidebar}>
      {!isPlatform('ios') && !isPlatform('android') && (
      <IonRouterLink routerLink={routePaths.public.home}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <img
            src={logo}
            alt="home"
            style={{
              height: '60px', width: '164px', marginTop: '5px',
            }}
          />
        </div>
      </IonRouterLink>
      )}
      <h3 className={[appClasses.title, 'ion-margin'].join(' ')}>
        <FormattedMessage id="profile" />
      </h3>
      <h4 className={[appClasses.subtitle, 'ion-margin mb-24'].join(' ')}>
        <FormattedMessage id="please-tell-us-about-yourself" />
      </h4>
      <div className={[appClasses.whitebg, 'ml-16 mr-16'].join(' ')}>
        <h4 className={appClasses.subtitle}>
          <FormattedMessage id="ethnicity-selector-header" />
        </h4>
        <IonSelect
          className="cc-select"
          placeholder={getTranslatedString('my-race-ethnicity', language)}
          value={profileEthnicity}
          multiple
          interfaceOptions={ethnicitiesAlertOptions}
          onIonChange={(e): void => {
            setProfileEthnicity(e.detail.value);
            setProfileEthnicityInput('');
          }}
        >
          {ethnicities.map((ethnicity) => (
            <IonSelectOption key={ethnicity} value={ethnicity}>
              {useESStrings ? getTranslatedString(ethnicity, 'es') : getTranslatedString(ethnicity, 'en')}
            </IonSelectOption>
          ))}
        </IonSelect>
      </div>
      <IonItem hidden={!profileEthnicity.includes('Other')} className={[onboardingClasses.input, 'ml-16 mr-16'].join(' ')}>
        <IonInput
          value={profileEthnicityInput}
          type="text"
          onIonChange={(e): void => {
            setProfileEthnicityInput(e.detail.value ?? '');
          }}
          placeholder={getTranslatedString('enter-race-ethnicity', language)}
        />
      </IonItem>
      <div className={[appClasses.whitebg, 'mt-24 ml-16 mr-16'].join(' ')}>
        <h4 className={appClasses.subtitle}>
          <FormattedMessage id="gender-i-identify-with" />
        </h4>
        <IonSelect
          className="cc-select"
          placeholder={getTranslatedString('my-gender', language)}
          value={profileGender?.length > 0 ? profileGender : null}
          interfaceOptions={genderAlertOptions}
          onIonChange={(e): void => {
            setProfileGender(e.detail.value);
            setProfileGenderInput('');
          }}
        >
          {genders.map((gender) => (
            <IonSelectOption key={gender} value={gender}>
              {useESStrings ? getTranslatedString(gender, 'es') : getTranslatedString(gender, 'en')}
            </IonSelectOption>
          ))}
          <IonSelectOption
            key={-1}
            value=""
          >
            {getTranslatedString('clear-gender-selection', language)}
          </IonSelectOption>
        </IonSelect>
      </div>
      <IonItem hidden={profileGender !== genders[2]} className={[onboardingClasses.input, 'ml-16 mr-16'].join(' ')}>
        <IonInput
          value={profileGenderInput}
          type="text"
          onIonChange={(e): void => {
            setProfileGenderInput(e.detail.value ?? '');
          }}
          placeholder={getTranslatedString('describe-gender', language)}
        />
      </IonItem>
    </div>
  );
};

export default SettingsSidebar;
