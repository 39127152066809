import { IonLoading } from '@ionic/react';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import routePaths from '../../lib/routePaths';
import { useStoreState } from '../../store';
import CategoriesWrapper from './components/ListingCategories/ListingCategoriesWrapper';
import CrisisCategoryUpsert from './components/CrisisHotlines/CrisisCategoryUpsert';
import CrisisHotlineUpsert from './components/CrisisHotlines/CrisisHotlineUpsert';
import CrisisHotlineWrapper from './components/CrisisHotlines/CrisisHotlineWrapper';
import EditServiceListing from './components/EditServiceListing';
import InformationalPages from './components/InformationalPages';
import PageUpsert from './components/InformationalPages/PageUpsert';
import ProviderOrgInfo from './components/ProviderOrgInfo';
import Dashboard from './Dashboard';
import ListingCategoryEdit from './components/ListingCategories/ListingCategoryEdit';
import ListingSubcategoryEdit from './components/ListingCategories/ListingSubcategoryEdit';
import Analytics from './components/Analytics/Analytics';

let initialPath = '';

const Admin: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { user } = useStoreState((state) => state.auth);

  if (!initialPath) {
    initialPath = location.pathname;
  }

  const {
    serviceListings: { loading: serviceListingsLoading },
  } = useStoreState((state) => ({
    serviceListings: state.serviceListings,
  }));

  useEffect(() => {
    if (!user) {
      history.push(routePaths.provider.signin);
    } else if (initialPath) {
      history.push(initialPath);
      initialPath = '';
    }
  }, [history, user]);

  return (
    <>
      <Switch>
        <Route
          exact
          path={routePaths.admin.informationalPages}
          component={InformationalPages}
        />
        <Route
          exact
          path={routePaths.admin.crisisHotlines}
          component={CrisisHotlineWrapper}
        />
        <Route
          exact
          path={routePaths.admin.addCrisisHotline}
          component={CrisisHotlineUpsert}
        />
        <Route
          exact
          path={routePaths.admin.editCrisisHotline}
          component={CrisisHotlineUpsert}
        />
        <Route
          exact
          path={routePaths.admin.addCrisisHotlineGroup}
          component={CrisisCategoryUpsert}
        />
        <Route
          exact
          path={routePaths.admin.editCrisisHotlineGroup}
          component={CrisisCategoryUpsert}
        />
        <Route
          exact
          path={routePaths.admin.editInformationalPage}
          component={PageUpsert}
        />
        <Route
          exact
          path={routePaths.admin.addInformationalPage}
          component={PageUpsert}
        />
        <Route
          exact
          path={routePaths.admin.orgInfo}
          component={ProviderOrgInfo}
        />
        <Route
          path={routePaths.admin.editServiceId}
          component={EditServiceListing}
        />
        <Route
          path={routePaths.admin.editListingCategory}
          component={ListingCategoryEdit}
        />
        <Route
          path={routePaths.admin.editListingSubcategory}
          component={ListingSubcategoryEdit}
        />
        <Route
          path={routePaths.admin.addListingSubcategory}
          component={ListingSubcategoryEdit}
        />
        <Route
          path={routePaths.admin.listingCategories}
          component={CategoriesWrapper}
        />
        <Route exact path={routePaths.admin.dashboard} component={Dashboard} />
        <Route exact path={routePaths.admin.analytics} component={Analytics} />
        <Redirect
          path={routePaths.admin.root}
          to={routePaths.admin.dashboard}
        />
      </Switch>
      {serviceListingsLoading ? <IonLoading isOpen /> : null}
    </>
  );
};

export default Admin;
