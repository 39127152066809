/* eslint-disable max-len */
import {
  IonAlert, IonButton, IonCard,
  IonCardHeader,
  IonCardTitle, IonCol, IonGrid, IonIcon, IonInput, IonItem, IonLabel, IonList, IonRow, IonSelect,
  IonSelectOption, IonText, IonTextarea, IonToggle,
} from '@ionic/react';
import { ClickAwayListener, IconButton, Tooltip } from '@material-ui/core';
import {
  call, caretDown, caretUp, globeOutline, location, mail,
} from 'ionicons/icons';
import React, {
  MutableRefObject, useCallback, useEffect, useRef, useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Icon from '../../../../components/Icon';
import UploadComponent from '../../../../components/UploadComponent';
import Listing, { Hour, WebsiteForm } from '../../../../interfaces/Listing';
import { SubCategory } from '../../../../interfaces/Meta';
import ServiceData, {
  HoursData,
  HoursEntry,
} from '../../../../interfaces/ServiceData';
import { uploadImage } from '../../../../lib/api/custom';
import useForm from '../../../../lib/hooks/useForm';
import useIsDesktop from '../../../../lib/hooks/useIsDesktop';
import usePrevious from '../../../../lib/hooks/usePrevious';
import routePaths from '../../../../lib/routePaths';
import { getTranslatedString } from '../../../../lib/utils';
import { useStoreActions, useStoreState } from '../../../../store';
import useAppStyles from '../../../styles';
import useOnboardingStyles from '../../Onboarding/styles';
import useProvidersStyles from '../../styles';
// @ts-ignore
import OperatingHours from '../OperatingHours';
import useStyles from './styles';

const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/bmp',
];

const ServiceSchemaEs = yup.object().shape({
  serviceNameEs: yup.string().trim().required('Service Name is required.'),
  descriptionEs: yup.string().trim().required('Description is required.'),
});

const ServiceSchema = yup.object().shape({
  serviceName: yup.string().trim().required('Service Name is required.'),
  mainCategory: yup.string().trim().required('Main Category Name is required.'),
  subCategory: yup.string().trim(),
  description: yup.string().trim().required('Description is required.'),
  address: yup.string().trim().required('Address is required.'),
  cityId: yup
    .number()
    .min(1, 'City is required.')
    .required('City is required.'),
  zip: yup.string().trim().required('Zip code is required.'),
  phoneNumber: yup.string().trim().required('Phone Number is required.'),
  ages: yup.string().trim().required('Ages Served is required'),
  languages: yup.string().trim().required('Languages Spoken is required'),
  doNotProvideEmail: yup.boolean(),
  email: yup
    .string()
    .when('doNotProvideEmail', {
      is: (doNotProvideEmail) => doNotProvideEmail === false,
      then: yup.string().trim().email('Invalid Email').required('Email is required.'),
      otherwise: yup.string().trim().email('Invalid Email.'),
    }),
  websites: yup
    .array()
    .of(yup.object()
      .shape({
        name: yup
          .string()
          .trim()
          .required('Text to Display is required'),
        url: yup
          .string()
          .trim()
          .url('Invalid URL, Please format http://www.yourwebsite.com')
          .required('Service Website URL is required'),
      })),
});

const positiveButtonTexts = ['OK', 'OK', 'Delete Service'];
const negativeButtonTexts = ['', '', 'Cancel'];
const popupTexts = [
  'Your listing has been saved.',
  'Your listing has been submitted and is in review.',
  'Are you sure you want to delete this listing permanently?',
];

const ServiceListing: React.FC = () => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [formJustSubmitted, setFormJustSubmitted] = useState(false);
  const [popupIndex, setPopupIndex] = useState<number>(-1);
  const appClasses = useAppStyles();
  const onboardingClasses = useOnboardingStyles();
  const providersClasses = useProvidersStyles();
  const classes = useStyles();
  const history = useHistory();
  const locationState = useLocation();
  const previousLocationState = usePrevious(location);
  const isDesktop = useIsDesktop();
  /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
  const params = useParams() as { listingId: string };
  const { language } = useStoreState(state => state.translations);

  const topDivEl = useRef() as MutableRefObject<HTMLDivElement>;
  const serviceNameEl = useRef() as MutableRefObject<HTMLIonItemElement>;
  const mainCategoryEl = useRef() as MutableRefObject<HTMLIonSelectElement>;
  const subcategoryEl = useRef() as MutableRefObject<HTMLIonSelectElement>;
  const descriptionEl = useRef() as MutableRefObject<HTMLIonItemElement>;
  const addressEl = useRef() as MutableRefObject<HTMLIonItemElement>;
  const phoneNumberEl = useRef() as MutableRefObject<HTMLIonItemElement>;
  const emailEl = useRef() as MutableRefObject<HTMLIonItemElement>;
  const websiteEl = useRef() as MutableRefObject<HTMLIonItemElement>;
  const agesServedEl = useRef() as MutableRefObject<HTMLIonSelectElement>;
  const languagesSpokenEl = useRef() as MutableRefObject<HTMLIonSelectElement>;
  const formEl = useRef() as MutableRefObject<HTMLFormElement>;
  const overviewSectionEl = useRef() as MutableRefObject<HTMLIonItemElement>;
  const contactSectionEl = useRef() as MutableRefObject<HTMLIonItemElement>;
  const populationsSectionEl = useRef() as MutableRefObject<HTMLIonItemElement>;
  const hoursSectionEl = useRef() as MutableRefObject<HTMLIonItemElement>;

  const categoryAlertOptions = {
    header: getTranslatedString('select-categories', language),
  };
  const subcategoryAlertOptions = {
    header: getTranslatedString('select-subcategories', language),
  };
  const agesAlertOptions = {
    header: getTranslatedString('select-age-groups', language),
  };
  const groupsAlertOptions = {
    header: getTranslatedString('select-groups', language),
  };
  const languagesAlertOptions = {
    header: getTranslatedString('select-languages', language),
  };
  const cityAlertOptions = {
    header: getTranslatedString('select-city', language),
  };
  const insurancesAlertOptions = {
    header: getTranslatedString('select-accepted-insurances', language),
  };

  const executeScroll = (
    ref: MutableRefObject<
    HTMLIonItemElement | HTMLIonSelectElement | HTMLFormElement
    >,
  ): void => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const [overviewOpen, setOverviewOpen] = useState<boolean>(true);
  const [contactSectionOpen, setContactSectionOpen] = useState<boolean>(false);
  const [populationsSectionOpen, setPopulationsSectionOpen] = useState<boolean>(false);
  const [hoursSectionOpen, setHoursSectionOpen] = useState<boolean>(false);
  const [hours, setHours] = useState<HoursData | null>(null);
  const [currentImageUrl, setCurrentImageUrl] = useState<string | null>(null);
  const [listingToEdit, setListingToEdit] = useState<Listing | null>(null);

  const {
    listingsApi: { error, success },
    meta: { data },
  } = useStoreState((state) => ({
    listingsApi: state.serviceListings,
    meta: state.meta,
  }));
  const {
    create,
    updateListing,
    saveAndSubmit,
    setSuccess,
    remove,
    setLoading,
    setError,
  } = useStoreActions((actions) => actions.serviceListings);
  const setCurrentStep = useStoreActions(
    (actions) => actions.createOrg.setCurrentStep,
  );
  const { currentProvider } = useStoreState((state) => state.provider);

  const deserializeHours = (hoursIn: Hour[]): HoursData | null => {
    const sundayTimes: HoursEntry[] = [];
    let sunday24 = false;
    const mondayTimes: HoursEntry[] = [];
    let monday24 = false;
    const tuesdayTimes: HoursEntry[] = [];
    let tuesday24 = false;
    const wednesdayTimes: HoursEntry[] = [];
    let wednesday24 = false;
    const thursdayTimes: HoursEntry[] = [];
    let thursday24 = false;
    const fridayTimes: HoursEntry[] = [];
    let friday24 = false;
    const saturdayTimes: HoursEntry[] = [];
    let saturday24 = false;
    hoursIn.forEach((hourIn: Hour) => {
      if (!hourIn.is_closed) {
        if (hourIn.day_of_week === 0) {
          if (hourIn.open_24_hours) {
            sunday24 = true;
          } else if (hourIn.open.length > 0 && hourIn.close.length > 0) {
            sundayTimes.push({
              openTime: new Date(`1970/01/01 ${hourIn.open}`),
              closeTime: new Date(`1970/01/01 ${hourIn.close}`),
            });
          }
        } else if (hourIn.day_of_week === 1) {
          if (hourIn.open_24_hours) {
            monday24 = true;
          } else if (hourIn.open.length > 0 && hourIn.close.length > 0) {
            mondayTimes.push({
              openTime: new Date(`1970/01/01 ${hourIn.open}`),
              closeTime: new Date(`1970/01/01 ${hourIn.close}`),
            });
          }
        } else if (hourIn.day_of_week === 2) {
          if (hourIn.open_24_hours) {
            tuesday24 = true;
          } else if (hourIn.open.length > 0 && hourIn.close.length > 0) {
            tuesdayTimes.push({
              openTime: new Date(`1970/01/01 ${hourIn.open}`),
              closeTime: new Date(`1970/01/01 ${hourIn.close}`),
            });
          }
        } else if (hourIn.day_of_week === 3) {
          if (hourIn.open_24_hours) {
            wednesday24 = true;
          } else if (hourIn.open.length > 0 && hourIn.close.length > 0) {
            wednesdayTimes.push({
              openTime: new Date(`1970/01/01 ${hourIn.open}`),
              closeTime: new Date(`1970/01/01 ${hourIn.close}`),
            });
          }
        } else if (hourIn.day_of_week === 4) {
          if (hourIn.open_24_hours) {
            thursday24 = true;
          } else if (hourIn.open.length > 0 && hourIn.close.length > 0) {
            thursdayTimes.push({
              openTime: new Date(`1970/01/01 ${hourIn.open}`),
              closeTime: new Date(`1970/01/01 ${hourIn.close}`),
            });
          }
        } else if (hourIn.day_of_week === 5) {
          if (hourIn.open_24_hours) {
            friday24 = true;
          } else if (hourIn.open.length > 0 && hourIn.close.length > 0) {
            fridayTimes.push({
              openTime: new Date(`1970/01/01 ${hourIn.open}`),
              closeTime: new Date(`1970/01/01 ${hourIn.close}`),
            });
          }
        } else if (hourIn.day_of_week === 6) {
          if (hourIn.open_24_hours) {
            saturday24 = true;
          } else if (hourIn.open.length > 0 && hourIn.close.length > 0) {
            saturdayTimes.push({
              openTime: new Date(`1970/01/01 ${hourIn.open}`),
              closeTime: new Date(`1970/01/01 ${hourIn.close}`),
            });
          }
        }
      }
    });

    const all24 = sunday24
      && monday24
      && tuesday24
      && wednesday24
      && thursday24
      && friday24
      && saturday24;
    const hoursData: HoursData = {
      alwaysOpen: all24,
      sun24: sunday24,
      sunTimes: sundayTimes,
      mon24: monday24,
      monTimes: mondayTimes,
      tue24: tuesday24,
      tueTimes: tuesdayTimes,
      wed24: wednesday24,
      wedTimes: wednesdayTimes,
      thu24: thursday24,
      thuTimes: thursdayTimes,
      fri24: friday24,
      friTimes: fridayTimes,
      sat24: saturday24,
      satTimes: saturdayTimes,
    };
    return hoursData;
  };

  const [showSpanishVersion, setShowSpanishVersion] = useState<boolean>(false);
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState<number[]>(
    [],
  );
  const [deleteListing, setDeleteListing] = useState<boolean>(false);
  const [avaialableSubcategories, setAvailableSubcategories] = useState<
  SubCategory[]
  >([]);

  const {
    errors,
    values,
    onChange,
    handleSubmit,
    setValues,
    setSchema,
    reset,
    resetCache,
    isCacheEmpty,
  } = useForm<ServiceData>({
    schema: showSpanishVersion ? ServiceSchemaEs : ServiceSchema,
    initial: {
      ...{
        serviceName: listingToEdit?.name ?? '',
        serviceNameEs: listingToEdit?.translation?.es?.name ?? '',
        mainCategory:
          listingToEdit?.categories?.map((category) => category.id) ?? [],
        subCategory:
          listingToEdit?.sub_categories?.map((subcategory) => subcategory.id)
          ?? [],
        address: listingToEdit?.address?.street_address_1 ?? '',
        cityId: listingToEdit?.address?.city_id ?? 0,
        zip: listingToEdit?.address?.zip_code ?? '',
        doNotProvideEmail: listingToEdit?.do_not_provide_email ?? false,
        description: listingToEdit?.description ?? '',
        descriptionEs: listingToEdit?.translation?.es?.description ?? '',
        phoneNumber: listingToEdit?.phone_number ?? '',
        email: listingToEdit?.email ?? '',
        ages: listingToEdit?.age_ranges?.map((ageRange) => ageRange.id) ?? [],
        groups: listingToEdit?.groups?.map((group) => group.id) ?? [],
        languages:
          listingToEdit?.languages?.map((languageIn) => languageIn.id) ?? [],
        documentation: listingToEdit?.documentation_required ?? '',
        documentationEs:
          listingToEdit?.translation?.es?.documentation_required ?? '',
        insurance: listingToEdit?.insurance_required ?? false,
        insurances: listingToEdit?.insurance?.map((ins) => ins.id) ?? [],
        hours: listingToEdit?.hours
          ? deserializeHours(listingToEdit.hours)
          : null,
        auto_translate: false,
        remove_translations: false,
        images: listingToEdit?.images ?? [],
        websites: listingToEdit?.websites.map<WebsiteForm>(w => {
          const deserializedWebsite: WebsiteForm = { id: w.id, name: w.name, url: w.url };
          if (w.translation) {
            deserializedWebsite.name_es = w.translation?.es?.name;
          }
          return deserializedWebsite;
        }) ?? [{ name: '', url: '' }],
      },
    },
  });

  const toggleLanguageDisplay = useCallback(
    (showSpanish: boolean): void => {
      setSchema(showSpanish ? ServiceSchemaEs : ServiceSchema);
      setShowSpanishVersion(showSpanish);
      reset();
    },
    [reset, setSchema],
  );

  const updateListingToEdit = useCallback(
    (newListing: Listing | null): void => {
      setListingToEdit(newListing);
      resetCache();
    }, [resetCache],
  );

  const getEditServiceUrl = (listingId: number): string => (locationState.pathname.startsWith(routePaths.admin.root)
    ? `${routePaths.admin.editService}/${listingId}`
    : locationState.pathname.startsWith(routePaths.provider.onboarding.root)
      ? `${routePaths.provider.onboarding.editService}/${listingId}`
      : `${routePaths.provider.approved.editService}/${listingId}`);

  const getRootUrl = useCallback(
    (): string => (locationState.pathname.startsWith(routePaths.admin.root)
      ? routePaths.admin.root
      : locationState.pathname.startsWith(routePaths.provider.onboarding.root)
        ? routePaths.provider.onboarding.root
        : routePaths.provider.approved.root),
    [locationState.pathname],
  );

  useEffect(() => {
    if (locationState !== previousLocationState) {
      if (listingToEdit) {
        const { images } = listingToEdit;
        if (!images?.length) {
          setCurrentImageUrl(null);
        }
      } else {
        setCurrentImageUrl(null);
      }
    }
  }, [locationState, previousLocationState, listingToEdit]);

  useEffect(() => {
    const query = new URLSearchParams(decodeURI(locationState.search));
    toggleLanguageDisplay(query.get('spanish') === 'true');
    topDivEl.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
  }, [
    locationState.search,
    params.listingId,
    setSchema,
    toggleLanguageDisplay,
    topDivEl,
  ]);

  useEffect(() => {
    setOverviewOpen(true);
    setContactSectionOpen(false);
    setPopulationsSectionOpen(false);
    setHoursSectionOpen(false);
  }, [params]);

  useEffect(() => {
    if ((params?.listingId?.length ?? 0) > 0) {
      let listingId = Number(params.listingId);
      if (listingId === 0 && (currentProvider?.listings?.length ?? 0) > 0) {
        listingId = currentProvider?.listings?.[0].id ?? 0;
      }
      const listing = currentProvider?.listings?.find((t) => t.id === listingId) ?? null;
      if (listing !== listingToEdit) {
        updateListingToEdit(listing);
      }
    } else if (listingToEdit) {
      updateListingToEdit(null);
      reset();
    }
  }, [currentProvider, params, listingToEdit, reset, updateListingToEdit]);

  useEffect(() => {
    if (isCacheEmpty()) {
      setValues({
        serviceName: listingToEdit?.name ?? '',
        serviceNameEs: listingToEdit?.translation?.es?.name ?? '',
        mainCategory:
          listingToEdit?.categories?.map((category) => category.id) ?? [],
        subCategory:
          listingToEdit?.sub_categories?.map((subcategory) => subcategory.id)
          ?? [],
        address: listingToEdit?.address?.street_address_1 ?? '',
        cityId: listingToEdit?.address?.city_id ?? 0,
        zip: listingToEdit?.address?.zip_code ?? '',
        description: listingToEdit?.description ?? '',
        descriptionEs: listingToEdit?.translation?.es?.description ?? '',
        doNotProvideEmail: listingToEdit?.do_not_provide_email ?? false,
        phoneNumber: listingToEdit?.phone_number ?? '',
        email: listingToEdit?.email ?? '',
        ages: listingToEdit?.age_ranges?.map((ageRange) => ageRange.id) ?? [],
        groups: listingToEdit?.groups?.map((group) => group.id) ?? [],
        languages: listingToEdit?.languages?.map((languageIn) => languageIn.id) ?? [],
        documentation: listingToEdit?.documentation_required ?? '',
        documentationEs:
          listingToEdit?.translation?.es?.documentation_required ?? '',
        insurance: listingToEdit?.insurance_required ?? false,
        insurances: listingToEdit?.insurance?.map((ins) => ins.id) ?? [],
        hours: listingToEdit?.hours
          ? deserializeHours(listingToEdit.hours)
          : null,
        auto_translate: false,
        remove_translations: false,
        images: listingToEdit?.images ?? [],
        websites: listingToEdit?.websites.map<WebsiteForm>(w => {
          const deserializedWebsite: WebsiteForm = { id: w.id, name: w.name, url: w.url };
          if (w.translation) {
            deserializedWebsite.name_es = w.translation?.es?.name;
          }
          return deserializedWebsite;
        }) ?? [{ name: '', url: '' }],
      });
    }
    setSelectedCategoriesIds(
      listingToEdit?.categories?.map((category) => category.id) ?? [],
    );
    if (listingToEdit && listingToEdit?.images) {
      const img = listingToEdit?.images[0];
      if (img) {
        const { images } = img;
        if (images) {
          const url = `${images['image-1x']} 1x, ${images['image-2x']} 2x, ${images['image-3x']} 3x, ${images['image-4x']} 4x`;
          setCurrentImageUrl(url);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingToEdit, setValues, setSelectedCategoriesIds]);

  useEffect(() => {
    const subcats: SubCategory[] = [];
    selectedCategoriesIds.forEach((categoryId: number) => {
      const category = data?.category.find((t) => t.id === categoryId) ?? null;
      if (category != null) {
        category.sub_categories.forEach((subcategory: SubCategory) => {
          subcats.push(subcategory);
        });
      }
    });

    setAvailableSubcategories(
      subcats.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) === i).sort((a, b) => ((a.name > b.name) ? 1 : -1)),
    );
  }, [selectedCategoriesIds, data]);

  useEffect(() => {
    setCurrentStep(3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (success && deleteListing) {
      setDeleteListing(false);
      setSuccess(false);
      history.push(getRootUrl());
      topDivEl.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
    }
  }, [success, deleteListing, history, getRootUrl, setSuccess, topDivEl]);

  useEffect(() => {
    const websiteErrors = !!Object.keys(errors).find((key) => key.startsWith('websites'));
    if (errors.serviceName) {
      if (formJustSubmitted) {
        setOverviewOpen(true);
        executeScroll(serviceNameEl);
        setFormJustSubmitted(false);
      }
    } else if (errors.mainCategory) {
      if (formJustSubmitted) {
        setOverviewOpen(true);
        executeScroll(mainCategoryEl);
        setFormJustSubmitted(false);
      }
    } else if (errors.subCategory) {
      if (formJustSubmitted) {
        setOverviewOpen(true);
        executeScroll(subcategoryEl);
        setFormJustSubmitted(false);
      }
    } else if (errors.description) {
      if (formJustSubmitted) {
        setOverviewOpen(true);
        executeScroll(descriptionEl);
        setFormJustSubmitted(false);
      }
    } else if (errors.address || errors.cityId || errors.zip) {
      if (formJustSubmitted) {
        setContactSectionOpen(true);
        executeScroll(addressEl);
        setFormJustSubmitted(false);
      }
    } else if (errors.phoneNumber) {
      if (formJustSubmitted) {
        setContactSectionOpen(true);
        executeScroll(phoneNumberEl);
        setFormJustSubmitted(false);
      }
    } else if (errors.email) {
      if (formJustSubmitted) {
        setContactSectionOpen(true);
        executeScroll(emailEl);
        setFormJustSubmitted(false);
      }
    } else if (websiteErrors) {
      if (formJustSubmitted) {
        setContactSectionOpen(true);
        executeScroll(websiteEl);
        setFormJustSubmitted(false);
      }
    } else if (errors.ages) {
      if (formJustSubmitted) {
        setPopulationsSectionOpen(true);
        executeScroll(agesServedEl);
        setFormJustSubmitted(false);
      }
    } else if (errors.languages) {
      if (formJustSubmitted) {
        setPopulationsSectionOpen(true);
        executeScroll(languagesSpokenEl);
        setFormJustSubmitted(false);
      }
    }
  }, [
    errors,
    formJustSubmitted,
    serviceNameEl,
    mainCategoryEl,
    subcategoryEl,
    descriptionEl,
    phoneNumberEl,
    addressEl,
    emailEl,
    websiteEl,
    agesServedEl,
    languagesSpokenEl,
  ]);

  const saveImage = async (formData: ServiceData): Promise<void> => {
    if (imageFile) {
      setLoading(true);
      const imageUploadResponse = await uploadImage(imageFile);
      if (imageUploadResponse) {
        formData.images = [{ id: imageUploadResponse.id }];
      } else {
        setLoading(false);
        setError('An error occured while uploading the image');
        executeScroll(formEl);
      }
    } else {
      formData.images = listingToEdit?.images ?? [];
    }
  };

  const routeOnSuccess = (listingId: number, routeToSpanish?: boolean): void => {
    const editServiceUrl = getEditServiceUrl(listingId);
    if (routeToSpanish) {
      history.push(`${editServiceUrl}?spanish=true`);
    } else if (editServiceUrl.startsWith(routePaths.provider.approved.root)) {
      if (values.remove_translations) {
        history.push(`${editServiceUrl}`);
      } else if (isDesktop) {
        // Not translating, and an approved provider - just scroll to the top.
        topDivEl.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
      } else {
        // Go back to the provider dashboard on mobile.
        history.push(routePaths.provider.approved.root);
      }
    } else if (editServiceUrl.startsWith(routePaths.provider.onboarding.root)) {
      // Not translating, and an onboarding provider - refresh editService screen if we have a listing ID (for the case where an onboarding user discarded their Spanish version), or the submit screen if we are not provided a listing ID.
      // if (listingId > 0) {
      //   history.push(`${editServiceUrl}`);
      // } else {
      history.push(routePaths.provider.onboarding.submit);
      // }
    } else {
      // We are an admin, route to dashboard.
      history.push(routePaths.admin.dashboard);
    }
  };

  const createServiceListing = (popupIndexOnSuccess: number, saveDraft: boolean) => async (
    formData: ServiceData,
  ): Promise<void> => {
    await saveImage(formData);
    formData.hours = hours;
    formData.auto_translate = !saveDraft && (listingToEdit?.translation?.es?.name?.length ?? 0) === 0;
    const newListing = await create(formData);
    if (newListing) {
      updateListingToEdit(newListing);
      if (popupIndexOnSuccess >= 0) {
        setPopupIndex(popupIndexOnSuccess);
      } else {
        routeOnSuccess(newListing?.id, formData.auto_translate);
      }
    }
  };

  const updateServiceListing = (popupIndexOnSuccess: number, saveDraft: boolean) => async (
    formData: ServiceData,
  ): Promise<void> => {
    await saveImage(formData);
    formData.hours = hours;
    formData.listingId = listingToEdit?.id;
    formData.auto_translate = !saveDraft && (listingToEdit?.translation?.es?.name?.length ?? 0) === 0;
    const updatedListing = await updateListing(formData);
    if (updatedListing) {
      updateListingToEdit(updatedListing);
      // If there are no translations, make sure we are displaying the English version.
      if ((updatedListing?.translation?.es?.name?.length ?? 0) === 0) {
        toggleLanguageDisplay(false);
      }
      if (popupIndexOnSuccess >= 0) {
        setPopupIndex(popupIndexOnSuccess);
      } else {
        routeOnSuccess(updatedListing?.id, formData.auto_translate);
      }
    }
  };

  const saveAndSubmitServiceListing = (popupIndexOnSuccess: number) => async (
    formData: ServiceData,
  ): Promise<void> => {
    await saveImage(formData);
    formData.hours = hours;
    formData.listingId = listingToEdit?.id;
    const submittedListing = await saveAndSubmit(formData);
    if (submittedListing) {
      updateListingToEdit(submittedListing);
      if (popupIndexOnSuccess >= 0) {
        setPopupIndex(popupIndexOnSuccess);
      }
    }
  };

  const categoriesSelected = (categoryIds: number[]): void => {
    values.mainCategory = categoryIds;
    setSelectedCategoriesIds(categoryIds);
  };

  const popupDismissHandler = (): void => {
    if (popupIndex === 2) {
      // Delete listing
      remove(listingToEdit?.id ?? 0);
    }
  };

  const handleTooltip = (isOpen: boolean) => {
    setTooltipOpen(isOpen);
  };

  return !data ? (
    <h3>Loading...</h3>
  ) : (
    <>
      <div
        ref={topDivEl}
        className={[appClasses.spaceBetweenRow, providersClasses.main].join(
          ' ',
        )}
      >
        <h3 className={appClasses.title}>
          {(params?.listingId?.length ?? 0) > 0
          || locationState.pathname.startsWith(routePaths.admin.editService)
            ? <FormattedMessage id="service-listing" />
            : <FormattedMessage id="create-service-listing" />}
        </h3>
        {(listingToEdit?.translation?.es?.name.length ?? 0) > 0 ? (
          <IonButton
            className={isDesktop ? '' : 'ion-margin-top'}
            shape="round"
            fill="outline"
            color="primary"
            onClick={(): void => {
              if (listingToEdit?.id) {
                const editServiceUrl = getEditServiceUrl(listingToEdit?.id);
                if (showSpanishVersion) {
                  history.push(editServiceUrl);
                } else {
                  history.push(`${editServiceUrl}?spanish=true`);
                }
                toggleLanguageDisplay(!showSpanishVersion);
              }
            }}
          >
            {showSpanishVersion ? 'English' : 'Spanish'}
          </IonButton>
        ) : null}
      </div>
      <div>
        <form ref={formEl} className={providersClasses.main}>
          {error && (
            <IonCard color="danger">
              <IonCardHeader>
                <IonCardTitle>{error}</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          )}
          <IonList
            lines="none"
            className={[
              onboardingClasses.inputsList,
              'ion-no-margin ion-no-padding',
            ].join(' ')}
          >
            <h4 className={appClasses.subtitle}>
              {showSpanishVersion
                ? <FormattedMessage id="service-listing-entering-subtitle-spanish" />
                : <FormattedMessage id="service-listing-entering-subtitle-english" />}
            </h4>
            {!showSpanishVersion && (
            <IonItem
              ref={overviewSectionEl}
              className={[
                classes.sectionHeader,
                overviewOpen ? 'isOpen' : '',
              ].join(' ')}
              onClick={(): void => {
                setOverviewOpen(!overviewOpen);
              }}
            >
              <IonLabel
                color={overviewOpen ? 'light' : 'primary'}
              >
                <FormattedMessage id="service-overview" />
              </IonLabel>
              <IonIcon
                className={classes.inputIcon}
                size="large"
                color={overviewOpen ? 'light' : 'primary'}
                icon={overviewOpen ? caretUp : caretDown}
              />
            </IonItem>
            )}
            <div className={[
              !showSpanishVersion ? classes.sectionContent : '',
              overviewOpen ? 'isOpen' : '',
            ].join(' ')}
            >
              {!showSpanishVersion && (
              <>
                <h3 className={[appClasses.subtitleBold, 'ion-no-margin mt-16'].join(' ')}>
                  <FormattedMessage id="image-camel" />
                </h3>
                <h4
                  className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                  style={{ color: '#000' }}
                >
                  <FormattedMessage id="image-max-size" /> <br />
                  <FormattedMessage id="image-supported-files" />
                </h4>
                <UploadComponent
                  name="image"
                  accept={SUPPORTED_FORMATS}
                  onChange={(f): void => setImageFile(f)}
                  url={currentImageUrl}
                />
              </>
              )}
              <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                <FormattedMessage id="service-name" />
              </h3>
              <h4
                className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                style={{ color: '#000' }}
              >
                <FormattedMessage id="service-name-label" />
              </h4>
              <IonItem
                ref={serviceNameEl}
                className={[
                  onboardingClasses.input,
                ].join(' ')}
              >
                <IonInput
                  value={
                  showSpanishVersion ? values.serviceNameEs : values.serviceName
                }
                  name={showSpanishVersion ? 'serviceNameEs' : 'serviceName'}
                  type="text"
                  onIonChange={onChange}
                />
              </IonItem>
              {(errors.serviceName || errors.serviceNameEs) && (
              <p className="error-message">
                <IonText color="danger">
                  {errors.serviceName || errors.serviceNameEs}
                </IonText>
              </p>
              )}
              <div hidden={showSpanishVersion}>
                <div className={appClasses.whitebg}>
                  <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                    <FormattedMessage id="category-subcategory-header" />
                  </h3>
                  <h4
                    className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                    style={{ color: '#000' }}
                  >
                    <FormattedMessage id="category-subcategory-subtext" />
                  </h4>
                  <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                    <FormattedMessage id="category-camel" />
                  </h3>
                  <IonSelect
                    ref={mainCategoryEl}
                    className={[
                      'cc-select mt-5',
                    ].join(' ')}
                    value={values.mainCategory}
                    interfaceOptions={categoryAlertOptions}
                    multiple
                    title="Select Categories"
                    onIonChange={(e): void => {
                      categoriesSelected(e.detail.value);
                    }}
                  >
                    {data.category.map((category) => (
                      <IonSelectOption key={category.id} value={category.id}>
                        {category.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </div>
                {errors.mainCategory && (
                <p className="error-message">
                  <IonText color="danger">{errors.mainCategory}</IonText>
                </p>
                )}
                <div className={appClasses.whitebg}>
                  <h3 className={[appClasses.subtitleBold, 'ion-no-margin mt-16'].join(' ')}>
                    <FormattedMessage id="sub-category" />
                  </h3>
                  <IonSelect
                    ref={subcategoryEl}
                    className={[
                      'cc-select mt-5',
                    ].join(' ')}
                    value={values.subCategory}
                    multiple
                    interfaceOptions={subcategoryAlertOptions}
                    onIonChange={(e): void => {
                      values.subCategory = e.detail.value;
                    }}
                  >
                    {avaialableSubcategories.map((subcategory) => (
                      <IonSelectOption
                        key={subcategory.id}
                        value={subcategory.id}
                      >
                        {subcategory.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </div>
                {errors.subCategory && (
                <p className="error-message">
                  <IonText color="danger">{errors.subCategory}</IonText>
                </p>
                )}
              </div>
              <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                <FormattedMessage id="service-description" />
              </h3>
              <h4
                className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                style={{ color: '#000' }}
              >
                <FormattedMessage id="service-description-subtext" />
              </h4>
              <IonItem
                ref={descriptionEl}
                className={[
                  onboardingClasses.tallInput,
                ].join(' ')}
              >
                <IonTextarea
                  color="primary"
                  className={[
                    classes.description,
                  ].join(' ')}
                  maxlength={showSpanishVersion ? 500 : 400}
                  value={
                  showSpanishVersion ? values.descriptionEs : values.description
                }
                  name={showSpanishVersion ? 'descriptionEs' : 'description'}
                  onIonChange={onChange}
                />
              </IonItem>
              {(errors.description || errors.descriptionEs) && (
              <p className="error-message">
                <IonText color="danger">
                  {errors.description || errors.descriptionEs}
                </IonText>
              </p>
              )}

              <div className={classes.descriptionCharacterCount}>
                <div className={classes.characteCountText}>
                  {showSpanishVersion ? '500' : '400'} character limit
                </div>
                <div className={classes.characterCountValue}>
                  {showSpanishVersion ? values.descriptionEs.length : values.description.length}/{showSpanishVersion ? '500' : '400'}
                </div>
              </div>
              {!showSpanishVersion && (
              <div className={classes.nextButtonContainer}>
                <IonButton
                  className="ion-margin-top"
                  shape="round"
                  fill="solid"
                  color="primary"
                  onClick={(): void => {
                    setOverviewOpen(false);
                    setContactSectionOpen(true);
                    setTimeout(() => {
                      contactSectionEl.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
                    }, 350);
                  }}
                >
                  <FormattedMessage id="next" />
                </IonButton>
              </div>
              )}
            </div>
            <div>
              {!showSpanishVersion && (
                <IonItem
                  ref={contactSectionEl}
                  className={[
                    classes.sectionHeader,
                    contactSectionOpen ? 'isOpen' : '',
                  ].join(' ')}
                  onClick={(): void => {
                    setContactSectionOpen(!contactSectionOpen);
                  }}
                >
                  <IonLabel
                    color={contactSectionOpen ? 'light' : 'primary'}
                  >
                    <FormattedMessage id="service-contact-information" />
                  </IonLabel>
                  <IonIcon
                    className={classes.inputIcon}
                    size="large"
                    color={contactSectionOpen ? 'light' : 'primary'}
                    icon={contactSectionOpen ? caretUp : caretDown}
                  />
                </IonItem>
              )}
              <div className={[
                !showSpanishVersion ? classes.sectionContent : '',
                contactSectionOpen ? 'isOpen' : '',
              ].join(' ')}
              >
                <div hidden={showSpanishVersion}>
                  <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                    <FormattedMessage id="service-address" />
                  </h3>
                  <h4
                    className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                    style={{ color: '#000' }}
                  >
                    <FormattedMessage id="service-address-subtext" />
                  </h4>
                  <IonGrid className={classes.iconInputGrid}>
                    <IonRow>
                      <IonCol
                        sizeXs="12"
                        sizeSm="6"
                        className={[classes.iconInputColumn, 'interiorItem'].join(
                          ' ',
                        )}
                      >
                        <div className={classes.iconInputRow}>
                          <IonIcon
                            className={classes.inputIcon}
                            size="large"
                            color="primary"
                            icon={location}
                          />
                          <IonItem
                            ref={addressEl}
                            className={[
                              onboardingClasses.input,
                              classes.input,
                            ].join(' ')}
                          >
                            <IonInput
                              value={values.address}
                              name="address"
                              onIonChange={onChange}
                              placeholder="Address"
                            />
                          </IonItem>
                        </div>
                        {errors.address && (
                        <p className="error-message">
                          <IonText color="danger">{errors.address}</IonText>
                        </p>
                        )}
                      </IonCol>
                      <IonCol
                        sizeXs="12"
                        sizeSm="6"
                        className={[classes.iconInputColumn, 'exteriorItem'].join(
                          ' ',
                        )}
                      >
                        <div
                          className={[
                            classes.iconInputRow,
                            appClasses.whitebg,
                          ].join(' ')}
                        >
                          <IonSelect
                            className="cc-select"
                            placeholder="Select City"
                            value={values.cityId}
                            interface="alert"
                            interfaceOptions={cityAlertOptions}
                            onIonChange={(e): void => {
                              values.cityId = e.detail.value;
                            }}
                          >
                            {data.city.map((city) => (
                              <IonSelectOption key={city.id} value={city.id}>
                                {city.name}
                              </IonSelectOption>
                            ))}
                          </IonSelect>
                        </div>
                        {!!errors.cityId && (
                        <p className="error-message">
                          <IonText color="danger">{errors.cityId}</IonText>
                        </p>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <IonGrid className={classes.iconInputGrid}>
                    <IonRow>
                      <IonCol
                        sizeXs="12"
                        sizeSm="6"
                        className={[classes.iconInputColumn, 'interiorItem'].join(
                          ' ',
                        )}
                      >
                        <div className={classes.iconInputRow}>
                          <div className={classes.dummyInputIcon} />
                          <IonItem
                            className={[
                              onboardingClasses.input,
                              classes.input,
                            ].join(' ')}
                          >
                            <IonInput
                              value="Florida*"
                              placeholder="State"
                              name="location"
                              disabled
                            />
                          </IonItem>
                        </div>
                      </IonCol>

                      <IonCol
                        sizeXs="12"
                        sizeSm="6"
                        className={[classes.iconInputColumn, 'exteriorItem'].join(
                          ' ',
                        )}
                      >
                        <div className={classes.iconInputRow}>
                          <IonItem
                            className={[
                              onboardingClasses.input,
                              classes.input,
                            ].join(' ')}
                          >
                            <IonInput
                              value={values.zip}
                              name="zip"
                              onIonChange={onChange}
                              placeholder="Zip Code"
                            />
                          </IonItem>
                        </div>
                        {errors.zip && (
                        <p className="error-message">
                          <IonText color="danger">{errors.zip}</IonText>
                        </p>
                        )}
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                    <FormattedMessage id="service-phone-number" />
                  </h3>
                  <h4
                    className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                    style={{ color: '#000' }}
                  >
                    <FormattedMessage id="service-phone-number-subtext" />
                  </h4>
                  <div
                    className={[classes.iconInputRow, classes.iconInputGrid].join(
                      ' ',
                    )}
                  >
                    <IonIcon
                      className={classes.inputIcon}
                      size="large"
                      color="primary"
                      icon={call}
                    />
                    <IonItem
                      ref={phoneNumberEl}
                      className={[
                        onboardingClasses.input,
                        classes.input,
                      ].join(' ')}
                    >
                      <IonInput
                        value={values.phoneNumber}
                        name="phoneNumber"
                        onIonChange={onChange}
                        placeholder="Phone Number*"
                      />
                    </IonItem>
                  </div>
                  {errors.phoneNumber && (
                  <p className="error-message">
                    <IonText color="danger">{errors.phoneNumber}</IonText>
                  </p>
                  )}

                  <h3 className={[appClasses.subtitleBold, 'ion-no-margin mt-16'].join(' ')}>
                    <FormattedMessage id="service-email" />
                  </h3>
                  <h4
                    className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                    style={{ color: '#000' }}
                  >
                    <FormattedMessage id="service-email-subtext" />
                  </h4>

                  <IonItem
                    className="ion-padding-left"
                  >
                    <IonToggle
                      name="doNotProvideEmail"
                      className="ion-no-padding"
                      mode="ios"
                      checked={values.doNotProvideEmail}
                      color="primary"
                      onIonChange={(e) => onChange({ target: { name: 'doNotProvideEmail', value: e.detail.checked } })}
                    />
                    <IonLabel className={`${classes.labelWrapped} ion-margin ion-no-padding`} color="primary">
                      <FormattedMessage id="service-listing-email-address-availibility" />
                      {isDesktop && (
                        <Tooltip title={<FormattedMessage id="tooltip-listing-email-address" />}>
                          <IconButton>
                            <Icon name="help" width={20} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {!isDesktop && (
                        <ClickAwayListener onClickAway={() => handleTooltip(false)}>
                          <Tooltip open={tooltipOpen} onClose={() => handleTooltip(false)} disableFocusListener disableHoverListener disableTouchListener title={<FormattedMessage id="tooltip-listing-email-address" />}>
                            <IconButton onClick={() => handleTooltip(!tooltipOpen)}>
                              <Icon name="help" width={20} />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                      )}
                    </IonLabel>
                  </IonItem>

                  <div
                    className={[classes.iconInputRow, classes.iconInputGrid].join(
                      ' ',
                    )}
                  >
                    <IonIcon
                      className={classes.inputIcon}
                      size="large"
                      color="primary"
                      icon={mail}
                    />
                    <IonItem
                      ref={emailEl}
                      className={[
                        onboardingClasses.input,
                        classes.input,
                      ].join(' ')}
                    >
                      <IonInput
                        disabled={values.doNotProvideEmail}
                        value={values.email}
                        name="email"
                        type="email"
                        onIonChange={onChange}
                        placeholder="Email (optional)"
                      />
                    </IonItem>
                  </div>
                  {errors.email && (
                  <p className="error-message">
                    <IonText color="danger">{errors.email}</IonText>
                  </p>
                  )}
                </div>

                <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                  <FormattedMessage id="service-website" />
                </h3>
                <h4
                  className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                  style={{ color: '#000' }}
                >
                  <FormattedMessage id="service-website-subtext" />
                </h4>
                <div>
                  {values.websites.map((w, i) => {
                    const key = `website-${i}`;
                    return (
                      <div key={key}>
                        <IonRow>
                          <IonCol sizeXs="12">
                            <div
                              className={[classes.iconInputRow, classes.iconInputGrid].join(
                                ' ',
                              )}
                            >
                              <IonItem
                                ref={websiteEl}
                                className={[
                                  onboardingClasses.input,
                                  classes.input,
                                ].join(' ')}
                              >
                                <IonInput
                                  placeholder="Text to Display*"
                                  disabled={showSpanishVersion}
                                  value={w.name}
                                  name={`websites[${i}].name`}
                                  onIonChange={onChange}
                                />
                              </IonItem>
                            </div>
                          </IonCol>
                          <IonCol sizeXs="12">
                            <IonText className={classes.helperText}>Example: "Make an Appointment"</IonText>
                          </IonCol>
                          {({ ...errors } as Record<string, unknown>)[`websites[${i}].name`] && (
                            <p className="error-message">
                              <IonText color="danger">{({ ...errors } as Record<string, unknown>)[`websites[${i}].name`] as string}</IonText>
                            </p>
                          )}
                        </IonRow>
                        {showSpanishVersion && (
                          <IonRow>
                            <IonCol sizeXs="12">
                              <div
                                className={[classes.iconInputRow, classes.iconInputGrid].join(
                                  ' ',
                                )}
                              >
                                <IonItem
                                  ref={websiteEl}
                                  className={[
                                    onboardingClasses.input,
                                    classes.input,
                                  ].join(' ')}
                                >
                                  <IonInput
                                    placeholder="Text to Display in Spanish*"
                                    value={w.name_es}
                                    name={`websites[${i}].name_es`}
                                    onIonChange={onChange}
                                  />
                                </IonItem>
                              </div>
                            </IonCol>
                            {({ ...errors } as Record<string, unknown>)[`websites[${i}].name_es`] && (
                              <p className="error-message">
                                <IonText color="danger">{({ ...errors } as Record<string, unknown>)[`websites[${i}].name_es`] as string}</IonText>
                              </p>
                            )}
                          </IonRow>
                        )}
                        <IonRow>
                          <IonCol sizeXs="12">
                            <div
                              className={[classes.iconInputGrid, i > 0 ? (showSpanishVersion ? classes.websiteUrlInputWithRemoveButtonSpanish : classes.websiteUrlInputWithRemoveButton) : classes.websiteUrlInput].join(
                                ' ',
                              )}
                            >
                              <IonIcon
                                className={classes.inputIcon}
                                size="large"
                                color="primary"
                                icon={globeOutline}
                              />
                              <IonItem
                                ref={websiteEl}
                                className={[
                                  onboardingClasses.input,
                                  classes.input,
                                ].join(' ')}
                              >
                                <IonInput
                                  disabled={showSpanishVersion}
                                  placeholder="Website*"
                                  value={w.url}
                                  name={`websites[${i}].url`}
                                  onIonChange={onChange}
                                />
                              </IonItem>
                              {i > 0 && !showSpanishVersion && (
                                <IonButton
                                  className={`ion-margin-top ${classes.circleButton}`}
                                  shape="round"
                                  fill="solid"
                                  color="danger"
                                  onClick={(): void => {
                                    const updatedWebsites = [...values.websites];
                                    updatedWebsites.splice(i, 1);
                                    setValues({ ...values, websites: updatedWebsites });
                                  }}
                                >
                                  <Icon name="remove" width={24} color="white" />
                                </IonButton>
                              )}
                            </div>
                          </IonCol>
                          {({ ...errors } as Record<string, unknown>)[`websites[${i}].url`] && (
                            <p className="error-message">
                              <IonText color="danger">{({ ...errors } as Record<string, unknown>)[`websites[${i}].url`] as string}</IonText>
                            </p>
                          )}
                        </IonRow>
                      </div>
                    );
                  })}
                  {!showSpanishVersion && (
                    <div>
                      <div className={classes.addButtonWrapper}>
                        <IonButton
                          className={`ion-margin-top ${classes.circleButton}`}
                          shape="round"
                          fill="solid"
                          color="primary"
                          disabled={values.websites.length >= 3}
                          onClick={(): void => {
                            if (values.websites.length <= 2) {
                              setValues({
                                ...values,
                                websites: [...values.websites, { name: '', url: '' }],
                              });
                            }
                          }}
                        >
                          <Icon name="add" width={24} color="white" />
                        </IonButton>
                      </div>
                      <div className={classes.addButtonHelperTextWrapper}>
                        <h4
                          className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                          style={{ color: '#000' }}
                        >
                          <FormattedMessage id="service-website-button" />
                        </h4>
                      </div>
                    </div>
                  )}
                </div>

                {!showSpanishVersion && (
                <div className={classes.nextButtonContainer}>
                  <IonButton
                    className="ion-margin-top"
                    shape="round"
                    fill="solid"
                    color="primary"
                    onClick={(): void => {
                      setContactSectionOpen(false);
                      setPopulationsSectionOpen(true);
                      setTimeout(() => {
                        populationsSectionEl.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
                      }, 350);
                    }}
                  >
                    <FormattedMessage id="next" />
                  </IonButton>
                </div>
                )}
              </div>
            </div>
            <div>
              <div hidden={showSpanishVersion}>
                <IonItem
                  ref={populationsSectionEl}
                  className={[
                    classes.sectionHeader,
                    populationsSectionOpen ? 'isOpen' : '',
                  ].join(' ')}
                  onClick={(): void => {
                    setPopulationsSectionOpen(!populationsSectionOpen);
                  }}
                >
                  <IonLabel
                    color={populationsSectionOpen ? 'light' : 'primary'}
                  >
                    <FormattedMessage id="populations-served" />
                  </IonLabel>
                  <IonIcon
                    className={classes.inputIcon}
                    size="large"
                    color={populationsSectionOpen ? 'light' : 'primary'}
                    icon={populationsSectionOpen ? caretUp : caretDown}
                  />
                </IonItem>
                <div className={[
                  classes.sectionContent,
                  populationsSectionOpen ? 'isOpen' : '',
                ].join(' ')}
                >
                  <div className={appClasses.whitebg}>
                    <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                      <FormattedMessage id="ages-served" />
                    </h3>
                    <IonSelect
                      ref={agesServedEl}
                      className={[
                        'cc-select mt-5',
                      ].join(' ')}
                      placeholder="Select Ages Served"
                      value={values.ages}
                      multiple
                      interfaceOptions={agesAlertOptions}
                      onIonChange={(e): void => {
                        values.ages = e.detail.value;
                      }}
                    >
                      {data.age_range.map((ageGroup) => (
                        <IonSelectOption key={ageGroup.id} value={ageGroup.id}>
                          {ageGroup.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                  {errors.ages && (
                  <p className="error-message">
                    <IonText color="danger">{errors.ages}</IonText>
                  </p>
                  )}
                  <div className={appClasses.whitebg}>
                    <h3 className={[appClasses.subtitleBold, 'ion-no-margin mt-16'].join(' ')}>
                      <FormattedMessage id="groups-served" />
                    </h3>
                    <h4
                      className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                      style={{ color: '#000' }}
                    >
                      <FormattedMessage id="groups-served-subtext" />
                    </h4>
                    <IonSelect
                      className={[
                        'cc-select mt-5',
                      ].join(' ')}
                      placeholder="Select Groups Served"
                      value={values.groups}
                      multiple
                      interfaceOptions={groupsAlertOptions}
                      onIonChange={(e): void => {
                        values.groups = e.detail.value;
                      }}
                    >
                      {data.group.map((groupName) => (
                        <IonSelectOption key={groupName.id} value={groupName.id}>
                          {groupName.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                  <div className={appClasses.whitebg}>
                    <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                      <FormattedMessage id="languages-header" />
                    </h3>
                    <IonSelect
                      ref={languagesSpokenEl}
                      className={[
                        'cc-select mt-5',
                      ].join(' ')}
                      placeholder="Languages Spoken"
                      value={values.languages}
                      multiple
                      interfaceOptions={languagesAlertOptions}
                      onIonChange={(e): void => {
                        values.languages = e.detail.value;
                      }}
                    >
                      {data.language.map((languageEntry) => (
                        <IonSelectOption
                          key={languageEntry.id}
                          value={languageEntry.id}
                        >
                          {languageEntry.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                  {errors.languages && (
                  <p className="error-message">
                    <IonText color="danger">{errors.languages}</IonText>
                  </p>
                  )}
                  <h3 className={[appClasses.subtitleBold, 'ion-no-margin mt-16'].join(' ')}>
                    <FormattedMessage id="documentation-header" />
                  </h3>
                  <h4
                    className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                    style={{ color: '#000' }}
                  >
                    <FormattedMessage id="documentation-subtext" />
                  </h4>
                  <IonItem
                    className={[
                      onboardingClasses.input,
                    ].join(' ')}
                  >
                    <IonInput
                      value={
                    showSpanishVersion
                      ? values.documentationEs
                      : values.documentation
                  }
                      name={showSpanishVersion ? 'documentationEs' : 'documentation'}
                      type="text"
                      placeholder="Example: Registration Form, Enrollment Application"
                      onIonChange={onChange}
                    />
                  </IonItem>
                  <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                    <FormattedMessage id="insurance-header" />
                  </h3>
                  <IonItem
                    className={[
                      appClasses.toggleItemSingleLine,
                      'ion-no-padding ion-no-margin',
                    ].join(' ')}
                  >
                    <IonToggle
                      className={[
                        appClasses.toggle,
                      ].join(' ')}
                      checked={values.insurance}
                      mode="ios"
                      onIonChange={(e): void => {
                        values.insurance = e.detail.checked;
                      }}
                    />
                    <IonLabel className={appClasses.subtitle} color="primary">
                      <FormattedMessage id="insurance-toggle-text" />
                    </IonLabel>
                  </IonItem>
                  <div className={appClasses.whitebg}>
                    <IonSelect
                      className={[
                        'cc-select mt-16',
                      ].join(' ')}
                      placeholder="Select Types of Insurance Accepted"
                      value={values.insurances}
                      multiple
                      interfaceOptions={insurancesAlertOptions}
                      onIonChange={(e): void => {
                        values.insurances = e.detail.value;
                      }}
                    >
                      {data.insurance.map((insuranceItem) => (
                        <IonSelectOption
                          key={insuranceItem.id}
                          value={insuranceItem.id}
                        >
                          {insuranceItem.name}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                  {!showSpanishVersion && (
                  <div className={classes.nextButtonContainer}>
                    <IonButton
                      className="ion-margin-top"
                      shape="round"
                      fill="solid"
                      color="primary"
                      onClick={(): void => {
                        setPopulationsSectionOpen(false);
                        setHoursSectionOpen(true);
                        setTimeout(() => {
                          hoursSectionEl.current?.scrollIntoView({ behavior: 'auto', block: 'start' });
                        }, 350);
                      }}
                    >
                      <FormattedMessage id="next" />
                    </IonButton>
                  </div>
                  )}
                </div>
                <IonItem
                  ref={hoursSectionEl}
                  className={[
                    classes.sectionHeader,
                    hoursSectionOpen ? 'isOpen' : '',
                  ].join(' ')}
                  onClick={(): void => {
                    setHoursSectionOpen(!hoursSectionOpen);
                  }}
                >
                  <IonLabel
                    color={hoursSectionOpen ? 'light' : 'primary'}
                  >
                    <FormattedMessage id="service-operating-hours" />
                  </IonLabel>
                  <IonIcon
                    className={classes.inputIcon}
                    size="large"
                    color={hoursSectionOpen ? 'light' : 'primary'}
                    icon={hoursSectionOpen ? caretUp : caretDown}
                  />
                </IonItem>
                <div className={[
                  classes.sectionContent,
                  hoursSectionOpen ? 'isOpen' : '',
                ].join(' ')}
                >
                  <h3 className={[appClasses.subtitleBold, 'required-field ion-no-margin mt-16'].join(' ')}>
                    <FormattedMessage id="operating-hours-header" />
                  </h3>
                  <OperatingHours
                    setHours={setHours}
                    hoursData={values.hours}
                    readOnly={false}
                  />
                  {!showSpanishVersion && (
                  <div className={classes.nextButtonContainer}>
                    <IonButton
                      className="ion-margin-top"
                      shape="round"
                      fill="solid"
                      color="primary"
                      onClick={(): void => {
                        setHoursSectionOpen(false);
                      }}
                    >
                      <FormattedMessage id="next" />
                    </IonButton>
                  </div>
                  )}
                </div>
              </div>
              <div hidden={!showSpanishVersion}>
                <h3 className={[appClasses.subtitleBold, 'ion-no-margin mt-16'].join(' ')}>
                  <FormattedMessage id="documentation-header" />
                </h3>
                <h4
                  className={[appClasses.subtext, 'ion-no-margin mb-5 mt-5'].join(' ')}
                  style={{ color: '#000' }}
                >
                  <FormattedMessage id="documentation-subtext" />
                </h4>
                <IonItem
                  className={[
                    onboardingClasses.input,
                  ].join(' ')}
                >
                  <IonInput
                    value={values.documentationEs}
                    name="documentationEs"
                    type="text"
                    placeholder="Example: Registration Form, Enrollment Application"
                    onIonChange={onChange}
                  />
                </IonItem>
              </div>
            </div>
          </IonList>

          {currentProvider?.approved_date && !listingToEdit?.submit_date ? (
            // This is an unapproved, unsubmitted listing from an approved provider.
            // Could be a brand new unsaved listing or one that was previously saved but not submitted.
            // Show SAVE AS DRAFT and SAVE AND SUBMIT buttons.
            <div className="mt-24">
              {showSpanishVersion ? <div className="mb-16" /> : null}
              <div className={appClasses.spaceBetweenRow}>
                <IonButton
                  className={
                    isDesktop
                      ? 'cc-button cc-tall-button'
                      : 'cc-tall-button-16px'
                  }
                  shape="round"
                  disabled={values.serviceName.length === 0}
                  onClick={(): void => {
                    setFormJustSubmitted(true);
                    if (listingToEdit) {
                      updateServiceListing(0, true)(values);
                    } else {
                      createServiceListing(0, true)(values);
                    }
                  }}
                >
                  Save as Draft
                </IonButton>
                <IonButton
                  className={
                    isDesktop
                      ? 'cc-button cc-tall-button'
                      : 'cc-tall-button-16px'
                  }
                  shape="round"
                  onClick={(e): void => {
                    setFormJustSubmitted(true);
                    if (listingToEdit) {
                      handleSubmit(saveAndSubmitServiceListing(-1))(e);
                      // TODO: How to know if user hasn't seen the translation?
                      // Could be they saved as draft on just English, then returned
                      // and clicked Save And Continue, in which case we should call
                      // the commented out updateServiceListing below instead.
                      // handleSubmit(updateServiceListing(-1, false))(e);
                    } else {
                      handleSubmit(createServiceListing(-1, false))(e);
                    }
                  }}
                >
                  {!showSpanishVersion
                    ? 'Save and Continue'
                    : 'Save and Submit'}
                </IonButton>
              </div>
            </div>
          ) : !currentProvider?.submit_date ? (
            // This is a new unsaved listing by a new provider that is onboarding. Show SAVE AND CONTINUE button.
            <div className="mt-24">
              <IonButton
                className="cc-button cc-tall-button"
                shape="round"
                expand="block"
                onClick={(e): void => {
                  setFormJustSubmitted(true);
                  if (listingToEdit?.id) {
                    handleSubmit(updateServiceListing(-1, false))(e);
                  } else {
                    handleSubmit(createServiceListing(-1, false))(e);
                  }
                }}
              >
                Save and Continue
              </IonButton>
            </div>
          ) : (
            // Listing has already been saved, show UPDATE button.
            <div
              className={[
                showSpanishVersion && isDesktop
                  ? appClasses.spaceBetweenRow
                  : '',
                'mt-24',
              ].join(' ')}
            >
              <IonButton
                className="cc-button cc-tall-button"
                shape="round"
                expand="block"
                onClick={(e): void => {
                  setFormJustSubmitted(true);
                  handleSubmit(updateServiceListing(0, false))(e);
                }}
              >
                <FormattedMessage id="update-listing" />
              </IonButton>
              {locationState.pathname.startsWith(
                routePaths.provider.approved.root,
              ) && !showSpanishVersion ? (
                <IonButton
                  className="cc-button cc-tall-button"
                  shape="round"
                  expand="block"
                  fill="solid"
                  color="secondary"
                  onClick={(): void => {
                    setPopupIndex(2);
                  }}
                >
                  <FormattedMessage id="delete-service" />
                </IonButton>
                ) : null}
            </div>
          )}
        </form>
      </div>
      <IonAlert
        isOpen={popupIndex >= 0}
        onDidDismiss={(): void => {
          setPopupIndex(-1);
          if (popupIndex !== 2) {
            routeOnSuccess(listingToEdit?.id ?? 0);
          }
        }}
        message={popupTexts[popupIndex]}
        buttons={
          (negativeButtonTexts[popupIndex]?.length ?? 0) > 0
            ? [
              {
                text: negativeButtonTexts[popupIndex],
              },
              {
                text: positiveButtonTexts[popupIndex],
                handler: popupDismissHandler,
              },
            ]
            : [
              {
                text: positiveButtonTexts[popupIndex],
                handler: popupDismissHandler,
              },
            ]
        }
      />
    </>
  );
};

export default ServiceListing;
