import { createUseStyles } from 'react-jss';

export default createUseStyles({
  adminContent: {
    padding: '12px',
    height: '100%',
    '& h3': {
      fontWeight: 'bold',
    },
  },
  adminTabs: {
    justifyContent: 'left',
    alignItems: 'flex-start',
  },
  adminTab: {
    alignItems: 'center',
    overflowWrap: 'normal',
    padding: '8px',
    '&.interiorItem': {
      marginRight: '2px',
    },

  },
  adminTabContent: {
    width: '100%',
    backgroundColor: '#D3D3D3',
    padding: '12px',
  },

  providerCardHeader: {
    width: '100%',
    backgroundColor: 'var(--ion-color-primary)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px',
    '& ion-label': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .icons svg': {
      cursor: 'pointer',
    },
    '& .icon-wrap': {
      display: 'inline-block',
    },
  },
  providerCardContent: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    padding: '5px',
    marginBottom: '16px',
  },
  categoryCardContent: {
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  providerNameRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  providerOrgName: {
    flex: '1',
  },
  providerCommentItem: {
    border: '2px solid var(--ion-color-primary)',
    borderRadius: '5px',
    '--background': '#FFF',
    '--min-height': '50px',
    '@media (min-width:992px)': {
      '--min-height': '168px',
    },
    '--border-color': 'transparent!important',
    '--highlight-height': '0',
    padding: '0px',
    '&.item-has-focus': {
      border: '3px solid var(--ion-color-primary)',
    },
  },
  providerCommentTextarea: {
    '--placeholder-color': 'var(--ion-color-primary)',
    '--placeholder-opacity': '.7',
  },
  providerActionButton: {
    '--text-transform': 'none',
    '--padding-top': '8px',
    '--padding-bottom': '8px',
    '--padding-start': '0px',
    '--padding-end': '0px',
    '--font-weight': '400',
    fontSize: '12px',
    minWidth: '140px',
    width: '100%',
    height: '34px',
    '&.linkButton': {
      'text-decoration': 'underline',
    },
  },
  providerServicesModalContent: {
    '--padding-start': '8px',
    '--padding-end': '8px',
    '--padding-top': '8px',
    '--padding-bottom': '8px',
  },
  addInformationalPage: {
    width: 125,
    marginBottom: 25,
  },
  adminCenteredContent: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& #header': {
      '& h3': {
        fontWeight: 'bold',
        float: 'left',
      },
    },
    '& #toggle-wrapper': {
      float: 'right',
    },
    '& #body': {
      '@media (min-width:992px)': {
        width: '50%',
      },
      '@media (max-width:992px)': {
        width: '100%',
      },
    },
  },
  upsertInformationalPage: {
    backgroundColor: '#D3D3D3',
    padding: 20,
  },
  wysiwyg: {
    marginTop: 20,
    marginBottom: 20,
    '& .ql-container': {
      background: 'white',
    },
    '& .ql-toolbar': {
      background: 'white',
    },
  },
  description: {
    '& textarea': {
      height: '140px',
    },
  },
  contentWrapper: {
    marginBottom: 24,
  },
  crisisCategoryCardHeader: {
    width: '100%',
    backgroundColor: 'var(--ion-color-primary)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '5px',
    height: '25px',
  },
  subcategoryNames: {
    color: 'var(--ion-color-primary)',
    // fontSize: 16,
  },
  subcategoryNamesTitle: {
    color: 'var(--ion-color-primary)',
    fontSize: '14px!important',
  },
  removeButton: {
    '--text-transform': 'none',
    '--padding-top': '8px',
    '--padding-bottom': '8px',
    '--padding-start': '0px',
    '--padding-end': '0px',
    '--font-weight': '400',
    fontSize: '12px',
    minWidth: '140px',
    width: '100%',
    height: '34px',
    '&.linkButton': {
      'text-decoration': 'underline',
    },
    '--background': '#d01527',
    '--background-focused': 'var(--ion-color-primary-contrast, #fff)',
    '--background-activated-opacity': 0,
    '--background-hover-opacity': 0.08,
    '--background-activated': 'transparent',
    '--color': 'white',
    '--border-color': '#d01527',
    '--box-shadow': '0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
  },
  removeButtonCol: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  saveButton: {
    '--text-transform': 'none',
    '--padding-top': '8px',
    '--padding-bottom': '8px',
    '--padding-start': '0px',
    '--padding-end': '0px',
    '--font-weight': '400',
    fontSize: '12px',
    minWidth: '140px',
    '@media (min-width: 960px)': {
      width: '30%',
    },
    '@media(max-width: 960px)': {
      width: '90%',
    },
    height: '34px',
    '&.linkButton': {
      'text-decoration': 'underline',
    },
    marginBottom: 20,
  },
  cancelButton: {
    '--background-color': '#e2b40c',
    '--text-transform': 'none',
    '--padding-top': '8px',
    '--padding-bottom': '8px',
    '--padding-start': '0px',
    '--padding-end': '0px',
    '--font-weight': '400',
    fontSize: '12px',
    minWidth: '140px',
    '@media (min-width: 960px)': {
      width: '30%',
    },
    '@media(max-width: 960px)': {
      width: '90%',
    },
    height: '34px',
    '&.linkButton': {
      'text-decoration': 'underline',
    },
    marginBottom: 20,
  },
  rowLabel: {
    '@media(max-width: 960px)': {
      marginBottom: '10px!important',
    },
  },
  saveButtonWrapper: {
    marginTop: '40px',
    marginBottom: '40px',
    width: '100%',
    display: 'flex',
    '@media (min-width: 960px)': {
      justifyContent: 'space-evenly',
    },
    '@media (max-width: 960px)': {
      flexDirection: 'column',
      '& ion-button': {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
  categorySelect: {
    border: '2px solid var(--ion-color-primary)',
    borderRadius: '5px',
    '--background': '#FFF',
    '--border-color': 'transparent!important',
    '--highlight-height': '0',
    paddingLeft: '15px',
    padding: '0px',
    '&.item-has-focus': {
      border: '3px solid var(--ion-color-primary)',
    },
    '&.item-input ion-label, &.item-textarea ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
    },
    '&.item-has-focus ion-label, &.item-has-value ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
      position: 'absolute',
      fontSize: 10,
      top: 5,
      left: 7,
      margin: 0,
    },
    '& ion-input': {
      '--placeholder-color': 'var(--ion-color-primary)',
    },
    height: '35px',
    '@media (min-width: 960px)': {
      width: '30%',
    },
    '@media (max-width: 960px)': {
      width: '90%',
    },
  },
  input: {
    width: '100%',
    marginTop: '17px',
    border: '2px solid var(--ion-color-primary)',
    borderRadius: '5px',
    '--background': '#FFF',
    '--border-color': 'transparent!important',
    '--highlight-height': '0',
    padding: '0px',
    '&.item-has-focus': {
      border: '3px solid var(--ion-color-primary)',
    },
    '&.item-input ion-label, &.item-textarea ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
    },
    '&.item-has-focus ion-label, &.item-has-value ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
      position: 'absolute',
      fontSize: 10,
      top: 5,
      left: 7,
      margin: 0,
    },
    '& ion-input': {
      '--placeholder-color': 'var(--ion-color-primary)',
    },
  },
  subcategoryFormRow: {
    display: 'flex',
    flexDirection: 'column',
  },
});
