import { IonButton, IonIcon, IonLabel } from '@ionic/react';
import { caretDown, locate } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import Icon from '../../components/Icon';
import useClasses from '../../components/styles';
import { Category, City } from '../../interfaces/Meta';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import { getFiltersFromQuery, translateName } from '../../lib/utils';
import { useStoreState } from '../../store';
import SearchFilters from './SearchFilters';
import SearchLocation from './SearchLocation';

interface SearchHeaderProps {
  category: Category | null;
  searchResults: number;
  showMap: boolean;
  setShowMap: (showMap: boolean) => void;
}

const SearchHeader: React.FunctionComponent<SearchHeaderProps> = ({
  category,
  searchResults,
  showMap,
  setShowMap,
}: SearchHeaderProps) => {
  const primaryColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--ion-color-primary');
  const classes = useClasses({ backgroundColor: category?.color });
  const [filtersModal, openFiltersModal] = useState(false);
  const [locationModal, openLocationModal] = useState(false);
  const [selectedCities, setSelectedCities] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string | null>('');
  const isDesktop = useIsDesktop();
  const { data } = useStoreState((state) => state.meta);
  const location = useLocation();
  const { filters } = getFiltersFromQuery({
    locationSearch: location.search,
    categories: data?.category || [],
  });
  const { useLocationOnSearch } = useStoreState((state) => state.settings);
  const locale = useStoreState((state) => state.translations.language);

  useEffect(() => {
    if (filters.cities && data?.city) {
      const selected = filters.cities
        .map((id: string) => data.city.find((c) => Number(c.id) === Number(id)))
        .filter((c: City) => c !== null && c !== undefined)
        .map((c: City) => c?.name)
        .sort()
        .join(', ');
      setSelectedCities(selected);
    }
  }, [data, filters]);

  useEffect(() => {
    const params = new URLSearchParams(decodeURI(location.search));
    const term = params.get('term');
    setSearchTerm(term);
  }, [location]);

  return (
    <div className={classes.searchFiltersContainer}>
      {/* First Row Title */}

      <div className={classes.searchHeaderFirstRowTitle}>
        {/* Icon */}
        {category && (
          <div className={classes.searchHeaderCategoryIcon}>
            <Icon name={category.icon} width={24} color="white" />
          </div>
        )}
        <div className={classes.searchHeaderCategory}>
          {category && (
            <>
              <IonLabel className={classes.searchHeaderCategoryName}>
                {translateName(category, locale)}
              </IonLabel>
              <IonLabel className={[classes.searchHeaderCategoryIn, 'ml-5'].join(' ')}>
                <FormattedMessage id="in" />
              </IonLabel>
            </>
          )}
          {!category && (
            <>
              <IonLabel className={classes.searchHeaderTerm}>"{searchTerm}"</IonLabel>
              <IonLabel className={[classes.searchHeaderTermIn, 'ml-5'].join(' ')}>
                <FormattedMessage id="in" />
              </IonLabel>
            </>
          )}
        </div>

        {category ? isDesktop ? null : showMap ? (
          <div
            className={classes.searchHeaderMapIcon}
            onClick={(): void => setShowMap(false)}
          >
            <Icon name="list" width={40} color={primaryColor} />
          </div>
        ) : (
          <div
            className={classes.searchHeaderMapIcon}
            onClick={(): void => setShowMap(true)}
          >
            <IonIcon color="primary" size="medium" icon={locate} />
          </div>
        ) : null}

        {!category && !isDesktop && (
          <div className={classes.userDesktopMenuContainer}>
            {showMap ? (
              <div onClick={(): void => setShowMap(false)}>
                <Icon name="list" width={40} color={primaryColor} />
              </div>
            ) : (
              <div onClick={(): void => setShowMap(true)}>
                <Icon name="map" width={40} color={primaryColor} />
              </div>
            )}
          </div>
        )}
      </div>

      {/* Selected cities CSV */}
      <div onClick={(): void => openLocationModal(true)} className={classes.citySelector}>
        <div>
          {useLocationOnSearch && (
            <>
              <div id="cities">
                <FormattedMessage id="my-current-location" />
              </div>
              <div className={classes.searchHeaderCities}>
                <Icon name="arrow_right" width={24} color={primaryColor} />
              </div>
            </>
          )}
          {!useLocationOnSearch && filters.cities && filters.cities.length && (
            <>
              <div id="cities">
                {selectedCities}
              </div>
              <div className={classes.searchHeaderCities}>
                <Icon name="arrow_right" width={24} color={primaryColor} />
              </div>
            </>
          )}
          {!useLocationOnSearch
            && (!filters.cities || !filters.cities.length) && (
              <div>
                <div id="cities">
                  Collier County
                </div>
                <div className={classes.searchHeaderCities}>
                  <Icon name="arrow_right" width={24} color={primaryColor} />
                </div>
              </div>
          )}
        </div>
      </div>

      {/* Search results qty */}
      <div className={classes.searchHeaderQty}>
        <IonLabel>
          <FormattedMessage id="search-results" />
          {`: ${searchResults}`}
        </IonLabel>
        <IonButton
          onClick={(): void => openFiltersModal(true)}
          className={[classes.filterButton, 'ml-16'].join(' ')}
        >
          <FormattedMessage id="filters-camel" />
          <IonIcon icon={caretDown} color={primaryColor} className="ml-5 mr-16" />
        </IonButton>
      </div>

      <SearchFilters showModal={filtersModal} toggleModal={openFiltersModal} />
      <SearchLocation showModal={locationModal} toggleModal={openLocationModal} />
    </div>
  );
};

export default SearchHeader;
