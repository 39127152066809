import { useState, useCallback } from 'react';
import * as yup from 'yup';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cache = new Map<string, any>();
export default function useForm<T>({
  schema,
  initial,
}: {
  schema: yup.ObjectSchema;
  initial?: T;
}): {
    values: T;
    errors: T;
    resetCache: () => void;
    reset: () => void;
    isCacheEmpty: () => boolean;
    setSchema: (schema: yup.ObjectSchema) => void;
    setValues: (data: T) => void;
    // eslint-disable-next-line
    onChange: (event: any) => void;
    // eslint-disable-next-line
    handleSubmit: (cb: (data: T) => any) => (event: any) => void;
  } {
  const [id] = useState(JSON.stringify(initial));
  const [values, setValues] = useState<T>(cache.get(id) || { ...initial });
  // @ts-ignore
  const [errors, setErrors] = useState<T>({});
  const [touched, setTouched] = useState(false);
  const [currentSchema, setCurrentSchema] = useState(schema);
  const validate = useCallback(async (cb?: (data: T) => void): Promise<void> => {
    let fieldErrors = {};
    try {
      const data = await currentSchema.validate(values, {
        abortEarly: false,
      });
      // @ts-ignore
      setErrors(fieldErrors);
      if (cb) {
        cb((data as unknown) as T);
      }
    } catch (error) {
      if (Array.isArray(error.inner)) {
        fieldErrors = error.inner.reduce((all: T, item: yup.ValidationError) => {
          // @ts-ignore
          all[item.path] = item.message;
          return all;
        }, fieldErrors);
        // @ts-ignore
        setErrors(fieldErrors);
      }
    }
  }, [currentSchema, values]);
  // eslint-disable-next-line
  const onChange = useCallback((event: any): void => {
    const nameRegex = RegExp(/^([a-zA-Z\d_]+)\[(\d)+\]\.([a-zA-Z\d_]+)$/);
    if (nameRegex.test(event.target.name)) {
      setValues((prevValues: T) => {
        const key: string = event.target.name.replace(nameRegex, '$1');
        const arrayIndex = Number(event.target.name.replace(nameRegex, '$2'));
        const property: string = event.target.name.replace(nameRegex, '$3');
        const arr = (prevValues as Record<string, unknown>)[key] as Record<string, unknown>[];
        if (arr[arrayIndex]) {
          arr[arrayIndex][property] = event.target.value;
        } else {
          arr[arrayIndex] = { [property]: event.target.value };
        }
        const updatedValues = {
          ...prevValues,
          [key]: arr,
        };
        cache.set(id, updatedValues);
        return updatedValues;
      });
    } else {
      setValues((prevValues: T) => {
        const updatedValues = {
          ...prevValues,
          [event.target.name]: event.target.value,
        };
        cache.set(id, updatedValues);
        return updatedValues;
      });
    }

    if (touched) {
      validate();
    }
  }, [id, validate, touched]);

  const isCacheEmpty = ((): boolean => cache.size === 0);

  const resetCache = useCallback((): void => {
    cache.delete(id);
  }, [id]);

  const handleSubmit = (cb: (data: T) => void) => (
    event: any, // eslint-disable-line
  ): void => {
    event.preventDefault();
    setTouched(true);
    validate(cb);
    resetCache();
  };

  const reset = useCallback((): void => {
    setTouched(false);
    // @ts-ignore
    setErrors({});
  }, [setTouched, setErrors]);
  return {
    errors,
    values,
    onChange,
    setValues,
    reset,
    resetCache,
    isCacheEmpty,
    handleSubmit,
    setSchema: setCurrentSchema,
  };
}
