import {
  Capacitor,
} from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { StoreProvider } from 'easy-peasy';
import React from 'react';
import { IntlProvider } from 'react-intl';
import App from './App';
import { store, useStoreState } from './store';

// setupConfig({
//   navAnimation() {
//     return createAnimation();
//   },
// });

const IntlApp: React.FC = () => {
  const { language, messages } = useStoreState(state => state.translations);
  return (
    <IntlProvider locale={language} messages={messages}>
      <App />
    </IntlProvider>
  );
};

const Root: React.FC = () => {
  if (Capacitor.isPluginAvailable('StatusBar')) {
    StatusBar.hide();
  }
  return (
    <IonApp style={{ marginTop: 'env(safe-area-inset-top)' }}>
      <StoreProvider store={store}>
        <IonReactRouter>
          <IntlApp />
        </IonReactRouter>
      </StoreProvider>
    </IonApp>
  );
};

export default Root;
