import { action, thunk } from 'easy-peasy';
import { Category } from '../interfaces/Meta';
import CategoriesModel from '../interfaces/StoreModel/CategoriesModel';
import { categories } from '../lib/api/services';

const model: CategoriesModel = {
  error: '',
  loading: false,
  success: false,
  setSuccess: action((state, payload) => {
    state.success = payload;
  }),
  data: null,
  setData: action((state, payload) => {
    state.data = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  get: thunk(async (actions, id, { getStoreState }) => {
    const { categories: { data } } = getStoreState();
    let foundCategory: Category | null = null;
    actions.setLoading(true);
    try {
      actions.setError('');
      foundCategory = await categories.get(id);
      const newCategories = [...(data ?? [])];
      if (foundCategory) {
        const foundIndex = data?.findIndex(c => c.id === Number(id));
        if (foundIndex !== undefined && foundIndex > -1) {
          newCategories[foundIndex] = foundCategory;
        } else {
          newCategories.push(foundCategory);
        }
      }
      actions.setData(newCategories);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
    return foundCategory;
  }),
  find: thunk(async (actions) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      const data = await categories.find();
      actions.setData(data);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  update: thunk(async (actions, data, { getStoreState }) => {
    const { categories: { data: currentCategories } } = getStoreState();
    actions.setLoading(true);
    try {
      const dataWithoutId = { ...data };
      delete dataWithoutId.id;
      const updatedCategory = await categories.update(data.id, dataWithoutId);
      const foundIndex = currentCategories?.findIndex(c => c.id === data.id);
      const updatedCategories: Category[] = [...(currentCategories ?? [])];
      if (foundIndex !== undefined && foundIndex > -1) {
        updatedCategories[foundIndex] = updatedCategory;
      } else {
        updatedCategories.push(updatedCategory);
      }
      actions.setData(updatedCategories);
      actions.setSuccess(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
};

export default model;
