import React, { useState } from 'react';
import {
  IonSegmentButton, IonSegment,
} from '@ionic/react';

import useStyles from '../styles';
import { useStoreState, useStoreActions } from '../../../store';
import CCAdminPage from '../../../components/CCAdminPage';
import NewProviders from '../components/NewProviders';
import NewServices from '../components/NewServices';
import RecentlyUpdatedServices from '../components/RecentlyUpdatedServices';
import useService from '../../../lib/hooks/useService';
import { providers } from '../../../lib/api/services';
import Provider from '../../../interfaces/Provider';

const Tabs = new Map<string, React.FC>([
  ['newProviders', NewProviders],
  ['newServices', NewServices],
  ['recentlyUpdatedServices', RecentlyUpdatedServices],
]);

const AdminDashboard: React.FC = () => {
  const classes = useStyles();
  const { selectedReviewTab } = useStoreState(state => state.admin);
  const { setSelectedReviewTab } = useStoreActions(state => state.admin);

  const [page] = useState({ limit: 0, skip: 0 });
  const [newServicesFilter] = useState({
    approved_date: {
      $ne: null,
    },
    listings: {
      submitted: true,
      approved: false,
    },
  });
  const [newProvidersFilter] = useState({
    approver_id: null,
    submit_date: {
      $ne: null,
    },
  });
  const [recentlyUpdatedProvidersFilter] = useState({
    approved_date: {
      $ne: null,
    },
    listings: {
      submitted: true,
      approved: true,
    },
  });

  const { result: newServicesResult } = useService<Provider>(providers, page, newServicesFilter);
  const { result: newProvidersResult } = useService<Provider>(providers, page, newProvidersFilter);
  // eslint-disable-next-line max-len
  const { result: recentlyUpdatedResult } = useService<Provider>(providers, page, recentlyUpdatedProvidersFilter);

  const Tab = Tabs.get(selectedReviewTab);

  return (
    <CCAdminPage
      title="Dashboard"
      content={(
        <div className={classes.adminContent}>
          <h3>Providers & Services</h3>
          <div className="mt-24">
            <IonSegment className={classes.adminTabs} value={selectedReviewTab} onIonChange={(e): void => setSelectedReviewTab(e.detail.value as string)} color="primary" mode="md">
              <IonSegmentButton className={[classes.adminTab, 'interiorItem ion-text-wrap'].join(' ')} value="newProviders"> New Providers ({newProvidersResult.total})</IonSegmentButton>
              <IonSegmentButton className={[classes.adminTab, 'interiorItem ion-text-wrap'].join(' ')} value="newServices">New Services ({newServicesResult.total})</IonSegmentButton>
              <IonSegmentButton className={[classes.adminTab, 'ion-text-wrap'].join(' ')} value="recentlyUpdatedServices">Providers ({recentlyUpdatedResult.total})</IonSegmentButton>
            </IonSegment>
          </div>
          {Tab ? <Tab /> : null}
        </div>
      )}
    />
  );
};

export default AdminDashboard;
