import { IonAlert, IonLabel, IonRouterLink } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useStoreActions } from '../store';
import menuItems from './AdminMenuItems';
import useStyles from './styles';

const AdminDesktopMenu: React.FunctionComponent = () => {
  const location = useLocation();
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const { logout } = useStoreActions(actions => actions.auth);
  const [selectedMenuTitleId, setSelectedMenuTitleId] = useState<string | null>(null);
  const classes = useStyles();

  useEffect(() => {
    if (location.pathname.includes('listing-category') || location.pathname.includes('listing-subcategory')) {
      setSelectedMenuTitleId('admin.menutitle.listingCategories');
    } else if (location.pathname.includes('informational-page')) {
      setSelectedMenuTitleId('admin.menutitle.informationalpages');
    } else if (location.pathname.includes('crisis-hotline')) {
      setSelectedMenuTitleId('admin.menutitle.crisishotlines');
    } else {
      setSelectedMenuTitleId(null);
    }
  }, [location]);

  return (
    <div className={classes.userDesktopMenuContainer}>
      {menuItems
        .filter(
          (menuItem) => menuItem.desktopNavInfo !== undefined
            && menuItem.desktopNavInfo !== null,
        )
        .sort(
          // @ts-ignore
          (a, b) => a.desktopNavInfo?.navOrderIndex - b.desktopNavInfo?.navOrderIndex,
        )
        .map((navItem) => (navItem.desktopNavInfo?.navTitleId === 'admin.navtitle.signout' ? (
          <IonLabel
            style={{ cursor: 'pointer' }}
            className="mr-24"
            key={navItem.desktopNavInfo?.navTitleId}
            onClick={(): void => setShowLogoutAlert(true)}
            color={navItem.desktopNavInfo?.color}
          >
            <FormattedMessage id={navItem.desktopNavInfo?.navTitleId} />
          </IonLabel>
        ) : (
          <IonRouterLink
            className="mr-24"
            key={navItem.desktopNavInfo?.navTitleId}
            routerLink={navItem.url}
            color={navItem.desktopNavInfo?.color}
          >
            {selectedMenuTitleId ? (
              <>
                {selectedMenuTitleId === navItem.menuTitleId ? (
                  <b><FormattedMessage id={navItem.menuTitleId} /></b>
                ) : (
                  <FormattedMessage id={navItem.menuTitleId} />
                )}
              </>
            ) : (
              <>
                {location.pathname === navItem.url ? (
                  <b><FormattedMessage id={navItem.menuTitleId} /></b>
                ) : (
                  <FormattedMessage id={navItem.menuTitleId} />
                )}
              </>
            )}
          </IonRouterLink>
        )))}
      <IonAlert
        isOpen={showLogoutAlert}
        onDidDismiss={(): void => setShowLogoutAlert(false)}
        message="Would you like to sign out?"
        buttons={[
          {
            text: 'Cancel',
          },
          {
            text: 'Sign Out',
            handler: (): void => {
              logout();
            },
          },
        ]}
      />
    </div>
  );
};

export default AdminDesktopMenu;
