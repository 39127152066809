import React from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import OnboardingPage from '../components/OnboardingPage';
import routePaths from '../../../lib/routePaths';
import ProviderType from './ProviderType';
import Requirements from './Requirements';
import OrgInfo from '../components/OrgInfo';
import Approval from './Approval';
import ServiceListing from '../components/ServiceListing';
import useIsDesktop from '../../../lib/hooks/useIsDesktop';
import CurrentStepMobile from '../components/CurrentStep/Mobile';
import EmailUnverified from './EmailUnverified';
import SubmitForReview from './SubmitForReview';

const Onboarding: React.FC = () => {
  const isDesktop = useIsDesktop();
  const location = useLocation();

  return (
    <OnboardingPage
      content={(
        <>
          {isDesktop
            || location.pathname === routePaths.provider.onboarding.viewProfile ? null
            : <CurrentStepMobile />}
          <Switch>
            <Route exact path={routePaths.provider.onboarding.root} component={ProviderType} />
            <Route exact path={routePaths.provider.onboarding.questions} component={Requirements} />
            <Route exact path={routePaths.provider.onboarding.orgInfo} component={OrgInfo} />
            <Route exact path={routePaths.provider.onboarding.viewProfile} component={OrgInfo} />
            <Route exact path={routePaths.provider.onboarding.approval} component={Approval} />
            <Route
              exact
              path={routePaths.provider.onboarding.addService}
              component={ServiceListing}
            />
            <Route path={routePaths.provider.onboarding.editServiceId} component={ServiceListing} />
            <Route exact path={routePaths.provider.onboarding.submit} component={SubmitForReview} />
            <Route
              exact
              path={routePaths.provider.onboarding.unverifiedEmail}
              component={EmailUnverified}
            />
          </Switch>
        </>
      )}
    />
  );
};

export default Onboarding;
