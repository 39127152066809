import { IonLabel } from '@ionic/react';
import getDay from 'date-fns/getDay';
import React from 'react';
import { useStoreActions, useStoreState } from '../../store';
import Icon from '../../components/Icon';
import useClasses from '../../components/styles';
import Listing from '../../interfaces/Listing';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import {
  getDayOfWeekOpenHours, goToDirections, translateName, getTranslatedString,
} from '../../lib/utils';

interface ListingProps {
  listing: Listing;
  isFavorite: boolean;
}

const ListingCard: React.FunctionComponent<ListingProps> = ({
  listing,
  isFavorite,
}: ListingProps) => {
  const primaryColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--ion-color-primary');
  const classes = useClasses();
  const isDesktop = useIsDesktop();
  const { language } = useStoreState(state => state.translations);
  const locale = useStoreState((state) => state.translations.language);
  const hours = getDayOfWeekOpenHours(listing.hours, getDay(new Date()), language, false);

  const closedHoursText = [getTranslatedString('closed-today', language), getTranslatedString('closed', language), getTranslatedString('closed-now', language), getTranslatedString('closed-today', language)];

  const { removeFavoriteListingById, addFavoriteListing } = useStoreActions(
    (actions) => actions.favorites,
  );

  return (
    <div className={classes.listingCard}>
      <IonLabel className={classes.listingTitle}>{translateName(listing, locale)}</IonLabel>
      <IonLabel className={classes.listingSubcategory}>
        {listing.sub_categories.map((s) => translateName(s, locale)).join(' - ')}
      </IonLabel>
      <IonLabel className={classes.listingProvider}>
        {listing.provider?.name}
      </IonLabel>
      <div className={classes.listingBottom}>
        <div
          className={
            closedHoursText.indexOf(hours) > -1
              ? [classes.scheduleText, classes.scheduleTextClosed].join(' ')
              : classes.scheduleText
          }
        >
          {closedHoursText.indexOf(hours) === -1 && (
            <Icon name="check_circle" width={24} color="#04b515" />
          )}
          <IonLabel>{hours}</IonLabel>
        </div>
        <div
          onClick={(
            e: React.MouseEvent<HTMLDivElement, MouseEvent>,
          ): void => {
            if (isFavorite) {
              removeFavoriteListingById(listing.id ?? -1);
            } else {
              addFavoriteListing(listing);
            }
            isFavorite = !isFavorite;
            e.stopPropagation();
          }}
          className={classes.listingIcons}
        >
          <div className={classes.listingIcon}>
            <Icon name={isFavorite ? 'heart_solid' : 'heart'} width={24} color={primaryColor} />
          </div>
          <a
            onClick={(
              e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
            ): void => e.stopPropagation()}
            href={`tel:${listing.phone_number}`}
            className={classes.listingIcon}
          >
            <Icon name="phone" width={24} color={primaryColor} />
          </a>
          <div
            onClick={(
              e: React.MouseEvent<HTMLDivElement, MouseEvent>,
            ): void => {
              goToDirections(isDesktop, listing);
              e.stopPropagation();
            }}
            className={classes.listingIcon}
          >
            <Icon name="directions" width={24} color={primaryColor} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
