import { action, thunk, computed } from 'easy-peasy';

import MetaModel, { CategoriesByName } from '../interfaces/StoreModel/MetaModel';
import { meta, pages } from '../lib/api/services';
import { slugify } from '../lib/utils';
import { Category, SubCategory } from '../interfaces/Meta';

const model: MetaModel = {
  error: '',
  loading: false,
  data: null,
  categoriesByName: computed((state) => {
    if (!state.data) return {};
    return state.data.category.reduce((byName: CategoriesByName, item) => {
      byName[item.name] = item.sub_categories.map((subItem) => subItem.name);
      return byName;
    }, {});
  }),
  setData: action((state, payload) => {
    state.data = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  get: thunk(async (actions) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      const [data, terms_and_conditions] = await Promise.all([
        meta.find({
          query: {
            contents: true,
          },
        }),
        pages.get('terms-of-use-privacy-policy'),
      ]);
      const categories = data.category.map((c: Category) => {
        let sub_categories: SubCategory[] = [];
        if (c.sub_categories) {
          sub_categories = c.sub_categories.map(sc => ({ ...sc, slug: slugify(sc.name) }));
        }
        return { ...c, slug: slugify(c.name), sub_categories };
      });
      localStorage.setItem('categories', JSON.stringify(categories));
      actions.setData({ ...data, category: categories, terms_and_conditions });
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
};

export default model;
