import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '5rem',
    padding: '2rem',
  },
  button: {
    marginTop: '20px',
  },
  input: {
    marginTop: '17px',
    border: '2px solid var(--ion-color-primary)',
    borderRadius: '5px',
    '--background': '#FFF',
    '--border-color': 'transparent!important',
    '--highlight-height': '0',
    padding: '0px',
    '&.item-has-focus': {
      border: '3px solid var(--ion-color-primary)',
    },
    '&.item-input ion-label, &.item-textarea ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
    },
    '&.item-has-focus ion-label, &.item-has-value ion-label': {
      color: 'var(--ion-color-primary)',
      opacity: 0.5,
      position: 'absolute',
      fontSize: 10,
      top: 5,
      left: 7,
      margin: 0,
    },
    '& ion-input': {
      '--placeholder-color': 'var(--ion-color-primary)',
    },
  },
});
