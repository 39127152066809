// eslint-disable-next-line import/no-unresolved
import { Feature, FeatureCollection, Point } from 'geojson';
import {
  AnyLayer,
  GeoJSONSource, Layer, LngLat, LngLatBounds,
} from 'mapbox-gl';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import MapGL, {
  InteractiveMap,
  PointerEvent,
  ViewportProps,
  Popup,
  NavigationControl,
} from 'react-map-gl';
import {
  useHistory, useRouteMatch,
} from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import { FormattedMessage } from 'react-intl';
import SearchResult from '../interfaces/SearchResult';
import { useStoreState } from '../store';
import { listings } from '../lib/api/services';
import useStyles from './styles';
import routePaths from '../lib/routePaths';
import { translateName } from '../lib/utils';
import PaginatedListingCard from '../apps/Public/PaginatedListingCard';
import Listing from '../interfaces/Listing';
import useIsDesktop from '../lib/hooks/useIsDesktop';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const collierCountyBounds = {
  top: 26.525144,
  bottom: 25.799152,
  left: -81.847844,
  right: -80.867314,
};

const debug = false;
// @ts-ignore
const debugLog = (...args) => {
  if (debug) {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

function withinCollierCounty(latitude: number, longitude: number): boolean {
  if (
    collierCountyBounds.top >= latitude
    && latitude >= collierCountyBounds.bottom
  ) {
    if (
      collierCountyBounds.left <= collierCountyBounds.right
      && collierCountyBounds.left <= longitude
      && longitude <= collierCountyBounds.right
    ) {
      return true;
    }
    if (
      collierCountyBounds.left > collierCountyBounds.right
      && (collierCountyBounds.left <= longitude
        || longitude <= collierCountyBounds.right)
    ) {
      return true;
    }
  }
  return false;
}

interface MatchParams {
  id?: string;
}

interface Properties {
  id: number;
}

interface SearchResultProperties extends SearchResult {
  items: Listing[];
}

interface MapProps {
  showMultiPopup?: boolean;
  data: SearchResult[];
  onSelected?: (result: Properties | null) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMultiSelected?: (results: any[]) => void;
}
const initialZoom = 9;
const sourceId = 'ncef';
const clustersLayerId = 'clusters';
const pinsLayerId = 'unclustered-point';

// Create a FeatureCollection of pins for the map from the SearchResult supplied.
const dataToFeatureCollection = (
  data: SearchResult[],
  locale: string,
  priorityId: string,
): FeatureCollection => {
  const uniqueLocations = new Map<string, SearchResult[]>();
  const locations = data.filter((d) => d.longitude && d.latitude);

  locations.forEach((location) => {
    const id = [location.longitude, location.latitude].join(',');
    if (!uniqueLocations.has(id)) {
      uniqueLocations.set(id, []);
    }
    (uniqueLocations.get(id) as SearchResult[]).push(location);
  });

  return {
    type: 'FeatureCollection',
    // @ts-ignore
    features: [...uniqueLocations.values()].map((items: SearchResult[]) => {
      const [location] = items;
      if (priorityId) {
        debugLog('finding top item...', priorityId);
        const id = Number(priorityId);
        const topResult = items.findIndex((item) => item.id === id);
        if (topResult) {
          const [topItem] = items.splice(topResult, 1);
          items.unshift(topItem);
        }
      }
      let name = translateName(items[0], locale);
      const remainingItems = items.length - 1;
      if (items.length > 1) {
        if (locale === 'en') {
          name += ` and ${remainingItems} more.`;
        } else {
          name += ` y ${remainingItems} más.`;
        }
      }
      return {
        type: 'Feature',
        properties: {
          id: location.id,
          name,
          latitude: location.latitude,
          longitude: location.longitude,
          items,
          listing_count: items.length,
        },
        geometry: {
          type: 'Point',
          coordinates: [location.longitude, location.latitude, 0],
        },
      };
    }),
  };
};

// Create a Layer for the map pin clusters.
const getLayer = (layerId: string): Layer => ({
  id: layerId,
  source: sourceId,
  type: 'circle',
  filter: ['has', 'point_count'],
  paint: {
    // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
    // with three steps to implement three types of circles:
    //   * Blue, 20px circles when point count is less than 100
    //   * Yellow, 30px circles when point count is between 100 and 750
    //   * Pink, 40px circles when point count is greater than or equal to 750
    'circle-color': [
      'step',
      ['get', 'point_count'],
      'rgba(48, 176, 166, 0.6)',
      20,
      'rgba(226, 180, 11, 0.6)',
      40,
      'rgba(219, 0, 104, 0.6)',
    ],
    'circle-radius': ['step', ['get', 'point_count'], 20, 20, 30, 40, 40],
  },
});

const MapComponent: React.FC<MapProps> = ({
  data,
  showMultiPopup = true,
  onSelected = (): void => {},
  onMultiSelected = (): void => {},
}: MapProps) => {
  const mapRef = useRef<InteractiveMap>(null);
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [popupItem, setPopupItem] = useState<SearchResultProperties | null>(null);
  const match = useRouteMatch(routePaths.public.listingDetail);
  const priorityId = (match?.params as MatchParams)?.id;
  const [originalPriorityId, setOriginalPriorityId] = useState(priorityId);
  const currentCenter = useStoreState((state) => state.map.center);
  const favoriteListings = useStoreState(
    (state) => state.favorites.favoriteListings,
  );
  const locale = useStoreState((state) => state.translations.language);
  const [currentData, setCurrentData] = useState(data);
  const [currentLocale, setCurrentLocale] = useState(locale);
  const [currentFeatureCollection, setCurrentFeatureCollection] = useState<FeatureCollection>({
    type: 'FeatureCollection',
    features: [],
  });
  const [viewport, setViewport] = useState<Partial<ViewportProps>>({
    latitude: 26.06611,
    longitude: -81.69728,
    zoom: initialZoom,
  });
  const isDesktop = useIsDesktop();

  const centerOnPins = useCallback(
    (features: Array<Feature>): void => {
      if (currentCenter && currentCenter.latitude && currentCenter.longitude) return;
      if (features.length === 0) return;
      const firstPointCoordinates = (features[0].geometry as Point)
        .coordinates as [number, number];
      const bounds = features.reduce(
        (boundsAcc, f) => boundsAcc.extend(
          new LngLat(
            (f.geometry as Point).coordinates[0],
            (f.geometry as Point).coordinates[1],
          ),
        ),
        new LngLatBounds(firstPointCoordinates, firstPointCoordinates),
      );

      debugLog('centering on pins...');
      const center = bounds.getCenter();
      setViewport((current) => ({
        latitude: center.lat,
        longitude: center.lng,
        zoom: current.zoom,
      }));
    },
    [currentCenter],
  );

  useEffect(() => {
    if (currentCenter) {
      if (
        withinCollierCounty(currentCenter.latitude, currentCenter.longitude)
      ) {
        setViewport((current) => ({
          latitude: currentCenter.latitude,
          longitude: currentCenter.longitude,
          zoom: current.zoom,
        }));
      }
    }
  }, [currentCenter]);

  useEffect(() => {
    const map = mapRef.current && mapRef.current.getMap();
    if (loading || !map) return;
    const mapSource = map.getSource(sourceId) as GeoJSONSource;

    if (mapSource) {
      debugLog('setting data...');
      mapSource.setData(currentFeatureCollection);
    }
  }, [loading, currentFeatureCollection]);

  useEffect(() => {
    if (currentCenter && currentCenter.latitude && currentCenter.longitude) {
      debugLog('setting explicit center zoom 16');
      setViewport({
        latitude: currentCenter.latitude,
        longitude: currentCenter.longitude,
        zoom: 16,
      });
    } else {
      debugLog('reseting zoom');
      setViewport((current) => ({
        latitude: current.latitude,
        longitude: current.longitude,
        zoom: initialZoom,
      }));
    }
    setPopupItem(null);
  }, [currentCenter]);

  useEffect(() => {
    if (loading && currentFeatureCollection.features.length === 0) {
      debugLog('loading... setting initial features.');
      const featureCollection = dataToFeatureCollection(
        data,
        locale,
        priorityId || '',
      );
      setCurrentFeatureCollection(featureCollection);
      centerOnPins(currentFeatureCollection.features);
    } else if (currentLocale !== locale) {
      debugLog('locale changed... setting feature collection...');
      setCurrentLocale(locale);
      setCurrentFeatureCollection(
        dataToFeatureCollection(data, locale, priorityId || ''),
      );
    } else if (currentData !== data || originalPriorityId !== priorityId) {
      debugLog('data changed! updating feature collection...');
      setCurrentFeatureCollection(dataToFeatureCollection(data, locale, priorityId || ''));
      setOriginalPriorityId(priorityId);
    }
  }, [
    loading,
    currentFeatureCollection,
    currentData,
    data,
    currentLocale,
    locale,
    centerOnPins,
    priorityId,
    originalPriorityId,
  ]);

  useEffect(() => {
    setCurrentData(data);
  }, [data]);

  // Add Layers on load
  const handleMapLoaded = useCallback(() => {
    const map = mapRef.current && mapRef.current.getMap();
    if (!map) return;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    map.loadImage('assets/map/marker.png', (error: unknown, image: any) => {
      if (error) return;
      map.addImage('marker', image);
      // Create and add map pin data source.
      map.addSource(sourceId, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: [],
        },
        cluster: true,
        clusterMaxZoom: 14, // Max zoom to cluster points on
        clusterRadius: 70, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: {
          total_listings: ['+', ['get', 'listing_count']],
        },
      });

      map.addLayer(getLayer(clustersLayerId) as AnyLayer);

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: sourceId,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{total_listings}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 18,
          'text-allow-overlap': true,
          'icon-allow-overlap': true,
        },
      });

      // Create and add layer for map pins.
      map.addLayer({
        id: pinsLayerId,
        type: 'symbol',
        source: sourceId,
        filter: ['!', ['has', 'point_count']],
        layout: {
          'text-field': ['get', 'name'],
          'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
          'text-radial-offset': 1,
          // @ts-ignore
          'text-justify': 'auto',
          'icon-image': 'marker',
          'text-allow-overlap': true,
          'icon-allow-overlap': true,
        },
        paint: {
          'text-color': '#00205b',
          'text-halo-color': '#ffffff',
          'text-halo-width': 2,
        },
      });

      setLoading(false);
    });
  }, []);

  // Global Click handler
  const handleClick = useCallback(
    (event: PointerEvent) => {
      // Bail if map is not available.
      const map = mapRef.current && mapRef.current.getMap();
      if (!map) return;

      // Determine if this event occurred on the clusters layer.
      const clusterFeature = event.features
        && (event.features as Feature[]).find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (f: any) => f.layer.id === clustersLayerId,
        );
      if (clusterFeature) {
        /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion,
        @typescript-eslint/no-explicit-any */
        const clusterId = (clusterFeature.properties as any).cluster_id;
        /* eslint-enable @typescript-eslint/no-unnecessary-type-assertion,
        @typescript-eslint/no-explicit-any */
        const mapSource = map.getSource(sourceId) as GeoJSONSource;
        if (!mapSource) return;
        // Zoom in on cluster.
        mapSource.getClusterExpansionZoom(clusterId, (error, zoom) => {
          if (error) return;
          /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion,
          @typescript-eslint/no-explicit-any */
          const center = (clusterFeature.geometry as any).coordinates;
          /* eslint-enable @typescript-eslint/no-unnecessary-type-assertion,
          @typescript-eslint/no-explicit-any */
          setViewport({ longitude: center[0], latitude: center[1], zoom });
        });
        return;
      }

      // Determine if this event occurred on the map pins layer.
      const pinsFeature = event.features
        && (event.features as Feature[]).find(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (f: any) => f.layer.id === pinsLayerId,
        );
      if (pinsFeature) {
        let items: SearchResult[] = [];
        try {
          items = JSON.parse(pinsFeature.properties?.items);
        } catch (error) {
          // unable to parse...
        }
        if (items.length > 1) {
          // @ts-ignore
          setPopupItem({
            ...pinsFeature.properties,
            items: [],
          });
          (async (): Promise<void> => {
            const foundItems = await listings.find({
              query: {
                id: {
                  $in: items.map((item) => item.id),
                },
                $limit: items.length,
              },
            });
            if (showMultiPopup) {
              const { data: resultsData } = foundItems;
              if (priorityId) {
                debugLog('finding top item...', priorityId);
                const id = Number(priorityId);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const topResult = resultsData.findIndex((item: any) => item.id === id);
                if (topResult) {
                  const [topItem] = resultsData.splice(topResult, 1);
                  resultsData.unshift(topItem);
                }
              }
              // @ts-ignore
              setPopupItem({
                ...pinsFeature.properties,
                items: resultsData,
              });
            } else {
              setPopupItem(null);
              onMultiSelected(foundItems.data);
            }
          })();
        } else {
          setPopupItem(null);
          onSelected(pinsFeature.properties as Properties);
        }
      }
    },
    [onSelected, showMultiPopup, onMultiSelected, priorityId],
  );

  useEffect(() => {
    setPopupItem(null);
  }, [priorityId]);

  const handleCursor = useCallback(
    ({ isHovering }) => (isHovering ? 'pointer' : 'grab'),
    [],
  );

  const goToListingDetail = (id: number | undefined): void => {
    if (id) {
      setPopupItem(null);
      const path = routePaths.public.listingDetail.replace(':id', `${id}`);
      history.push(path);
    }
  };

  return (
    <MapGL
      ref={mapRef}
      {...viewport}
      width="auto"
      height="auto"
      onViewportChange={(v): void => setViewport(v)}
      onLoad={handleMapLoaded}
      getCursor={handleCursor}
      interactiveLayerIds={[clustersLayerId, pinsLayerId]}
      onClick={handleClick}
      mapboxApiAccessToken={MAPBOX_TOKEN}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      style={{
        position: 'absolute',
        overflow: 'hidden',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
      }}
    >
      {isDesktop && (
        <NavigationControl
          className={classes.mapNavigationControl}
          showCompass={false}
        />
      )}
      {showMultiPopup && popupItem ? (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={popupItem.longitude}
          latitude={popupItem.latitude}
          closeOnClick={false}
          onClose={(): void => setPopupItem(null)}
          className={classes.popupStyles}
        >
          {!popupItem.items.length ? (
            <h2>
              <FormattedMessage id="loading-ellipsis" />
            </h2>
          ) : (
            <PaginatedListingCard
              favoriteListings={favoriteListings}
              items={popupItem.items}
              goToListingDetail={goToListingDetail}
            />
          )}
        </Popup>
      ) : null}
    </MapGL>
  );
};

export default MapComponent;
