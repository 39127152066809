import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IonButton, IonIcon } from '@ionic/react';

import { addCircle } from 'ionicons/icons';
import useAppStyles from '../../styles';
import { useStoreActions, useStoreState } from '../../../store';
import routePaths from '../../../lib/routePaths';

const SubmitForReview: React.FC = () => {
  const appClasses = useAppStyles();
  const history = useHistory();
  const {
    createOrg: { setCurrentStep },
    provider: { getProviderById, submitForApprovalById },
  } = useStoreActions((actions) => ({
    createOrg: actions.createOrg,
    provider: actions.provider,
  }));
  const { user } = useStoreState((state) => state.auth);
  const { currentProvider } = useStoreState((state) => state.provider);

  useEffect(() => {
    setCurrentStep(3);
    if (user && user.provider && user.provider.id && currentProvider === null) {
      getProviderById(Number(user.provider.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addService = (): void => {
    history.push(routePaths.provider.onboarding.addService);
  };

  const submitForReview = (): void => {
    if (user && user.provider && user.provider.id) {
      submitForApprovalById(Number(user.provider.id));
    }
  };

  return (
    <>
      <h4 className={appClasses.title}>
        Your service listing(s) below have been saved. Would you like to submit
        your saved listing(s) for review now, or add another? If your
        organization is approved, you can add more service listings later.
      </h4>
      {currentProvider && currentProvider.listings && currentProvider.listings.length > 0 ? (
        currentProvider?.listings?.map((listing) => (
          <h4
            key={listing.id}
            className={appClasses.serviceLink}
            onClick={(): void => history.push(`${routePaths.provider.onboarding.editService}/${listing.id}`)}
          >• {listing.name}
          </h4>
        ))) : (
        null)}
      <IonButton
        className="cc-button cc-tall-button mt-24"
        fill="clear"
        color="primary"
        expand="block"
        onClick={(): void => addService()}
      >
        Add Another Listing
        <IonIcon slot="start" icon={addCircle} />
      </IonButton>
      <IonButton
        className="cc-button cc-tall-button mt-24"
        shape="round"
        type="submit"
        expand="block"
        onClick={(): void => submitForReview()}
      >
        Submit Saved Listing(s)
      </IonButton>
    </>
  );
};

export default SubmitForReview;
