import { Storage } from '@capacitor/storage';
import { isPlatform } from '@ionic/react';
import React, { useEffect } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import routePaths from '../../lib/routePaths';
import CrisisPage from './CrisisPage';
import Help from './Help';
import Home from './Home';
import ListingDetailPage from './ListingDetailPage';
import Onboarding from './Onboarding';
import Page from './Page';
import SavedServices from './SavedServices';
import Search from './Search';
import SearchResultsPage from './SearchResultsPage';
import Settings from './Settings';
import SubcategoryPage from './SubcategoryPage';

const routes = [
  {
    component: Help,
    path: routePaths.public.help,
  },
  {
    component: SavedServices,
    path: routePaths.public.favorites,
  },
  {
    component: Settings,
    path: routePaths.public.settings,
  },
  {
    component: Onboarding,
    path: routePaths.public.onboarding,
  },
  {
    component: Home,
    path: routePaths.public.home,
  },
  {
    component: Search,
    path: routePaths.public.search,
  },
  {
    component: SubcategoryPage,
    path: routePaths.public.subcategories,
  },
  {
    component: SearchResultsPage,
    path: routePaths.public.listings,
  },
  {
    component: ListingDetailPage,
    path: routePaths.public.listingDetail,
  },
  {
    component: CrisisPage,
    path: routePaths.public.crisis,
  },
  {
    component: Page,
    path: routePaths.public.page,
  },
];

const Public: React.FC = () => {
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    async function routeToOnboarding(): Promise<void> {
      const onboarded = await Storage.get({ key: 'seeker_onboarded' });
      if (onboarded.value == null) {
        history.push(routePaths.public.onboarding);
      }
    }
    if (((isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb')) && location.pathname === routePaths.public.root) {
      routeToOnboarding();
    }
  }, [history, location.pathname]);

  return isDesktop ? (
    <Switch>
      <Route exact path={routePaths.public.settings} component={Settings} />
      <Route exact path={routes.map(({ path }) => path)} component={Home} />
      <Redirect path={routePaths.public.root} to={routePaths.public.home} />
    </Switch>
  ) : (
    <Switch>
      {routes.map(({ component, path }) => (
        <Route key={path} exact path={path} component={component} />
      ))}
      <Redirect path={routePaths.public.root} to={routePaths.public.home} />
    </Switch>
  );
};

export default Public;
