import { action, thunk, computed } from 'easy-peasy';
import { Network } from '@capacitor/network';
import {
  CrisisGroup,
  CrisisGroupForm,
  CrisisHotline,
  CrisisHotlineForm,
  CrisisHotlineWithCategory,
} from '../interfaces/CrisisHotlines';
import CrisisHotlinesModel from '../interfaces/StoreModel/CrisisHotlinesModel';
import { crisisHotlines, crisisHotlineCategories } from '../lib/api/services';

const serializeCrisisHotline = (data: CrisisHotlineForm): CrisisHotline => {
  (data as CrisisHotline).translation = {
    es: {
      name: data.name_es || '',
    },
  };
  delete data.name_es;
  return data as CrisisHotline;
};

const serializeCrisisGroup = (data: CrisisGroupForm): CrisisGroup => {
  (data as CrisisGroup).translation = {
    es: {
      name: data.name_es || '',
    },
  };
  delete data.name_es;
  return data as CrisisGroup;
};

const model: CrisisHotlinesModel = {
  error: '',
  loading: false,
  success: false,
  setSuccess: action((state, payload) => {
    state.success = payload;
  }),
  data: null,
  setData: action((state, payload) => {
    state.data = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  getCategory: thunk(async (actions, id) => {
    let response: CrisisGroup | null = null;
    actions.setLoading(true);
    try {
      actions.setError('');
      response = await crisisHotlineCategories.get(id);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
    return response;
  }),
  get: thunk(async (actions, id, { getStoreState }) => {
    const {
      crisisHotlines: { data: crisisGroups },
    } = getStoreState();
    actions.setLoading(true);
    try {
      actions.setError('');
      const data: CrisisHotline = await crisisHotlines.get(id);
      const newCrisisGroups = crisisGroups?.reduce<CrisisGroup[]>(
        (acc, curr) => {
          const group = { ...curr };
          if (curr.id === data.crisis_hotline_category_id) {
            const foundHotlineIndex = curr.hotlines?.findIndex(
              (h) => h.id === data.id,
            );
            const newHotlines = [...(curr.hotlines ?? [])];
            if (foundHotlineIndex !== undefined && foundHotlineIndex > -1) {
              newHotlines[foundHotlineIndex] = data;
            } else {
              newHotlines.push(data);
            }
            group.hotlines = newHotlines;
          }
          acc = [...acc, group];
          return acc;
        },
        [],
      );
      actions.setData(newCrisisGroups ?? []);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  find: thunk(async (actions) => {
    actions.setLoading(true);
    try {
      let data = [];
      const status = await Network.getStatus();
      actions.setError('');
      if (status.connected) {
        data = await crisisHotlines.find();
        localStorage.setItem('crisisHotlines', JSON.stringify(data));
        localStorage.setItem('crisisHotlinesLastFetchedDate', String(new Date().getTime()));
      } else {
        data = localStorage.getItem('crisisHotlines') ? JSON.parse(localStorage.getItem('crisisHotlines') as string) : [];
      }
      actions.setData(data);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  create: thunk(async (actions, data, { getStoreState }) => {
    actions.setLoading(true);
    const { data: crisisGroups } = getStoreState().crisisHotlines;
    try {
      actions.setError('');
      serializeCrisisHotline(data);
      let newCrisisGroups: CrisisGroup[] = [];
      if (crisisGroups) {
        newCrisisGroups = [...crisisGroups];
      }
      const newCrisisHotline: CrisisHotline = await crisisHotlines.create(data);
      const groupIndex = newCrisisGroups.findIndex(
        (g) => g.id === newCrisisHotline.crisis_hotline_category_id,
      );
      if (groupIndex > -1) {
        const hotlines = newCrisisGroups[groupIndex].hotlines ?? [];
        const newHotlines = [...hotlines, newCrisisHotline];
        newCrisisGroups[groupIndex].hotlines = newHotlines;
        actions.setData(newCrisisGroups);
      }
      actions.setSuccess(true);
    } catch (error) {
      actions.setSuccess(false);
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  update: thunk(async (actions, data, { getStoreState }) => {
    actions.setLoading(true);
    const { data: crisisGroups } = getStoreState().crisisHotlines;
    try {
      actions.setError('');
      serializeCrisisHotline(data);
      const dataWithoutId = { ...data };
      delete dataWithoutId.id;
      const updatedCrisisHotline: CrisisHotline = await crisisHotlines.update(
        data.id,
        dataWithoutId,
      );
      const updatedCrisisGroups = crisisGroups?.map<CrisisGroup>((group) => {
        let newHotlines: CrisisHotline[] = [];
        if (group.hotlines) {
          newHotlines = [...group.hotlines];
        }
        const hotlineIndex = newHotlines.findIndex((h) => h.id === data.id);
        if (group.id === updatedCrisisHotline.crisis_hotline_category_id) {
          if (hotlineIndex > -1) {
            newHotlines[hotlineIndex] = {
              ...newHotlines[hotlineIndex],
              ...updatedCrisisHotline,
            };
          } else {
            newHotlines.push({ ...updatedCrisisHotline, id: data.id as number });
          }
        } else if (hotlineIndex > -1) {
          newHotlines.splice(hotlineIndex, 1);
        }

        return { ...group, hotlines: newHotlines };
      });
      actions.setData(updatedCrisisGroups ?? []);
      actions.setSuccess(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  remove: thunk(async (actions, id, { getStoreState }) => {
    actions.setLoading(true);
    const { data: crisisGroups } = getStoreState().crisisHotlines;
    try {
      actions.setError('');
      await crisisHotlines.remove(id);
      const newCrisisGroups = crisisGroups?.reduce<CrisisGroup[]>(
        (acc, curr) => {
          const { hotlines } = curr;
          let newHotlines: CrisisHotline[] = [];
          if (hotlines) {
            newHotlines = [...hotlines];
            const hotlineIndex = hotlines?.findIndex((h) => h.id === id);
            if (hotlineIndex !== undefined && hotlineIndex > -1) {
              newHotlines?.splice(hotlineIndex, 1);
            }
          }
          acc = [...acc, { ...curr, hotlines: newHotlines }];
          return acc;
        },
        [],
      );
      actions.setData(newCrisisGroups ?? []);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  createCategory: thunk(async (actions, data, { getStoreState }) => {
    actions.setLoading(true);
    const { data: crisisGroups } = getStoreState().crisisHotlines;
    try {
      actions.setError('');
      serializeCrisisGroup(data);
      const newCrisisGroup: CrisisGroup = await crisisHotlineCategories.create(
        data,
      );
      const newCrisisGroups = [...(crisisGroups ?? []), newCrisisGroup];
      actions.setData(newCrisisGroups);
      actions.setSuccess(true);
    } catch (error) {
      actions.setSuccess(false);
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  updateCategory: thunk(async (actions, data, { getStoreState }) => {
    actions.setLoading(true);
    const { data: crisisGroups } = getStoreState().crisisHotlines;
    try {
      actions.setError('');
      serializeCrisisGroup(data);
      const dataWithoutId = { ...data };
      delete dataWithoutId.id;
      const updatedCrisisGroup = await crisisHotlineCategories.update(
        data.id,
        dataWithoutId,
      );
      const crisisGroupIndex = crisisGroups?.findIndex((g) => g.id === data.id);
      if (
        crisisGroups !== null
        && crisisGroupIndex !== undefined
        && crisisGroupIndex > -1
      ) {
        const newCrisisGroups = [...(crisisGroups ?? [])];
        newCrisisGroups.splice(crisisGroupIndex, 1, {
          ...crisisGroups[crisisGroupIndex],
          ...updatedCrisisGroup,
        });
        actions.setData(newCrisisGroups);
      }
      actions.setSuccess(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  removeCategory: thunk(async (actions, id, { getStoreState }) => {
    actions.setLoading(true);
    const { data: crisisGroups } = getStoreState().crisisHotlines;
    try {
      actions.setError('');
      await crisisHotlineCategories.remove(id);
      const newCrisisGroups = [...(crisisGroups ?? [])];
      const crisisGroupIndex = crisisGroups?.findIndex((g) => g.id === id);
      if (crisisGroupIndex !== undefined && crisisGroupIndex > -1) {
        newCrisisGroups.splice(crisisGroupIndex, 1);
        actions.setData(newCrisisGroups);
      }
      actions.setSuccess(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  hotlines: computed(state => {
    const hotlines = state.data?.reduce<
    CrisisHotlineWithCategory[]
    >((acc, curr) => {
      const category = { id: curr.id, name: curr.name };
      const newHotlines = curr.hotlines?.map<CrisisHotlineWithCategory>(
        (crisisHotline) => ({
          ...crisisHotline,
          category,
        }),
      );
      acc = [...acc, ...(newHotlines ?? [])];
      return acc;
    }, []);
    return hotlines ?? [];
  }),
};

export default model;
