import { IonRouterLink } from '@ionic/react';
import React, { useEffect } from 'react';
import {
  Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import ServiceListings from '../apps/Provider/Approved/ServiceListings';
import CurrentStepDesktop from '../apps/Provider/components/CurrentStep/Desktop';
import ViewProviderTypeDesktop from '../apps/Provider/components/ViewProviderTypeDesktop';
// @ts-ignore
import logo from '../assets/cc_logo.png';
import routePaths from '../lib/routePaths';
import MainCategory from '../apps/Public/MainCategory';
import SearchResults from '../apps/Public/SearchResults';
import useStyles from './styles';
import Subcategories from '../apps/Public/Subcategories';
import ListingDetail from '../apps/Public/ListingDetail';
import CrisisPage from '../apps/Public/CrisisPage';
import SettingsSidebar from '../apps/Public/SettingsSidebar';
import Icon from './Icon';

const DesktopSidebar: React.FunctionComponent = () => {
  const primaryColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--ion-color-primary');
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === routePaths.provider.approved.root) {
      history.push(`${routePaths.provider.approved.editService}/0`);
    }
  }, [location.pathname, history]);

  return (
    <div className={classes.sidebar}>
      {location.pathname === routePaths.public.home
      || location.pathname === routePaths.public.listings
      || location.pathname === routePaths.public.favorites
      || location.pathname.startsWith('/cc/page/')
      || location.pathname.startsWith(routePaths.provider.root) ? (
        <>
          <div className={classes.ccDesktopSidebarHeader}>
            <div className={classes.ccDesktopLogoWrapper}>
              <IonRouterLink routerLink={routePaths.public.home}>
                <img
                  src={logo}
                  alt="home"
                  style={{
                    height: '60px',
                    width: '164px',
                  }}
                />
              </IonRouterLink>
            </div>
            {(location.pathname === routePaths.public.listings
              || location.pathname === routePaths.public.subcategories) && (
              <div
                className={classes.headerGoBackIcon}
                onClick={() => history.goBack()}
              >
                <Icon name="arrow_back" width={40} color={primaryColor} />
              </div>
            )}
          </div>
        </>
        ) : null}
      <Switch>
        <Route path={routePaths.public.home} component={MainCategory} />
        <Route path={routePaths.public.page} component={MainCategory} />
        <Route
          path={routePaths.public.listingDetail}
          component={ListingDetail}
        />
        <Route
          path={routePaths.public.subcategories}
          component={Subcategories}
        />
        <Route path={routePaths.public.crisis} component={CrisisPage} />
        <Route path={routePaths.public.listings} component={SearchResults} />
        <Route path={routePaths.public.favorites} component={SearchResults} />
        <Route path={routePaths.public.settings} component={SettingsSidebar} />
        <Route
          path={routePaths.provider.onboarding.viewProfile}
          component={ViewProviderTypeDesktop}
        />
        <Route
          path={routePaths.provider.approved.editProfile}
          component={ViewProviderTypeDesktop}
        />
        <Route
          path={routePaths.provider.onboarding.root}
          component={CurrentStepDesktop}
        />
        {location.pathname.startsWith(routePaths.provider.approved.root) ? (
          <Route component={ServiceListings} />
        ) : null}
      </Switch>
    </div>
  );
};

export default DesktopSidebar;
