/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  IonLabel,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonButtons,
  IonIcon,
  IonAlert,
} from '@ionic/react';

import React, { useState } from 'react';
import { globe, caretForward, caretBack } from 'ionicons/icons';
import { format } from 'date-fns';
import useStyles from '../styles';
import useService from '../../../lib/hooks/useService';
import { providers } from '../../../lib/api/services';
import Provider from '../../../interfaces/Provider';
import { Page } from '../../../interfaces/PaginationResult';
import { useStoreActions } from '../../../store';
import ServiceListingsPreview from './ServiceListingsPreview';

const NewProviders: React.FC = () => {
  const { setProvider } = useStoreActions(actions => actions.provider);
  const { setListingPreviewModalListings } = useStoreActions(actions => actions.admin);
  const classes = useStyles();
  const resultPageSize = 5;
  const [page, setPage] = useState({ limit: resultPageSize, skip: 0 });
  const [filter, setFilter] = useState({
    approver_id: null,
    submit_date: {
      $ne: null,
    },
  });
  const [showDenyConfirm, setShowDenyConfirm] = useState<Provider | null>(null);
  const [showApproveConfirm, setShowApproveConfirm] = useState<Provider | null>(null);
  const { error, result } = useService<Provider>(providers, page, filter);
  const { approveById } = useStoreActions(actions => actions.provider);

  const formatProviderTypeString = (provider: Provider): string => {
    if (provider.type == null || provider.type.name.trim().length === 0) {
      return '';
    }
    return provider.type.name + (provider.ein != null && provider.ein.length > 0 ? ` EIN - ${provider.ein}` : '');
  };

  const formatSubmittedString = (isoString: string): string => {
    const createdDate: Date = new Date(isoString);
    return `Submitted ${format(createdDate, 'MM/dd/yyyy')}`;
  };

  const formatResubmittedString = (
    isoStringUpdated: string | null | undefined,
    isoStringCreated: string | null | undefined,
  ): string => {
    if (isoStringUpdated && (isoStringCreated !== isoStringUpdated)) {
      const updatedDate: Date = new Date(isoStringUpdated);
      return `Resubmitted ${format(updatedDate, 'MM/dd/yyyy')}`;
    }
    return '';
  };

  const changeResultsPage = (newPage: Page): void => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  const approveProvider = async (id: number, approved: boolean): Promise<void> => {
    await approveById({ id, approved });
    setFilter({ ...filter });
  };

  return (
    <div className={classes.adminTabContent}>
      {result.data.map((provider) => (
        <React.Fragment key={provider.id}>
          <div className={classes.providerCardHeader}>
            <IonLabel
              className="ion-no-margin ion-no-padding"
              color="secondary"
            >
              {formatProviderTypeString(provider)}
            </IonLabel>
            <div>
              <IonLabel className="mr-16 ion-no-padding" color="light">
                {formatResubmittedString(provider.updated_at, provider.submit_date)}
              </IonLabel>
              <IonLabel className="ion-no-margin ion-no-padding" color="light">
                {provider.submit_date === null ? '' : formatSubmittedString(provider.submit_date!)}
              </IonLabel>
            </div>
          </div>
          <div className={classes.providerCardContent}>
            <IonGrid>
              <IonRow>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="8">
                  <div className={classes.providerNameRow}>
                    <IonItem className={[classes.providerOrgName, 'disabled-pointer-events'].join(' ')}>
                      <IonLabel position="floating" color="primary">
                        Organization Name
                      </IonLabel>
                      <IonInput
                        color="primary"
                        value={provider.name}
                      />
                    </IonItem>
                    <IonIcon className="mt-16" size="large" color="primary" icon={globe} onClick={(): Window | null => window.open(provider.website_url, '_blank')} />
                  </div>
                  <IonItem className="disabled-pointer-events">
                    <IonLabel position="floating" color="primary">
                      Contact
                    </IonLabel>
                    <IonInput
                      color="primary"
                      value={provider.user?.name}
                    />
                  </IonItem>
                  <IonItem className="disabled-pointer-events">
                    <IonLabel position="floating" color="primary">
                      Email
                    </IonLabel>
                    <IonInput
                      color="primary"
                      value={provider.user?.email}
                    />
                  </IonItem>
                </IonCol>
                {/* <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="4">
                  <IonItem className={classes.providerCommentItem}>
                    <IonTextarea
                      className={classes.providerCommentTextarea}
                      placeholder="Comment"
                    />
                  </IonItem>
                </IonCol> */}
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="1" />
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
                  <IonButton
                    className={classes.providerActionButton}
                    shape="round"
                    fill="solid"
                    color="primary"
                    disabled={(provider.listings?.length ?? 0) === 0}
                    onClick={(): void => {
                      setProvider(provider);
                      setListingPreviewModalListings(provider.listings ?? []);
                    }}
                  >
                    View Services ({provider.listings?.length ?? 0})
                  </IonButton>
                  <IonButton
                    className={classes.providerActionButton}
                    shape="round"
                    fill="solid"
                    color="tertiary"
                    onClick={(): void => setShowApproveConfirm(provider)}
                  >
                    Approve
                  </IonButton>
                  <IonButton
                    className={classes.providerActionButton}
                    shape="round"
                    fill="outline"
                    color="primary"
                    href={`mailto:${provider.user?.email}`}
                  >
                    Contact
                  </IonButton>
                  <IonButton
                    className={[
                      classes.providerActionButton,
                      'linkButton',
                    ].join(' ')}
                    fill="clear"
                    color="primary"
                    onClick={(): void => setShowDenyConfirm(provider)}
                  >
                    Deny
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </React.Fragment>
      ))}
      <IonButtons slot="start">
        <IonButton hidden={result.skip === 0} color="primary" onClick={(): void => changeResultsPage({ limit: resultPageSize, skip: result.skip - resultPageSize })}>
          <IonIcon color="primary" icon={caretBack} />
          {result.skip - resultPageSize + 1} - {result.skip}
        </IonButton>
        <IonButton hidden={result.skip + resultPageSize >= (result.total ?? 0)} color="primary" onClick={(): void => changeResultsPage({ limit: resultPageSize, skip: result.skip + resultPageSize })}>
          {result.skip + resultPageSize + 1} - {result.skip + (2 * resultPageSize)}
          <IonIcon color="primary" icon={caretForward} />
        </IonButton>
      </IonButtons>
      <ServiceListingsPreview />
      {error.length > 0 ? <h4>Uh-oh, unable to retrieve data at this time.</h4> : null}
      <IonAlert
        isOpen={!!showApproveConfirm}
        onDidDismiss={(): void => setShowApproveConfirm(null)}
        message="Confirm that you want to approve this provider."
        buttons={[
          {
            text: 'Cancel',
          },
          {
            text: 'Confirm',
            handler: (): void => {
              if (showApproveConfirm) {
                approveProvider(Number(showApproveConfirm.id), true);
              }
            },
          },
        ]}
      />
      <IonAlert
        isOpen={!!showDenyConfirm}
        onDidDismiss={(): void => setShowDenyConfirm(null)}
        message="Confirm that you want to deny this provider."
        buttons={[
          {
            text: 'Cancel',
          },
          {
            text: 'Deny',
            handler: (): void => {
              if (showDenyConfirm) {
                approveProvider(Number(showDenyConfirm.id), false);
              }
            },
          },
        ]}
      />
    </div>
  );
};

export default NewProviders;
