import { action, thunk } from 'easy-peasy';
import { SubCategory, SubCategoryForm } from '../interfaces/Meta';
import SubCategoriesModel from '../interfaces/StoreModel/SubcategoriesModel';
import { subcategories } from '../lib/api/services';

const serializeSubcategory = (data: SubCategoryForm): SubCategory => {
  (data as SubCategory).translation = {
    es: {
      name: data.name_es || '',
    },
  };
  delete data.name_es;
  return data as SubCategory;
};

const model: SubCategoriesModel = {
  error: '',
  loading: false,
  success: false,
  setSuccess: action((state, payload) => {
    state.success = payload;
  }),
  data: null,
  setData: action((state, payload) => {
    state.data = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  create: thunk(async (actions, data, { getStoreState }) => {
    const {
      subcategories: { data: currentSubcategories },
    } = getStoreState();
    actions.setLoading(true);
    try {
      actions.setError('');
      serializeSubcategory(data);
      const newSubcategory = await subcategories.create(data);
      actions.setData([...(currentSubcategories ?? []), newSubcategory]);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  get: thunk(async (actions, id, { getStoreState }) => {
    const {
      subcategories: { data },
    } = getStoreState();
    let fetchedSubcategory: SubCategory | null = null;
    actions.setLoading(true);
    try {
      actions.setError('');
      fetchedSubcategory = await subcategories.get(id);
      const newSubcategories = [...(data ?? [])];
      if (fetchedSubcategory) {
        const foundIndex = data?.findIndex(s => s.id === Number(id));
        if (foundIndex !== undefined && foundIndex > -1) {
          newSubcategories[foundIndex] = fetchedSubcategory;
        } else {
          newSubcategories.push(fetchedSubcategory);
        }
      }
      actions.setData(newSubcategories);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
    return fetchedSubcategory;
  }),
  find: thunk(async (actions) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      const data = await subcategories.find();
      actions.setData(data);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  update: thunk(async (actions, data, { getStoreState }) => {
    const {
      subcategories: { data: currentSubcategories },
    } = getStoreState();
    actions.setLoading(true);
    try {
      const dataWithoutId = { ...data };
      delete dataWithoutId.id;
      const updatedSubcategory = await subcategories.update(
        data.id,
        serializeSubcategory(dataWithoutId),
      );
      const foundIndex = currentSubcategories?.findIndex(
        (c) => c.id === data.id,
      );
      const updatedSubcategories: SubCategory[] = [
        ...(currentSubcategories ?? []),
      ];
      if (foundIndex !== undefined && foundIndex > -1) {
        updatedSubcategories[foundIndex] = updatedSubcategory;
      } else {
        updatedSubcategories.push(updatedSubcategory);
      }
      actions.setData(updatedSubcategories);
      actions.setSuccess(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  remove: thunk(async (actions, id, { getStoreState }) => {
    actions.setLoading(true);
    const {
      subcategories: { data: currentSubcategories },
    } = getStoreState();
    try {
      actions.setError('');
      await subcategories.remove(id);
      const newSubcategories = [...(currentSubcategories ?? [])];
      const foundIndex = newSubcategories.findIndex((s) => s.id === id);
      if (foundIndex !== undefined && foundIndex > -1) {
        newSubcategories.splice(foundIndex, 1);
        actions.setData(newSubcategories ?? []);
      }
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
};

export default model;
