import { EventEmitter } from 'events';
import { ServiceMethods } from '@feathersjs/feathers';
import { action, thunk, computed } from 'easy-peasy';

import ServiceModel, {
  ObjectWithId,
  MetaType,
} from '../interfaces/StoreModel/ServiceModel';

export const serviceModel = <DataItem extends ObjectWithId>(
  service: ServiceMethods<DataItem> & EventEmitter,
  dataItemFormater: (dataItem: DataItem) => DataItem = (
    item: DataItem,
  ): DataItem => item,
  itemSortCompare: (a: DataItem, b: DataItem) =>
  number = (a, b): number => (b.id as number) - (a.id as number),
): ServiceModel<DataItem> => {
  let listeners = {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    created: (item: DataItem): void => {},
    updated: (item: DataItem): void => {},
    patched: (item: DataItem): void => {},
    removed: (item: DataItem): void => {},
    /* eslint-enable @typescript-eslint/no-unused-vars */
  };
  return {
    loading: {
      items: false,
      create: false,
      modify: false,
    },
    error: {
      items: '',
      create: '',
      modify: '',
    },
    items: [],
    sortedItems: computed((state) => state.items.slice().sort(itemSortCompare)),
    itemsById: computed((state) => state.items.reduce((byId, item) => {
      byId.set((item.id as number), item);
      return byId;
    }, new Map<number | string, DataItem>())),
    setLoading: action((state, metaType) => {
      state.loading[metaType.type] = metaType.value;
    }),
    setError: action((state, metaType) => {
      state.error[metaType.type] = metaType.value;
    }),
    setItems: action((state, items) => {
      state.items = items;
    }),
    addItem: action((state, item) => {
      state.items.push(item);
    }),
    updateItem: action((state, item) => {
      const foundIndex = state.items.findIndex((i) => i.id === item.id);
      if (foundIndex !== -1) {
        state.items.splice(foundIndex, 1, item);
      }
    }),
    removeItem: action((state, id) => {
      state.items = state.items.filter((i) => i.id !== id);
    }),
    create: thunk(async (actions, item) => {
      actions.setLoading({
        type: MetaType.CREATE,
        value: true,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let created: any = null;
      try {
        created = await service.create(item);
        actions.setError({
          type: MetaType.CREATE,
          value: '',
        });
      } catch (error) {
        actions.setError({
          type: MetaType.CREATE,
          value: error.message,
        });
      }
      actions.setLoading({
        type: MetaType.CREATE,
        value: false,
      });
      return created;
    }),
    update: thunk(async (actions, item) => {
      actions.setLoading({
        type: MetaType.MODIFY,
        value: true,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let updated: any = null;
      try {
        updated = await service.update((item.id as number), item);
        actions.setError({
          type: MetaType.MODIFY,
          value: '',
        });
      } catch (error) {
        actions.setError({
          type: MetaType.MODIFY,
          value: error.message,
        });
      }
      actions.setLoading({
        type: MetaType.MODIFY,
        value: false,
      });
      return updated;
    }),
    patch: thunk(async (actions, item) => {
      actions.setLoading({
        type: MetaType.MODIFY,
        value: true,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let patched: any = null;
      try {
        patched = await service.patch((item.id as number), item);
        actions.setError({
          type: MetaType.MODIFY,
          value: '',
        });
      } catch (error) {
        actions.setError({
          type: MetaType.MODIFY,
          value: error.message,
        });
      }
      actions.setLoading({
        type: MetaType.MODIFY,
        value: false,
      });
      return patched;
    }),
    remove: thunk(async (actions, id) => {
      actions.setLoading({
        type: MetaType.MODIFY,
        value: true,
      });
      try {
        await service.remove(id);
        actions.setError({
          type: MetaType.MODIFY,
          value: '',
        });
      } catch (error) {
        actions.setError({
          type: MetaType.MODIFY,
          value: error.message,
        });
      }
      actions.setLoading({
        type: MetaType.MODIFY,
        value: false,
      });
    }),
    list: thunk(async (actions) => {
      actions.setLoading({
        type: MetaType.ITEMS,
        value: true,
      });
      try {
        const items = (await service.find()) as DataItem[];
        items.forEach(dataItemFormater);
        actions.setItems(items);
        actions.unListen();
        listeners = {
          created: (item: DataItem): void => actions.addItem(dataItemFormater(item)),
          updated: (item: DataItem): void => actions.updateItem(dataItemFormater(item)),
          patched: (item: DataItem): void => {
            if (item.archived) {
              actions.removeItem((item.id as number));
            } else {
              actions.updateItem(dataItemFormater(item));
            }
          },
          removed: (item: DataItem): void => actions.removeItem((item.id as number)),
        };
        service.on('created', listeners.created);
        service.on('updated', listeners.updated);
        service.on('patched', listeners.patched);
        service.on('removed', listeners.removed);
      } catch (error) {
        actions.setError({
          type: MetaType.ITEMS,
          value: error.message,
        });
      }
      actions.setLoading({
        type: MetaType.ITEMS,
        value: false,
      });
    }),
    unListen: action(() => {
      service.off('created', listeners.created);
      service.off('updated', listeners.updated);
      service.off('patched', listeners.patched);
      service.off('removed', listeners.removed);
    }),
  };
};
