import { Share } from '@capacitor/share';
import {
  IonButton,
  IonCol,
  IonLabel,
  IonLoading,
  IonRouterLink,
  IonRow,
  isPlatform,
} from '@ionic/react';
import getDay from 'date-fns/getDay';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import useAppStyles from '../styles';
// @ts-ignore
import logo from '../../assets/cc_logo.png';
import Icon from '../../components/Icon';
import useClasses from '../../components/styles';
import Listing from '../../interfaces/Listing';
import { listings } from '../../lib/api/services';
import useIsDesktop from '../../lib/hooks/useIsDesktop';
import useService from '../../lib/hooks/useService';
import routePaths from '../../lib/routePaths';
import {
  getDayOfWeekOpenHours,
  goToDirections,
  translateName,
  translateProp,
} from '../../lib/utils';
import { useStoreActions, useStoreState } from '../../store';
import ListingDetailHours from './ListingDetailHours';
import ListingDetailOtherListings from './ListingDetailOtherListings';
import IonContentContext from '../../lib/context/IonContentContext';

const ListingDetail: React.FC = () => {
  const classes = useClasses();
  const appClasses = useAppStyles();
  const primaryColor = getComputedStyle(
    document.documentElement,
  ).getPropertyValue('--ion-color-primary');
  const params = useParams();
  const isDesktop = useIsDesktop();
  const isNotXtraSmall = useIsDesktop(321);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { id } = params as any;
  const [listing, setListing] = useState<Listing>({} as Listing);
  const [otherListings, setOtherListings] = useState(false);
  const [currentImage, setCurrentImage] = useState<string | null>(null);
  const [excludedListings, setExcludedListings] = useState<number[]>([]);
  const [page] = useState({ skip: 0, limit: 1 });
  const [filters, setFilters] = useState({ id });
  const [showHoursModal, setShowHoursModal] = useState(false);
  const history = useHistory();
  const { result, loading } = useService<Listing>(listings, page, filters);
  const { language } = useStoreState(state => state.translations);
  const { favoriteListings } = useStoreState((state) => state.favorites);
  const locale = useStoreState((state) => state.translations.language);
  const {
    favorites: {
      addFavoriteListing,
      removeFavoriteListingById,
      replaceFavoriteListing,
    },
    map: { setCenter },
    analytics: { createEvent },
    search: { updateFilters },
  } = useStoreActions((actions) => ({
    favorites: actions.favorites,
    map: actions.map,
    analytics: actions.analytics,
    search: actions.search,
  }));

  useEffect(() => {
    const { data, total } = result;
    if (total !== undefined) {
      if (total > 0) {
        setListing(data[0]);
      } else if (total === 0) {
        history.push(`${routePaths.public.home}?redirect=no_listing`);
      }
    } else {
      const cachedListings = favoriteListings.filter(
        (fav) => fav.id === Number(id),
      );
      if (cachedListings.length > 0) {
        setListing(cachedListings[0]);
      }
    }
  }, [result, history, favoriteListings, id, replaceFavoriteListing]);

  useEffect(() => {
    setFilters({ id });
    setExcludedListings([id]);
    createEvent({
      type: 'listing-view',
      meta: {
        id,
      },
    });
    updateFilters({});
  }, [id, createEvent, updateFilters]);

  useEffect(() => {
    if (showHoursModal) {
      createEvent({
        type: 'listing-view-hours',
        meta: {
          id,
        },
      });
    }
  }, [showHoursModal, id, createEvent]);

  useEffect(() => {
    if (listing) {
      setCenter({
        latitude: listing.address?.latitude || 0,
        longitude: listing.address?.longitude || 0,
      });
    }
    return (): void => {
      setCenter(null);
    };
  }, [listing, setCenter]);

  const share = (): void => {
    const BASE_URL: string = process.env.REACT_APP_WEB_HOST || 'http://localhost:5000';
    const url = `${BASE_URL}/cc/listings/${listing.id}`;
    Share.share({
      title: `${listing.provider?.name}: ${listing.name}`,
      dialogTitle: `${listing.provider?.name}: ${listing.name}`,
      text: `${listing.provider?.name}: ${listing.name}`,
      url,
    });
  };

  useEffect(() => {
    if (listing && listing?.images) {
      const img = listing?.images[0];
      if (img) {
        const { images } = img;
        if (images) {
          const url = `${images['image-1x']} 1x, ${images['image-2x']} 2x, ${images['image-3x']} 3x, ${images['image-4x']} 4x`;
          setCurrentImage(url);
        }
      }
    }
  }, [listing]);
  useEffect(() => {
    // Update cached favorite with current data.
    if (listing.id) {
      replaceFavoriteListing(listing);
    }
  }, [listing, replaceFavoriteListing]);

  return (
    <div className={classes.listingDetail}>
      <div
        style={{ marginBottom: 20 }}
        className={classes.ccDesktopSidebarHeaderSubcategories}
      >
        <div className={classes.ccDesktopLogoWrapper}>
          <IonRouterLink routerLink={routePaths.public.home}>
            <img className={appClasses.ccLogo} src={logo} alt="home" />
          </IonRouterLink>
        </div>
        <div
          className={[classes.arrowBack, classes.headerGoBackIcon].join(' ')}
          onClick={(): void => history.goBack()}
        >
          <Icon name="arrow_back" width={isNotXtraSmall ? 40 : 35} color={primaryColor} />
        </div>
        {((isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb')) && (
          <div className={classes.listingDetailRowShare}>
            <IonButton onClick={share} className={classes.flippedIcon}>
              <Icon name="reply" color={primaryColor} width={isNotXtraSmall ? 40 : 35} />
            </IonButton>
          </div>
        )}
        <div
          className={((isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb')) ? classes.listingDetailRowHeartMobile : classes.listingDetailRowHeartWeb}
          onClick={(): void => {
            if (
              favoriteListings.filter((fav) => fav.id === Number(listing.id))
                .length > 0
            ) {
              removeFavoriteListingById(listing.id ?? -1);
            } else {
              addFavoriteListing(listing);
            }
          }}
        >
          <Icon
            name={
              favoriteListings.filter((fav) => fav.id === Number(listing.id))
                .length > 0
                ? 'heart_solid'
                : 'heart'
            }
            width={isNotXtraSmall ? 40 : 35}
            color={primaryColor}
          />
        </div>
      </div>
      {currentImage && (
        <IonRow className={classes.listingDetailImageRow}>
          <img srcSet={currentImage || ''} alt="listing" />
        </IonRow>
      )}
      <IonRow className={classes.listingDetailTitle}>
        <IonLabel className={classes.listingDetailEllipsisText}>
          {translateName(listing, locale)}
        </IonLabel>
      </IonRow>
      <IonRow className={classes.listingDetailSubcategories}>
        <IonLabel className={classes.listingDetailEllipsisText}>
          {listing.sub_categories
            ?.map((s) => translateName(s, locale))
            .join(', ') || ''}
        </IonLabel>
      </IonRow>
      <IonRow className={classes.listingDetailProvider}>
        <IonLabel className={classes.listingDetailEllipsisText}>
          {listing.provider?.name || ''}
        </IonLabel>
      </IonRow>
      <IonRow className={classes.listingDetailIcons}>
        <div
          onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
            createEvent({
              type: 'listing-directions-click',
              meta: {
                id,
              },
            });
            goToDirections(isDesktop, listing);
            e.stopPropagation();
          }}
          className={classes.listingIcon}
        >
          <Icon name="directions" width={24} color={primaryColor} />
        </div>
        <div className={classes.listingIcon}>
          <a
            onClick={(): void => {
              createEvent({
                type: 'listing-phone-click',
                meta: {
                  id,
                },
              });
            }}
            href={`tel:${listing.phone_number}`}
          >
            <Icon name="phone" width={24} color={primaryColor} />
          </a>
        </div>
        <div className={classes.listingIcon}>
          <a
            onClick={(): void => {
              createEvent({
                type: 'listing-email-click',
                meta: {
                  id,
                },
              });
            }}
            href={`mailto:${listing.email}`}
          >
            <Icon name="email" width={24} color={primaryColor} />
          </a>
        </div>
        <div className={classes.listingIcon}>
          <a
            onClick={(): void => {
              createEvent({
                type: 'listing-website-click',
                meta: {
                  id,
                },
              });
            }}
            href={listing.provider?.website_url}
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="language" width={24} color={primaryColor} />
          </a>
        </div>
      </IonRow>
      <IonRow
        onClick={(): void => setShowHoursModal(true)}
        className={classes.listingDetailOpenHours}
      >
        <IonCol className={classes.listingDetailIconCol} size="2">
          <Icon name="watch_later" width={30} color={primaryColor} />
        </IonCol>
        <IonCol className={classes.listingDetailHourColumn} size="8">
          <IonLabel className={classes.listingDetailHoursText}>
            {getDayOfWeekOpenHours(listing.hours || [], getDay(new Date()), language, false)}
          </IonLabel>
          <IonLabel className={[classes.listingDetailSeeMore, classes.listingDetailLink].join(' ')}>
            <FormattedMessage id="see-more-hours" />
          </IonLabel>
        </IonCol>
        <IonCol className={classes.listingDetailIconCol} size="2">
          <Icon name="arrow_right" width={36} color={primaryColor} />
        </IonCol>
      </IonRow>
      <IonRow className={classes.listingDetailInfoRow}>
        <IonCol className={classes.listingDetailIconCol} size="2">
          <Icon name="room" width={30} color={primaryColor} />
        </IonCol>
        <IonCol size="10">
          <div
            className={
              (listing.address?.latitude ?? 0) > 0
                ? classes.listingDetailLink : classes.listingDetailDirectionsNoAddress
            }
            onClick={(
              e: React.MouseEvent<HTMLDivElement, MouseEvent>,
            ): void => {
              if ((listing.address?.latitude ?? 0) > 0) {
                createEvent({
                  type: 'listing-directions-click',
                  meta: {
                    id,
                  },
                });
                goToDirections(isDesktop, listing);
              }
              e.stopPropagation();
            }}
          >
            {`${listing.address?.street_address_1}`}
          </div>
        </IonCol>
      </IonRow>
      <IonRow className={classes.listingDetailInfoRow}>
        <IonCol className={classes.listingDetailIconCol} size="2">
          <Icon name="phone" width={30} color={primaryColor} />
        </IonCol>
        <IonCol size="10">
          <a
            onClick={(): void => {
              createEvent({
                type: 'listing-phone-click',
                meta: {
                  id,
                },
              });
            }}
            href={`tel:${listing.phone_number}`}
          >
            <div className={classes.listingDetailLink}>
              {listing.phone_number}
            </div>
          </a>
        </IonCol>
      </IonRow>
      {!listing.do_not_provide_email && (
        <IonRow className={classes.listingDetailInfoRow}>
          <IonCol className={classes.listingDetailIconCol} size="2">
            <Icon name="email" width={30} color={primaryColor} />
          </IonCol>
          <IonCol size="10">
            <a
              onClick={(): void => {
                createEvent({
                  type: 'listing-email-click',
                  meta: {
                    id,
                  },
                });
              }}
              href={`mailto:${listing.email}`}
            >
              <div className={classes.listingDetailLink}>
                {listing.email}
              </div>
            </a>
          </IonCol>
        </IonRow>
      )}
      {listing.websites && listing.websites.length > 0 ? (
        <>
          {listing.websites.map((website, i) => {
            const key = `website-${i}`;
            return (
              <IonRow key={key} className={classes.listingDetailInfoRow}>
                <IonCol className={classes.listingDetailIconCol} size="2">
                  <Icon name="language" width={30} color={primaryColor} />
                </IonCol>
                <IonCol size="10">
                  <a
                    onClick={(): void => {
                      createEvent({
                        type: 'listing-website-click',
                        meta: {
                          id,
                          url: website.url,
                        },
                      });
                    }}
                    href={website.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className={classes.listingDetailLink}>
                      {translateName(website, locale)}
                    </div>
                  </a>
                </IonCol>
              </IonRow>
            );
          })}
        </>
      ) : (
        <IonRow className={classes.listingDetailInfoRow}>
          <IonCol className={classes.listingDetailIconCol} size="2">
            <Icon name="language" width={30} color={primaryColor} />
          </IonCol>
          <IonCol size="10">
            <a
              onClick={(): void => {
                createEvent({
                  type: 'provider-website-click',
                  meta: {
                    listing_id: id,
                    provider_id: listing.provider?.id,
                    url: listing.provider?.website_url,
                  },
                });
              }}
              href={listing.provider?.website_url}
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage id="visit-website" />
            </a>
          </IonCol>
        </IonRow>
      )}
      <IonRow
        className={[
          classes.listingDetailDescriptionRow,
          classes.listingDetailDescriptionText,
        ].join(' ')}
      >
        <IonLabel>
          <FormattedMessage id="description-upper" />
        </IonLabel>
        <br />
        <br />
        {translateProp(listing, 'description', locale)}
      </IonRow>
      <IonRow
        className={[
          classes.listingDetailDescriptionRow,
          classes.listingDetailDescriptionText,
        ].join(' ')}
      >
        <IonLabel>
          <FormattedMessage id="serves-upper" />
        </IonLabel>
        <br />
        <br />
        <FormattedMessage id="ages-camel" />
        {': '}
        {listing.age_ranges?.map((a) => a.name).join(' / ')}
        <br />
        <br />
        {listing.groups?.length > 0 && <FormattedMessage id="groups-camel" />}
        {listing.groups?.length > 0 && ': '}
        {listing.groups?.map((g) => translateName(g, locale)).join(', ')}
      </IonRow>
      <IonRow
        className={[
          classes.listingDetailDescriptionRow,
          classes.listingDetailDescriptionText,
        ].join(' ')}
      >
        <IonLabel>
          <FormattedMessage id="languages-spoken-upper" />
        </IonLabel>
        <br />
        <br />
        {listing.languages?.map((l) => translateName(l, locale)).join(', ')}
      </IonRow>
      {listing.documentation_required && (
        <IonRow
          className={[
            classes.listingDetailDescriptionRow,
            classes.listingDetailDescriptionText,
          ].join(' ')}
        >
          <IonLabel>
            <FormattedMessage id="documentation-required-upper" />
          </IonLabel>
          <br />
          <br />
          {translateProp(listing, 'documentation_required', locale)}
        </IonRow>
      )}
      <IonRow
        className={[
          classes.listingDetailDescriptionRow,
          classes.listingDetailDescriptionText,
        ].join(' ')}
      >
        <IonLabel>
          <FormattedMessage id="insurance-required-upper" />
        </IonLabel>
        <br />
        <br />
        {listing.insurance_required ? (
          <IonLabel color="black">
            <FormattedMessage id="yes-camel" />
          </IonLabel>
        ) : (
          <IonLabel color="black">
            <FormattedMessage id="none-camel" />
          </IonLabel>
        )}
      </IonRow>
      {listing.insurance && listing.insurance.length > 0 && (
        <IonRow
          className={[
            classes.listingDetailDescriptionRow,
            classes.listingDetailDescriptionText,
          ].join(' ')}
        >
          <IonLabel>
            <FormattedMessage id="types-of-insurance-accepted-upper" />
          </IonLabel>
          <br />
          <br />
          {listing.insurance?.map((i) => i.name).join(', ')}
        </IonRow>
      )}
      {listing && listing.id && listing.provider_id && (
        <IonRow className={classes.listingDetailAllServices}>
          {otherListings && (
            <>
              <IonLabel>
                <FormattedMessage id="all-services-from-this-provider-upper" />
              </IonLabel>
              <br />
              <br />
            </>
          )}
          <IonContentContext.Consumer>
            {(content) => (
              <ListingDetailOtherListings
                providerId={listing.provider_id}
                excludedListings={excludedListings}
                otherListingsCB={setOtherListings}
                contentRef={content}
              />
            )}
          </IonContentContext.Consumer>
        </IonRow>
      )}
      <ListingDetailHours
        toggleModal={setShowHoursModal}
        showModal={showHoursModal}
        hours={listing.hours}
      />
      <IonLoading isOpen={loading} />
    </div>
  );
};

export default ListingDetail;
