import { FormattedMessage } from 'react-intl';
import {
  IonButton,
  IonCol,
  IonContent,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Icon from '../../components/Icon';
import useClasses from '../../components/styles';
import routePaths from '../../lib/routePaths';
import { getFiltersFromQuery, getTranslatedString } from '../../lib/utils';
import { useStoreActions, useStoreState } from '../../store';

interface SearchLocationProps {
  showModal: boolean;
  toggleModal: Function;
}

enum LocationType {
  CURRENT_LOCATION = 'current',
  SPECIFIC_CITIES = 'cities',
  COUNTY = 'county',
}

interface Coords {
  lat: string;
  long: string;
}

const SearchLocation: React.FC<SearchLocationProps> = ({
  showModal,
  toggleModal,
}: SearchLocationProps) => {
  const classes = useClasses();
  const [selectedCities, setSelectedCities] = useState<number[]>([]);
  const [coords, setCoords] = useState<Coords | null>(null);
  const [selected, setSelected] = useState<string>('');
  const { data } = useStoreState((state) => state.meta);
  const { language } = useStoreState(state => state.translations);
  const getLocation = useStoreActions(actions => actions.analytics.getLocation);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(decodeURI(location.search));
    const lat = searchParams.get('lat');
    const long = searchParams.get('long');
    const cities = searchParams.getAll('cities[]');

    if (lat || long) {
      setSelected(LocationType.CURRENT_LOCATION);
    } else if (cities && cities.length) {
      setSelectedCities(cities.map((c) => Number(c)));
      setSelected(LocationType.SPECIFIC_CITIES);
    } else {
      setSelected(LocationType.COUNTY);
    }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (selected === LocationType.CURRENT_LOCATION) {
      getLocation()
        .then((currentLocation) => {
          if (currentLocation) {
            setCoords({
              lat: `${currentLocation.latitude}`,
              long: `${currentLocation.longitude}`,
            });
          } else {
            setSelected(LocationType.COUNTY);
          }
        });
    }
  }, [getLocation, selected]);

  const updateSearchResults = (): void => {
    let newQueryParams = '';
    const result = getFiltersFromQuery({
      locationSearch: location.search,
      categories: data?.category || [],
    });

    if (result.filters.term) {
      newQueryParams += newQueryParams.length ? '&' : '';
      newQueryParams += `term=${result.filters.term}`;
    } else {
      Object.keys(result.filters).forEach((v) => {
        if (v === 'category') {
          newQueryParams += newQueryParams.length ? '&' : '';
          newQueryParams += `${v}=${result.category?.slug}`;
        }
        if (v === 'sub_categories') {
          const subcategoriesQuery = result.category?.sub_categories
            .filter((s) => result.filters.sub_categories.includes(`${s.id}`))
            .map((s) => `${v}[]=${s.slug}`)
            .join('&');
          newQueryParams += newQueryParams.length ? '&' : '';
          newQueryParams += subcategoriesQuery;
        }
      });
    }
    if (selected === LocationType.CURRENT_LOCATION) {
      newQueryParams += newQueryParams.length ? '&' : '';
      newQueryParams += `lat=${coords?.lat}&long=${coords?.long}`;
    } else if (selected === LocationType.SPECIFIC_CITIES) {
      newQueryParams += newQueryParams.length ? '&' : '';
      newQueryParams += selectedCities.map((id) => `cities[]=${id}`).join('&');
    }
    toggleModal(false);
    history.push(`${routePaths.public.listings}?${newQueryParams}`);
  };

  return (
    <IonContent>
      <IonModal isOpen={showModal} onDidDismiss={() => toggleModal(false)}>
        <div className={classes.modalContainer}>
          <IonRow className={classes.rowHeader}>
            <div className={classes.modalHeaderWrapper}>
              <div
                className={classes.modalHeaderCloseIcon}
                onClick={(): void => toggleModal(false)}
              >
                <Icon name="close" width={40} color="black" />
              </div>
              <div>
                <IonLabel className={classes.modalHeaderText}>
                  <FormattedMessage id="location" />
                </IonLabel>
              </div>
            </div>
          </IonRow>
          <IonRow className={classes.btnRow}>
            <IonLabel>
              <FormattedMessage id="select-location-preference" />
            </IonLabel>
            <IonSelect
              className="cc-select mt-16"
              value={selected}
              onIonChange={(e): void => setSelected(e.detail.value)}
            >
              <IonSelectOption value="current">
                <FormattedMessage id="my-current-location" />
              </IonSelectOption>
              <IonSelectOption value="cities">
                <FormattedMessage id="specific-cities-in-cc" />
              </IonSelectOption>
              <IonSelectOption value="county">Collier County</IonSelectOption>
            </IonSelect>
          </IonRow>

          {selected === 'cities' && (
            <IonRow className={classes.btnRow}>
              <IonSelect
                className="cc-select"
                placeholder={getTranslatedString('select-cities', language)}
                value={selectedCities}
                onIonChange={(e): void => setSelectedCities(e.detail.value)}
                multiple
              >
                {data?.city.map((city) => (
                  <IonSelectOption key={city.id} value={city.id}>
                    {city.name}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonRow>
          )}

          <IonRow>
            <IonCol sizeXs="12">
              <IonButton
                onClick={updateSearchResults}
                className={classes.searchBtn}
              >
                <FormattedMessage id="search-results" />
              </IonButton>
            </IonCol>
          </IonRow>
        </div>
      </IonModal>
    </IonContent>
  );
};

export default SearchLocation;
