import { action, thunk, computed } from 'easy-peasy';
import { Network } from '@capacitor/network';
import { pages } from '../lib/api/services';
import PageModel from '../interfaces/StoreModel/PageModel';
import { Page, PageForm } from '../interfaces/Page';

const serializePage = (data: PageForm): Page => {
  (data as Page).translation = {
    es: {
      title: data.title_es || '',
      contents: data.contents_es || '',
      navbar_title: data.navbar_title_es || '',
    },
  };
  delete data.title_es;
  delete data.contents_es;
  delete data.navbar_title_es;
  return data as Page;
};

const model: PageModel = {
  error: '',
  loading: false,
  success: false,
  data: null,
  pagesByCategoryId: computed((state) => {
    if (!state.data) return new Map<number, Page[]>();
    const byId = state.data.reduce((byCategory, page) => {
      if (!byCategory.get(page.navbar_category)) {
        byCategory.set(page.navbar_category, []);
      }
      (byCategory.get(page.navbar_category) || []).push(page);
      return byCategory;
    }, new Map<number, Page[]>());
    byId.forEach((items) => {
      items.sort((a, b) => (a?.navbar_order ?? 0) - (b?.navbar_order ?? 0));
    });
    return byId;
  }),
  create: thunk(
    async (actions, data) => {
      actions.setLoading(true);
      try {
        serializePage(data);
        const newPage = await pages.create(data);
        actions.setSuccess(true);
        return newPage;
      } catch (error) {
        actions.setError(error.message);
        actions.setSuccess(false);
      }
      actions.setLoading(false);
    },
  ),
  update: thunk(async (actions, data) => {
    actions.setLoading(true);
    try {
      serializePage(data);
      const updatedPage = await pages.patch(data.id, data);
      actions.setSuccess(true);
      return updatedPage;
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  // @ts-ignore
  remove: thunk(async (actions, id, store: ModuleStore<PageModel>) => {
    actions.setLoading(true);
    await pages.remove(id);
    try {
      const { data } = store.getStoreState().pages;
      const filteredPages = data.filter((p: Page) => p.id !== id);
      actions.setData(filteredPages);
      actions.setSuccess(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  setData: action((state, payload) => {
    state.data = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setSuccess: action((state, payload) => {
    state.success = payload;
  }),
  // @ts-ignore
  get: thunk(async (actions, id, store: ModuleStore<PageModel>) => {
    actions.setLoading(true);
    try {
      const page = await pages.get(id);
      const { data } = store.getStoreState().pages;
      const newPages = data || [];
      const filteredPages = newPages.filter((p: Page) => p.id !== page.id);
      actions.setData([...filteredPages, page]);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  find: thunk(async (actions) => {
    actions.setLoading(true);
    try {
      const status = await Network.getStatus();
      actions.setError('');
      let data = [];
      if (status.connected) {
        data = await pages.find({ query: { contents: true } });
        localStorage.setItem('pages', JSON.stringify(data));
        localStorage.setItem('pagesLastFetchedDate', String(new Date().getTime()));
      } else {
        data = localStorage.getItem('pages') ? JSON.parse(localStorage.getItem('pages') as string) : [];
      }
      actions.setData(data);
    } catch (error) {
      actions.setError(error.message);
    }
    actions.setLoading(false);
  }),
  // @ts-ignore
  moveUp: thunk(async (actions, id, store: ModuleStore<PageModel>) => {
    actions.setLoading(true);
    try {
      const { data } = store.getStoreState().pages;
      // eslint-disable-next-line max-len
      const sortedPages = data.sort((a: Page, b: Page) => (a?.navbar_order ?? 0) - (b?.navbar_order ?? 0));
      const pageToMoveUpIndex = Number(sortedPages.findIndex((p: Page) => p.id === id));
      if (sortedPages.length > 1 && pageToMoveUpIndex > 0) {
        // eslint-disable-next-line max-len
        const currentPage = { ...sortedPages[pageToMoveUpIndex], navbar_order: pageToMoveUpIndex - 1 };
        // eslint-disable-next-line max-len
        sortedPages[pageToMoveUpIndex] = { ...sortedPages[pageToMoveUpIndex - 1], navbar_order: pageToMoveUpIndex };
        sortedPages[pageToMoveUpIndex - 1] = currentPage;

        // Patch everything
        for (let i = 0; i < sortedPages.length; i += 1) {
          sortedPages[i].navbar_order = i;
          // eslint-disable-next-line no-await-in-loop
          await pages.patch(sortedPages[i].id, { navbar_order: i });
        }
      }
      actions.setData(sortedPages);
      actions.setSuccess(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
  // @ts-ignore
  moveDown: thunk(async (actions, id, store: ModuleStore<PageModel>) => {
    actions.setLoading(true);
    try {
      const { data } = store.getStoreState().pages;
      // eslint-disable-next-line max-len
      const sortedPages = data.sort((a: Page, b: Page) => (a?.navbar_order ?? 0) - (b?.navbar_order ?? 0));
      const pageToMoveDownIndex = Number(sortedPages.findIndex((p: Page) => p.id === id));
      if (pageToMoveDownIndex !== sortedPages.length - 1) {
        // eslint-disable-next-line max-len
        const currentPage = { ...sortedPages[pageToMoveDownIndex], navbar_order: pageToMoveDownIndex + 1 };
        // eslint-disable-next-line max-len
        sortedPages[pageToMoveDownIndex] = { ...sortedPages[pageToMoveDownIndex + 1], navbar_order: pageToMoveDownIndex };
        sortedPages[pageToMoveDownIndex + 1] = currentPage;

        // Patch everything
        for (let i = 0; i < sortedPages.length; i += 1) {
          sortedPages[i].navbar_order = i;
          // eslint-disable-next-line no-await-in-loop
          await pages.patch(sortedPages[i].id, { navbar_order: i });
        }
      }
      actions.setData(sortedPages);
      actions.setSuccess(true);
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
};

export default model;
