/* eslint-disable max-len */
import React, { useEffect } from 'react';
import {
  IonButton,
  IonList,
  IonItem,
  IonLabel,
  IonRadioGroup,
  IonListHeader,
  IonRadio,
  isPlatform,
} from '@ionic/react';

import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import useAppStyles from '../../styles';
import { useStoreActions, useStoreState } from '../../../store';
import routePaths from '../../../lib/routePaths';

const ProviderType: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const appClasses = useAppStyles();
  const { meta, createOrg } = useStoreState(state => ({
    meta: state.meta,
    createOrg: state.createOrg,
  }));
  const { setCurrentStep, setProviderType } = useStoreActions(
    (actions) => actions.createOrg,
  );

  useEffect(() => {
    setCurrentStep(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h3 className={appClasses.title}>Service Provider Eligibility</h3>
      <div className={classes.description}>
        <IonLabel>
          Do you have a service to list in Collier CARES? To submit your
          service(s) for review, lets first check your eligibility.
        </IonLabel>
      </div>
      <IonLabel
        className={classes.descriptionLink}
        onClick={(): void => {
          if (isPlatform('android') || isPlatform('ios')) {
            history.push('/cc/page/provider-requirements');
          } else {
            window.open('/cc/page/provider-requirements', '_blank');
          }
        }}
      >
        View provider requirements.
      </IonLabel>
      <div>
        <IonList className={classes.inputsList}>
          <IonRadioGroup
            value={createOrg.providerType}
            onIonChange={(e): void => setProviderType(e.detail.value)}
          >
            <IonListHeader className="ion-no-padding ion-no-margin">
              <IonLabel className={classes.radioGroupHeader}>
                Describe your organization:
              </IonLabel>
            </IonListHeader>
            {!meta.data ? <p>Loading...</p> : meta.data.provider_type.map((type) => (
              <IonItem key={type.id} lines="none" className={classes.radioButtonItem}>
                <IonRadio
                  className={classes.radioButton}
                  mode="md"
                  value={type}
                />
                <IonLabel className={classes.radioButtonLabel}>
                  {type.name}
                </IonLabel>
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
      </div>
      <div className="ion-padding-top">
        <IonButton
          routerLink={`${routePaths.provider.onboarding.requirements}/${createOrg.providerType.id}`}
          disabled={createOrg.providerType.id === -1}
          className="cc-button cc-tall-button"
          shape="round"
          type="submit"
          expand="block"
        >
          Next
        </IonButton>
      </div>
    </>
  );
};

export default ProviderType;
