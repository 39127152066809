import React from 'react';
import { IconProps } from './Icon';

const MentalHealth: React.FunctionComponent<IconProps> = ({ color, height, width }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 500 500"
  >
    <g fill={color}>
      <path
        id="Selection #1"
        d="M 305.04,198.00
           C 305.04,198.00 341.00,198.00 341.00,198.00
             346.83,198.08 349.31,198.81 352.60,204.02
             356.87,210.76 351.47,215.53 348.91,222.00
             345.00,231.91 348.62,241.23 358.00,246.04
             360.42,247.28 363.28,248.51 366.00,248.80
             373.61,249.60 383.92,243.26 386.64,236.00
             387.12,233.96 387.18,228.19 386.64,226.00
             386.64,226.00 379.52,208.97 379.52,208.97
             379.21,206.72 379.97,205.24 381.17,203.44
             385.58,196.84 390.77,197.01 398.00,197.00
             398.00,197.00 422.00,197.00 422.00,197.00
             434.15,196.98 433.12,191.09 434.75,181.00
             434.75,181.00 437.96,162.00 437.96,162.00
             438.29,154.50 436.98,152.64 436.15,146.00
             436.15,146.00 433.71,126.00 433.71,126.00
             433.71,126.00 425.05,103.28 425.05,103.28
             417.91,89.86 398.88,69.56 386.57,60.74
             386.57,60.74 364.00,48.11 364.00,48.11
             364.00,48.11 350.00,42.80 350.00,42.80
             350.00,42.80 343.00,41.62 343.00,41.62
             338.50,40.61 338.43,39.33 330.00,38.10
             330.00,38.10 320.00,38.10 320.00,38.10
             309.30,37.18 304.16,33.85 304.00,45.00
             304.00,45.00 304.00,62.00 304.00,62.00
             304.00,62.00 304.97,74.00 304.97,74.00
             304.99,77.71 303.53,83.36 307.30,85.68
             311.65,88.36 314.47,84.94 318.09,83.27
             318.09,83.27 330.00,79.64 330.00,79.64
             333.94,78.64 335.46,79.61 339.00,80.99
             349.53,85.08 355.86,92.33 356.00,104.00
             356.03,107.13 356.17,111.05 355.13,114.00
             352.00,122.85 341.07,132.19 331.42,132.17
             325.90,132.16 314.63,125.21 312.00,124.72
             308.43,124.06 305.68,126.72 305.04,130.09
             305.04,130.09 305.04,135.00 305.04,135.00
             305.04,135.00 305.04,198.00 305.04,198.00 Z
           M 350.00,455.00
           C 348.35,447.81 344.27,440.65 341.27,433.91
             341.27,433.91 336.68,420.00 336.68,420.00
             336.68,420.00 335.53,411.00 335.53,411.00
             333.87,402.16 332.02,405.17 332.00,393.00
             332.00,393.00 332.00,379.00 332.00,379.00
             332.10,370.93 333.33,370.92 334.96,364.00
             334.96,364.00 336.41,354.00 336.41,354.00
             336.41,354.00 339.31,347.00 339.31,347.00
             339.31,347.00 340.87,340.00 340.87,340.00
             343.68,331.87 352.10,316.07 357.18,309.09
             364.77,298.63 374.70,286.73 378.00,274.00
             378.00,274.00 330.00,274.00 330.00,274.00
             325.69,274.01 317.56,274.48 318.70,281.04
             319.13,283.51 323.65,290.13 324.88,293.00
             327.67,299.50 327.61,312.63 323.70,318.71
             319.12,325.84 306.48,333.91 298.00,333.82
             293.95,333.77 292.42,332.25 289.00,330.88
             289.00,330.88 283.17,328.84 283.17,328.84
             276.46,325.18 268.14,313.77 267.61,306.00
             267.11,298.54 272.79,291.70 275.06,285.00
             276.50,281.07 275.70,277.92 275.06,274.00
             275.06,274.00 243.00,274.00 243.00,274.00
             230.82,273.98 229.02,270.73 229.00,259.00
             229.00,259.00 229.00,193.00 229.00,193.00
             229.01,185.03 231.66,176.80 241.00,176.10
             249.27,175.48 251.59,179.14 255.17,180.46
             255.17,180.46 267.00,183.41 267.00,183.41
             271.12,183.61 279.76,179.63 282.51,176.56
             292.35,165.59 290.23,144.34 276.00,137.98
             273.04,136.66 268.29,136.98 265.00,137.00
             254.48,137.07 248.62,143.74 243.96,144.67
             238.61,145.74 231.54,139.71 229.74,135.00
             228.87,132.73 229.00,129.44 229.00,127.00
             229.00,127.00 229.00,82.00 229.00,82.00
             220.84,82.20 221.35,83.76 215.00,84.70
             210.92,85.30 208.44,84.62 204.00,85.99
             204.00,85.99 196.00,89.20 196.00,89.20
             196.00,89.20 190.17,90.54 190.17,90.54
             190.17,90.54 184.91,93.30 184.91,93.30
             184.91,93.30 180.00,94.95 180.00,94.95
             180.00,94.95 167.00,101.76 167.00,101.76
             146.64,112.46 132.31,122.01 119.85,141.96
             119.85,141.96 116.46,147.28 116.46,147.28
             116.46,147.28 112.37,157.09 112.37,157.09
             112.37,157.09 110.87,164.00 110.87,164.00
             110.87,164.00 108.26,171.00 108.26,171.00
             106.28,178.87 107.01,197.96 107.00,207.00
             106.98,217.55 106.27,213.72 103.68,222.00
             103.68,222.00 102.13,230.00 102.13,230.00
             99.96,236.27 91.97,248.73 87.70,254.01
             81.82,261.28 68.06,271.04 68.18,281.00
             68.30,291.03 85.78,294.78 89.42,305.04
             92.60,314.00 85.41,319.56 86.32,324.83
             86.98,328.72 91.85,331.40 92.77,334.17
             93.99,337.84 89.60,340.52 90.37,344.91
             91.11,349.18 97.39,351.79 98.70,360.00
             100.43,370.84 95.12,378.49 95.00,388.00
             94.96,391.70 94.56,396.71 96.43,399.95
             99.79,405.75 106.91,407.55 113.00,407.96
             124.76,409.37 123.37,408.50 134.00,407.96
             134.00,407.96 143.00,407.96 143.00,407.96
             143.00,407.96 151.00,405.51 151.00,405.51
             154.47,404.74 160.43,404.05 164.00,404.00
             175.05,403.87 182.98,402.06 190.57,412.04
             193.53,415.92 192.67,418.61 193.46,423.00
             193.46,423.00 196.48,434.00 196.48,434.00
             196.48,434.00 197.29,443.00 197.29,443.00
             197.84,447.01 200.28,454.36 204.22,456.13
             207.00,457.38 217.44,457.04 221.00,457.00
             221.00,457.00 231.99,456.00 231.99,456.00
             231.99,456.00 244.00,456.00 244.00,456.00
             244.00,456.00 306.00,456.00 306.00,456.00
             306.00,456.00 333.00,456.00 333.00,456.00
             338.65,456.00 344.53,456.55 350.00,455.00 Z"
      />
    </g>
  </svg>
);

export default MentalHealth;
