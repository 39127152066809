import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import React from 'react';
import useStyles from '../../styles';
import { Page } from '../../../../interfaces/Page';
import appMenuItems from '../../../../lib/appMenuItems';
import { getTranslatedString } from '../../../../lib/utils';
import Icon from '../../../../components/Icon';

interface PageRowProps {
  page: Page;
  editPage: (id: number) => void;
  deletePage: (id: number) => void;
  moveUp: (id: number) => void;
  moveDown: (id: number) => void;
}

const PageRow: React.FunctionComponent<PageRowProps> = ({
  page,
  editPage,
  deletePage,
  moveDown,
  moveUp,
}: PageRowProps) => {
  const classes = useStyles();
  const {
    id, title, enabled, navbar_title, navbar,
  } = page;

  return (
    <>
      <div className={classes.providerCardHeader}>
        <IonLabel className="ion-no-margin ion-no-padding" color="secondary">
          {title}
        </IonLabel>
        <div className="icons">
          <div className="icon-wrap" onClick={() => moveDown(id ?? 0)}>
            <Icon name="arrow_downward" color="#FFF" height={16} width={24} />
          </div>
          <div className="icon-wrap" onClick={() => moveUp(id ?? 0)}>
            <Icon name="arrow_upward" color="#FFF" height={16} width={24} />
          </div>
        </div>
      </div>
      <div className={classes.providerCardContent}>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="8">
              <div className={classes.providerNameRow}>
                <IonItem
                  className={[
                    classes.providerOrgName,
                    'disabled-pointer-events',
                  ].join(' ')}
                >
                  <IonLabel position="floating" color="primary">
                    Title
                  </IonLabel>
                  <IonInput color="primary" value={title} />
                </IonItem>
              </div>
              <IonItem className="disabled-pointer-events">
                <IonLabel position="floating" color="primary">
                  Navbar Title
                </IonLabel>
                <IonInput color="primary" value={navbar_title} />
              </IonItem>
              <IonItem className="disabled-pointer-events">
                <IonLabel position="floating" color="primary">
                  Enabled
                </IonLabel>
                <IonInput color="primary" value={enabled ? 'True' : 'False'} />
              </IonItem>
              <IonItem className="disabled-pointer-events">
                <IonLabel position="floating" color="primary">
                  Show in Navbar
                </IonLabel>
                <IonInput color="primary" value={navbar ? 'True' : 'False'} />
              </IonItem>
              <IonItem className="disabled-pointer-events">
                <IonLabel position="floating" color="primary">
                  Navbar Category
                </IonLabel>
                <IonInput
                  color="primary"
                  value={
                    getTranslatedString(
                      appMenuItems.find(
                        (i) => i.navbar_category === page.navbar_category,
                      )?.navTitleId || '',
                      'en',
                    ) || 'More Resources'
                  }
                />
              </IonItem>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="1" />
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
              <IonButton
                className={classes.providerActionButton}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => editPage(id as number)}
              >
                Edit
              </IonButton>
              <IonButton
                className={classes.providerActionButton}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => deletePage(id as number)}
              >
                Delete
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default PageRow;
