import React from 'react';
import CCAdminPage from '../../../components/CCAdminPage';
import OrgInfo from '../../Provider/components/OrgInfo';

const ProviderOrgInfo: React.FC = () => (
  <CCAdminPage
    title="Dashboard"
    content={(
      <OrgInfo />
      )}
  />
);

export default ProviderOrgInfo;
