/* eslint-disable max-len */
import {
  isPlatform, IonItem, IonToggle, IonLabel, IonSelect, IonSelectOption,
} from '@ionic/react';
import React from 'react';

import { FormattedMessage } from 'react-intl';
import { useStoreState, useStoreActions } from '../../store';
import { getTranslatedString, translateName } from '../../lib/utils';
import useAppStyles from '../styles';
import CCPage from '../../components/CCPage';
import SettingsSidebar from './SettingsSidebar';

const Settings: React.FC = () => {
  const { language } = useStoreState(state => state.translations);
  const locale = useStoreState((state) => state.translations.language);
  const meta = useStoreState(state => state.meta);
  const ageAlertOptions = {
    header: getTranslatedString('select-age-group', language),
  };
  const groupsAlertOptions = {
    header: getTranslatedString('select-groups', language),
  };
  const citiesAlertOptions = {
    header: getTranslatedString('select-cities', language),
  };
  const {
    getUrgentNotifications,
    getGeneralNotifications,
    useAgeOnSearch,
    useCitiesOnSearch,
    useGroupsOnSearch,
    useLocationOnSearch,
  } = useStoreState(state => state.settings);
  const {
    setGetAllNotifications,
    setGetUrgentNotifications,
    setUseAgeOnSearch,
    setUseCitiesOnSearch,
    setUseGroupsOnSearch,
    setUseLocationOnSearch,
  } = useStoreActions(state => state.settings);
  const appClasses = useAppStyles();

  return (
    <CCPage
      useLogo
      content={(
        <div>
          <div className={[appClasses.whitebg, 'ml-16 mr-16'].join(' ')}>
            {!isPlatform('ios') && !isPlatform('android')
              ? (
                <h1 className={appClasses.title}>
                  <FormattedMessage id="app.menutitle.settings" />
                </h1>
              )
              : null}
            <h4 className={appClasses.subtitle}>
              <FormattedMessage id="set-up-filters" />
            </h4>
            <h4 className={appClasses.subtitle}>
              <FormattedMessage id="search-in-age-range" />
            </h4>
            <IonSelect
              className="cc-select"
              placeholder={getTranslatedString('select-age-group', language)}
              value={useAgeOnSearch}
              interfaceOptions={ageAlertOptions}
              multiple
              onIonChange={(e): void => {
                if (Array.isArray(e.detail.value)) {
                  setUseAgeOnSearch(e.detail.value);
                }
              }}
            >
              {meta.data?.age_range.map((ageGroup) => (
                <IonSelectOption key={ageGroup.id} value={ageGroup.id}>
                  {ageGroup.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </div>
          <div className={[appClasses.whitebg, 'ml-16 mr-16'].join(' ')}>
            <h4 className={appClasses.subtitle}>
              <FormattedMessage id="search-in-groups" />
            </h4>
            <IonSelect
              className="cc-select"
              placeholder={getTranslatedString('select-groups', language)}
              value={useGroupsOnSearch}
              multiple
              interfaceOptions={groupsAlertOptions}
              onIonChange={(e): void => {
                if (Array.isArray(e.detail.value)) {
                  setUseGroupsOnSearch(e.detail.value);
                }
              }}
            >
              {meta.data?.group.map((groupName) => (
                <IonSelectOption key={groupName.id} value={groupName.id}>
                  {translateName(groupName, locale)}
                </IonSelectOption>
              ))}
            </IonSelect>
          </div>
          <div className={[appClasses.divider, 'ion-margin'].join(' ')} />
          <div className="ion-margin">
            <h3 className={appClasses.subtitle} style={{ color: 'var(--ion-color-primary)' }}>
              <FormattedMessage id="search-location" />
            </h3>
            <IonItem className={[appClasses.toggleItemSingleLine, 'ion-no-padding ion-no-margin'].join(' ')}>
              <IonToggle
                className={appClasses.toggle}
                checked={useLocationOnSearch}
                mode="ios"
                onIonChange={(e): void => {
                  if (!e.detail.checked) {
                    setUseCitiesOnSearch([]);
                  }
                  if (e.detail.checked !== useLocationOnSearch) {
                    setUseLocationOnSearch(e.detail.checked);
                  }
                }}
              />
              <IonLabel className={appClasses.subtitle} style={{ color: '#000' }}>
                <FormattedMessage id="find-services-near-me" />
              </IonLabel>
            </IonItem>
          </div>
          <div className={[appClasses.whitebg, 'ion-margin'].join(' ')} hidden={useLocationOnSearch}>
            <h4 className={appClasses.subtitle}>
              <FormattedMessage id="search-for-services-in-these-cities" />
            </h4>
            <IonSelect
              className="cc-select"
              placeholder={getTranslatedString('select-cities', language)}
              value={useCitiesOnSearch}
              multiple
              interfaceOptions={citiesAlertOptions}
              onIonChange={(e): void => {
                if (Array.isArray(e.detail.value)) {
                  setUseCitiesOnSearch(e.detail.value);
                }
              }}
            >
              {meta.data?.city.map((city) => (
                <IonSelectOption key={city.id} value={city.id}>
                  {city.name}
                </IonSelectOption>
              ))}
            </IonSelect>
          </div>
          <div className={[appClasses.divider, 'ion-margin'].join(' ')} />
          {(isPlatform('ios') || isPlatform('android')) && !isPlatform('mobileweb') ? (
            <div>
              <SettingsSidebar />
              <div className={[appClasses.divider, 'ion-margin'].join(' ')} />
              <div className="ion-margin mt-24">
                <h3 className={appClasses.title}>
                  <FormattedMessage id="mobile-notifications" />
                </h3>
                <IonItem className={[appClasses.toggleItemSingleLine, 'ion-no-padding ion-no-margin'].join(' ')}>
                  <IonToggle
                    className={appClasses.toggle}
                    checked={
                      getGeneralNotifications
                      && getUrgentNotifications
                    }
                    mode="ios"
                    onIonChange={(e): void => {
                      if ((e.detail.checked && (!getGeneralNotifications || !getUrgentNotifications))
                        || (!e.detail.checked && (getGeneralNotifications || getUrgentNotifications))) {
                        setGetAllNotifications(e.detail.checked);
                      }
                    }}
                  />
                  <IonLabel className={[appClasses.subtitle, 'ion-text-wrap'].join(' ')} color="primary">
                    <FormattedMessage id="get-all-push-notifications" />
                  </IonLabel>
                </IonItem>
                <IonItem className={[appClasses.toggleItemSingleLine, 'ion-no-padding ion-no-margin'].join(' ')}>
                  <IonToggle
                    className={appClasses.toggle}
                    mode="ios"
                    disabled={getGeneralNotifications}
                    checked={getUrgentNotifications}
                    onIonChange={(e): void => {
                      if ((e.detail.checked && !getUrgentNotifications)
                        || (!e.detail.checked && getUrgentNotifications)) {
                        setGetUrgentNotifications(e.detail.checked);
                      }
                    }}
                  />
                  <IonLabel className={[appClasses.subtitle, 'ion-text-wrap'].join(' ')} color="primary">
                    <FormattedMessage id="get-urgent-alerts" />
                  </IonLabel>
                </IonItem>
              </div>
            </div>
          ) : null}
        </div>
      )}
    />
  );
};

export default Settings;
