/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  IonLabel,
  IonItem,
  IonInput,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonButtons,
  IonIcon,
} from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { globe, caretForward, caretBack } from 'ionicons/icons';
import { format } from 'date-fns';
import useStyles from '../styles';
import useService from '../../../lib/hooks/useService';
import { providers } from '../../../lib/api/services';
import Provider from '../../../interfaces/Provider';
import { Page } from '../../../interfaces/PaginationResult';
import { useStoreActions, useStoreState } from '../../../store';
import ServiceListingsPreview from './ServiceListingsPreview';
import Listing from '../../../interfaces/Listing';

const formatSubmittedString = (listings: Listing[]): string => {
  const submittedDates = listings
    .filter((listing) => listing.submit_date !== null)
    .map((listing) => new Date(listing.submit_date as string))
    .sort((a, b) => b.getTime() - a.getTime());
  return `Submitted ${format(submittedDates[0], 'MM/dd/yyyy')}`;
};

const NewServices: React.FC = () => {
  const { listingPreviewModalListings } = useStoreState((state) => state.admin);
  const { setProvider } = useStoreActions(actions => actions.provider);
  const { setListingPreviewModalListings } = useStoreActions(actions => actions.admin);
  const classes = useStyles();
  const resultPageSize = 5;
  const [page, setPage] = useState({ limit: resultPageSize, skip: 0 });
  const [filter, setFilter] = useState({
    approved_date: {
      $ne: null,
    },
    listings: {
      submitted: true,
      approved: false,
    },
  });
  const { error, result } = useService<Provider>(providers, page, filter);

  useEffect(() => {
    setFilter({
      approved_date: {
        $ne: null,
      },
      listings: {
        submitted: true,
        approved: false,
      },
    });
  }, [listingPreviewModalListings]);

  const formatProviderTypeString = (provider: Provider): string => {
    if (provider.type == null || provider.type.name.trim().length === 0) {
      return '';
    }
    return provider.type.name + (provider.ein != null && provider.ein.length > 0 ? ` EIN - ${provider.ein}` : '');
  };

  const changeResultsPage = (newPage: Page): void => {
    setPage(newPage);
    window.scrollTo(0, 0);
  };

  return (
    <div className={classes.adminTabContent}>
      {result.data.map((provider) => (
        <React.Fragment key={provider.id}>
          <div className={classes.providerCardHeader}>
            <IonLabel
              className="ion-no-margin ion-no-padding"
              color="secondary"
            >
              {formatProviderTypeString(provider)}
            </IonLabel>
            <IonLabel className="ion-no-margin ion-no-padding" color="light">
              {!provider.listings ? '' : formatSubmittedString(provider.listings)}
            </IonLabel>
          </div>
          <div className={classes.providerCardContent}>
            <IonGrid>
              <IonRow>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="8">
                  <div className={classes.providerNameRow}>
                    <IonItem className={[classes.providerOrgName, 'disabled-pointer-events'].join(' ')}>
                      <IonLabel position="floating" color="primary">
                        Organization Name
                      </IonLabel>
                      <IonInput
                        color="primary"
                        value={provider.name}
                      />
                    </IonItem>
                    <IonIcon className="mt-16" size="large" color="primary" icon={globe} onClick={(): Window | null => window.open(provider.website_url, '_blank')} />
                  </div>
                  <IonItem className="disabled-pointer-events">
                    <IonLabel position="floating" color="primary">
                      Contact
                    </IonLabel>
                    <IonInput
                      color="primary"
                      value={provider.user?.name}
                    />
                  </IonItem>
                  <IonItem className="disabled-pointer-events">
                    <IonLabel position="floating" color="primary">
                      Email
                    </IonLabel>
                    <IonInput
                      color="primary"
                      value={provider.user?.email}
                    />
                  </IonItem>
                </IonCol>
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="1" />
                <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
                  <IonButton
                    className={classes.providerActionButton}
                    shape="round"
                    fill="solid"
                    color="primary"
                    disabled={(provider.listings?.filter(
                      (listing) => listing.submit_date && !listing.denied_date
                        && !listing.approved_date,
                    ).length ?? 0) === 0}
                    onClick={(): void => {
                      setProvider(provider);
                      setListingPreviewModalListings(provider.listings?.filter(
                        (listing) => listing.submit_date && !listing.denied_date
                          && !listing.approved_date,
                      ) ?? []);
                    }}
                  >
                    View Services ({provider.listings?.filter(
                    (listing) => listing.submit_date && !listing.denied_date
                          && !listing.approved_date,
                  ).length ?? 0})
                  </IonButton>
                  <IonButton
                    className={classes.providerActionButton}
                    shape="round"
                    fill="outline"
                    color="primary"
                    href={`mailto:${provider.user?.email}`}
                  >
                    Contact
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </React.Fragment>
      ))}
      <IonButtons slot="start">
        <IonButton hidden={result.skip === 0} color="primary" onClick={(): void => changeResultsPage({ limit: resultPageSize, skip: result.skip - resultPageSize })}>
          <IonIcon color="primary" icon={caretBack} />
          {result.skip - resultPageSize + 1} - {result.skip}
        </IonButton>
        <IonButton hidden={result.skip + resultPageSize >= (result.total ?? 0)} color="primary" onClick={(): void => changeResultsPage({ limit: resultPageSize, skip: result.skip + resultPageSize })}>
          {result.skip + resultPageSize + 1} - {result.skip + (2 * resultPageSize)}
          <IonIcon color="primary" icon={caretForward} />
        </IonButton>
      </IonButtons>
      <ServiceListingsPreview />
      {error.length > 0 ? <h4>Uh-oh, unable to retrieve data at this time.</h4> : null}
    </div>
  );
};

export default NewServices;
