import React from 'react';
import CCAdminPage from '../../../components/CCAdminPage';
import ServiceListing from '../../Provider/components/ServiceListing';

const EditServiceListing: React.FC = () => (
  <CCAdminPage
    title="Edit Listing"
    content={(
      <ServiceListing />
      )}
  />
);

export default EditServiceListing;
