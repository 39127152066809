import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonInput,
  IonItem,
  IonLoading,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import * as yup from 'yup';
import CCAdminPage from '../../../../components/CCAdminPage';
import {
  CrisisGroup,
  CrisisGroupForm,
} from '../../../../interfaces/CrisisHotlines';
import useForm from '../../../../lib/hooks/useForm';
import routePaths from '../../../../lib/routePaths';
import { useStoreActions, useStoreState } from '../../../../store';
import useOnboardingStyles from '../../../Provider/Onboarding/styles';
import useStyle from '../../styles';

const schema = yup.object().shape({
  name: yup.string().trim().required('Category is required'),
  name_es: yup.string().trim().required('Category Spanish is required'),
});

const deserializeCrisisGroup = (
  crisisGroup: CrisisGroup | null,
): CrisisGroupForm => ({
  name: crisisGroup?.name || '',
  name_es: crisisGroup?.translation?.es?.name || '',
});

const CrisisCategoryUpsert: React.FC = () => {
  const location = useLocation();
  const params = useParams();
  const classes = useStyle();
  const history = useHistory();
  const onboardingClasses = useOnboardingStyles();
  const [currentMode, setCurrentMode] = useState<string | null>(null);
  const [crisisGroupToEdit, setCrisisGroupToEdit] = useState<CrisisGroup | null>(
    null,
  );

  const {
    getCategory, setSuccess, createCategory, updateCategory,
  } = useStoreActions(
    (actions) => actions.crisisHotlines,
  );
  const {
    data: crisisGroups, loading, success, error,
  } = useStoreState(
    (state) => state.crisisHotlines,
  );

  const initialData = deserializeCrisisGroup(crisisGroupToEdit);

  const {
    errors, values, onChange, handleSubmit, setValues,
  } = useForm<
  CrisisGroupForm
  >({
    schema,
    initial: { ...initialData },
  });

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      const { id } = params as { id: number };
      if (id) {
        const crisisGroupPromise: Promise<CrisisGroup> = getCategory(id);
        crisisGroupPromise.then(crisisGroup => {
          setCrisisGroupToEdit(crisisGroup);
          setValues(deserializeCrisisGroup(crisisGroup));
        });
      }
    }
    setSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!location.pathname.includes('edit')) {
      setValues(deserializeCrisisGroup(null));
    }
  }, [crisisGroups, location.pathname, params, setValues]);

  useEffect(() => {
    if (location.pathname.includes('create')) {
      setCurrentMode('create');
    } else {
      setCurrentMode('edit');
    }
  }, [location]);

  const createCrisisGroup = async (data: CrisisGroupForm): Promise<void> => {
    await createCategory(data);
  };

  const updateCrisisGroup = async (data: CrisisGroupForm): Promise<void> => {
    data.id = crisisGroupToEdit?.id;
    await updateCategory(data);
  };

  useEffect(() => {
    if (success) {
      history.push(routePaths.admin.crisisHotlines);
    }
    return (): void => {
      setSuccess(false);
    };
  }, [success, setSuccess, history]);

  return (
    <>
      <CCAdminPage
        title={
          currentMode === 'create'
            ? 'Create crisis group'
            : 'Edit crisis group'
        }
        content={(
          <>
            <div className={classes.adminCenteredContent}>
              <div id="header">
                <h3 style={{ float: 'left' }}>
                  {currentMode === 'create'
                    ? 'Create crisis group'
                    : 'Edit crisis group'}
                </h3>
              </div>
              <div id="body" className={classes.upsertInformationalPage}>
                {error || Object.keys(errors).length ? (
                  <IonCard color="danger">
                    <IonCardHeader>
                      <IonCardTitle>
                        {error || 'Please correct the errors below.'}
                      </IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                ) : null}
                <form>
                  <IonItem className={onboardingClasses.input}>
                    <IonInput
                      value={values.name}
                      name="name"
                      type="text"
                      onIonChange={onChange}
                      placeholder="Crisis Category"
                    />
                  </IonItem>
                  {errors.name && (
                    <p className="error-message">
                      <IonText color="danger">{errors.name}</IonText>
                    </p>
                  )}
                  <br />
                  <IonItem className={onboardingClasses.input}>
                    <IonInput
                      value={values.name_es}
                      name="name_es"
                      type="text"
                      onIonChange={onChange}
                      placeholder="Crisis Category Spanish"
                    />
                  </IonItem>
                  {errors.name_es && (
                    <p className="error-message">
                      <IonText color="danger">{errors.name_es}</IonText>
                    </p>
                  )}
                  <br />
                  <div>
                    <IonButton
                      className={classes.providerActionButton}
                      shape="round"
                      fill="solid"
                      color="primary"
                      onClick={(e): void => {
                        if (currentMode === 'create') {
                          handleSubmit(createCrisisGroup)(e);
                        } else {
                          handleSubmit(updateCrisisGroup)(e);
                        }
                      }}
                    >
                      {currentMode === 'create'
                        ? 'Create crisis group'
                        : 'Update crisis group'}
                    </IonButton>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      />
      <IonLoading isOpen={loading || currentMode === null} />
    </>
  );
};

export default CrisisCategoryUpsert;
