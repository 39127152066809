import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  IonButton,
  IonLabel,
  IonRadioGroup,
  IonItem,
  IonRadio,
  IonListHeader,
  IonList,
  IonInput,
} from '@ionic/react';

import useStyles from './styles';
import useAppStyles from '../../styles';
import OrganizationType from '../../../interfaces/OrganizationType';
import { useStoreActions, useStoreState } from '../../../store';
import routePaths from '../../../lib/routePaths';

interface Indexable {
  [key: string]: string;
}

interface IndexableArray {
  [key: string]: string[];
}

const States: Indexable = {
  Loading: 'Loading...',
  Invalid: 'Unknown provider type.',
};

const questions: IndexableArray = {
  [States.Loading]: [],
  [States.Invalid]: [],
  [OrganizationType.NonProfit]: [
    'Are you a registered 501 (c) 3?',
    'Do you provide services in Collier County at no cost, or on a sliding scale?',
    'Can you confirm that your service(s) do not require referrals?',
    'Is your organization listed in the Community Foundation of Collier County’s Nonprofit Directory?',
  ],
  [OrganizationType.Public]: [
    'Do you provide services in Collier County?',
    'Are you a medical provider?',
    'Do you accept Medicare, Medicaid or other forms of governmental assistance?',
    'Do you provide services in Collier County at no cost, or on a sliding scale?',
  ],
  [OrganizationType.ForProfit]: [
    'Do you provide services in Collier County?',
    'Are you a medical provider?',
    'Do you accept Medicare, Medicaid or other forms of governmental assistance?',
    'Do you provide services in Collier County at no cost, or on a sliding scale?',
  ],
};

const Requirements: React.FC = () => {
  const classes = useStyles();
  const appClasses = useAppStyles();
  const history = useHistory();
  const meta = useStoreState(state => state.meta);
  const { setEin: setStoreEIN } = useStoreActions(
    (actions) => actions.createOrg,
  );

  /* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
  const params = useParams() as {
    providerTypeId: string;
  };

  const [questionIdx, setQuestionIdx] = useState<number>(0);
  const [selected, setSelected] = useState<string>('');
  const [ein, setEIN] = useState<string>('');
  const setCurrentStep = useStoreActions(
    (actions) => actions.createOrg.setCurrentStep,
  );

  useEffect(() => {
    setSelected('');
    setQuestionIdx(0);
    setEIN('');
    setCurrentStep(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let providerType = States.Loading;

  if (meta.data) {
    const type = meta.data.provider_type.find(t => t.id === Number(params.providerTypeId));
    if (type && questions[type.name]) {
      providerType = type.name;
    } else {
      providerType = States.Invalid;
    }
  }

  const nextClicked = (): void => {
    if (selected === 'no') {
      if (providerType !== OrganizationType.NonProfit && questionIdx === 1) {
        setQuestionIdx(questionIdx + 2);
        setSelected('');
      } else {
        history.push(routePaths.public.root);
      }
    } else if (questionIdx + 1 < questions[providerType].length
      && !(providerType !== OrganizationType.NonProfit && questionIdx === 2)) {
      setQuestionIdx(questionIdx + 1);
      setSelected('');
    } else {
      setStoreEIN(ein);
      history.push(routePaths.provider.onboarding.orgInfo);
    }
  };

  return (
    <>
      <h3 className={appClasses.title}>Service Provider Eligibility</h3>
      {(providerType === States.Loading || providerType === States.Invalid) ? (
        <p>{providerType}</p>
      ) : (
        <>
          <div>
            <IonList className={classes.inputsList}>
              <IonRadioGroup
                value={selected}
                onIonChange={(e): void => setSelected(e.detail.value)}
              >
                <IonListHeader className="ion-no-padding ion-no-margin">
                  <IonLabel className={classes.radioGroupHeader}>
                    {questions[providerType][questionIdx]}
                  </IonLabel>
                </IonListHeader>

                <IonItem lines="none" className={classes.radioButtonItem}>
                  <IonRadio
                    className={classes.radioButton}
                    mode="md"
                    value="yes"
                  />
                  <IonLabel className={classes.radioButtonLabel}>Yes</IonLabel>
                </IonItem>

                <IonItem lines="none" className={classes.radioButtonItem}>
                  <IonRadio
                    className={classes.radioButton}
                    mode="md"
                    value="no"
                  />
                  <IonLabel className={classes.radioButtonLabel}>No</IonLabel>
                </IonItem>
              </IonRadioGroup>
            </IonList>
          </div>
          <div>
            <IonInput
              className={classes.input}
              name="ein"
              placeholder="EIN*"
              hidden={
                    providerType !== OrganizationType.NonProfit
                    || questionIdx !== 0
                  }
              onIonChange={(e): void => setEIN(e.detail.value ?? '')}
            />
          </div>
          <div className="ion-padding-top">
            <IonLabel
              className={classes.ineligibleText}
              hidden={selected !== 'no' || (providerType !== OrganizationType.NonProfit && questionIdx === 1)}
            >
              Unfortunately, your organization does not meet the requirements to
              list your service on Collier CARES. Please contact the Collier
              CARES administrator if you have any questions at
              admin@colliercares.org
            </IonLabel>
          </div>
          <div className="ion-padding-top">
            <IonButton
              disabled={
                    selected === ''
                    || (selected === 'yes'
                      && providerType === OrganizationType.NonProfit
                      && questionIdx === 0
                      && ein.length === 0)
                  }
              className="cc-button cc-tall-button"
              shape="round"
              type="submit"
              expand="block"
              onClick={(): void => nextClicked()}
            >
              Next
            </IonButton>
          </div>
        </>
      )}
    </>
  );
};

export default Requirements;
