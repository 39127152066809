/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import { ServiceMethods } from '@feathersjs/feathers';

import PaginationResult, { Page } from '../../interfaces/PaginationResult';
import { useStoreState } from '../../store';

interface ServiceResult<T> {
  loading: boolean;
  error: string;
  result: PaginationResult<T>;
}

export default function useService<T>(service: ServiceMethods<T>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  query: Page, filter: Object, requireFilters = false): ServiceResult<T> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const locale = useStoreState((state) => state.translations.language);
  const [result, setResult] = useState<PaginationResult<T>>({
    limit: 0,
    skip: 0,
    data: [],
  });

  useEffect(() => {
    (async (): Promise<void> => {
      if (requireFilters && !Object.keys(filter).length) return;
      setLoading(true);
      try {
        const serviceResult = (await service.find({
          query: {
            $limit: query.limit,
            $skip: query.skip,
            locale,
            ...filter,
          },
        }) as PaginationResult<T>);
        setResult(serviceResult);
      } catch (err) {
        setError(err.message);
      }
      setLoading(false);
    })();
  }, [requireFilters, service, query, filter, locale]);

  return {
    loading,
    error,
    result,
  };
}
