import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import CCAdminPage from '../../../../components/CCAdminPage';
import {
  Category,
  SubCategory,
  SubCategoryForm,
} from '../../../../interfaces/Meta';
import { listings } from '../../../../lib/api/services';
import useForm from '../../../../lib/hooks/useForm';
import { useStoreActions, useStoreState } from '../../../../store';
import useStyle from '../../styles';
import ServiceListingsPreview from '../ServiceListingsPreview';
import ListingAssociatedCategoryRow from './ListingAssociatedCategoryRow';

const schema = yup.object().shape({
  name: yup.string().trim().required('Subcategory is required'),
  name_es: yup.string().trim().required('Subcategory Name Spanish is required'),
});

const deserializeSubcategory = (
  subcategory: SubCategory | null,
): SubCategoryForm => ({
  name: subcategory?.name || '',
  name_es: subcategory?.translation?.es?.name || '',
});

const ListingSubcategoryEdit: React.FC = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [
    subcategoryToEdit,
    setSubcategoryToEdit,
  ] = useState<SubCategory | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null,
  );
  const [
    originalAssociatedCategories,
    setOriginalAssociatedCategories,
  ] = useState<Category[]>([]);
  const [
    selectedCategoriesToAssociate,
    setSelectedCategoriesToAssociate,
  ] = useState<Category[]>([]);
  const [areThereUnsavedChanges, setAreThereUnsavedChanges] = useState(false);
  const [finishedFetching, setFinishedFetching] = useState(false);
  const [availableCategoriesList, setAvailableCategoriesList] = useState<
  Category[]
  >([]);
  const { data: categories } = useStoreState(state => state.categories);
  const { create, update, get } = useStoreActions((actions) => actions.subcategories);
  const { setListingPreviewModalListings } = useStoreActions(
    (actions) => actions.admin,
  );
  const classes = useStyle();

  const initialData = deserializeSubcategory(subcategoryToEdit);
  const {
    errors, values, onChange, handleSubmit, setValues,
  } = useForm<
  SubCategoryForm
  >({
    schema,
    initial: { ...initialData },
  });

  // eslint-disable-next-line max-len
  const getSortedCategories = (categoriesToSort: Category[]): Category[] => categoriesToSort.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  useEffect(() => {
    const { id } = params as { id: number };
    if (id) {
      (async (): Promise<void> => {
        if (!subcategoryToEdit) {
          const fetchedSubcategory = await get(id);
          setSubcategoryToEdit(fetchedSubcategory);
          setOriginalAssociatedCategories(fetchedSubcategory.categories ?? []);
          setSelectedCategoriesToAssociate(fetchedSubcategory.categories ?? []);
          setFinishedFetching(true);
        }
      })();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const calculatedCategoriesList = categories?.filter(
      (category) => !selectedCategoriesToAssociate.find((s) => s.id === category.id),
    );
    setAvailableCategoriesList(calculatedCategoriesList ?? []);
    if (calculatedCategoriesList && calculatedCategoriesList[0]) {
      setSelectedCategory(calculatedCategoriesList[0]);
    } else {
      setSelectedCategory(null);
    }
  }, [categories, selectedCategoriesToAssociate, setSelectedCategoriesToAssociate]);

  useEffect(() => {
    if (location.pathname.includes('create')) {
      setValues(deserializeSubcategory(null));
    } else if (subcategoryToEdit) {
      setValues(deserializeSubcategory(subcategoryToEdit));
    }
  }, [location.pathname, setValues, subcategoryToEdit]);

  useEffect(() => {
    let result = false;
    if (finishedFetching) {
      if (originalAssociatedCategories.length > selectedCategoriesToAssociate.length) {
        result = originalAssociatedCategories.some(
          c => !selectedCategoriesToAssociate.find(s => s.id === c.id),
        );
      } else if (originalAssociatedCategories.length < selectedCategoriesToAssociate.length) {
        result = selectedCategoriesToAssociate.some(
          c => !originalAssociatedCategories.find(s => s.id === c.id),
        );
      // eslint-disable-next-line max-len
      } else if (originalAssociatedCategories.length !== 0 && selectedCategoriesToAssociate.length !== 0) {
        result = selectedCategoriesToAssociate.some(
          c => !originalAssociatedCategories.find(s => s.id === c.id),
        );
      }
      setAreThereUnsavedChanges(result);
    }
  }, [originalAssociatedCategories, selectedCategoriesToAssociate, finishedFetching]);

  const handleAddCategory = (): void => {
    if (selectedCategory) {
      setSelectedCategoriesToAssociate(
        getSortedCategories([
          ...(selectedCategoriesToAssociate ?? []),
          selectedCategory,
        ]),
      );
    }
  };

  const handleRemoveCategory = (id: number): void => {
    const foundIndex = selectedCategoriesToAssociate.findIndex(
      (s) => s.id === id,
    );
    const newCategoriesToAssociate = [...selectedCategoriesToAssociate];
    newCategoriesToAssociate.splice(foundIndex, 1);
    setSelectedCategoriesToAssociate(
      getSortedCategories(newCategoriesToAssociate),
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelectCategory = (event: any): void => {
    const categoryId = event.target.value;
    const foundCategory = categories?.find(
      (c) => c.id === Number(categoryId),
    );
    if (foundCategory) {
      setSelectedCategory(foundCategory);
    }
  };

  const handleViewCategoryServices = async (): Promise<void> => {
    const results = await listings.find({
      query: { sub_categories: [subcategoryToEdit?.id], $limit: subcategoryToEdit?.num_services },
    });
    setListingPreviewModalListings(results.data);
  };

  const handleCreateCategory = async (
    formData: SubCategoryForm,
  ): Promise<void> => {
    await create({
      ...formData,
      categories: selectedCategoriesToAssociate.map((c) => ({ id: c.id })),
    });
    history.goBack();
  };

  const handleUpdateSubcategory = async (
    formData: SubCategoryForm,
  ): Promise<void> => {
    await update({
      ...formData,
      categories: selectedCategoriesToAssociate.map((c) => ({ id: c.id })),
      id: subcategoryToEdit?.id,
    });
    history.goBack();
  };

  let title = 'Edit Subcategory Associations';
  if (location.pathname.includes('create')) {
    title = 'Create Subcategory';
  }

  return (
    <>
      <CCAdminPage
        title={title}
        content={(
          <>
            <div className={classes.adminContent}>
              <h3>{title}</h3>
              <IonGrid>
                <IonRow>
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="9">
                    <div className={classes.subcategoryFormRow}>
                      <IonItem className={classes.input}>
                        <IonInput
                          value={values.name}
                          name="name"
                          type="text"
                          maxlength={64}
                          onIonChange={onChange}
                          placeholder="Subcategory Name"
                        />
                      </IonItem>
                      {errors.name && (
                        <p className="error-message">
                          <IonText color="danger">{errors.name}</IonText>
                        </p>
                      )}
                    </div>
                  </IonCol>
                  {location.pathname.includes('edit') && (
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
                      <IonButton
                        className={classes.providerActionButton}
                        shape="round"
                        fill="solid"
                        color="primary"
                        onClick={handleViewCategoryServices}
                      >
                        View Associated Services ({subcategoryToEdit?.num_services})
                      </IonButton>
                    </IonCol>
                  )}
                </IonRow>
                <IonRow>
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="9">
                    <div className={classes.subcategoryFormRow}>
                      <IonItem className={classes.input}>
                        <IonInput
                          value={values.name_es}
                          name="name_es"
                          type="text"
                          maxlength={64}
                          onIonChange={onChange}
                          placeholder="Subcategory Name Spanish"
                        />
                      </IonItem>
                      {errors.name_es && (
                        <p className="error-message">
                          <IonText color="danger">{errors.name_es}</IonText>
                        </p>
                      )}
                    </div>
                  </IonCol>
                </IonRow>
                {areThereUnsavedChanges && (
                  <IonRow>
                    <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12">
                      <p style={{ color: '#777', textAlign: 'left' }}>
                        <i><FormattedMessage id="unsaved-changes" /></i>
                      </p>
                    </IonCol>
                  </IonRow>
                )}
                <IonRow>
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="9">
                    <IonItem>
                      <IonLabel>Categories</IonLabel>
                      <IonSelect
                        onIonChange={handleSelectCategory}
                        className={classes.categorySelect}
                        value={selectedCategory?.id}
                      >
                        {getSortedCategories(availableCategoriesList).map(
                          (item) => (
                            <IonSelectOption key={item.id} value={item.id}>
                              {item.name}
                            </IonSelectOption>
                          ),
                        )}
                      </IonSelect>
                    </IonItem>
                  </IonCol>
                  <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
                    <IonButton
                      className={classes.providerActionButton}
                      shape="round"
                      fill="solid"
                      color="primary"
                      onClick={handleAddCategory}
                    >
                      Add
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
              <ServiceListingsPreview />
              {selectedCategoriesToAssociate.map((category) => (
                <ListingAssociatedCategoryRow
                  key={category.id}
                  category={category}
                  removeCategory={handleRemoveCategory}
                />
              ))}
              <div className={classes.saveButtonWrapper}>
                <IonButton
                  className={classes.cancelButton}
                  shape="round"
                  fill="solid"
                  color="secondary"
                  onClick={(e): void => {
                    e.preventDefault();
                    history.goBack();
                  }}
                >
                  Cancel
                </IonButton>

                <IonButton
                  className={classes.saveButton}
                  shape="round"
                  fill="solid"
                  color="primary"
                  onClick={(e): void => {
                    if (location.pathname.includes('create')) {
                      handleSubmit(handleCreateCategory)(e);
                    } else {
                      handleSubmit(handleUpdateSubcategory)(e);
                    }
                  }}
                >
                  Save
                </IonButton>
              </div>
            </div>
          </>
        )}
      />
    </>
  );
};

export default ListingSubcategoryEdit;
