import {
  IonButton,
  IonCheckbox,
  IonInput,
  IonItem,
  IonLabel,
  IonLoading,
  IonText,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import CCAdminPage from '../../../../components/CCAdminPage';
import WysiwygInput from '../../../../components/WysiwygInput';
import { Page, PageForm } from '../../../../interfaces/Page';
import useForm from '../../../../lib/hooks/useForm';
import routePaths from '../../../../lib/routePaths';
import { useStoreActions, useStoreState } from '../../../../store';
import useOnboardingStyles from '../../../Provider/Onboarding/styles';
import useStyle from '../../styles';
import appMenuItems from '../../../../lib/appMenuItems';
import { getTranslatedString } from '../../../../lib/utils';

const schema = yup.object().shape({
  title: yup.string().trim().required('Title is required'),
  navbar_title: yup.string().trim().required('Main Nav Title is required'),
  navbar: yup.boolean(),
  enabled: yup.boolean(),
  contents: yup.string().trim().required('Contents is required'),
  title_es: yup.string().trim().required('Spanish Title is required'),
  navbar_title_es: yup
    .string()
    .trim()
    .required('Spanish Main Nav Title is required'),
  contents_es: yup.string().trim().required('Spanish Contents is required'),
  navbar_category: yup.number().required(),
});

const deserializePage = (page: Page | null): PageForm => ({
  title: page?.title || '',
  navbar_title: page?.navbar_title || '',
  contents: page?.contents || '',
  enabled: page?.enabled || false,
  navbar: page?.navbar || false,
  navbar_order: page?.navbar_order || 0,
  navbar_category: (page?.navbar_category === null || page?.navbar_category === undefined)
    ? 0 : page?.navbar_category,
  title_es: page?.translation?.es?.title || '',
  navbar_title_es: page?.translation?.es?.navbar_title || '',
  contents_es: page?.translation?.es?.contents || '',
});

interface PageUpsertParams {
  id: string;
}

const PageUpsert: React.FC = () => {
  const location = useLocation();
  const params = useParams<PageUpsertParams>();
  const classes = useStyle();
  const history = useHistory();
  const onboardingClasses = useOnboardingStyles();
  const [currentMode, setCurrentMode] = useState<string | null>(null);
  const [pageToEdit, setPageToEdit] = useState<Page | null>(null);

  const {
    create, update, get, setSuccess,
  } = useStoreActions(
    (actions) => actions.pages,
  );
  const {
    data: pages, loading, error, success,
  } = useStoreState(
    (state) => state.pages,
  );

  const initialData = deserializePage(pageToEdit);

  const {
    errors, values, onChange, handleSubmit, setValues, reset, resetCache,
  } = useForm<
  PageForm
  >({
    schema,
    initial: { ...initialData },
  });

  useEffect(() => () => {
    reset();
    resetCache();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      const { id } = params;
      if (id) {
        get(id);
      }
    }
    setSuccess(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (location.pathname.includes('edit')) {
      const { id } = params;
      const foundPage = pages?.find((p) => Number(p.id) === Number(id));
      if (foundPage) {
        setPageToEdit(foundPage);
        setValues(deserializePage(foundPage));
      }
    } else {
      setValues(deserializePage(null));
    }
  }, [pages, location.pathname, params, setValues]);

  useEffect(() => {
    if (location.pathname.includes('create')) {
      setCurrentMode('create');
    } else {
      setCurrentMode('edit');
    }
  }, [location]);

  const createPage = async (data: PageForm): Promise<void> => {
    await create(data);
  };

  const updatePage = async (data: PageForm): Promise<void> => {
    data.id = pageToEdit?.id;
    await update(data);
  };

  useEffect(() => {
    if (success) {
      history.push(routePaths.admin.informationalPages);
    }
    return (): void => {
      setSuccess(false);
    };
  }, [success, setSuccess, history]);

  return (
    <>
      <CCAdminPage
        title={currentMode === 'create' ? 'Create new page' : 'Edit page'}
        content={(
          <>
            <div className={classes.adminCenteredContent}>
              <div id="header">
                <h3 style={{ float: 'left' }}>
                  {currentMode === 'create' ? 'Create new page' : 'Edit page'}
                </h3>
              </div>
              <div id="body" className={classes.upsertInformationalPage}>
                {error || Object.keys(errors).length ? (
                  <IonCard color="danger">
                    <IonCardHeader>
                      <IonCardTitle>
                        {error || 'Please correct the errors below.'}
                      </IonCardTitle>
                    </IonCardHeader>
                  </IonCard>
                ) : null}
                <form>
                  <IonItem className={onboardingClasses.checkbox}>
                    <IonCheckbox
                      name="enabled"
                      checked={values.enabled}
                      onIonChange={(e): void => onChange({
                        target: {
                          name: 'enabled',
                          value: e.detail.checked,
                        },
                      })}
                    />
                    <IonLabel>Enabled</IonLabel>
                  </IonItem>
                  <IonItem className={onboardingClasses.checkbox}>
                    <IonCheckbox
                      name="enabled"
                      checked={values.navbar}
                      onIonChange={(e): void => onChange({
                        target: {
                          name: 'navbar',
                          value: e.detail.checked,
                        },
                      })}
                    />
                    <IonLabel>Show Navbar Title</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonLabel>Navbar Category</IonLabel>
                    <IonSelect
                      name="navbar_category"
                      onIonChange={onChange}
                      className={classes.categorySelect}
                      value={values.navbar_category}
                    >
                      {appMenuItems.map((item) => (
                        <IonSelectOption key={item.navbar_category} value={item.navbar_category}>
                          {getTranslatedString(item.navTitleId, 'en')}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonItem>
                  <br />
                  <IonItem lines="full">
                    <h4>English</h4>
                  </IonItem>
                  <IonItem className={onboardingClasses.input}>
                    <IonInput
                      value={values.title}
                      name="title"
                      type="text"
                      onIonChange={onChange}
                      placeholder="Title"
                    />
                  </IonItem>
                  {errors.title && (
                    <p className="error-message">
                      <IonText color="danger">{errors.title}</IonText>
                    </p>
                  )}
                  <IonItem className={onboardingClasses.input}>
                    <IonInput
                      value={values.navbar_title}
                      name="navbar_title"
                      type="text"
                      onIonChange={onChange}
                      placeholder="Navbar Title"
                    />
                  </IonItem>
                  {errors.navbar_title && (
                    <p className="error-message">
                      <IonText color="danger">{errors.navbar_title}</IonText>
                    </p>
                  )}
                  <div className={classes.wysiwyg}>
                    <WysiwygInput
                      key={`page-${params.id}-content-en`}
                      name="contents"
                      placeholder="Content"
                      value={values.contents}
                      onChange={onChange}
                    />
                  </div>
                  {errors.contents && (
                    <p className="error-message">
                      <IonText color="danger">{errors.contents}</IonText>
                    </p>
                  )}
                  <IonItem lines="full">
                    <h4>Spanish</h4>
                  </IonItem>
                  <IonItem className={onboardingClasses.input}>
                    <IonInput
                      value={values.title_es}
                      name="title_es"
                      type="text"
                      onIonChange={onChange}
                      placeholder="Spanish Title"
                    />
                  </IonItem>
                  {errors.title_es && (
                    <p className="error-message">
                      <IonText color="danger">{errors.title_es}</IonText>
                    </p>
                  )}
                  <IonItem className={onboardingClasses.input}>
                    <IonInput
                      value={values.navbar_title_es}
                      name="navbar_title_es"
                      type="text"
                      onIonChange={onChange}
                      placeholder="Spanish Navbar Title"
                    />
                  </IonItem>
                  {errors.navbar_title_es && (
                    <p className="error-message">
                      <IonText color="danger">{errors.navbar_title_es}</IonText>
                    </p>
                  )}
                  <div className={classes.wysiwyg}>
                    <WysiwygInput
                      key={`page-${params.id}-content-es`}
                      name="contents_es"
                      placeholder="Spanish Content"
                      value={values.contents_es}
                      onChange={onChange}
                    />
                  </div>
                  {errors.contents_es && (
                    <p className="error-message">
                      <IonText color="danger">{errors.contents_es}</IonText>
                    </p>
                  )}
                  <div>
                    <IonButton
                      className={classes.providerActionButton}
                      shape="round"
                      fill="solid"
                      color="primary"
                      onClick={(e): void => {
                        if (currentMode === 'create') {
                          handleSubmit(createPage)(e);
                        } else {
                          handleSubmit(updatePage)(e);
                        }
                      }}
                    >
                      {currentMode === 'create' ? 'Create Page' : 'Update Page'}
                    </IonButton>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      />
      <IonLoading isOpen={loading || currentMode === null} />
    </>
  );
};

export default PageUpsert;
