import { createUseStyles } from 'react-jss';

export default createUseStyles({
  labelRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  stepItem: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    justifyContent: 'center',
    paddingBottom: '5px',
    paddingTop: '0px',
    background: '#777',
    color: '#ECECEC',
    textAlign: 'center',
    '&.interiorItem': {
      marginRight: '5px',
    },
    '& .title': {
      fontSize: '10px',
    },
    '& .number': {
      fontSize: '14px',
    },
  },
  stepItemActive: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: '5px',
    paddingTop: '0px',
    background: 'var(--ion-color-primary)',
    color: '#ECECEC',
    textAlign: 'center',
    '&.interiorItem': {
      marginRight: '5px',
    },
    '& .title': {
      fontSize: '10px',
    },
    '& .number': {
      fontSize: '14px',
    },
  },
  stepNumber: {
    padding: '0px',
    marginTop: '5px',
    marginBottom: '5px',
    fontWeight: '700',
  },
  stepTitle: {
    padding: '0px',
    fontWeight: '400',
  },
  labelRowDesktop: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginBottom: '20px',
    fontSize: '20px',
    fontWeight: '300',
    color: 'var(--ion-color-primary)',
    '&.active .number': {
      background: '#EAAA00',
      border: 'none',
      color: '#FFF',
    },
    '&.active .label': {
      fontWeight: '700',
    },
  },
  stepItemDesktop: {
  },
  stepNumberDesktop: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    border: '2px solid var(--ion-color-primary)',
    margin: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '400',
  },
  stepTitleDesktop: {
  },
});
