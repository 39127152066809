/* eslint-disable max-len */
import { PushNotifications } from '@capacitor/push-notifications';
import { Storage } from '@capacitor/storage';
import {
  IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonModal, IonSelect,
  IonSelectOption, IonSpinner, IonToggle, IonToolbar,
} from '@ionic/react';
import { caretBack, caretForward } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReactMarkdown from 'react-markdown/with-html';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import logo from '../../assets/cc_logo.png';
import { getTranslatedString, translateName, translateProp } from '../../lib/utils';
import { useStoreActions, useStoreState } from '../../store';
import useAppStyles from '../styles';

const Onboarding: React.FC = () => {
  const appClasses = useAppStyles();
  const history = useHistory();
  const meta = useStoreState(state => state.meta);
  const { language } = useStoreState(state => state.translations);
  const {
    getGeneralNotifications,
    getUrgentNotifications,
    useAgeOnSearch,
    useCitiesOnSearch,
    useGroupsOnSearch,
    useLocationOnSearch,
  } = useStoreState(state => state.settings);
  const {
    setGetAllNotifications,
    setGetGeneralNotifications,
    setGetUrgentNotifications,
    setUseAgeOnSearch,
    setUseLocationOnSearch,
    setUseGroupsOnSearch,
    setUseCitiesOnSearch,
  } = useStoreActions(state => state.settings);
  const locale = useStoreState((state) => state.translations.language);
  const [questionIdx, setQuestionIdx] = useState<number>(0);
  const [nextEnabled, setNextEnabled] = useState<boolean>(false);
  const [showNag, setShowNag] = useState<boolean>(false);
  const ageAlertOptions = {
    header: getTranslatedString('select-age-group', language),
  };
  const groupsAlertOptions = {
    header: getTranslatedString('select-groups', language),
  };
  const citiesAlertOptions = {
    header: getTranslatedString('select-cities', language),
  };

  useEffect(() => {
    async function showDisclaimer(): Promise<void> {
      const disclaimerApproved = await Storage.get({ key: 'disclaimer_approved' });
      if (disclaimerApproved.value === null) {
        setShowNag(true);
      }
    }
    showDisclaimer();
    setQuestionIdx(0);
    Storage.set({ key: 'seeker_onboarded', value: 'true' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (questionIdx === 0) {
      setNextEnabled(useLocationOnSearch || useCitiesOnSearch.length > 0);
    } else if (questionIdx === 1) {
      setNextEnabled(useAgeOnSearch.length > 0);
    } else if (questionIdx === 2) {
      setNextEnabled(useGroupsOnSearch.length > 0);
    } else if (questionIdx === 3) {
      setNextEnabled(
        getGeneralNotifications || getUrgentNotifications,
      );
    } else {
      history.goBack();
    }
  }, [
    questionIdx, history, useLocationOnSearch, useCitiesOnSearch.length,
    useAgeOnSearch, useGroupsOnSearch.length, getGeneralNotifications, getUrgentNotifications,
  ]);

  useEffect(() => {
    if (getGeneralNotifications || getUrgentNotifications) {
      PushNotifications.checkPermissions().then(res => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then(newRes => {
            if (newRes.receive === 'denied') {
              setGetGeneralNotifications(false);
              setGetUrgentNotifications(false);
            }
          });
        }
      });
    }
  }, [getGeneralNotifications, getUrgentNotifications, setGetGeneralNotifications, setGetUrgentNotifications]);

  const skipClicked = (): void => {
    // Clear any previously set preference before moving forward.
    if (questionIdx === 0) {
      setUseLocationOnSearch(false);
      setUseCitiesOnSearch([]);
    } else if (questionIdx === 1) {
      setUseAgeOnSearch([]);
    } else if (questionIdx === 2) {
      setUseGroupsOnSearch([]);
    } else if (questionIdx === 3) {
      setGetGeneralNotifications(false);
      setGetUrgentNotifications(false);
    }
    setQuestionIdx(questionIdx + 1);
  };

  return (
    <IonContent scrollY>
      <div className="ion-margin p-1">
        <div style={{
          display: 'flex', justifyContent: 'center',
        }}
        >
          <img
            src={logo}
            alt="home"
          />
        </div>
        <h3 className={[appClasses.title, 'mt-16', 'mb-24'].join(' ')}>
          <FormattedMessage id="set-up-filters-onboarding" />
        </h3>
        {
          (questionIdx === 0)
            ? (
              <>
                <h3 className={appClasses.subtitle} style={{ color: 'var(--ion-color-primary)' }}>
                  <FormattedMessage id="search-location" />
                </h3>
                <IonItem className={[appClasses.toggleItemSingleLine, 'ion-no-padding ion-no-margin'].join(' ')}>
                  <IonToggle
                    className={appClasses.toggle}
                    checked={useLocationOnSearch}
                    mode="ios"
                    onIonChange={(e): void => {
                      if (!e.detail.checked) {
                        setUseCitiesOnSearch([]);
                      }
                      if (e.detail.checked !== useLocationOnSearch) {
                        setUseLocationOnSearch(e.detail.checked);
                      }
                    }}
                  />
                  <IonLabel className={appClasses.subtitle} style={{ color: '#000' }}>
                    <FormattedMessage id="find-services-near-me" />
                  </IonLabel>
                </IonItem>
                <div className={appClasses.whitebg} hidden={useLocationOnSearch}>
                  <h4 className={appClasses.subtitle}>
                    <FormattedMessage id="search-for-services-in-these-cities" />
                  </h4>
                  <IonSelect
                    key="cities"
                    className="cc-select"
                    placeholder={getTranslatedString('select-cities', language)}
                    value={useCitiesOnSearch}
                    multiple
                    interfaceOptions={citiesAlertOptions}
                    onIonChange={(e): void => {
                      if (Array.isArray(e.detail.value)) {
                        setUseCitiesOnSearch(e.detail.value);
                      }
                    }}
                  >
                    {meta.data?.city.map((city) => (
                      <IonSelectOption key={city.id} value={city.id}>
                        {city.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </div>
              </>
            )
            : (questionIdx === 1)
              ? (
                <div className={appClasses.whitebg}>
                  <h4 className={appClasses.subtitle}>
                    <FormattedMessage id="search-in-age-range" />
                  </h4>
                  <IonSelect
                    key="age"
                    className="cc-select"
                    placeholder={getTranslatedString('select-age-group', language)}
                    value={useAgeOnSearch}
                    interfaceOptions={ageAlertOptions}
                    multiple
                    onIonChange={(e): void => {
                      if (Array.isArray(e.detail.value)) {
                        setUseAgeOnSearch(e.detail.value);
                      }
                    }}
                  >
                    {meta.data?.age_range.map((ageGroup) => (
                      <IonSelectOption key={ageGroup.id} value={ageGroup.id}>
                        {ageGroup.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </div>
              ) : (questionIdx === 2)
                ? (
                  <div className={appClasses.whitebg}>
                    <h4 className={appClasses.subtitle}>
                      <FormattedMessage id="search-in-groups" />
                    </h4>
                    <IonSelect
                      key="groups"
                      className="cc-select"
                      placeholder={getTranslatedString('select-groups', language)}
                      value={useGroupsOnSearch}
                      multiple
                      interfaceOptions={groupsAlertOptions}
                      onIonChange={(e): void => {
                        if (Array.isArray(e.detail.value)) {
                          setUseGroupsOnSearch(e.detail.value);
                        }
                      }}
                    >
                      {meta.data?.group.map((groupName) => (
                        <IonSelectOption key={groupName.id} value={groupName.id}>
                          {translateName(groupName, locale)}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </div>
                ) : (questionIdx === 3)
                  ? (
                    <>
                      <h3 className={appClasses.subtitle}>
                        <FormattedMessage id="mobile-notifications" />
                      </h3>
                      <IonItem className={[appClasses.toggleItemSingleLine, 'ion-no-padding ion-no-margin'].join(' ')}>
                        <IonToggle
                          className={appClasses.toggle}
                          checked={
                            getGeneralNotifications
                            && getUrgentNotifications
                          }
                          mode="ios"
                          onIonChange={(e): void => {
                            if ((e.detail.checked && (!getGeneralNotifications || !getUrgentNotifications))
                              || (!e.detail.checked && (getGeneralNotifications || getUrgentNotifications))) {
                              setGetAllNotifications(e.detail.checked);
                            }
                          }}
                        />
                        <IonLabel className={appClasses.subtitle} color="primary">
                          <FormattedMessage id="get-all-push-notifications" />
                        </IonLabel>
                      </IonItem>
                      <IonItem className={[appClasses.toggleItemSingleLine, 'ion-no-padding ion-no-margin'].join(' ')}>
                        <IonToggle
                          className={appClasses.toggle}
                          mode="ios"
                          disabled={getGeneralNotifications}
                          checked={getUrgentNotifications}
                          onIonChange={(e): void => {
                            if ((e.detail.checked && !getUrgentNotifications)
                              || (!e.detail.checked && getUrgentNotifications)) {
                              setGetUrgentNotifications(e.detail.checked);
                            }
                          }}
                        />
                        <IonLabel className={[appClasses.subtitle, 'ion-text-wrap'].join(' ')} color="primary">
                          <FormattedMessage id="get-urgent-alerts" />
                        </IonLabel>
                      </IonItem>
                    </>
                  )
                  : null
        }
        <div
          style={{
            marginTop: '30px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <IonButtons slot="start">
            <IonButton hidden={questionIdx === 0} color="primary" onClick={(): void => setQuestionIdx(questionIdx - 1)}>
              <IonIcon color="primary" icon={caretBack} />
              Back
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={(): void => skipClicked()}>
              Skip
            </IonButton>
            <IonButton color="primary" disabled={!nextEnabled} onClick={(): void => setQuestionIdx(questionIdx + 1)}>
              Next
              <IonIcon color="primary" icon={caretForward} />
            </IonButton>
          </IonButtons>
        </div>
        <IonModal
          isOpen={showNag}
          onDidDismiss={(): void => setShowNag(false)}
          backdropDismiss={false}
        >
          <IonHeader>
            <IonToolbar color="primary">
              <IonButtons collapse slot="end">
                <IonButton
                  onClick={(): void => {
                    Storage.set({ key: 'disclaimer_approved', value: 'true' });
                    setShowNag(false);
                  }}
                >
                  I Agree / Estoy de acuerdo
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {meta.data && meta.data.terms_and_conditions ? (
              <ReactMarkdown
                source={translateProp(meta.data.terms_and_conditions, 'contents', language)}
                escapeHtml={false}
              />
            ) : <IonSpinner />}
          </IonContent>
        </IonModal>
      </div>
    </IonContent>
  );
};

export default Onboarding;
