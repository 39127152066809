import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonContent,
  IonLabel,
  IonModal,
  IonRow,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useHistory } from 'react-router';
import Icon from '../../components/Icon';
import useClasses from '../../components/styles';
import { AgeRange, Group } from '../../interfaces/Meta';
import { useStoreState, useStoreActions } from '../../store';
import routePaths from '../../lib/routePaths';
import { getTranslatedString, translateName } from '../../lib/utils';

interface SearchFiltersProps {
  showModal: boolean;
  toggleModal: Function;
}

interface OtherFilter {
  id: string;
  name: string;
}

type Filter = AgeRange | Group | OtherFilter;

interface ListingFilter {
  name: string;
  filterName: string;
  options: Filter[];
}

const clearBtnStyle = { right: 0 };
const SearchFilters: React.FC<SearchFiltersProps> = ({
  showModal,
  toggleModal,
}: SearchFiltersProps) => {
  const classes = useClasses();
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const { data } = useStoreState((state) => state.meta);
  const { language } = useStoreState((state) => state.translations);
  const { setUseDefaultFilters } = useStoreActions((state) => state.settings);
  const [listingFilters, setListingFilters] = useState<ListingFilter[]>([]);
  const locale = useStoreState((state) => state.translations.language);
  const [otherFilters, setOtherFilters] = useState<string[]>([]);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(decodeURI(location.search));
    const sFilters: string[] = [];
    const others: string[] = [];
    searchParams.forEach((p, i) => {
      if (i.includes('[]')) {
        const keyName = i.substring(0, i.length - 2);
        const isFilter = !!listingFilters.find((f) => f.filterName === keyName);
        if (isFilter) {
          sFilters.push(`${keyName}_${p}`);
        } else {
          others.push(`${i}=${p}`);
        }
      } else {
        const foundFilter = listingFilters.find((f) => f.filterName === i);
        if (foundFilter) {
          sFilters.push(`${i}_${foundFilter.options[0].id}`);
        } else {
          others.push(`${i}=${p}`);
        }
      }
    });
    setSelectedFilters(sFilters);
    setOtherFilters(others);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, listingFilters]);

  useEffect(() => {
    const aFilters = [];
    if (data) {
      if (data.age_range) {
        aFilters.push({
          name: getTranslatedString('ages-served', language),
          filterName: 'age_ranges',
          options: data.age_range,
        });
      }
      if (data.group) {
        aFilters.push({
          name: getTranslatedString('groups-served', language),
          filterName: 'groups',
          options: data.group,
        });
      }
      setListingFilters([{
        name: 'Others',
        filterName: 'open',
        options: [{ name: getTranslatedString('open-now', language), id: 'open' }],
      }, ...aFilters]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, language]);

  useEffect(() => {
    const optionsLength = listingFilters.reduce((acc, curr) => {
      acc += curr.options.length;
      return acc;
    }, 0);
    setSelectAllChecked(optionsLength === selectedFilters.length);
  }, [listingFilters, selectedFilters.length]);

  const updateSelectedFilters = (id: number | string, group: string): void => {
    const slug = `${group}_${id}`;
    const index = selectedFilters.indexOf(slug);
    let newSet = [];
    if (index > -1) {
      newSet = [...selectedFilters];
      newSet.splice(index, 1);
    } else {
      newSet = [...selectedFilters, slug];
    }
    setSelectedFilters(newSet);
  };

  const selectAll = (e: React.MouseEvent<HTMLIonCheckboxElement>): void => {
    const { checked } = e.target as HTMLIonCheckboxElement;
    if (checked) {
      const selected = listingFilters.reduce((acc, curr) => {
        const opts = curr.options.map((f) => `${curr.filterName}_${f.id}`);
        acc = [...acc, ...opts];
        return acc;
      }, [] as string[]);
      setSelectedFilters(selected);
    } else {
      setSelectedFilters([]);
    }
  };

  const filterSearchResults = (): void => {
    setUseDefaultFilters(false);
    const queryString = listingFilters.reduce((acc, curr) => {
      if (curr.options && curr.options.length > 1) {
        const querySelectedFiltersString = curr.options
          .filter((opt) => selectedFilters.includes(`${curr.filterName}_${opt.id}`))
          .map((opt) => `${curr.filterName}[]=${opt.id}`)
          .join('&');
        if (querySelectedFiltersString.length) {
          acc += `${querySelectedFiltersString}&`;
        }
      } else if (curr.options.length === 1) {
        const isSelected = selectedFilters.includes(
          `${curr.filterName}_${curr.options[0].id}`,
        );
        if (isSelected) {
          acc += `${curr.filterName}=true&`;
        }
      }
      return acc;
    }, '');
    const otherFiltersString = otherFilters.length
      ? `${otherFilters.join('&')}${queryString.length ? '&' : ''}`
      : '';
    history.push(
      `${routePaths.public.listings}?${otherFiltersString}${queryString.slice(
        0,
        -1,
      )}`,
    );
    toggleModal(false);
  };

  let rows: JSX.Element[] = [];
  let columns: JSX.Element[] = [];
  const groups: JSX.Element[] = [];

  listingFilters.forEach((f) => {
    rows = [];
    const { options } = f;
    options.forEach((s, i) => {
      const slug = `${f.filterName}_${s.id}`;
      const subcategoryBtnClass = selectedFilters.includes(slug)
        ? [classes.subcategoryBtn, classes.selectedFilterBtn].join(' ')
        : classes.subcategoryBtn;
      columns.push(
        <IonCol
          key={columns.length}
          sizeXs="6"
          className={classes.subCategoryColPair}
        >
          <IonButton
            onClick={(): void => updateSelectedFilters(s.id, f.filterName)}
            className={subcategoryBtnClass}
          >
            <IonLabel>{translateName(s, locale)}</IonLabel>
          </IonButton>
        </IonCol>,
      );
      if ((i > 0 && i % 2 !== 0) || i === options.length - 1) {
        rows.push(<IonRow key={rows.length}>{columns}</IonRow>);
        columns = [];
      }
    });
    groups.push(
      <div key={groups.length} className={classes.filterGroup}>
        {f.name !== 'Others' && <IonLabel>{translateName(f, locale)}</IonLabel>}
        {rows}
      </div>,
    );
  });

  return (
    <IonContent>
      <IonModal isOpen={showModal} onDidDismiss={() => toggleModal(false)}>
        <div className={classes.modalContainer}>
          <IonRow className={classes.rowHeader}>
            <div className={classes.modalHeaderWrapper}>
              <div
                className={classes.modalHeaderCloseIcon}
                onClick={(): void => toggleModal(false)}
              >
                <Icon name="close" width={40} color="black" />
              </div>
              <div>
                <IonLabel className={classes.modalHeaderText}>
                  <FormattedMessage id="filters-camel" />
                </IonLabel>
              </div>
            </div>
          </IonRow>
          <IonRow className={classes.btnRow}>
            <div className={classes.listingDetailSelectAllWrapper}>
              <IonCheckbox
                onClick={selectAll}
                checked={selectAllChecked}
                className={classes.selectAllCheckbox}
              />
              <IonLabel className={classes.checkBoxText}>
                <FormattedMessage id="select-all" />
              </IonLabel>
            </div>
            <div className={classes.listingDetailClearWrapper}>
              <IonButton
                onClick={(): void => setSelectedFilters([])}
                className={classes.clearBtn}
                style={clearBtnStyle}
              >
                <FormattedMessage id="clear-upper" />
              </IonButton>
            </div>
          </IonRow>
          <div className={classes.subcategoryRow}>{groups}</div>

          <IonRow>
            <IonCol sizeXs="12">
              <IonButton
                onClick={filterSearchResults}
                className={classes.searchBtn}
              >
                <FormattedMessage id="search-results" />
              </IonButton>
            </IonCol>
          </IonRow>
        </div>
      </IonModal>
    </IonContent>
  );
};

export default SearchFilters;
