import { IonRouterLink, IonAlert, IonLabel } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useStoreActions, useStoreState } from '../store';
import menuItems from './ProviderMenuItems';
import useStyles from './styles';

const ProviderDesktopMenu: React.FunctionComponent = () => {
  const [showLogoutAlert, setShowLogoutAlert] = useState(false);
  const [providerApproved, setProviderApproved] = useState(false);
  const { logout } = useStoreActions(actions => actions.auth);
  const { currentProvider } = useStoreState(state => state.provider);
  const { getProviderById } = useStoreActions(actions => actions.provider);
  const { user } = useStoreState((state) => state.auth);
  const classes = useStyles();

  useEffect(() => {
    if (currentProvider === null && user?.provider?.id) {
      getProviderById(Number(user?.provider?.id));
    }
    setProviderApproved((currentProvider?.approved_date?.length ?? 0) > 0);
  }, [currentProvider, setProviderApproved, user, getProviderById]);

  return (
    <div className={classes.userDesktopMenuContainer}>
      {menuItems
        .filter(
          (menuItem) => menuItem.desktopNavInfo !== undefined
            && menuItem.desktopNavInfo !== null,
        )
        .sort(
          // @ts-ignore
          (a, b) => a.desktopNavInfo?.navOrderIndex - b.desktopNavInfo?.navOrderIndex,
        )
        .map((navItem) => (navItem.desktopNavInfo?.navTitleId === 'provider.navtitle.signout' ? (
          <IonLabel
            style={{ cursor: 'pointer' }}
            className="mr-24"
            key={navItem.desktopNavInfo?.navTitleId}
            onClick={(): void => setShowLogoutAlert(true)}
            color={navItem.desktopNavInfo?.color}
          >
            <FormattedMessage id={navItem.desktopNavInfo?.navTitleId} />
          </IonLabel>
        ) : (
          <IonRouterLink
            className="mr-24"
            key={navItem.desktopNavInfo?.navTitleId}
            routerLink={providerApproved && navItem.approvedUrl ? navItem.approvedUrl : navItem.url}
            color={navItem.desktopNavInfo?.color}
          >
            <FormattedMessage id={navItem.desktopNavInfo?.navTitleId} />
          </IonRouterLink>
        )))}
      <IonAlert
        isOpen={showLogoutAlert}
        onDidDismiss={(): void => setShowLogoutAlert(false)}
        message="Would you like to sign out?"
        buttons={[
          {
            text: 'Cancel',
          },
          {
            text: 'Sign Out',
            handler: (): void => {
              logout();
            },
          },
        ]}
      />
    </div>
  );
};

export default ProviderDesktopMenu;
