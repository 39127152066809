import io from 'socket.io-client';
import feathers from '@feathersjs/client';

import getServiceName from './getServiceName';

const API_URL: string = process.env.REACT_APP_API_HOST || 'http://localhost:5000';

export const socket = io(API_URL, {
  transports: ['websocket', 'polling'],
});
const client = feathers();

client.configure(feathers.socketio(socket, { timeout: 15000 }));
client.configure(feathers.authentication({
  path: getServiceName('auth'),
}));

export default client;
