import { action, thunk } from 'easy-peasy';

import CreateOrgModel from '../interfaces/StoreModel/CreateOrgModel';
import ModuleStore from '../interfaces/ModuleStore';
import { MetaType } from '../interfaces/StoreModel/ServiceModel';

const model: CreateOrgModel = {
  error: '',
  loading: false,
  success: false,
  providerType: {
    id: -1,
    name: 'Select an Organization Type',
  },
  ein: '',
  currentStep: 1,
  setEin: action((state, payload) => {
    state.ein = payload;
  }),
  setSuccess: action((state, payload) => {
    state.success = payload;
  }),
  setCurrentStep: action((state, payload) => {
    state.currentStep = payload;
  }),
  setProviderType: action((state, payload) => {
    state.providerType = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  // TODO: figure out how to get access to the Store without a ts-ignore
  // @ts-ignore
  create: thunk(async (actions, orgData, store: ModuleStore<CreateOrgModel>) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      const state = store.getState();
      let userState = store.getStoreState().users;
      const user = {
        name: orgData.contactName,
        email: orgData.contactEmail,
        password: orgData.password,
        provider: {
          name: orgData.orgName,
          website_url: orgData.website,
          provider_type_id: state.providerType.id,
          ein: state.ein,
        },
      };
      // TODO: should we just call the service directly here?
      store.getStoreActions().auth.setError('');
      store.getStoreActions().users.setError({
        type: MetaType.CREATE,
        value: '',
      });
      await store.getStoreActions().users.create(user);
      userState = store.getStoreState().users;
      if (userState.error.create) {
        actions.setError(userState.error.create);
        actions.setSuccess(false);
      } else {
        await store.getStoreActions().auth.login(
          { password: orgData.password, email: orgData.contactEmail },
        );
        actions.setSuccess(true);
      }
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),

  // TODO: figure out how to get access to the Store without a ts-ignore
  // @ts-ignore
  patch: thunk(async (actions, orgData, store: ModuleStore<CreateOrgModel>) => {
    actions.setLoading(true);
    try {
      actions.setError('');
      let userState = store.getStoreState().users;
      const provider: {
        id?: number;
        name: string;
        website_url: string;
        provider_type_id: number;
        ein?: string;
      } = {
        id: orgData.providerId,
        name: orgData.orgName,
        website_url: orgData.website,
        provider_type_id: orgData.providerTypeId ?? -1,
      };
      if (orgData.ein) {
        provider.ein = orgData.ein;
      }
      const user = {
        id: orgData.userId,
        name: orgData.contactName,
        email: orgData.contactEmail,
        provider,
      };
      // TODO: should we just call the service directly here?
      store.getStoreActions().auth.setError('');
      store.getStoreActions().users.setError({
        type: MetaType.MODIFY,
        value: '',
      });
      await store.getStoreActions().users.patch(user);
      userState = store.getStoreState().users;
      if (userState.error.modify) {
        actions.setError(userState.error.modify);
        actions.setSuccess(false);
      } else {
        if (orgData.providerId) {
          await store.getStoreActions().provider.getProviderById(orgData.providerId);
        }
        actions.setSuccess(true);
      }
    } catch (error) {
      actions.setError(error.message);
      actions.setSuccess(false);
    }
    actions.setLoading(false);
  }),
};

export default model;
