import { Image } from '../../interfaces/Image';

export const uploadImage = async (file: File): Promise<Image | null> => {
  const BASE_URL: string = process.env.REACT_APP_API_HOST || 'http://localhost:5000';
  const formData = new FormData();
  formData.append('uri', file);
  const response = await fetch(`${BASE_URL}/api/v1/images`, {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${localStorage['feathers-jwt']}`,
    },
  });
  if (response.status === 201) {
    const image: Image = await response.json();
    return image;
  }
  return null;
};
