import {
  IonButton, IonCard,
  IonCardContent, IonCardHeader,
  IonCardTitle, IonIcon,
  IonItem, IonLabel,
} from '@ionic/react';
import { addCircle } from 'ionicons/icons';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
// @ts-ignore
import editIcon from '../../../assets/editicon.png';
import routePaths from '../../../lib/routePaths';
import { useStoreActions, useStoreState } from '../../../store';
import useAppStyles from '../../styles';
import useStyles from '../styles';

const ServiceListings: React.FC = () => {
  const classes = useStyles();
  const appClasses = useAppStyles();
  const { currentProvider } = useStoreState((state) => state.provider);
  const { user } = useStoreState((state) => state.auth);
  const {
    provider: { getProviderById },
  } = useStoreActions((actions) => ({
    provider: actions.provider,
  }));

  useEffect(() => {
    if (currentProvider === null && user !== null) {
      getProviderById(Number(user.provider?.id ?? 0));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        <h3 className={[appClasses.title, 'ml-16'].join(' ')}><FormattedMessage id="service-listings" /> </h3>

        <IonButton
          className={classes.iconButton}
          fill="clear"
          color="primary"
          routerLink={`${routePaths.provider.approved.addService}`}
        >
          <FormattedMessage id="add-another-listing" />
          <IonIcon size="large" slot="start" icon={addCircle} />
        </IonButton>
        {currentProvider
          && currentProvider.listings
          && currentProvider.listings.length > 0
          && currentProvider?.listings?.filter((item) => item.approved_date).length > 0 ? (
            <>
              <IonItem className="mt-24">
                <IonLabel color="primary" className={appClasses.subtitle}>
                  <FormattedMessage id="approved-listings" />
                </IonLabel>
              </IonItem>
              {currentProvider?.listings?.filter((item) => item.approved_date).sort(
                // @ts-ignore
                (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
              ).map((listing) => {
                const categoryString = listing.categories.map((category) => (
                  category.name
                )).join(', ');
                return (
                  <IonCard key={listing.id} button routerLink={`${routePaths.provider.approved.editService}/${listing.id}`}>
                    <IonCardHeader>
                      <IonCardTitle color="primary">{listing.name}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonItem lines="none" className={[appClasses.transparentBackground, 'ion-no-padding ion-no-margin'].join(' ')}>
                        <IonLabel color="primary">{categoryString}</IonLabel>
                      </IonItem>
                      <div className={classes.alignRight}>
                        <img
                          src={editIcon}
                          className={classes.editIcon}
                          alt="background"
                        />
                      </div>
                    </IonCardContent>
                  </IonCard>
                );
              })}
            </>
          ) : null }

        {currentProvider
          && currentProvider.listings
          && currentProvider.listings.length > 0
          && currentProvider?.listings?.filter((item) => !item.submit_date).length > 0 ? (
            <>
              <IonItem className="mt-24">
                <IonLabel color="primary" className={appClasses.subtitle}>
                  Draft Listings
                </IonLabel>
              </IonItem>
              {currentProvider?.listings?.filter((item) => !item.submit_date).sort(
                // @ts-ignore
                (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
              ).map((listing) => {
                const categoryString = listing.categories.map((category) => (
                  category.name
                )).join(', ');
                return (
                  <IonCard key={listing.id} button routerLink={`${routePaths.provider.approved.editService}/${listing.id}`}>
                    <IonCardHeader>
                      <IonCardTitle color="primary">{listing.name}</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                      <IonItem lines="none" className={[appClasses.transparentBackground, 'ion-no-padding ion-no-margin'].join(' ')}>
                        <IonLabel color="primary">{categoryString}</IonLabel>
                      </IonItem>
                      <div className={classes.alignRight}>
                        <img
                          src={editIcon}
                          className={classes.editIcon}
                          alt="background"
                        />
                      </div>
                    </IonCardContent>
                  </IonCard>
                );
              })}
            </>
          ) : null }

        {currentProvider
          && currentProvider.listings
          && currentProvider.listings.length > 0
          && currentProvider?.listings?.filter((item) => item.submit_date
          && !item.approved_date
          && !item.denied_date)
            .length > 0 ? (
              <>
                <IonItem className="mt-24">
                  <IonLabel color="primary" className={appClasses.subtitle}>
                    Listings In Review
                  </IonLabel>
                </IonItem>
                {currentProvider?.listings?.filter((item) => item.submit_date
                && !item.approved_date && !item.denied_date).sort(
                // @ts-ignore
                  (a, b) => new Date(b.updated_at) - new Date(a.updated_at),
                ).map((listing) => {
                  const categoryString = listing.categories.map((category) => (
                    category.name
                  )).join(', ');
                  return (
                    <IonCard key={listing.id} button routerLink={`${routePaths.provider.approved.editService}/${listing.id}`}>
                      <IonCardHeader>
                        <IonCardTitle color="primary">{listing.name}</IonCardTitle>
                      </IonCardHeader>
                      <IonCardContent>
                        <IonItem lines="none" className={[appClasses.transparentBackground, 'ion-no-padding ion-no-margin'].join(' ')}>
                          <IonLabel color="primary">{categoryString}</IonLabel>
                        </IonItem>
                        <div className={classes.alignRight}>
                          <img
                            src={editIcon}
                            className={classes.editIcon}
                            alt="background"
                          />
                        </div>
                      </IonCardContent>
                    </IonCard>
                  );
                })}
              </>
          ) : null }
      </div>
    </>
  );
};
export default ServiceListings;
