import {
  IonButton,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Category } from '../../../../interfaces/Meta';
import useStyles from '../../styles';

interface ListingCategoryRowProps {
  category: Category;
  editCategory: (id: number) => void;
}

const ListingCategoryRow: React.FunctionComponent<ListingCategoryRowProps> = ({
  category,
  editCategory,
}: ListingCategoryRowProps) => {
  const classes = useStyles();
  const [subcategoryNames, setSubcategoryNames] = useState('');
  const {
    id, name, translation,
  } = category;

  useEffect(() => {
    const names = category.sub_categories
      .map(s => s.name)
      .join(', ');
    setSubcategoryNames(names);
  }, [category]);

  return (
    <>
      <div className={classes.crisisCategoryCardHeader} />
      <div className={classes.providerCardContent}>
        <IonGrid>
          <IonRow>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="8">
              <div className={classes.providerNameRow}>
                <IonItem
                  className={[
                    classes.providerOrgName,
                    'disabled-pointer-events',
                  ].join(' ')}
                >
                  <IonLabel position="floating" color="primary">
                    Category Name
                  </IonLabel>
                  <IonInput color="primary" value={`${name} / ${translation?.es?.name}`} />
                </IonItem>
              </div>
              <IonItem className="disabled-pointer-events">
                <IonLabel className={`${classes.subcategoryNamesTitle} ${classes.rowLabel}`} position="floating" color="primary">
                  Associated Subcategories
                </IonLabel>
                {/* <IonInput color="primary" value={subcategoryNames} /> */}
                <br />
                <div className={[classes.subcategoryNames, 'mt-16'].join(' ')}>
                  {subcategoryNames}
                </div>
              </IonItem>
            </IonCol>
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="1" />
            <IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="3">
              <IonButton
                className={classes.providerActionButton}
                shape="round"
                fill="outline"
                color="primary"
                onClick={(): void => editCategory(id)}
              >
                Edit
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default ListingCategoryRow;
