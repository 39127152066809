import {
  IonContent,
  IonPage,
  IonItem,
  IonInput,
  IonButton,
} from '@ionic/react';
import { useParams } from 'react-router-dom';

import React, { useState } from 'react';
// @ts-ignore
import logo from '../../assets/cc_logo.png';
import useAppStyles from '../styles';
import useStyles from './styles';
import { useStoreState, useStoreActions } from '../../store';

interface TokenParams {
  token: string;
}

const ChangePassword: React.FC = () => {
  const appClasses = useAppStyles();
  const classes = useStyles();
  const params = useParams<TokenParams>();
  const { loading, error, passwordReset } = useStoreState(state => state.password);
  const { resetPassword, setError } = useStoreActions(actions => actions.password);
  const [newPassword, setNewPassword] = useState<string | null | undefined>('');
  const [confirmPassword, setConfirmPassword] = useState<string | null | undefined>('');

  const submitClicked = (): void => {
    setError('');
    if (newPassword) {
      if (newPassword !== confirmPassword) {
        setError('Passwords must match.');
      } else if ((newPassword?.length ?? 0) < 4) {
        setError('Passwords must be at least 4 characters.');
      } else {
        resetPassword({ token: params.token, password: newPassword });
      }
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div className={[appClasses.desktopContainer, classes.container].join(' ')}>
          <div>
            <img src={logo} alt="logo" />
          </div>
          {params.token && !loading && !passwordReset ? (
            <>
              <IonItem className={classes.input} style={{ width: '80%' }}>
                <IonInput
                  onIonChange={(e): void => setNewPassword(e.detail.value)}
                  name="password"
                  type="password"
                  placeholder="New Password*"
                />
              </IonItem>
              <IonItem className={classes.input} style={{ width: '80%' }}>
                <IonInput
                  onIonChange={(e): void => setConfirmPassword(e.detail.value)}
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirm Password*"
                />
              </IonItem>
              <IonButton
                className={[appClasses.minWidth175, 'cc-button mt-24'].join(' ')}
                shape="round"
                fill="solid"
                color="primary"
                type="submit"
                onClick={(): void => submitClicked()}
              >
                Update Password
              </IonButton>
            </>
          ) : null}
          <h1>{loading && 'Changing Password...'}</h1>
          <h1>{!loading && (params.token ? (passwordReset ? 'Your password has been changed.' : error) : 'Invalid Password Reset Token')}</h1>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
