import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import useAppStyles from '../../styles';
import { useStoreActions, useStoreState } from '../../../store';
import routePaths from '../../../lib/routePaths';

const Approval: React.FC = () => {
  const appClasses = useAppStyles();
  const history = useHistory();
  const {
    createOrg: { setCurrentStep },
    provider: { getProviderById },
  } = useStoreActions((actions) => ({
    createOrg: actions.createOrg,
    provider: actions.provider,
  }));
  const { user } = useStoreState((state) => state.auth);
  const { currentProvider } = useStoreState((state) => state.provider);

  useEffect(() => {
    setCurrentStep(4);
    if (user && user.provider && user.provider.id && currentProvider === null) {
      getProviderById(Number(user.provider.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <h4 className={[appClasses.title, 'mb-24'].join(' ')}>
        Your application is currently in review. If your organization is approved,
        you can add more service listings later. Changes can still be made to existing
        listings by selecting them below.
      </h4>
      {currentProvider && currentProvider.listings && currentProvider.listings.length > 0 ? (
        currentProvider?.listings?.map((listing) => (
          <h4
            key={listing.id}
            className={appClasses.serviceLink}
            onClick={(): void => history.push(`${routePaths.provider.onboarding.editService}/${listing.id}`)}
          >• {listing.name}
          </h4>
        ))) : (
        null)}
    </>
  );
};

export default Approval;
