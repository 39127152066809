import { action, thunk } from 'easy-peasy';
import { Storage } from '@capacitor/storage';

import LocalTranslationsModel from '../interfaces/LocalTranslations';
import localeData from '../lib/translations/locales';

const model: LocalTranslationsModel = {
  language: 'en',
  messages: localeData.en,
  setLocale: action((state, payload) => {
    state.language = payload;
    Storage.set({ key: 'locale', value: payload });
    state.messages = localeData[payload];
  }),
  loadSavedLanguage: thunk(async (actions) => {
    const lang = await Storage.get({ key: 'locale' });
    if (lang.value === 'es' || lang.value === 'en') {
      actions.setLocale(lang.value);
    }
  }),
};

export default model;
