import React from 'react';
import { IonItem, IonLabel } from '@ionic/react';
import useAppStyles from '../../styles';
import { useStoreState } from '../../../store';

const ViewProviderTypeDesktop: React.FC = () => {
  const appClasses = useAppStyles();
  const { currentProvider } = useStoreState(state => state.provider);
  const meta = useStoreState(state => state.meta);

  let providerTypeName = '';

  if (meta.data) {
    const type = meta.data.provider_type
      .find(t => t.id === Number(currentProvider?.provider_type_id));
    if (type) {
      providerTypeName = type.name;
    }
  }

  return (
    <>
      {currentProvider === null || (currentProvider.id ?? 0) === 0 ? null
        : (
          <>
            <IonItem lines="none">
              <IonLabel color="primary" className={appClasses.title}>{providerTypeName} </IonLabel>
            </IonItem>
            {currentProvider.ein ? (
              <IonItem lines="none">
                <IonLabel color="primary" className={appClasses.title}>{`EIN: ${currentProvider.ein}`} </IonLabel>
              </IonItem>
            ) : null}
          </>
        )}
    </>
  );
};
export default ViewProviderTypeDesktop;
