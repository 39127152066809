import { Geolocation } from '@capacitor/geolocation';
import { Storage } from '@capacitor/storage';
import { Device } from '@capacitor/device';
import {
  computed, action, State, thunk,
} from 'easy-peasy';

import SettingsModel from '../interfaces/Settings';
import LocalTranslationsModel from '../interfaces/LocalTranslations';
import AnalyticsModel, { AnalyticsEvent } from '../interfaces/StoreModel/AnalyticsModel';
import { analyticsEvents, analyticsUsers } from '../lib/api/services';

let currentUser = {};
let lastRequest: number | undefined;
let token: string | null = null;
let queue: AnalyticsEvent[] = [];

const model: AnalyticsModel = {
  location: null,
  user: computed(
    [
      (_, store): State<SettingsModel> => store.settings,
      (_, store): State<LocalTranslationsModel> => store.translations,
    ],
    (settings, translations) => {
      const user = {
        locale: translations.language,
        general_notifications: settings.getGeneralNotifications,
        urgent_notifications: settings.getUrgentNotifications,
        default_filters: settings.useDefaultFilters,
        service_near_me: settings.useLocationOnSearch,
        age_range: settings.useAgeOnSearch,
        gender: settings.profileGender,
        gender_self_describe: settings.profileGenderInput,
        ethnicity: settings.profileEthnicity,
        other_ethnicity: settings.profileEthnicityInput,
        cities: settings.useCitiesOnSearch,
        groups: settings.useGroupsOnSearch,
      };
      if (JSON.stringify(currentUser) !== JSON.stringify(user)) {
        currentUser = user;
        if (token) {
          try {
            analyticsUsers.update('', {
              token,
              user,
            });
          } catch (error) {
            // error updating analytics user
          }
        }
      }
      return user;
    },
  ),
  createEvent: thunk(async (actions, event, store) => {
    if (!token) {
      return queue.push(event);
    }
    try {
      const { location } = store.getState();
      const device = await Device.getInfo();
      const payload = {
        token,
        type: event.type,
        data: {
          device,
          latitude: location ? location.latitude : null,
          longitude: location ? location.longitude : null,
          precise_location: location !== null,
          screen_width: window.innerWidth,
          screen_height: window.innerHeight,
          meta: event.meta,
        },
      };
      await analyticsEvents.create(payload);
    } catch (error) {
      // silent fail
    }
  }),
  setLocation: action((state, location) => {
    state.location = location;
  }),
  getLocation: thunk(async (actions, _, store) => {
    try {
      const now = Date.now();
      if (!lastRequest || lastRequest < now - 10000) {
        lastRequest = now;
        const {
          coords: { latitude, longitude },
        } = await Geolocation.getCurrentPosition();
        const location = { latitude, longitude };
        actions.setLocation(location);
        return location;
      }
      return store.getState().location;
    } catch (error) {
      actions.setLocation(null);
      return null;
    }
  }),
  initialize: thunk(async (actions, _, store) => {
    const storageToken = await Storage.get({
      key: 'analytics-token',
    });
    if (!storageToken.value) {
      const { user } = store.getState();
      const result = await analyticsUsers.create(user);
      await Storage.set({
        key: 'analytics-token',
        value: result.token,
      });
      token = result.token;
    } else {
      token = storageToken.value;
    }
    if (token && queue.length) {
      queue.forEach((event) => actions.createEvent(event));
      queue = [];
    }
  }),
};

export default model;
