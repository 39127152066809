/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  IonItem,
  IonLabel,
  IonIcon,
  IonToggle,
  IonButton,
  IonChip,
  IonModal,
} from '@ionic/react';
import { addCircle, closeCircle } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import HoursModal from './modal';
import useStyles from './styles';
import { HoursEntry, HoursData } from '../../../../interfaces/ServiceData';

interface OperatingHoursProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setHours: any;
  hoursData: HoursData | null;
  readOnly: boolean;
}

const OperatingHours: React.FC<OperatingHoursProps> = ({
  setHours, hoursData, readOnly,
}: OperatingHoursProps) => {
  const classes = useStyles();
  const [showHoursModal, setShowHoursModal] = useState(false);
  const [currentDay, setCurrentDay] = useState('');
  const [alwaysOpen, setAlwaysOpen] = useState<boolean>(hoursData?.alwaysOpen ?? true);
  const [sun24, setSun24] = useState<boolean>(hoursData?.sun24 ?? false);
  const [mon24, setMon24] = useState<boolean>(hoursData?.mon24 ?? false);
  const [tue24, setTue24] = useState<boolean>(hoursData?.tue24 ?? false);
  const [wed24, setWed24] = useState<boolean>(hoursData?.wed24 ?? false);
  const [thu24, setThu24] = useState<boolean>(hoursData?.thu24 ?? false);
  const [fri24, setFri24] = useState<boolean>(hoursData?.fri24 ?? false);
  const [sat24, setSat24] = useState<boolean>(hoursData?.sat24 ?? false);
  const [sunTimes, setSunTimes] = useState<HoursEntry[]>(hoursData?.sunTimes ?? []);
  const [monTimes, setMonTimes] = useState<HoursEntry[]>(hoursData?.monTimes ?? []);
  const [tueTimes, setTueTimes] = useState<HoursEntry[]>(hoursData?.tueTimes ?? []);
  const [wedTimes, setWedTimes] = useState<HoursEntry[]>(hoursData?.wedTimes ?? []);
  const [thuTimes, setThuTimes] = useState<HoursEntry[]>(hoursData?.thuTimes ?? []);
  const [friTimes, setFriTimes] = useState<HoursEntry[]>(hoursData?.friTimes ?? []);
  const [satTimes, setSatTimes] = useState<HoursEntry[]>(hoursData?.satTimes ?? []);

  useEffect(() => {
    if (hoursData != null) {
      setAlwaysOpen(hoursData.alwaysOpen);
      setSun24(hoursData.sun24);
      setMon24(hoursData.mon24);
      setTue24(hoursData.tue24);
      setWed24(hoursData.wed24);
      setThu24(hoursData.thu24);
      setFri24(hoursData.fri24);
      setSat24(hoursData.sat24);
      setSunTimes(hoursData.sunTimes);
      setMonTimes(hoursData.monTimes);
      setTueTimes(hoursData.tueTimes);
      setWedTimes(hoursData.wedTimes);
      setThuTimes(hoursData.thuTimes);
      setFriTimes(hoursData.friTimes);
      setSatTimes(hoursData.satTimes);
    } else {
      setAlwaysOpen(true);
      setSun24(false);
      setMon24(false);
      setTue24(false);
      setWed24(false);
      setThu24(false);
      setFri24(false);
      setSat24(false);
      setSunTimes([]);
      setMonTimes([]);
      setTueTimes([]);
      setWedTimes([]);
      setThuTimes([]);
      setFriTimes([]);
      setSatTimes([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoursData]);

  useEffect(() => {
    setHours({
      alwaysOpen,
      sun24,
      mon24,
      tue24,
      wed24,
      thu24,
      fri24,
      sat24,
      sunTimes,
      monTimes,
      tueTimes,
      wedTimes,
      thuTimes,
      friTimes,
      satTimes,
    });
  }, [
    setHours,
    alwaysOpen,
    sun24,
    mon24,
    tue24,
    wed24,
    thu24,
    fri24,
    sat24,
    sunTimes,
    monTimes,
    tueTimes,
    wedTimes,
    thuTimes,
    friTimes,
    satTimes,
  ]);

  const openHoursModal = (day: string): void => {
    setCurrentDay(day);
    setShowHoursModal(true);
  };

  const updateTimes = (
    entries: HoursEntry[],
    newEntry: HoursEntry,
  ): HoursEntry[] => {
    let addEntry = true;
    let modifiedEntry: HoursEntry;
    entries.forEach((entry) => {
      if (
        entry.openTime.getTime() <= newEntry.openTime.getTime()
        && entry.closeTime.getTime() >= newEntry.closeTime.getTime()
      ) {
        addEntry = false;
      } else if (
        newEntry.openTime.getTime() <= entry.openTime.getTime()
        && newEntry.closeTime.getTime() >= entry.closeTime.getTime()
      ) {
        addEntry = false;
        entry.openTime = newEntry.openTime;
        entry.closeTime = newEntry.closeTime;
      } else if (
        newEntry.closeTime.getTime() > entry.closeTime.getTime()
        && newEntry.openTime.getTime() <= entry.closeTime.getTime()
      ) {
        entry.closeTime = newEntry.closeTime;
        modifiedEntry = entry;
        addEntry = false;
      } else if (
        newEntry.openTime.getTime() < entry.openTime.getTime()
        && newEntry.closeTime.getTime() >= entry.openTime.getTime()
      ) {
        // Check to see if this can be combined with an entry we already modified.
        if (
          modifiedEntry != null
          && entry.openTime.getTime() >= modifiedEntry.openTime.getTime()
          && entry.closeTime.getTime() >= modifiedEntry.openTime.getTime()
        ) {
          modifiedEntry.closeTime = entry.closeTime;
          entries = entries.filter((item) => item !== entry);
        } else {
          entry.openTime = newEntry.openTime;
        }
        addEntry = false;
      }
    });
    if (addEntry) {
      entries.push(newEntry);
    }
    entries.sort((a, b) => a.openTime.getTime() - b.openTime.getTime());
    return entries;
  };

  const closeHoursModal = (dayForHours: string, open: Date, close: Date): void => {
    setShowHoursModal(false);
    const entry: HoursEntry = { openTime: open, closeTime: close };
    if (dayForHours === 'Monday') {
      const times = updateTimes(Array.from(monTimes), entry);
      setMonTimes(times);
    } else if (dayForHours === 'Tuesday') {
      const times = updateTimes(Array.from(tueTimes), entry);
      setTueTimes(times);
    } else if (dayForHours === 'Wednesday') {
      const times = updateTimes(Array.from(wedTimes), entry);
      setWedTimes(times);
    } else if (dayForHours === 'Thursday') {
      const times = updateTimes(Array.from(thuTimes), entry);
      setThuTimes(times);
    } else if (dayForHours === 'Friday') {
      const times = updateTimes(Array.from(friTimes), entry);
      setFriTimes(times);
    } else if (dayForHours === 'Saturday') {
      const times = updateTimes(Array.from(satTimes), entry);
      setSatTimes(times);
    } else if (dayForHours === 'Sunday') {
      const times = updateTimes(Array.from(sunTimes), entry);
      setSunTimes(times);
    }
  };

  const setMonday24 = (): void => {
    setMonTimes([]);
    setMon24(true);
  };

  const removeMondayHours = (entryToRemove: HoursEntry): void => {
    let times = Array.from(monTimes);
    times = times.filter((item) => item !== entryToRemove);
    setMonTimes(times);
  };

  const setTuesday24 = (): void => {
    setTueTimes([]);
    setTue24(true);
  };

  const removeTuesdayHours = (entryToRemove: HoursEntry): void => {
    let times = Array.from(tueTimes);
    times = times.filter((item) => item !== entryToRemove);
    setTueTimes(times);
  };

  const setWednesday24 = (): void => {
    setWedTimes([]);
    setWed24(true);
  };

  const removeWednesdayHours = (entryToRemove: HoursEntry): void => {
    let times = Array.from(wedTimes);
    times = times.filter((item) => item !== entryToRemove);
    setWedTimes(times);
  };

  const setThursday24 = (): void => {
    setThuTimes([]);
    setThu24(true);
  };

  const removeThursdayHours = (entryToRemove: HoursEntry): void => {
    let times = Array.from(thuTimes);
    times = times.filter((item) => item !== entryToRemove);
    setThuTimes(times);
  };

  const setFriday24 = (): void => {
    setFriTimes([]);
    setFri24(true);
  };

  const removeFridayHours = (entryToRemove: HoursEntry): void => {
    let times = Array.from(friTimes);
    times = times.filter((item) => item !== entryToRemove);
    setFriTimes(times);
  };

  const setSaturday24 = (): void => {
    setSatTimes([]);
    setSat24(true);
  };

  const removeSaturdayHours = (entryToRemove: HoursEntry): void => {
    let times = Array.from(satTimes);
    times = times.filter((item) => item !== entryToRemove);
    setSatTimes(times);
  };

  const setSunday24 = (): void => {
    setSunTimes([]);
    setSun24(true);
  };

  const removeSundayHours = (entryToRemove: HoursEntry): void => {
    let times = Array.from(sunTimes);
    times = times.filter((item) => item !== entryToRemove);
    setSunTimes(times);
  };

  const getChipText = (hoursEntry: HoursEntry): string => {
    const openStr = hoursEntry.openTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    const closeStr = hoursEntry.closeTime.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return `${openStr} - ${closeStr}`;
  };

  return (
    <>
      <IonItem
        className={readOnly ? 'disabled-pointer-events ion-padding-left' : 'ion-padding-left'}
      >
        <IonToggle
          className="ion-no-padding"
          mode="ios"
          checked={alwaysOpen}
          name="alwaysOpenCheckbox"
          color="primary"
          onIonChange={(e): void => setAlwaysOpen(e.detail.checked)}
        />
        <IonLabel className="ion-margin ion-no-padding" color="primary">
          Always Open (24/7)*
        </IonLabel>
      </IonItem>
      <div hidden={alwaysOpen}>
        <div className="ion-padding-left">
          <IonLabel className={classes.dayLabel} color="primary">
            {sunTimes.length === 0 && !sun24 ? 'Sunday: Closed' : 'Sunday:'}
          </IonLabel>
          {sunTimes.length > 0 || sun24 ? (
            <div className={classes.chipsRow}>
              {sun24 ? (
                <IonChip
                  className={readOnly ? 'disabled-pointer-events' : ''}
                  color="primary"
                >
                  <IonLabel color="primary">Open 24 Hours</IonLabel>
                  <IonIcon
                    color="primary"
                    icon={closeCircle}
                    onClick={(): void => setSun24(false)}
                  />
                </IonChip>
              ) : (
                sunTimes.map((hoursEntry: HoursEntry) => (
                  <IonChip
                    color="primary"
                    key={getChipText(hoursEntry)}
                    className={readOnly ? 'disabled-pointer-events' : ''}
                  >
                    <IonLabel color="primary">
                      {getChipText(hoursEntry)}
                    </IonLabel>
                    <IonIcon
                      color="primary"
                      icon={closeCircle}
                      onClick={(): void => removeSundayHours(hoursEntry)}
                    />
                  </IonChip>
                ))
              )}
            </div>
          ) : null}
        </div>
        <div className={classes.chipsRow}>
          <IonButton
            disabled={sun24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => setSunday24()}
          >
            24 Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
          <IonButton
            disabled={sun24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => openHoursModal('Sunday')}
          >
            Add Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
        </div>

        <div className="ion-padding-left">
          <div className={classes.hoursDivider} />
          <IonLabel className={classes.dayLabel} color="primary">
            {monTimes.length === 0 && !mon24 ? 'Monday: Closed' : 'Monday:'}
          </IonLabel>
          {monTimes.length > 0 || mon24 ? (
            <div className={classes.chipsRow}>
              {mon24 ? (
                <IonChip
                  className={readOnly ? 'disabled-pointer-events' : ''}
                  color="primary"
                >
                  <IonLabel color="primary">Open 24 Hours</IonLabel>
                  <IonIcon
                    color="primary"
                    icon={closeCircle}
                    onClick={(): void => setMon24(false)}
                  />
                </IonChip>
              ) : (
                monTimes.map((hoursEntry: HoursEntry) => (
                  <IonChip
                    color="primary"
                    key={getChipText(hoursEntry)}
                    className={readOnly ? 'disabled-pointer-events' : ''}
                  >
                    <IonLabel color="primary">
                      {getChipText(hoursEntry)}
                    </IonLabel>
                    <IonIcon
                      color="primary"
                      icon={closeCircle}
                      onClick={(): void => removeMondayHours(hoursEntry)}
                    />
                  </IonChip>
                ))
              )}
            </div>
          ) : null}
        </div>
        <div className={classes.chipsRow}>
          <IonButton
            disabled={mon24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => setMonday24()}
          >
            24 Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
          <IonButton
            disabled={mon24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => openHoursModal('Monday')}
          >
            Add Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
        </div>

        <div className="ion-padding-left">
          <div className={classes.hoursDivider} />
          <IonLabel className={classes.dayLabel} color="primary">
            {tueTimes.length === 0 && !tue24 ? 'Tuesday: Closed' : 'Tuesday:'}
          </IonLabel>
          {tueTimes.length > 0 || tue24 ? (
            <div className={classes.chipsRow}>
              {tue24 ? (
                <IonChip
                  className={readOnly ? 'disabled-pointer-events' : ''}
                  color="primary"
                >
                  <IonLabel color="primary">Open 24 Hours</IonLabel>
                  <IonIcon
                    color="primary"
                    icon={closeCircle}
                    onClick={(): void => setTue24(false)}
                  />
                </IonChip>
              ) : (
                tueTimes.map((hoursEntry: HoursEntry) => (
                  <IonChip
                    color="primary"
                    key={getChipText(hoursEntry)}
                    className={readOnly ? 'disabled-pointer-events' : ''}
                  >
                    <IonLabel color="primary">
                      {getChipText(hoursEntry)}
                    </IonLabel>
                    <IonIcon
                      color="primary"
                      icon={closeCircle}
                      onClick={(): void => removeTuesdayHours(hoursEntry)}
                    />
                  </IonChip>
                ))
              )}
            </div>
          ) : null}
        </div>
        <div className={classes.chipsRow}>
          <IonButton
            disabled={tue24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => setTuesday24()}
          >
            24 Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
          <IonButton
            disabled={tue24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => openHoursModal('Tuesday')}
          >
            Add Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
        </div>

        <div className="ion-padding-left">
          <div className={classes.hoursDivider} />
          <IonLabel className={classes.dayLabel} color="primary">
            {wedTimes.length === 0 && !wed24
              ? 'Wednesday: Closed'
              : 'Wednesday:'}
          </IonLabel>
          {wedTimes.length > 0 || wed24 ? (
            <div className={classes.chipsRow}>
              {wed24 ? (
                <IonChip
                  className={readOnly ? 'disabled-pointer-events' : ''}
                  color="primary"
                >
                  <IonLabel color="primary">Open 24 Hours</IonLabel>
                  <IonIcon
                    color="primary"
                    icon={closeCircle}
                    onClick={(): void => setWed24(false)}
                  />
                </IonChip>
              ) : (
                wedTimes.map((hoursEntry: HoursEntry) => (
                  <IonChip
                    color="primary"
                    key={getChipText(hoursEntry)}
                    className={readOnly ? 'disabled-pointer-events' : ''}
                  >
                    <IonLabel color="primary">
                      {getChipText(hoursEntry)}
                    </IonLabel>
                    <IonIcon
                      color="primary"
                      icon={closeCircle}
                      onClick={(): void => removeWednesdayHours(hoursEntry)}
                    />
                  </IonChip>
                ))
              )}
            </div>
          ) : null}
        </div>
        <div className={classes.chipsRow}>
          <IonButton
            disabled={wed24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => setWednesday24()}
          >
            24 Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
          <IonButton
            disabled={wed24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => openHoursModal('Wednesday')}
          >
            Add Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
        </div>

        <div className="ion-padding-left">
          <div className={classes.hoursDivider} />
          <IonLabel className={classes.dayLabel} color="primary">
            {thuTimes.length === 0 && !thu24 ? 'Thursday: Closed' : 'Thursday:'}
          </IonLabel>
          {thuTimes.length > 0 || thu24 ? (
            <div className={classes.chipsRow}>
              {thu24 ? (
                <IonChip
                  className={readOnly ? 'disabled-pointer-events' : ''}
                  color="primary"
                >
                  <IonLabel color="primary">Open 24 Hours</IonLabel>
                  <IonIcon
                    color="primary"
                    icon={closeCircle}
                    onClick={(): void => setThu24(false)}
                  />
                </IonChip>
              ) : (
                thuTimes.map((hoursEntry: HoursEntry) => (
                  <IonChip
                    color="primary"
                    key={getChipText(hoursEntry)}
                    className={readOnly ? 'disabled-pointer-events' : ''}
                  >
                    <IonLabel color="primary">
                      {getChipText(hoursEntry)}
                    </IonLabel>
                    <IonIcon
                      color="primary"
                      icon={closeCircle}
                      onClick={(): void => removeThursdayHours(hoursEntry)}
                    />
                  </IonChip>
                ))
              )}
            </div>
          ) : null}
        </div>
        <div className={classes.chipsRow}>
          <IonButton
            disabled={thu24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => setThursday24()}
          >
            24 Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
          <IonButton
            disabled={thu24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => openHoursModal('Thursday')}
          >
            Add Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
        </div>

        <div className="ion-padding-left">
          <div className={classes.hoursDivider} />
          <IonLabel className={classes.dayLabel} color="primary">
            {friTimes.length === 0 && !fri24 ? 'Friday: Closed' : 'Friday:'}
          </IonLabel>
          {friTimes.length > 0 || fri24 ? (
            <div className={classes.chipsRow}>
              {fri24 ? (
                <IonChip
                  className={readOnly ? 'disabled-pointer-events' : ''}
                  color="primary"
                >
                  <IonLabel color="primary">Open 24 Hours</IonLabel>
                  <IonIcon
                    color="primary"
                    icon={closeCircle}
                    onClick={(): void => setFri24(false)}
                  />
                </IonChip>
              ) : (
                friTimes.map((hoursEntry: HoursEntry) => (
                  <IonChip
                    color="primary"
                    key={getChipText(hoursEntry)}
                    className={readOnly ? 'disabled-pointer-events' : ''}
                  >
                    <IonLabel color="primary">
                      {getChipText(hoursEntry)}
                    </IonLabel>
                    <IonIcon
                      color="primary"
                      icon={closeCircle}
                      onClick={(): void => removeFridayHours(hoursEntry)}
                    />
                  </IonChip>
                ))
              )}
            </div>
          ) : null}
        </div>
        <div className={classes.chipsRow}>
          <IonButton
            disabled={fri24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => setFriday24()}
          >
            24 Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
          <IonButton
            disabled={fri24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => openHoursModal('Friday')}
          >
            Add Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
        </div>

        <div className="ion-padding-left">
          <div className={classes.hoursDivider} />
          <IonLabel className={classes.dayLabel} color="primary">
            {satTimes.length === 0 && !sat24 ? 'Saturday: Closed' : 'Saturday:'}
          </IonLabel>
          {satTimes.length > 0 || sat24 ? (
            <div className={classes.chipsRow}>
              {sat24 ? (
                <IonChip
                  className={readOnly ? 'disabled-pointer-events' : ''}
                  color="primary"
                >
                  <IonLabel color="primary">Open 24 Hours</IonLabel>
                  <IonIcon
                    color="primary"
                    icon={closeCircle}
                    onClick={(): void => setSat24(false)}
                  />
                </IonChip>
              ) : (
                satTimes.map((hoursEntry: HoursEntry) => (
                  <IonChip
                    color="primary"
                    key={getChipText(hoursEntry)}
                    className={readOnly ? 'disabled-pointer-events' : ''}
                  >
                    <IonLabel color="primary">
                      {getChipText(hoursEntry)}
                    </IonLabel>
                    <IonIcon
                      color="primary"
                      icon={closeCircle}
                      onClick={(): void => removeSaturdayHours(hoursEntry)}
                    />
                  </IonChip>
                ))
              )}
            </div>
          ) : null}
        </div>
        <div className={classes.chipsRow}>
          <IonButton
            disabled={sat24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => setSaturday24()}
          >
            24 Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
          <IonButton
            disabled={sat24 || readOnly}
            shape="round"
            fill="outline"
            color="primary"
            size="small"
            onClick={(): void => openHoursModal('Saturday')}
          >
            Add Hours
            <IonIcon slot="start" icon={addCircle} />
          </IonButton>
        </div>

        <IonModal
          className={classes.hoursModalContainer}
          isOpen={showHoursModal}
          onDidDismiss={() => setShowHoursModal(false)}
        >
          <HoursModal day={currentDay} closeAction={closeHoursModal} />
        </IonModal>
      </div>
    </>
  );
};

export default OperatingHours;
