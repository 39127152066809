import { IonAlert, IonButton } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routePaths from '../../../../lib/routePaths';
import { useStoreActions, useStoreState } from '../../../../store';
import useStyles from '../../styles';
import ListingSubcategoryRow from './ListingSubcategoryRow';

const ListingSubcategories: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const { data } = useStoreState(state => state.subcategories);
  const { find, remove } = useStoreActions(actions => actions.subcategories);

  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [currentDelete, setCurrentDelete] = useState<number | null>(null);

  useEffect(() => {
    find();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteSubcategory = async (): Promise<void> => {
    setShowDeleteAlert(false);
    const idToBeDeleted = currentDelete as number;
    await remove(idToBeDeleted);
  };

  return (
    <div className={classes.adminTabContent}>
      <div className={classes.addInformationalPage}>
        <IonButton
          className={classes.providerActionButton}
          shape="round"
          fill="solid"
          color="primary"
          onClick={(): void => history.push(routePaths.admin.addListingSubcategory)}
        >
          Add
        </IonButton>
      </div>

      {data?.map((subcategory) => (
        <ListingSubcategoryRow
          key={subcategory.id}
          subcategory={subcategory}
          editSubcategory={(id): void => {
            history.push(
              routePaths.admin.editListingSubcategory.replace(':id', `${id}`),
            );
          }}
          deleteSubcategory={(id): void => {
            setCurrentDelete(id);
            setShowDeleteAlert(true);
          }}
        />
      ))}
      {/* Delete Confirmation Modal */}
      <IonAlert
        isOpen={showDeleteAlert}
        onDidDismiss={(): void => setShowDeleteAlert(false)}
        message="Are you sure you want to delete this subcategory? This will remove the subcategory from all associated service listings as well."
        buttons={[
          { text: 'Cancel' },
          {
            text: 'Delete',
            handler: deleteSubcategory,
          },
        ]}
      />
    </div>
  );
};

export default ListingSubcategories;
