import React from 'react';
import { IconProps } from './Icon';

const SubstanceAbuse: React.FunctionComponent<IconProps> = ({
  color,
  height,
  width,
}: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 500 500"
  >
    <g fill={color}>
      <path
        id="Selection #3"
        d="M 230.00,42.42
           C 206.57,45.75 193.94,46.76 171.00,55.42
             122.62,73.69 80.93,111.90 59.31,159.00
             18.08,248.79 44.05,353.25 124.00,412.12
             140.68,424.40 159.38,433.80 179.00,440.33
             195.37,445.78 218.78,450.79 236.00,451.00
             272.23,451.42 306.65,445.29 339.00,428.22
             356.98,418.73 376.89,404.64 390.96,390.00
             454.37,324.02 467.58,224.65 422.28,145.00
             413.51,129.57 399.93,111.15 387.00,99.09
             362.98,76.68 338.17,61.83 307.00,51.67
             285.83,44.77 252.08,39.74 230.00,42.42 Z
           M 372.00,341.00
           C 366.48,338.39 364.41,335.16 360.00,331.17
             351.80,323.75 346.02,321.71 346.00,310.00
             346.00,310.00 346.00,246.00 346.00,246.00
             346.00,246.00 304.00,246.00 304.00,246.00
             304.00,246.00 304.00,273.00 304.00,273.00
             300.64,270.48 296.03,266.03 294.59,262.00
             294.59,262.00 294.00,232.00 294.00,232.00
             294.00,232.00 347.00,232.00 347.00,232.00
             347.00,220.96 347.77,208.70 341.30,199.00
             336.97,192.51 321.65,180.58 319.60,175.00
             318.88,173.06 319.00,170.09 319.00,168.00
             319.00,168.00 319.00,149.00 319.00,149.00
             319.18,139.55 323.17,141.09 325.40,135.00
             326.40,132.27 326.00,118.81 326.00,115.00
             326.00,115.00 274.00,115.00 274.00,115.00
             274.00,118.80 273.60,132.28 274.60,135.00
             277.23,142.15 281.92,139.43 282.00,151.00
             282.00,151.00 282.00,168.00 282.00,168.00
             281.97,181.68 277.33,176.06 263.29,194.00
             254.40,205.35 254.54,210.48 252.00,224.00
             252.00,224.00 226.00,199.00 226.00,199.00
             226.00,199.00 180.00,153.00 180.00,153.00
             180.00,153.00 159.00,132.00 159.00,132.00
             159.00,132.00 148.00,119.00 148.00,119.00
             148.00,119.00 177.00,100.18 177.00,100.18
             177.00,100.18 191.00,95.66 191.00,95.66
             191.00,95.66 211.00,89.00 211.00,89.00
             211.00,89.00 219.00,89.00 219.00,89.00
             241.73,86.66 239.39,86.67 262.00,89.00
             262.00,89.00 279.00,90.53 279.00,90.53
             279.00,90.53 301.00,98.60 301.00,98.60
             301.00,98.60 322.00,106.95 322.00,106.95
             322.00,106.95 340.00,120.80 340.00,120.80
             353.87,131.85 355.94,134.95 367.28,148.00
             367.28,148.00 380.04,163.00 380.04,163.00
             380.04,163.00 396.38,199.00 396.38,199.00
             396.38,199.00 400.16,227.00 400.16,227.00
             401.08,235.56 402.10,238.87 402.00,248.00
             402.00,248.00 397.54,286.00 397.54,286.00
             397.54,286.00 389.10,314.00 389.10,314.00
             389.10,314.00 372.00,341.00 372.00,341.00 Z
           M 117.00,151.00
           C 117.00,151.00 217.00,251.00 217.00,251.00
             217.00,251.00 237.28,271.58 237.28,271.58
             245.02,279.19 249.98,280.58 250.00,292.00
             250.00,292.00 250.00,348.00 250.00,348.00
             250.02,359.52 254.36,363.86 266.00,364.00
             266.00,364.00 323.00,364.00 323.00,364.00
             331.23,364.06 334.39,367.14 337.00,375.00
             337.00,375.00 307.00,392.55 307.00,392.55
             307.00,392.55 278.00,401.69 278.00,401.69
             278.00,401.69 256.00,404.28 256.00,404.28
             256.00,404.28 242.00,405.82 242.00,405.82
             242.00,405.82 223.00,404.17 223.00,404.17
             223.00,404.17 207.00,402.49 207.00,402.49
             199.41,400.79 183.30,394.23 176.00,390.78
             169.47,387.70 162.96,385.35 157.00,381.08
             136.90,366.65 114.76,343.82 102.90,322.00
             95.85,309.04 86.02,277.67 86.00,263.00
             86.00,263.00 85.00,234.00 85.00,234.00
             85.01,224.80 87.89,214.97 90.13,206.00
             93.86,191.04 95.62,188.70 101.58,175.00
             104.89,167.38 110.69,156.34 117.00,151.00 Z
           M 128.00,220.46
           C 125.06,220.84 121.77,220.99 119.00,221.96
             105.77,226.61 98.16,240.53 98.00,254.00
             97.94,259.77 97.88,264.67 100.56,270.00
             103.10,275.08 116.28,287.28 121.00,292.00
             121.00,292.00 171.00,342.00 171.00,342.00
             175.12,346.12 190.31,361.97 194.00,364.07
             198.24,366.48 212.94,367.43 218.00,366.82
             227.90,365.61 239.70,354.19 242.89,345.00
             244.12,341.47 243.99,338.65 244.00,335.00
             244.01,330.73 244.06,323.93 242.44,320.00
             240.55,315.41 224.50,300.50 220.00,296.00
             220.00,296.00 163.00,239.00 163.00,239.00
             151.88,227.88 145.19,219.59 128.00,220.46 Z
           M 190.00,277.00
           C 190.00,277.00 217.00,304.00 217.00,304.00
             220.73,307.73 230.63,316.82 232.57,321.00
             233.76,323.58 233.83,326.25 234.34,329.00
             234.57,332.50 235.19,334.37 234.34,338.00
             232.49,349.21 220.55,355.88 210.00,356.00
             207.21,356.03 203.58,356.19 201.00,355.15
             194.88,352.68 179.36,336.36 174.00,331.00
             174.00,331.00 159.02,316.72 159.02,316.72
             157.22,315.05 154.61,312.81 155.34,310.04
             155.92,307.81 162.05,302.89 164.00,300.99
             172.43,292.76 180.34,283.83 190.00,277.00 Z"
      />
    </g>
  </svg>
);

export default SubstanceAbuse;
