import { createUseStyles } from 'react-jss';

export default createUseStyles({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '5rem',
    padding: '2rem',
  },
  button: {
    marginTop: '20px',
  },
});
